import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    HostListener,
    OnDestroy,
    Inject
} from '@angular/core';
import { CsFullScreenModalComponent } from "@app/directives";
import { mxgraph, mxgraphFactory } from "mxgraph-factory";
import { WorkflowhttpService } from "../services/workflowhttp.service";
import { MxGraphService } from "../services/mx-graph.service";
import {CsToastBoxService} from "@app/services";

const { mxGraph } = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

declare var _;

@Component({
    selector: 'app-diagram-view',
    templateUrl: './diagram-view.component.html',
    styles: []
})
export class DiagramViewComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() data:any;
    @Input() mainObjectsFields:any;
    @Input() csFullModal: CsFullScreenModalComponent;
    @Input() tags:any[] = [];
    @Input() userRoles:any[] = [];
    @Input() isMarketPlaceView:boolean=false;
    @Input() viewMode:boolean=false;
    @Input() usersList:any[] = [];
    @Input() scheduleActivityTypes:any[] = [];
    @Input() workflowType: string = 'Custom'
    @Input() whitelist: any = {};
    sourceXml:string=null;
    mxGraph: mxgraph.mxGraph;

    @ViewChild('mx_container') container: ElementRef<HTMLElement>;
    @ViewChild('outlineContainer') outlineContainer: ElementRef<HTMLElement>;
    constructor(
        public diagramService: MxGraphService,
        public httpService: WorkflowhttpService,
        @Inject('confirmationBoxHelper') public confirmationBoxHelper,
        @Inject('$scope') public ajsScope,
        public toastBox: CsToastBoxService,
    ) { }

    ngOnInit() {
        // console.log('diagram', this.mainObjectsFields);

    }

    ngAfterViewInit() {
        this.setContainerHeight();
        // console.log('tags', this.tags);
        this.diagramService.initialize(this.container, {
            xmlSource: this.data.xmlSource,
            workflowId: this.data.id,
            workflowStatus: this.data.status==1,
            isScheduler: this.data.scheduler,
            outlineContainer: this.outlineContainer,
            mainObjectsFields: this.mainObjectsFields,
            viewMode: this.viewMode,
            isMarketPlaceView: this.isMarketPlaceView,
            tags: this.tags,
            userRoles: this.userRoles,
            scheduleActivityTypes: this.scheduleActivityTypes,
            usersList: this.usersList,
            workflowType: this.workflowType,
            whitelist: this.whitelist,
            confirmationBoxHelper: this.confirmationBoxHelper,
            ajsScope: this.ajsScope
        });
        this.diagramService.setDiagramComponent(this);
        this.mxGraph = this.diagramService.getGraph();
        this.diagramService.render();
    }


    setContainerHeight() {
        setTimeout(() => {
            const height = window.innerHeight - (this.container.nativeElement.offsetTop + 10);
            this.container.nativeElement.style.minHeight= height + 'px';
        },100);
    }

    xmlValidator() {
        return true;
    }

    prepareDag() {
        let mxGraphModel = this.diagramService.getGraph().getModel();
        let rootCell = mxGraphModel.getCell('1');
        /*let dag:any = {
            'cs_initializer': []
        };
        let firstLevel = _.where(rootCell.children, {parentId: "cs_initializer"});
        if(firstLevel.length) {
            _.forEach(firstLevel, (c:mxgraph.mxCell) => {
                dag['cs_initializer'].push(c.getId());
            })
        }*/
        const getBlockObject = () => {
            if(!rootCell.children.length) {
                return [];
            }
            let vertex:any = {};
            _.chain(rootCell.children)
                .filter( (cell:mxgraph.mxCell) => {
                    return cell.isVertex();
                })
                .map( (cell:mxgraph.mxCell) => {
                    /*_.forEach(cell.edges, (e:mxgraph.mxCell) => {
                        if(_.has(e,'block_name') && e['block_name'] === "condition_edge" && e['source']['id'] == cell.getId()) {
                            const value = {
                                name: 'conditionEdge',
                                model: e.getValue(),
                                parentId: ""+cell.getId(),
                                targetId: ""+e['target']['id'],
                                id: ""+e.getId(),
                            };
                            vertex[e.getId()] = value;
                        }
                    })*/
                    return vertex[cell.getId()] = {
                        ...cell.getValue(),
                        parentId: ""+cell['parentId'],
                        id: cell.getId() === 'cs_initializer' ? 'cs_initializer' : ""+cell.getId()
                    }
                })
                .value();
            let transformVertex = _.clone(vertex);
            _.chain(transformVertex)
                .filter( (item) => _.has(item, 'targetId'))
                .map( (item) => {
                    vertex[item['targetId']]['parentId'] = ""+item.id;
                    return item;
                });
            //console.log('vertex', vertex)
            return vertex;
        };
        const processEndBlock = () => {
            if(! _.size(_dag) ) {
                return [];
            }
            let endBlocks:any[] = [];
            let arrayValues:any[] = _.flatten(Object.values(_dag));
            let arrayKeys:any[] = _.flatten(Object.keys(_dag));
            endBlocks = _.filter(arrayValues, (id) => {
                return !_.contains(arrayKeys, id);
            });
            return endBlocks;
        };

        let _dag:any = {
            'cs_initializer': []
        };
        const blocksList = getBlockObject();
        let firstChildren = _.where(blocksList, {parentId: "cs_initializer"});
        if(firstChildren.length) {
            _.forEach(firstChildren, (item:any) => {
                _dag['cs_initializer'].push(item.id);
            })
        }

        const _generateDag = (blockId:string) => {
            if(_dag[blockId].length) {
                _.forEach(_dag[blockId], (_id) => {
                    let children = _.where( Object.values(blocksList), {parentId: _id});
                    if(children.length) {
                        _dag[_id] = [];
                        _.forEach(children, (child:any) => {
                            _dag[_id].push(child.id);
                        });
                        _generateDag(_id);
                    }
                    else {
                        return;
                    }
                });
            }
        };
        _generateDag('cs_initializer');
        let airObject:any = {
            dagObject: _dag,
            blockObjects: getBlockObject(),
            endBlocks: processEndBlock()
        };
        //console.log('air', airObject)
        let mainObjectName='';
        let finalairDagMessage:any = {}, finalairEndDagMessage:any[]=[], finalairBlocksMessage:any={};
        _.forEach(airObject['dagObject'], (children, id) => {
            let dagName = id === 'cs_initializer' ? 'cs_initializer' : airObject['blockObjects'][id]['name']+'_'+id;
            finalairDagMessage[dagName] = [];
            _.forEach(children, (child_id) => {
                finalairDagMessage[dagName].push(airObject['blockObjects'][child_id]['name']+'_'+child_id);
            });
        });
        _.forEach(airObject['endBlocks'], (endId) => {
            finalairEndDagMessage.push(airObject['blockObjects'][endId]['name']+'_'+endId);
        });
        _.forEach(airObject['blockObjects'], (block, id) => {
            let _blockName = id === 'cs_initializer' ? 'cs_initializer' :  block['name'] + '_' + id;
            if(id === 'cs_initializer') {
                mainObjectName=block.mainObject;
            }
            finalairBlocksMessage[_blockName] = block;
        });
        // console.log('dagName', finalairDagMessage, finalairEndDagMessage, finalairBlocksMessage, mainObjectName);
        return {
            dagObject: finalairDagMessage,
            blockObjects: finalairBlocksMessage,
            endBlocks: finalairEndDagMessage,
            mainObjectName: mainObjectName
        };
    }

    public unSaveConfirm(close:boolean=true) {
        if(this.diagramService.unsaveConfirm) {
            this.confirmationBoxHelper
                .getConfirmation('Do you want to save your changes before close?', this.ajsScope)
                .then(() => {
                    this.saveXml(close);
                }, () => {
                    this.closeModal();
                });
        }
        else {
            this.closeModal();
        }
    }

    public getDiagramService() {
        return this.diagramService;
    }

    public saveXml(close:boolean=true) {
        if(! this.xmlValidator()) {
            return;
        }
        let dagSource = this.prepareDag();
        this.data.xmlSource = this.diagramService.getXml();
        let ObjName = dagSource.mainObjectName;
        if(this.workflowType == 'Custom')
            this.data.objectName = ObjName.charAt(0).toUpperCase() + ObjName.slice(1);
        else
            this.data.objectName = '';

        const promise = new Promise((resolve) => {
            this.httpService.updateXml({
                xmlSource:this.data.xmlSource,
                dagSource: dagSource,
                objectName: dagSource.mainObjectName,
                doRegenerateDag: false,
                // doRegenerateDag: (this.data.type == "Sequence" && this.data.status == 1 ) ? true : this.diagramService.doRegenerateDag,
            }, this.data.id).subscribe((resp) => {
                if(resp.data['newRevisionUpdated'] && resp.data['newRevisionUpdated'] > 1) {
                    this.toastBox.show('Revision '+ resp.data['newRevisionUpdated'] + ' updated.', 3000);
                }
                this.diagramService.unsaveConfirm = false;
                this.diagramService.doRegenerateDag = false;
                resolve(true);
                !close && this.httpService.triggerEvent('showActivationBtn');
                if(close)
                    this.closeModal();
            });
        });
        return promise;
    }

    closeModal() {
        this.csFullModal.close();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setContainerHeight();
    }

    ngOnDestroy() {
        this.mxGraph.destroy();
        this.data= {};
        console.log('destroyed mxgraph');
    }

    grabEvent($event:any) {
        const div:HTMLElement = document.getElementById('xyz');
       /* console.log(div.getBoundingClientRect());
        console.log($event)*/
    }
}
