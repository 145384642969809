import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CommonService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    /* Get client country code
        Response : {"countryName":"STRING<country-name>"}
    */
    getClientCountryCodes(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`get_client_country_code`, params));
    }
    /* Set new job phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    setNewJobPhoneCall(type: string, customerId: number, jobId: number, mode: string, data: object, params?: object){
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/notes/jobs/${jobId}/new_phone_call/${mode}`, params), JSON.stringify(data));
    }
    /* Set new estimate phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    setNewEstimatePhoneCall(type: string, customerId: number, estimateId: number, mode: string, data: object, params?: object){
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/notes/estimate/${estimateId}/new_phone_call/${mode}`, params), JSON.stringify(data));
    }
    /* Set new estimate phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    setNewCustomerPhoneCall(type: string, customerId: number, mode: string, data: object, params?: object){
        return this.http.post(this.getApiUrl(`${type}/new_phone_call/${customerId}/${mode}`, params), JSON.stringify(data));
    }
    /* Set new invoice phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    setNewInvoicePhoneCall(type: string, customerId: number, jobId: number,invoiceId: number, mode: string, data: object, params?: object){
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/notes/jobs/${jobId}/invoices/${invoiceId}/new_phone_call/${mode}`, params), JSON.stringify(data));
    }
    /* get infobar details from communications
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    getInfoBarDetails(Id: any, tableType: number): Observable<any> {
        return this.http.get(this.getApiUrl(`getInfoBarDetails?id=${Id}&type=${tableType}`));
    }
    /* get print and post credits */
    getCheckPrintPostCredits(): Observable<any>{
        return this.http.get(this.getApiUrl(`check_print_post_credits`));
    }
    /* process print and post */
    printAndPostService(type,id): Observable<any>{
        return this.http.get(this.getApiUrl(`process_printpost?tableType=${type}&tablePKID=${id}&type=print_post`));
    }

    saveUserNewUIPreference(data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`saveUserNewUIPreference`), data);
    }

    /* postcode details call*/
    getPostCodeService(postcode): Observable<any>{
        return this.http.get(this.getApiUrl(`search_postcode?postcode=${postcode}`))
    }
    /* Get county list */
    getCountiesList(): Observable<any>{
        return this.http.get(this.getApiUrl(`get_state_provinces`));
    }

    /*save county*/
    saveNewCounty(data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`save_province`),data);
    }

    /* save new landlords */
    saveNewLandlords(data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`landlord_create`),data);
    }

    /* save new workaddress */
    saveNewWorkaddress(id: number,type: string,data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${id}/work/new_works`), data);
    }

    /* Check unique county */
    checkUniqueCounty(province): Observable<any>{
        return this.http.get(this.getApiUrl(`is_province_exists?province=${province}`))
    }
    postNewInvoiceAddress(id: number,type: string,data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${id}/invoice_address_v2/new`),data)
    }

    getCurrentUser(): Observable<any> {
        let url = this.getApiUrl(`get_current_user`);
        return this.http.get(url);
    }

    updateNewInvoiceAddress(data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`invoiceAddressUpdate`),data);
    }

    getViewersInfo(data: object) {
        return data ? {
            imageSource: data['imageSource'] || 'images/profile_avatar_small.jpg',
            name: data['name'] || null
        } : null;
    }
}
