import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
	selector: 'app-list-creation-modal',
	templateUrl: './list-creation-modal.component.html',
	styleUrls: ['./list-creation-modal.component.scss']
})
export class ListCreationModalComponent implements OnInit {
	public onClose: Subject<{}>;
	title: string;
	select2Config: any;
	listForm: FormGroup
	suppliers: Array<any> = [];
	supplierArray: Array<any> = [];
	data: any;
	createdSection: any;
	subscriptions: any = {};
	coverPageDetails: any;
	selectedTemplate: any;
	supplierIdArray: any = [];
	loadedSuppliers: any;
	loadingSuppliers: boolean = true;
	loadingListData: boolean = true;
	loading: boolean = true;
	orderFrame: Array<any> = [
		{ 'id': 1, 'value': 'Order immediately on acceptance' },
		// { 'id': 2, 'value': 'A number of days after the proposal is accepted' }
	]
	listFormData = {
		id: '',
		listName: '',
		Suppliers: [],
		expiry: '',
		orderTimeFrame: '',
	};
	saving: boolean = false;
	constructor(
		private formBuilder: FormBuilder,
		private proposalService: ProposalTemplateService,
		public bsModalRef: BsModalRef
	) {
		this.subscriptions['sectionSubscriptions'] = this.proposalService.sectionData$.subscribe(
			data => {
				if (data) {
					this.createdSection = data
				}
			}
		)
		this.subscriptions['coverPageSubscriptions'] = this.proposalService.coverPage$.subscribe(data => {
			if (data) {
				this.coverPageDetails = data;
				if (this.coverPageDetails.hasOwnProperty('isoptionTemplate') && this.coverPageDetails['isoptionTemplate']) {
					this.selectedTemplate = 'option'
				} else if (this.coverPageDetails && this.coverPageDetails.hasOwnProperty('isopportunityTemplate') && this.coverPageDetails['isopportunityTemplate']) {
					this.selectedTemplate = 'opportunity'
				} else {
					this.selectedTemplate = 'proposal'
				}
			}
		})
	}

	ngOnInit() {
	    this.getSuppliers();
	    this.loadListData();
		this.onClose = new Subject();
		this.select2Config = {
			width: '100%'
		};
	}

	loadListData() {
        if (this.data.listId) {
            this.loadingListData = true;
            this.proposalService.getPartsListData(this.data.listId).subscribe(
                (data: any) => {
                    this.listFormData.id = data.id
                    this.listFormData.listName = data.listName
                    this.listFormData.expiry = data.expiry
                    if (data.expiry == null) {
                        this.listFormData.orderTimeFrame = '1';
                    } else {
                        this.listFormData.orderTimeFrame = '2';
                    }
                    this.loadedSuppliers = data.suppliers;
                    this.loadingListData = false;
                    this.handleFormBuildAction()
                }
            )
        } else {
            this.loadingListData = false;
            this.handleFormBuildAction();
        }
    }

    handleFormBuildAction() {
	    if(!this.loadingSuppliers && !this.loadingListData) {
            if (this.loadedSuppliers) {
                let suppliers = JSON.parse(this.loadedSuppliers);
                if (suppliers.length != 0) {
                    let supplierArray = []
                    for (let i = 0; i < suppliers.length; i++) {
                        let supplierId = suppliers[i].id;
                        this.supplierIdArray.push(parseInt(supplierId));
                        let assigneeIndex = this.suppliers.findIndex((i: any) => i.id == supplierId)
                        if (assigneeIndex != -1) {
                            supplierArray.push(`${supplierId}: '${suppliers[i]['name']}'`)
                            this.supplierArray.push({ id: supplierId, name: suppliers[i]['name'] })
                        }
                    }
                    this.listFormData.Suppliers = this.supplierIdArray;
                }
            }

	        this.loading = false;
            this.buildForm();
        }
    }

    getSuppliers() {
        this.loadingSuppliers = true;
        this.proposalService.getEstimatedSuppliers().subscribe(
            (data: any) => {
                this.suppliers = data;
                this.loadingSuppliers = false;
                this.handleFormBuildAction();
            }
        )
    }

	buildForm() {
		this.listForm = this.formBuilder.group({
			'id': [this.listFormData.id],
			'listName': [this.listFormData.listName, Validators.required],
			'Suppliers': [this.listFormData.Suppliers],
			'orderTimeFrame': [this.listFormData.orderTimeFrame, Validators.required],
			'expiry': ['']
		});
		this.listForm.get('orderTimeFrame').valueChanges
			.subscribe(value => {
				if (value == 2) {
					this.listForm.get('expiry').setValidators(Validators.required)
				} else {
					this.listForm.get('expiry').clearValidators();
				}
				this.listForm.get('expiry').updateValueAndValidity();
			});
		if (this.listFormData.orderTimeFrame == '2') {
			this.listForm.controls['expiry'].setValue(this.listFormData.expiry)
		}
		else {
			this.listForm.controls['expiry'].setValue('')
		}
        setTimeout(()=>{
            $('.suppliers').val(this.supplierIdArray).trigger('change')
        },300)

	}

	createList() {
        this.saving = true;
		if (this.listForm.value.orderTimeFrame == '1') {
			this.listForm.controls['expiry'].setValue(null)
		}
		if (this.data.sectionId == null && this.data.sectionIndex == 0 && this.createdSection && this.createdSection.hasOwnProperty('sectionId')) {
			this.data.sectionId = this.createdSection['sectionId']
		}
		let suppliers;
		if (this.supplierArray.length == 0) {
			suppliers = this.supplierArray
		} else {
			suppliers = JSON.stringify(this.supplierArray)
		}
		let listPartsData = {
			sectionData: {
				"listId": this.listForm.value.id,
				"listName": this.listForm.value.listName,
				"pageNumber": this.data.pageNumber,
				"proposalTemplatesId": this.data.proposalTemplatesId,
				"sectionId": this.data.sectionId,
				"sectionNumber": this.data.sectionId,
				"setupOptionId": this.data.setupOptionId,
				"expiry": this.listForm.value.expiry,
				"suppliers": suppliers,
                "supplierUpdated": this.supplierUpdated,
                "suppliersAdded": this.suppliersAdded
			}
		};

		this.proposalService.createPartsList(listPartsData).subscribe(
			data => {
				listPartsData.sectionData.listId = data['listId'];
				listPartsData.sectionData.sectionId = data['sectionId'];
				this.onClose.next(listPartsData.sectionData);
				this.bsModalRef.hide();
			}
		)
	}

    supplierUpdated: boolean = false;
    suppliersAdded: Array<any> = [];
	getselectedSuppliers(data) {
		let supplierArray = [];
		let supplierIdArray = [];
        if(data){
            for (var i = 0; i < data.length; i++) {
                let index = this.suppliers.findIndex((value: any) => value.id == parseInt(data[i]));
                let supplierId = this.suppliers[index]['id'];
                let supplierName = this.suppliers[index]['name'];
                supplierArray.push({
                    'id': supplierId, 'name': supplierName
                });
                let supplier = parseInt(supplierId);
                if (supplierIdArray.indexOf(supplier) == -1) {
                    supplierIdArray.push(supplier);
                }
            }
            this.supplierArray = supplierArray;
        }
		if(this.supplierIdArray.sort().join() != supplierIdArray.sort().join()) {
		    this.supplierUpdated = true;
        }
	}
	validateNumber(e: any) {
		let input = String.fromCharCode(e.charCode);
		const reg = /^\d*(?:[.,]\d{1,2})?$/;
		if (!reg.test(input)) {
		  e.preventDefault();
		}
        if(e.target.value.length >= 3){
            e.preventDefault();
        }
        if(e.target.value.length < 0 ){
            e.preventDefault();
        }
	}

}
