import { Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-setcustomfieldvalue-block',
  templateUrl: './setcustomfieldvalue-block.component.html',
  styles: ['.form-item input, .form-item select { width: 100%; }']
})
export class SetcustomfieldvalueBlockComponent implements OnInit {

    name: 'setcustomfieldvalueBlock';

    showDeleteBtn:boolean;
    activeCustomField:any;
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView: boolean = false;
    @Input() hasChild:boolean = false;
    @Input() allFields: any = {};
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() status:any;

    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();
    @Input() isReadOnlyMode:boolean = false;


    constructor(
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope) { }

    ngOnInit() {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if(this.isEdit) {
            this.activeCustomField = this.allFields[this.inputModel.model['col_name']];
        }
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    onSelectField(selected:any) {
        this.activeCustomField = this.allFields[selected];
        this.inputModel.model['templateId'] = this.activeCustomField['templateId'];
        this.inputModel.model['value'] = '';
        return;
    }

    addValue() {
        this.inputModel.model.textField = this.activeCustomField.text;
        this.outputModel.emit(this.inputModel);
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    validateItems() {
        if(!this.inputModel.model['col_name'] || !this.inputModel.model['value'] ) {
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

}
