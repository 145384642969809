import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";

declare var $;
declare  var _;

@Component({
  selector: 'app-notification-block',
  templateUrl: './notification-block.component.html',
  styles: []
})


export class NotificationBlockComponent implements OnInit {
  name:string = 'notificationBlock';
  showDeleteBtn:boolean;
  @Input() inputModel:any;
  @Input() activeField:any = {};
  @Input() isConditional:boolean = false;
  @Input() isEdit:boolean = false;
  @Input() operators:any;
  @Input() isMarketPlaceView:boolean = false;
  @Input() mainObjectName:string;
  @Input() userRoles:any[] = [];
  @Input() taggingList:any={};
  @Input() deleteMessage:string;
  @Input() warningMessage:string;
  @Input() workflowType: string = 'Custom';
  @Input() diagramOptions: any = {};
  @Input() status:any;
  @Input() isReadOnlyMode:boolean = false;

  @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
  @Output() close:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;

  select2LabelConfig: any;
  userData: any = [];
  tagsData: any = [];
  csTaggingList:any[]=[];
  editorOptions:any;
  isLoading:boolean = true;
  assignedUser:any = null;
  messageDelta:any = [];
  mentionContent:string = '';
  saving: boolean = false;

  constructor(
      private workflowService: WorkflowhttpService,
      private commonService: WorkflowCommonService,
      @Inject('confirmationBoxHelper') private confirmationBoxHelper,
      @Inject('$scope') private ajsScope,
  ) { }

  ngOnInit() {
      this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
      if(this.workflowType =='Sequence' && this.diagramOptions.mode == 'sequence_config') {
          this.showDeleteBtn = false;
      }
      const select2DisplayFormat = (item: any) => {
          return item.text;
      };
      this.select2LabelConfig = {
          width: '100%',
          data: this.userRoles,
          tags: true,
          formatSelection: select2DisplayFormat,
          formatResult: select2DisplayFormat,
          multiple: true
      }
      if(this.workflowType == 'Sequence') {
          this.mainObjectName = this.diagramOptions['sequenceObjectName'];
      }
      this.csTaggingList = this.commonService.getCsTaggingList(this.mainObjectName, this.taggingList, this.workflowType);
      this.setEditiorOptions();
      this.$resolveData();
  }

  oldData:any = null;
  onChange(event) {
      if(!event.html) {
        this.messageDelta=null;
        this.assignedUser=null;
        this.mentionContent='';
        return;
      }
      const delta = event.content;
      this.messageDelta = JSON.stringify(delta);
      this.mentionContent = event.html;
      const contentEl = $(event.html).find('[data-denotation-char="@"]');
      if(contentEl.length) {
        this.oldData = contentEl.data('id');
      }
      if(this.oldData && contentEl.length===0) {
        this.oldData = this.assignedUser = null;
      }
  }

  selectedRoles(event) {
    this.inputModel.model.roles =event;
  }

  setEditiorOptions() {
      this.editorOptions = {
          mention: {
              mentionListClass: "ql-mention-list mat-elevation-z8",
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              showDenotationChar: true,
              spaceAfterInsert: true,
              onSelect: (item: DOMStringMap, insertItem) => {
                  if(item.denotationChar === '[') {
                      item['value'] = item['value']+']';
                  }
                  insertItem(item)
                  if(item.denotationChar === '@') {
                      this.assignedUser = item.id;
                  }
              },
              mentionDenotationChars: ["@", "#", "["],
              source: (searchTerm, renderList, mentionChar) => {
                  let values = [];
                  if(mentionChar === '#') {
                      values = this.tagsData;
                  }
                  if(mentionChar === '[') {
                      values = this.csTaggingList;
                  }
                  else if(mentionChar === '@') {
                      values = this.assignedUser ? [] : this.userData;
                  }

                  if (searchTerm.length === 0) {
                      renderList(values, searchTerm)
                  }
                  else {
                      const matches = [];
                      for (let i = 0; i < values.length; i++)
                          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                            matches.push(values[i]);
                      renderList(matches, searchTerm);
                  }
              }
          },
          toolbar: false
      };
  }

    addValue() {
        this.saving = true;
        this.inputModel.model.assignTo = this.assignedUser;
        this.inputModel.model.message = this.mentionContent;
        this.inputModel.model.textMessage = this.mentionContent.replace(/(<([^>]+)>)/gi, "");
        this.inputModel.model.messageDelta = this.messageDelta;
        this.setAssignedTo(this.assignedUser);
        this.setRolesDisplay();
        // console.log('save',this.inputModel.model);
        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0)
    }

    setAssignedTo(value) {
        const dupUserData = Object.assign([], this.userData);
        let objectUser = _.indexBy(dupUserData, 'id');
        let selectedData = objectUser[value];
        return this.inputModel.model.assignToDisplay = selectedData ? selectedData['username'] : null;
    }

    setRolesDisplay() {
        const tmpRoles = Object.assign([], this.userRoles);
        let tmpRoleIds = _.indexBy(tmpRoles, 'id');
        this.inputModel.model.rolesDisplay = [];
        _.forEach(this.inputModel.model.roles, (id) => {
            this.inputModel.model.rolesDisplay.push(tmpRoleIds[id].text);
        });
    }

  closeModal() {
    this.close.emit(this.isEdit);
  }

  getUser() {
    const promise = new Promise( (resolve) => {
      this.workflowService.getUsersList()
        .subscribe(resp => {
            this.userData = resp['users'].map( (item) => {
                return { id: item.id, value: item.username, username: item.username }
            });
            resolve();
        });
    });
    return promise;
  }

  getTags() {
    const promise = new Promise( (resolve) => {
      this.workflowService.getHashTagsList()
        .subscribe(resp => {
            this.tagsData = resp['hashtags'].map( (item) => {
                return { id: item.id, value: item.description }
            });
            resolve();
        });
    });
    return promise;
  }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

  async $resolveData() {
    this.isLoading = true;
    await this.getUser();
    await this.getTags();
    this.isLoading = false;
    if(this.isEdit) {
      const {assignTo, messageDelta} = this.inputModel.model;
      this.assignedUser = messageDelta ? assignTo : null;
      this.messageDelta = messageDelta ? JSON.parse(messageDelta) : [];
      this.editor.quillEditor.setContents(this.messageDelta);
    }
  }
}
