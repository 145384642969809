import { OnInit } from '@angular/core';
import { ArticleEditorService } from '../article-editor.service';
import { BsModalRef } from 'ngx-bootstrap';
import { ImageBlocksComponent } from '../image-blocks/image-blocks.component';
import { SidepanelModalService } from '@app/sidepanel/services/sidepanel-modal.service';
import { Subject } from "rxjs";
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { Router } from "@angular/router";
var FormattingOptionsComponent = /** @class */ (function () {
    function FormattingOptionsComponent(articleService, modalService, bsModalRef, proposaltemplateservice, router) {
        this.articleService = articleService;
        this.modalService = modalService;
        this.bsModalRef = bsModalRef;
        this.proposaltemplateservice = proposaltemplateservice;
        this.router = router;
        this.options = {
            type: "component",
            showPalette: false,
            hideAfterPaletteSelect: true,
            showButtons: false,
            allowEmpty: true,
            preferredFormat: "hex",
            containerClassName: 'container-palette',
            showInput: true,
        };
        this.border_options = {
            type: "component",
            showPalette: false,
            hideAfterPaletteSelect: true,
            showButtons: false,
            allowEmpty: true,
            preferredFormat: "hex",
            containerClassName: 'container-palette',
            showInput: true,
        };
        this.displayOption = false;
        this.position_left = '';
        this.element_width = '';
        this.border_radius = '';
        this.border_width = '0';
        this.border_color = '';
        this.border_style = '';
        this.padding = '';
        this.margin = '';
        this.background_url = '';
        this.background_color = '';
        this.border_width_dir = {
            open: false,
            left: '',
            right: '',
            top: '',
            bottom: ''
        };
        this.border_radius_dir = {
            open: false,
            left: '',
            right: '',
            top: '',
            bottom: '',
            properties: ['border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius'],
            topLeft: '',
            topRight: '',
            bottomLeft: '',
            bottomRight: '',
        };
        this.margin_dir = {
            open: false,
            left: '',
            right: '',
            top: '',
            bottom: ''
        };
        this.padding_dir = {
            open: false,
            left: '',
            right: '',
            top: '',
            bottom: ''
        };
        this.block_size = {
            width: 0,
            height: 0
        };
        this.restoreEle = {};
        this.hasShowSize = false;
        this.colorPicker = false;
        this.imageStyling = false;
        this.marginError = false;
        this.paddingError = false;
        this.parentElement = 0;
        this.transferToJob = false;
        this.displayArray = [];
        this.existingOptionId = null;
        this.existingAddonId = null;
        this.saveButton = false;
        this.maximumWidth = false;
        this.marginDown = false;
        this.paddingDown = false;
        this.maximumHeight = false;
        this.heightDown = false;
        this.optionData = [];
        this.addonData = [];
        this.jobNotesValue = '-- Please choose --';
        this.savedData = [];
        this.closeDropdown = false;
        this.screenType = '';
    }
    FormattingOptionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        var that = this;
        this.textElementBackgroundImage = this.textElementBg;
        var url = this.router.url.split('/');
        this.screenType = url[url.length - 1];
        if (url.includes('option_template')) {
            this.screenType = 'option_template';
        }
        else if (url.includes('proposal_templates')) {
            this.screenType = 'proposal_templates';
        }
        else if (url.includes('opportunity')) {
            this.screenType = 'opportunity';
        }
        else if (url.includes('content_library')) {
            this.screenType = 'content_library';
        }
        else if (url.includes('addon_template')) {
            this.screenType = 'addon_template';
        }
        if (this.advanceStyle == "imageStyling") {
            this.imageStyling = true;
            this.displayOption = true;
        }
        else if (this.advanceStyle == 'transferToJob') {
            this.transferToJob = true;
            this.jobNotes = this.textElementBg;
        }
        else {
            var backgroundColor = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('background-color');
            if (backgroundColor && backgroundColor.includes('rgba')) {
                this.changedBackgroundColor = that.hexCode(backgroundColor);
            }
            else if (backgroundColor && backgroundColor.includes('rgb')) {
                this.changedBackgroundColor = that.rgbToHex(backgroundColor);
            }
            else {
                this.changedBackgroundColor = 'No background';
            }
            var borderColor = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-color');
            var borderThickness = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-width');
            this.changedBorderColor = that.rgbToHex(borderColor);
            if (this.changedBorderColor == '#595B65' || this.changedBorderColor == "#595b65" || this.changedBorderColor == '#505b65' && borderThickness == '0px') {
                this.changedBorderColor = 'No border';
                this.borderColorStyle = '#ffffff';
            }
            else {
                this.borderColorStyle = this.changedBorderColor;
            }
            var marginBlock = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            this.pageType = $(marginBlock).parents('div.cs_page').attr('data-page-type');
        }
        this.articleService.blockSelected.subscribe(function (res) {
            if (res) {
                var block;
                var marginBlock = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                if (_this.imageStyling) {
                    block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                }
                else {
                    block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                }
                // var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                _this.hasShowSize = block.closest('.row-item').length === 0 ? true : false;
                if (block && block.length > 0) {
                    that.displayOption = true;
                    that.background_url = block.css('background-image');
                    $.fn.inlineStyle = function (prop) {
                        var styles = this.attr("style"), value;
                        styles && styles.split(";").forEach(function (e) {
                            var style = e.split(":");
                            if ($.trim(style[0]) === prop) {
                                value = style[1];
                            }
                        });
                        return value;
                    };
                    that.position_left = block.inlineStyle('left');
                    if (typeof that.position_left == 'undefined') {
                        that.position_left = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'left': that.position_left });
                    }
                    _this.restoreEle.position_left = that.position_left.trim();
                    that.element_width = marginBlock.inlineStyle('width');
                    if (typeof that.element_width == 'undefined') {
                        that.element_width = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').css({ 'width': that.element_width });
                    }
                    _this.restoreEle.element_width = that.element_width.trim();
                    _this.restoreEle.element_width = _this.restoreEle.element_width.replace(' !important', '');
                    that.element_height = block.inlineStyle('height');
                    if (typeof that.element_height == 'undefined') {
                        that.element_height = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'height': that.element_height });
                    }
                    _this.restoreEle.element_height = that.element_height.trim();
                    _this.restoreEle.element_height = _this.restoreEle.element_height.replace(' !important', '');
                    that.border_color = block.inlineStyle('border-color');
                    if (typeof that.border_color == 'undefined') {
                        that.border_color = '';
                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'border-color': that.border_color });
                    }
                    _this.restoreEle.border_color = that.border_color;
                    that.background_color = block.inlineStyle('background-color');
                    if (typeof that.background_color == 'undefined') {
                        that.background_color = '';
                    }
                    _this.restoreEle.background_color = that.background_color;
                    that.border_style = block.css('border-style');
                    if (that.border_style == 'none') {
                        that.border_style = 'solid';
                        _this.setStyle(that.border_style);
                    }
                    _this.restoreEle.border_style = that.border_style;
                    if (block.inlineStyle('--allsideborderradius')) {
                        _this.border_radius_dir.topLeft = (block[0].style.borderTopLeftRadius) ? _this.removePixel(block[0].style.borderTopLeftRadius) : '0';
                        _this.border_radius_dir.topRight = (block[0].style.borderTopRightRadius) ? _this.removePixel(block[0].style.borderTopRightRadius) : 0;
                        _this.border_radius_dir.bottomLeft = (block[0].style.borderBottomLeftRadius) ? _this.removePixel(block[0].style.borderBottomLeftRadius) : 0;
                        _this.border_radius_dir.bottomRight = (block[0].style.borderBottomRightRadius) ? _this.removePixel(block[0].style.borderBottomRightRadius) : 0;
                        $('#border-radius-icon').addClass('selected-style');
                        _this.border_radius_dir.open = true;
                        _this.restoreEle.border_radius_dir = JSON.parse(JSON.stringify(that.border_radius_dir));
                        that.border_radius = 0;
                    }
                    else {
                        that.border_radius = block[0].style.borderRadius.replaceAll('px', '');
                        if (!that.border_radius || that.border_radius == '') {
                            that.border_radius = 0;
                        }
                        _this.restoreEle.border_radius = that.border_radius;
                        _this.border_radius_dir.open = false;
                        _this.restoreEle.border_radius_dir = JSON.parse(JSON.stringify(that.border_radius_dir));
                        $('#border-radius-icon').removeClass('selected-style');
                    }
                    if (block.inlineStyle('--allsideborderwidth')) {
                        _this.border_width_dir.left = (block[0].style.borderLeftWidth) ? _this.removePixel(block[0].style.borderLeftWidth) : 0;
                        _this.border_width_dir.top = (block[0].style.borderTopWidth) ? _this.removePixel(block[0].style.borderTopWidth) : 0;
                        _this.border_width_dir.right = (block[0].style.borderRightWidth) ? _this.removePixel(block[0].style.borderRightWidth) : 0;
                        _this.border_width_dir.bottom = (block[0].style.borderBottomWidth) ? _this.removePixel(block[0].style.borderBottomWidth) : 0;
                        $('#border-width-icon').addClass('selected-style');
                        _this.border_width_dir.open = true;
                        that.border_width = 0;
                        _this.restoreEle.border_width_dir = JSON.parse(JSON.stringify(that.border_width_dir));
                    }
                    else {
                        that.border_width = block[0].style.borderWidth.replaceAll('px', '');
                        if (!that.border_width || that.border_width == '') {
                            that.border_width = 0;
                            $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-width', '0px');
                        }
                        _this.border_width_dir.open = false;
                        _this.restoreEle.border_width_dir = JSON.parse(JSON.stringify(that.border_width_dir));
                        _this.restoreEle.border_width = that.border_width;
                        $('#border-width-icon').removeClass('selected-style');
                    }
                    if (marginBlock.inlineStyle('--allsidepadding')) {
                        _this.padding_dir.left = (marginBlock[0].style.paddingLeft) ? _this.removePixel(marginBlock[0].style.paddingLeft) : 0;
                        _this.padding_dir.top = (marginBlock[0].style.paddingTop) ? _this.removePixel(marginBlock[0].style.paddingTop) : 0;
                        _this.padding_dir.right = (marginBlock[0].style.paddingRight) ? _this.removePixel(marginBlock[0].style.paddingRight) : 0;
                        _this.padding_dir.bottom = (marginBlock[0].style.paddingBottom) ? _this.removePixel(marginBlock[0].style.paddingBottom) : 0;
                        $('#padding-icon').addClass('selected-style');
                        _this.padding_dir.open = true;
                        _this.restoreEle.padding_dir = JSON.parse(JSON.stringify(that.padding_dir));
                        that.padding = 0;
                    }
                    else {
                        that.padding = marginBlock[0].style.padding.replaceAll('px', '');
                        if (!that.padding || that.padding == '') {
                            that.padding = 0;
                        }
                        _this.restoreEle.padding = that.padding;
                        _this.padding_dir.open = false;
                        _this.restoreEle.padding_dir = JSON.parse(JSON.stringify(that.padding_dir));
                        $('#padding-icon').removeClass('selected-style');
                    }
                    if (_this.pageType == 'ECP') {
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                    }
                    if (block.inlineStyle('--allsidemargin') || _this.pageType == 'ECP') {
                        that.margin_dir.open = true;
                        that.margin_dir.left = (block[0].style.marginLeft) ? _this.removePixel(block[0].style.marginLeft) : 0;
                        that.margin_dir.right = (block[0].style.marginRight) ? _this.removePixel(block[0].style.marginRight) : 0;
                        if (_this.pageType == 'ECP') {
                            that.margin_dir.top = (block[0].style.marginTop) ? _this.removePixel(block[0].style.marginTop) : 10;
                            that.margin_dir.bottom = (block[0].style.marginBottom) ? _this.removePixel(block[0].style.marginBottom) : 10;
                        }
                        else {
                            that.margin_dir.top = (block[0].style.marginTop) ? _this.removePixel(block[0].style.marginTop) : 0;
                            that.margin_dir.bottom = (block[0].style.marginBottom) ? _this.removePixel(block[0].style.marginBottom) : 0;
                        }
                        $('#margin-icon').addClass('selected-style');
                        _this.margin_dir.open = true;
                        _this.restoreEle.margin_dir = JSON.parse(JSON.stringify(that.margin_dir));
                        that.margin = 0;
                    }
                    else {
                        that.margin_dir.open = false;
                        that.margin = marginBlock[0].style.margin.replaceAll('px', '');
                        if (!that.margin || that.margin == '') {
                            that.margin = 0;
                        }
                        _this.restoreEle.margin_dir = JSON.parse(JSON.stringify(that.margin_dir));
                        _this.restoreEle.margin = that.margin;
                        $('#margin-icon').removeClass('selected-style');
                    }
                    that.block_size.width = Math.round(marginBlock.css('width').replace('px', ''));
                    that.block_size.height = Math.round(block.css('height').replace('px', ''));
                }
            }
            else {
                that.border_radius = '';
                that.background_url = '';
                that.options['color'] = '#ffffff';
                that.border_options['color'] = '#ffffff';
                that.displayOption = false;
                that.background_url = '';
                that.border_color = '';
                that.border_width = '';
                that.border_style = '';
                that.padding = '';
                that.margin = '';
                $('#blocks-background-color').spectrum('destroy');
                $('#blocks-border-color').spectrum('destroy');
            }
        });
        this.csPageWidth = $($('#cs_editor').contents().find('.cs_page').get(0)).outerWidth();
        if (this.transferToJob) {
            var data = {
                'proposalId': this.proposalId
            };
            this.proposaltemplateservice.getJobNotesOptionAddonDetails(data).subscribe(function (data) {
                if (data) {
                    var value = data;
                    for (var i = 0; i < value.length; i++) {
                        _this.savedData.push(value[i]);
                    }
                }
            });
            this.proposaltemplateservice.getOptionAddonsDetails(this.screenName, this.proposalId, this.optionId).subscribe(function (data) {
                if (data && data.length) {
                    _this.displayArray = data;
                    for (var i = 0; i < _this.displayArray.length; i++) {
                        _this.displayArray[i]['displayName'] = "If " + _this.displayArray[i]['name'] + " chosen by customer";
                    }
                }
                _this.displayArray.unshift({
                    'id': _this.optionId,
                    'name': _this.optionName,
                    'type': 'option',
                    'displayName': "If " + _this.optionName + " chosen by customer"
                });
                if (_this.existingOptionId) {
                    for (var i = 0; i < _this.displayArray.length; i++) {
                        if (_this.displayArray[i].id == _this.existingOptionId) {
                            var value = _this.displayArray[i].id + '+' + _this.displayArray[i].type;
                            var selectedValue = _this.displayArray[i].displayName;
                            _this.jobNotesValue = selectedValue;
                            _this.selectedId = '#' + _this.displayArray[i].id + 'option';
                            _this.selectedTemplateType = 'option';
                            _this.selectedTemplateId = '';
                            setTimeout(function () {
                                $(_this.selectedId).addClass('dropdown-item-focus');
                            }, 500);
                        }
                    }
                }
                if (_this.existingAddonId) {
                    for (var i = 0; i < _this.displayArray.length; i++) {
                        if (_this.displayArray[i].id == _this.existingAddonId) {
                            var value = _this.displayArray[i].id + '+' + _this.displayArray[i].type;
                            var selectedValue = _this.displayArray[i].displayName;
                            _this.jobNotesValue = selectedValue;
                            _this.selectedId = '#' + _this.displayArray[i].id + 'addon';
                            _this.selectedTemplateType = 'addon';
                            _this.selectedTemplateId = _this.displayArray[i].id;
                            setTimeout(function () {
                                $(_this.selectedId).addClass('dropdown-item-focus');
                            }, 500);
                        }
                    }
                }
            });
        }
        setTimeout(function () {
            if ($('#cs_editor').contents().find('div.cs_block_s.cs_selected').parents('.cs_page').attr('name') == 'header_page') {
                $('.showPadding').show();
                $('.showMargin').show();
            }
            else {
                $('.showPadding').hide();
                $('.showMargin').hide();
            }
        }, 100);
    };
    FormattingOptionsComponent.prototype.ngOnDestroy = function () {
        $('#blocks-background-color').spectrum('destroy');
        $('#blocks-border-color').spectrum('destroy');
    };
    FormattingOptionsComponent.prototype.refreshSpectrum = function (id) {
        for (var index = 0; index < 3; index++) {
            $(id).trigger('change');
        }
    };
    // convert rgb to hex
    FormattingOptionsComponent.prototype.componentFromStr = function (numStr, percent) {
        var num = Math.max(0, parseInt(numStr, 10));
        return percent ?
            Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
    };
    FormattingOptionsComponent.prototype.removePixel = function (px) {
        return px.replaceAll('px', '');
    };
    FormattingOptionsComponent.prototype.rgbToHex = function (rgb) {
        if (rgb == "rgb(255, 255, 255)") {
            hex = 'No Background';
            return hex;
        }
        rgb = rgb.trim();
        var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
        var result, r, g, b, hex = "";
        if ((result = rgbRegex.exec(rgb))) {
            r = this.componentFromStr(result[1], result[2]);
            g = this.componentFromStr(result[3], result[4]);
            b = this.componentFromStr(result[5], result[6]);
            hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }
        return hex;
    };
    FormattingOptionsComponent.prototype.hexCode = function (color) {
        if (color == 'rgba(0, 0, 0, 0)') {
            var hex = 'No background';
            return hex;
        }
        else {
            var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
            var hex = "#" + ((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
                .toString(16).slice(1);
            if (rgba[3]) {
                var alpha = Math.round(rgba[3] * 255);
                var hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
                hex += hexAlpha;
            }
            return hex;
        }
    };
    FormattingOptionsComponent.prototype.setstyledesc = function (block) {
        this.padding_dir.left = block.css('padding');
    };
    FormattingOptionsComponent.prototype.setRadius = function (value, key) {
        var that = this;
        var deleteEle = [];
        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').each(function () {
            if (key != 'border-radius') {
                this.style.setProperty([key], value + "px", 'important');
                this.style.setProperty('--allsideborderradius', "0");
                that.border_radius = 0;
            }
            else {
                deleteEle = ['--allsideborderradius', 'border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius'];
                that.removeStyling(deleteEle);
                that.border_radius_dir.left = 0;
                that.border_radius_dir.right = 0;
                that.border_radius_dir.bottom = 0;
                that.border_radius_dir.top = 0;
                this.style.setProperty([key], value + "px", 'important');
            }
            if (value == null) {
                deleteEle = [key];
                that.removeStyling(deleteEle);
            }
        });
    };
    FormattingOptionsComponent.prototype.setSizing = function (value, key) {
        var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
        var pageWidth = parseInt(block.parents('.cs_page').css('width'));
        var left = parseInt(block.css('left'));
        var maxWidth = pageWidth - left;
        var pageHeight = parseInt(block.parents('.cs_page').css('height')) - 10;
        var top = parseInt(block.css('top')) + 10;
        var elementHeight = parseInt(block.css('height'));
        var maxHeight = pageHeight - top;
        if (value) {
            if (key == 'width') {
                var right = Math.round(block.css('right').replace('px', ''));
                if (value < maxWidth) {
                    $('#cs_editor').contents().find('div.cs_block_s.cs_selected').each(function () {
                        this.style.setProperty([key], value + "px", 'important');
                    });
                    this.maximumWidth = false;
                }
                else {
                    this.maximumWidth = true;
                }
            }
            else {
                if (value < maxHeight) {
                    $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').each(function () {
                        this.style.setProperty([key], value + "px", 'important');
                    });
                    this.maximumHeight = false;
                }
                else {
                    this.maximumHeight = true;
                }
            }
        }
    };
    FormattingOptionsComponent.prototype.setWidth = function (value, key) {
        var that = this;
        var deleteEle = [];
        if (!value) {
            value = 0;
        }
        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').each(function () {
            if (key != 'border-width') {
                this.style.setProperty([key], value + "px", 'important');
                this.style.setProperty('--allsideborderwidth', "0");
                that.border_width = 0;
            }
            else {
                deleteEle = ['--allsideborderwidth', 'border-left-width', 'border-right-width', 'border-bottom-width', 'border-top-width'];
                that.removeStyling(deleteEle);
                that.border_width_dir.left = 0;
                that.border_width_dir.right = 0;
                that.border_width_dir.bottom = 0;
                that.border_width_dir.top = 0;
                this.style.setProperty([key], value + "px", 'important');
            }
            if (value == null) {
                deleteEle = [key];
                that.removeStyling(deleteEle);
            }
        });
    };
    FormattingOptionsComponent.prototype.setStyle = function (value) {
        if (value) {
            var block;
            if (this.imageStyling) {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            else {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
            }
            block.each(function () {
                this.style.setProperty('border-style', "" + value, 'important');
            });
        }
    };
    FormattingOptionsComponent.prototype.setMargin = function (value, key) {
        var that = this;
        var deleteEle = [];
        var block;
        var content = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
        var pageWidth = parseInt(content.parents('.cs_page').css('width'));
        var elementWidth = parseInt(content.css('width'));
        var left = parseInt(content.css('left'));
        var marginTop = parseInt(content.css('margin-top'));
        var marginLeft = parseInt(content.css('margin-left'));
        var maxWidth = pageWidth - (left + marginLeft);
        var pageHeight = parseInt(content.parents('.cs_page').css('height')) - 10;
        var top = parseInt(content.css('top')) + marginTop;
        var elementHeight = parseInt(content.css('height'));
        var maxHeight = pageHeight - top;
        if (value >= 0) {
            if (this.imageStyling || this.pageType == 'ECP') {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            else {
                // block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            var limit_1 = false;
            block.each(function () {
                if (key != 'margin') {
                    this.style.setProperty([key], value + "px", 'important');
                    this.style.setProperty('--allsidemargin', "0");
                    that.margin = 0;
                    limit_1 = false;
                }
                else {
                    deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                    that.removeStyling(deleteEle, true);
                    that.margin_dir.left = 0;
                    that.margin_dir.right = 0;
                    that.margin_dir.bottom = 0;
                    that.margin_dir.top = 0;
                    this.style.setProperty([key], value + "px", 'important');
                }
                pageWidth = parseInt(content.parents('.cs_page').css('width'));
                elementWidth = parseInt(content.css('width'));
                left = parseInt(content.css('left'));
                marginTop = parseInt(content.css('margin-top'));
                marginLeft = parseInt(content.css('margin-left'));
                maxWidth = pageWidth - (left + marginLeft);
                pageHeight = parseInt(content.parents('.cs_page').css('height')) - 10;
                top = parseInt(content.css('top')) + marginTop;
                elementHeight = parseInt(content.css('height'));
                maxHeight = pageHeight - top;
                if (elementWidth > maxWidth || elementHeight > maxHeight) {
                    deleteEle = [key];
                    that.removeStyling(deleteEle, true);
                    limit_1 = true;
                }
                else {
                    limit_1 = false;
                }
            });
            if (limit_1) {
                this.marginError = true;
            }
            else {
                this.marginError = false;
            }
        }
        else {
            deleteEle = [key];
            that.removeStyling(deleteEle, true);
        }
    };
    FormattingOptionsComponent.prototype.setPadding = function (value, key) {
        var that = this;
        var deleteEle = [];
        var content = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
        var pageWidth = parseInt(content.parents('.cs_page').css('width'));
        var elementWidth = parseInt(content.css('width'));
        var left = parseInt(content.css('left'));
        var maxWidth = pageWidth - left;
        var pageHeight = parseInt(content.parents('.cs_page').css('height')) - 10;
        var top = parseInt(content.css('top'));
        var elementHeight = parseInt(content.css('height'));
        var maxHeight = pageHeight - top;
        if (value >= 0) {
            var block;
            if (this.imageStyling) {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            else {
                // block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            if (value < 0) {
                this.paddingError = true;
                value = 0;
            }
            else {
                this.paddingError = false;
            }
            var limit_2 = false;
            block.each(function () {
                if (key != 'padding') {
                    this.style.setProperty([key], value + "px", 'important');
                    this.style.setProperty('--allsidepadding', "0");
                    that.padding = 0;
                }
                else {
                    deleteEle = ['--allsidepadding', 'padding-left', 'padding-right', 'padding-bottom', 'padding-top'];
                    that.removeStyling(deleteEle, true);
                    that.margin_dir.left = 0;
                    that.margin_dir.right = 0;
                    that.margin_dir.bottom = 0;
                    that.margin_dir.top = 0;
                    this.style.setProperty([key], value + "px", 'important');
                }
                pageWidth = parseInt(content.parents('.cs_page').css('width'));
                elementWidth = parseInt(content.css('width'));
                left = parseInt(content.css('left'));
                maxWidth = pageWidth - left;
                pageHeight = parseInt(content.parents('.cs_page').css('height')) - 10;
                top = parseInt(content.css('top'));
                elementHeight = parseInt(content.css('height'));
                maxHeight = pageHeight - top;
                if ((elementWidth > maxWidth || elementHeight > maxHeight)) {
                    deleteEle = [key];
                    that.removeStyling(deleteEle, true);
                    limit_2 = true;
                }
                else {
                    limit_2 = false;
                }
            });
            if (limit_2) {
                this.paddingError = true;
            }
            else {
                this.paddingError = false;
            }
        }
        else {
            deleteEle = [key];
            that.removeStyling(deleteEle, true);
        }
    };
    FormattingOptionsComponent.prototype.removeStyling = function (deleteEle, isMarginOrPadding) {
        if (isMarginOrPadding === void 0) { isMarginOrPadding = false; }
        var block;
        if (this.imageStyling) {
            block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
        }
        else {
            block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
        }
        block.each(function () {
            var styleEle = this;
            deleteEle.forEach(function (val) {
                styleEle.style.removeProperty("" + val);
            });
        });
    };
    FormattingOptionsComponent.prototype.toggle = function (option) {
        switch (option) {
            case 'border_width':
                if (this.border_width_dir.open) {
                    this.border_width = 0;
                    this.border_width_dir.open = false;
                    $('#border-width-icon').removeClass('selected-style');
                }
                else {
                    this.border_width = 0;
                    $('#border-width-icon').addClass('selected-style');
                    this.border_width_dir.open = true;
                    var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                    block[0].style.removeProperty('border-width');
                    block[0].style.borderWidth = '0px';
                    this.border_width_dir.left = 0;
                    this.border_width_dir.top = 0;
                    this.border_width_dir.right = 0;
                    this.border_width_dir.bottom = 0;
                }
                break;
            case 'border_radius':
                if (this.border_radius_dir.open) {
                    this.border_radius_dir.open = false;
                    this.border_radius = 0;
                    $('#border-radius-icon').removeClass('selected-style');
                }
                else {
                    this.border_radius = 0;
                    var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                    block[0].style.removeProperty('border-radius');
                    $('#border-radius-icon').addClass('selected-style');
                    this.border_radius_dir.open = true;
                    this.border_radius_dir.topLeft = 0;
                    this.border_radius_dir.topRight = 0;
                    this.border_radius_dir.bottomLeft = 0;
                    this.border_radius_dir.bottomRight = 0;
                }
                break;
            case 'padding':
                if (this.padding_dir.open) {
                    this.padding_dir.open = false;
                    this.padding = 0;
                    $('#padding-icon').removeClass('selected-style');
                }
                else {
                    this.padding = 0;
                    var block;
                    if (this.imageStyling) {
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                    }
                    else {
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                    }
                    // var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    block[0].style.removeProperty('padding');
                    $('#padding-icon').addClass('selected-style');
                    this.padding_dir.open = true;
                    this.padding_dir.left = 0;
                    this.padding_dir.top = 0;
                    this.padding_dir.right = 0;
                    this.padding_dir.bottom = 0;
                }
                break;
            case 'margin':
                if (this.margin_dir.open) {
                    this.margin_dir.open = false;
                    this.margin = 0;
                    $('#margin-icon').removeClass('selected-style');
                }
                else {
                    var block;
                    if (this.imageStyling) {
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                    }
                    else {
                        block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
                    }
                    // var block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first')
                    block[0].style.removeProperty('margin');
                    $('#margin-icon').addClass('selected-style');
                    this.margin = 0;
                    this.margin_dir.open = true;
                    this.margin_dir.left = 0;
                    this.margin_dir.top = 0;
                    this.margin_dir.right = 0;
                    this.margin_dir.bottom = 0;
                }
                break;
        }
    };
    FormattingOptionsComponent.prototype.addBorder = function (e) {
        $(e.target).parent().find('.input-groups-text').addClass('input-group-border');
    };
    FormattingOptionsComponent.prototype.removeBorder = function (e) {
        $(e.target).parent().find('.input-groups-text').removeClass('input-group-border');
    };
    FormattingOptionsComponent.prototype.openImages = function () {
        var initialState = {
            title: 'upload.background.image.title.text',
            option: 'elementBackground',
            open: true,
            backgroundImage: true
        };
        this.bsModalRef = this.modalService.show(ImageBlocksComponent, {
            initialState: initialState
        });
        this.bsModalRef.content.closeBtnName = 'Close';
        this.bsModalRef.content.onClose.subscribe(function (result) {
            if (result) {
                var element = $("#cs_editor").contents().find("div.cs_selected").not('div.cs_page');
                if (element && element.length) {
                    $(element).css({
                        backgroundImage: "url(" + result + ")", backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: ''
                    });
                }
            }
        });
    };
    FormattingOptionsComponent.prototype.backgroundColor = function () {
    };
    FormattingOptionsComponent.prototype.borderColor = function () {
    };
    FormattingOptionsComponent.prototype.onScrollHideColourPalate = function (e) {
        $('.container-palette').addClass('sp-hidden');
    };
    FormattingOptionsComponent.prototype.rgbaToHex = function (r, g, b, a) {
        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];
        // Pad single-digit output values
        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        });
        return ('#' + outParts.join(''));
    };
    FormattingOptionsComponent.prototype.cancel = function () {
        if (!this.transferToJob) {
            var block;
            var marginBlock = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            if (this.imageStyling) {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            else {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
            }
            // let block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first');
            block.css({ 'background-color': this.restoreEle.background_color });
            block.css({ 'border-color': this.restoreEle.border_color });
            block.css('border-style', this.restoreEle.border_style, 'important');
            var pageType = $(marginBlock).parents('div.cs_page').attr('data-page-type');
            marginBlock[0].style.setProperty('width', this.restoreEle.element_width == '' ? pageType != 'ECP' ? 384 : '' : this.restoreEle.element_width);
            block[0].style.setProperty('left', this.restoreEle.position_left);
            block[0].style.setProperty('height', this.restoreEle.element_height);
            // border radius
            if (this.restoreEle.border_radius_dir.open == true) {
                var deleteEle = ['border-radius'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('--allsideborderradius', "0");
                block[0].style.setProperty('border-top-left-radius', this.restoreEle.border_radius_dir.topLeft + 'px');
                block[0].style.setProperty('border-top-right-radius', this.restoreEle.border_radius_dir.topRight + 'px');
                block[0].style.setProperty('border-bottom-left-radius', this.restoreEle.border_radius_dir.bottomLeft + 'px');
                block[0].style.setProperty('border-bottom-right-radius', this.restoreEle.border_radius_dir.bottomRight + 'px');
            }
            else {
                var deleteEle = ['--allsideborderradius', 'border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('border-radius', this.restoreEle.border_radius);
            }
            // border width
            if (this.restoreEle.border_width_dir.open == true) {
                var deleteEle = ['border-width'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('--allsideborderwidth', "0");
                block[0].style.setProperty('border-left-width', this.restoreEle.border_width_dir.left + 'px');
                block[0].style.setProperty('border-right-width', this.restoreEle.border_width_dir.right + 'px');
                block[0].style.setProperty('border-bottom-width', this.restoreEle.border_width_dir.bottom + 'px');
                block[0].style.setProperty('border-top-width', this.restoreEle.border_width_dir.top + 'px');
            }
            else {
                var deleteEle = ['--allsideborderwidth', 'border-left-width', 'border-right-width', 'border-bottom-width', 'border-top-width'];
                this.removeStyling(deleteEle);
                block[0].style.setProperty('border-width', this.restoreEle.border_width);
            }
            // margin
            if (this.pageType == 'ECP') {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
            }
            if (this.restoreEle.margin_dir.open == true) {
                var deleteEle = ['margin'];
                this.removeStyling(deleteEle, true);
                marginBlock[0].style.setProperty('--allsidemargin', "0");
                marginBlock[0].style.setProperty('margin-left', this.restoreEle.margin_dir.left);
                marginBlock[0].style.setProperty('margin-right', this.restoreEle.margin_dir.right);
                marginBlock[0].style.setProperty('margin-bottom', this.restoreEle.margin_dir.bottom);
                marginBlock[0].style.setProperty('margin-top', this.restoreEle.margin_dir.top);
            }
            else {
                var deleteEle = ['--allsidemargin', 'margin-left', 'margin-right', 'margin-bottom', 'margin-top'];
                this.removeStyling(deleteEle, true);
                if (this.restoreEle.margin != '') {
                    marginBlock[0].style.setProperty('margin', this.restoreEle.margin);
                }
            }
            // padding
            if (this.restoreEle.padding_dir.open == true) {
                block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                var deleteEle = ['padding'];
                this.removeStyling(deleteEle, true);
                block[0].style.setProperty('--allsidepadding', "0");
                block[0].style.setProperty('padding-left', this.restoreEle.padding_dir.left, 'important');
                block[0].style.setProperty('padding-right', this.restoreEle.padding_dir.right, 'important');
                block[0].style.setProperty('padding-bottom', this.restoreEle.padding_dir.bottom, 'important');
                block[0].style.setProperty('padding-top', this.restoreEle.padding_dir.top, 'important');
            }
            else {
                var deleteEle = ['--allsidepadding', 'padding-left', 'padding-right', 'padding-bottom', 'padding-top'];
                this.removeStyling(deleteEle, true);
                if (this.restoreEle.padding != '') {
                    block = $('#cs_editor').contents().find('div.cs_block_s.cs_selected');
                    block[0].style.setProperty('padding', this.restoreEle.padding, 'important');
                }
            }
        }
        this.proposaltemplateservice.saveContentLibrary(true);
        this.bsModalRef.hide();
        this.modalService._hideModal(1);
        // this.articleService.blockSelection(false)
    };
    FormattingOptionsComponent.prototype.save = function () {
        var _this = this;
        if (!this.transferToJob) {
            this.bsModalRef.hide();
            this.modalService._hideModal(1);
            this.proposaltemplateservice.setEditorThumbnail('updateInputElement');
            this.upgradeFreemium();
        }
        else {
            if (this.selectedTemplateId || this.selectedTemplateType) {
                var params_1 = {};
                if (!this.existingOptionId && !this.existingAddonId) {
                    params_1 = {
                        'screenName': this.screenName,
                        'templateId': this.proposalId,
                        'optionId': this.optionId,
                        'type': this.selectedTemplateType ? this.selectedTemplateType : '',
                        'addonId': this.selectedTemplateId ? this.selectedTemplateId : '',
                        'jobNotes': this.jobNotes,
                        'pageId': this.pageId,
                        'updateContent': false
                    };
                }
                else {
                    params_1 = {
                        'screenName': this.screenName,
                        'templateId': this.proposalId,
                        'optionId': this.optionId,
                        'type': this.selectedTemplateType ? this.selectedTemplateType : '',
                        'addonId': this.selectedTemplateId ? this.selectedTemplateId : '',
                        'jobNotes': this.jobNotes,
                        'pageId': this.pageId,
                        'updateContent': true,
                        'existingTableId': this.existingTableId
                    };
                }
                this.proposaltemplateservice.saveJobNotes(params_1).subscribe(function (res) {
                    if (res && res.id && !res.exists) {
                        var result = {
                            'templateId': res.id,
                            'optionId': _this.optionId,
                            'addon': false,
                            'addonId': '',
                            'dataExists': res.dataExists,
                            'updateJobNotes': res.updateJobNotes,
                            'type': res.type
                        };
                        if (_this.optionId && params_1['addonId']) {
                            result['addon'] = true;
                            result['addonId'] = params_1['addonId'];
                        }
                        _this.proposaltemplateservice.setTableTemplateId(result);
                        _this.upgradeFreemium();
                    }
                });
            }
            else if (!this.selectedTemplateId && !this.selectedTemplateType) {
                var classList_1 = $('#cs_editor').contents().find('div.cs_block_s.cs_selected')[0].className.split(' ');
                var searchJobString_1 = 'job';
                var searchOptionString_1 = 'option';
                var searchAddonString_1 = 'addon';
                var classArray = [];
                if (classList_1 && classList_1.length > 0) {
                    for (var i = 0; i < classList_1.length; i++) {
                        if (classList_1[i].startsWith(searchJobString_1)) {
                            var array = classList_1[i].split('-');
                            var id = array[array.length - 1];
                            var data = {
                                'tableId': id
                            };
                            this.proposaltemplateservice.deleteJobNotes(data).subscribe(function (res) {
                                for (var i_1 = 0; i_1 < classList_1.length; i_1++) {
                                    if (classList_1[i_1].startsWith(searchJobString_1) || classList_1[i_1].startsWith(searchOptionString_1) || classList_1[i_1].startsWith(searchAddonString_1)) {
                                        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').removeClass(classList_1[i_1]);
                                    }
                                }
                            });
                        }
                    }
                }
                this.upgradeFreemium();
            }
            this.proposaltemplateservice.saveContentLibrary(true);
            this.bsModalRef.hide();
            this.modalService._hideModal(1);
        }
        // this.articleService.blockSelection(false)
    };
    FormattingOptionsComponent.prototype.getColor = function (data, type) {
        if (type == 'background') {
            $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'background-color': data });
            this.changedBackgroundColor = data;
        }
        else if (type == 'border') {
            $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css({ 'border-color': data });
            this.changedBorderColor = data;
            this.borderColorStyle = data;
        }
    };
    FormattingOptionsComponent.prototype.removeTextBg = function () {
        this.textElementBackgroundImage = '';
        $('#cs_editor').contents().find('div.cs_block_s.cs_selected').css({ 'background-image': 'none' });
    };
    FormattingOptionsComponent.prototype.defaultColor = function (event) {
        var color = $('#cs_editor').contents().find('div.cs_block_s.cs_selected').find('div:first').css('border-color');
        var value = Number(event.target.value);
        if (value > 0 && (color == 'rgb(89, 91, 101)' || color == 'rgb(80, 91, 101)')) {
            this.changedBorderColor = '#595B65';
            this.borderColorStyle = this.changedBorderColor;
        }
        else if (value == 0 && this.changedBorderColor == '#595B65') {
            this.changedBorderColor = 'No border';
            this.borderColorStyle = '#ffffff';
        }
        else {
            return;
        }
    };
    FormattingOptionsComponent.prototype.detectChanges = function (data) {
        var that = this;
        this.myValue = '';
        setTimeout(function () {
            that.myValue = data;
        }, 50);
    };
    FormattingOptionsComponent.prototype.enableSave = function (id, type, text, disabled) {
        if (!disabled) {
            this.jobNotesValue = text;
            type == 'addon' ? this.selectedTemplateId = id : this.selectedTemplateId = '';
            this.selectedTemplateType = type;
            this.closeDropdown = true;
        }
    };
    FormattingOptionsComponent.prototype.upgradeFreemium = function () {
        if (this.screenType != 'content_library' && this.screenType != 'addon_template') {
            var data = {};
            if (this.screenType == 'option_template') {
                data = {
                    screenType: this.screenType,
                    id: this.optionId,
                    addon: false
                };
            }
            else if (this.screenType == 'proposal_templates') {
                data = {
                    screenType: this.screenType,
                    id: this.proposalTemplateId,
                    addon: false
                };
            }
            else if (this.screenType == 'opportunity') {
                data = {
                    screenType: this.screenType,
                    id: this.opportunityProposalId,
                    addon: false,
                    opportunityId: this.opportunityId
                };
            }
            this.proposaltemplateservice.upgradeDowngradeFreemiumBlocks(data).subscribe(function (res) {
            });
        }
    };
    return FormattingOptionsComponent;
}());
export { FormattingOptionsComponent };
