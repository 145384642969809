<ng-container>
    <div class="item form-item conditional-container">
        <div class="control px-15 py-15">
            <label class="control-label required_field">Add condition of "{{activeField.text}}"</label>
            <div class="condition-item cs-d-flex">
                <div class="cs-flex-1 mr-10">
                    <select class="" [(ngModel)]="inputModel.condition.operator" (change)="onSelectOperator($event)" [disabled]="isMarketPlaceView">
                        <option value="">Please.Choose</option>
                        <option *ngFor="let opt of activeField.operators" value="{{opt}}">{{operators[opt]}}</option>
                    </select>
                </div>
                <div class="cs-flex-1" [ngSwitch]="true">
                    <div *ngSwitchCase="activeField.input === 'select' || activeField.input === 'checkboxList'">
                        <select class="" [(ngModel)]="inputModel.condition.value" *ngIf="!['in', 'not_in'].includes(inputModel.condition.operator)"  [attr.disabled]="isReadOnlyMode ? '' : null">
                            <option value="">Please.Choose</option>
                            <option *ngFor="let option of activeField.options" value="{{option.id}}">{{option.text}}</option>
                        </select>
                        <ng-container *ngIf="['in', 'not_in'].includes(inputModel.condition.operator)">
                            <input (selected)="onSelectMultipleSelect($event)"
                                   [(ngModel)]="inputModel.condition.value"
                                   id="conditional_multiple_select"
                                   [attr.disabled]="isReadOnlyMode ? '' : null"
                                   csSelect2
                                   [dropdownvalue]="inputModel.condition.value"
                                   [select2Config]="{
                                  multiple: true,
                                  data: {
                                    text: dataFunction,
                                    results : activeField.options
                                  }
                                }" class="full-width-select2"/>
                        </ng-container>
                    </div>
                    <div *ngSwitchCase="activeField.input === 'number'">
                        <input [attr.disabled]="isReadOnlyMode ? '' : null" type="text" [(ngModel)]="inputModel.condition.value" class="control-input" appDigitDecimaNumber/>
                    </div>
                    <div [attr.disabled]="isReadOnlyMode ? '' : null" *ngSwitchCase="activeField.input === 'text'">
                        <input [(ngModel)]="inputModel.condition.value" type="text" class="control-input"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
