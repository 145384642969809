import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import * as moment from "moment";
import {TimelineDatePipe} from '@app/pipes/timeline-date.pipe';

@Injectable({
    providedIn: 'root'
})
export class TimelineService extends InterfaceService {

    fileTypeGroups: any = {
        image: ['jpg', 'png', 'gif', 'jpeg'],
        pdf: ['pdf'],
        csv: ['csv'],
        word_document: ['doc', 'docx'],
        excel: ['xls', 'xlsx', 'ods', 'xlr'],
        audio: ['mp3', 'mpa', 'ogg', 'mid', 'midi'],
        video: ['avi', 'flv', 'mov', 'mp4', 'mpg', 'mpeg'],
        compressed: ['7z', 'rar', 'zip', 'tar.gz'],
        power_point: ['odp', 'pps', 'ppt', 'pptx'],
    };

    constructor(private http: HttpClient,
                private timelineDateTime: TimelineDatePipe) {
        super();
    }

    getTimelineData(screen, primaryId, filters: object, portalAccess) {
        return this.http.get(this.getApiUrl(`shared/getTimelineData/${screen}/${primaryId}?portalAccess=${portalAccess}`), filters);
    }

    checkTimeLineHeaderDate(date) {
        let value = moment(date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]'
        });
        if (value == 'Today' || value == 'Tomorrow' || value == 'Yesterday') {
            return value
        } else {
            return null
        }
    }

    convertTimeLineHeaderDate(date) {
        return this.timelineDateTime.transform(date, 'date' );
    }

    convertTimeLineDateTime(date) {
        return this.timelineDateTime.transform(date, 'datetime' );
    }

    getDateDifference(date) {
        let now = moment(new Date());
        let fromDate = moment(date);
        let duration = moment.duration(fromDate.diff(now));
        return duration.humanize(true);
    }

    diaryDateTimeRange(fromDateTime, toDateTime, allDay, isSpecialEvent, duration) {
        fromDateTime = moment.utc(fromDateTime);
        toDateTime = moment.utc(toDateTime);
        let value = '';
        if(allDay === false) {
            if(isSpecialEvent) {
                value = this.timelineDateTime.transform(fromDateTime, 'date' ) + ' ' + duration + ' (' + this.timelineDateTime.transform(fromDateTime, 'diarytime' ) + ' - ' + this.timelineDateTime.transform(toDateTime,  'diarytime' ) + ')';
            } else {
                value = this.timelineDateTime.transform(fromDateTime, 'date' ) + ' (' + this.timelineDateTime.transform(fromDateTime, 'diarytime' ) + ' - ' + this.timelineDateTime.transform(toDateTime, 'diarytime' ) + ')';
            }
        } else {
            value = this.timelineDateTime.transform(fromDateTime, 'date' ) + ' ' + allDay;
        }
        return value;
    }

    getFileGroup(fileType) {
        let fileGroup = 'unknown';
        for (let key in this.fileTypeGroups) {
            if(this.fileTypeGroups.hasOwnProperty(key)) {
                let value = this.fileTypeGroups[key];
                if(value.indexOf(fileType) != -1) {
                    fileGroup = key;
                    break;
                }
            }
        }
        return fileGroup;
    }

    convertTimeLineScheduledDateTime(date) {
        date = moment.utc(date)
        return this.timelineDateTime.transform(date, 'datetime' );
    }
}
