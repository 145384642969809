import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//--
import { StrReplacePipe } from './str-replace.pipe';
import { MomentPipe } from './moment.pipe';
import {FirstLetterUpperPipe} from './first-letter-upper.pipe';
import { UnSortJSONPipe } from './unsortJSON.pipe';
import { toTrustedPipe } from "./toTrusted.pipe";
import { CapitalizePipe } from './capitalize.pipe';
import { CurrencyPipe } from './currency.pipe';
import { DatePipe } from './date.pipe';
import { TimePipe } from './time.pipe';
import {EscapeHtmlPipe } from  './keep-html.pipe';
import {KeysPipe} from "@app/pipes/keys.pipe";
import { LongDatePipe } from './long_date.pipe';
import { CompressCommasPipe } from './compress-commas.pipe';
import { TimelineDatePipe, TimeStringFromSeconds } from './timeline-date.pipe';
import {truncate} from "@app/pipes/truncate.pipe";

@NgModule({
  declarations: [
    StrReplacePipe,
    MomentPipe,
    FirstLetterUpperPipe,
    UnSortJSONPipe,
    toTrustedPipe,
    CapitalizePipe,
    CurrencyPipe,
    DatePipe,
    TimePipe,
    EscapeHtmlPipe,
    KeysPipe,
    LongDatePipe,
    CompressCommasPipe,
    TimelineDatePipe,
      TimeStringFromSeconds,
      truncate
  ],
  imports: [
    CommonModule
  ],
    exports: [
        StrReplacePipe,
        MomentPipe,
        FirstLetterUpperPipe,
        UnSortJSONPipe,
        toTrustedPipe,
        CapitalizePipe,
        CurrencyPipe,
        DatePipe,
        TimePipe,
        EscapeHtmlPipe,
        KeysPipe,
        LongDatePipe,
        CompressCommasPipe,
        TimelineDatePipe,
        TimeStringFromSeconds,
        truncate
    ]
})
export class PipesModule { }
