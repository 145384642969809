import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
var NotificationBlockComponent = /** @class */ (function () {
    function NotificationBlockComponent(workflowService, commonService, confirmationBoxHelper, ajsScope) {
        this.workflowService = workflowService;
        this.commonService = commonService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.name = 'notificationBlock';
        this.activeField = {};
        this.isConditional = false;
        this.isEdit = false;
        this.isMarketPlaceView = false;
        this.userRoles = [];
        this.taggingList = {};
        this.workflowType = 'Custom';
        this.diagramOptions = {};
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.userData = [];
        this.tagsData = [];
        this.csTaggingList = [];
        this.isLoading = true;
        this.assignedUser = null;
        this.messageDelta = [];
        this.mentionContent = '';
        this.saving = false;
        this.oldData = null;
    }
    NotificationBlockComponent.prototype.ngOnInit = function () {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if (this.workflowType == 'Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        var select2DisplayFormat = function (item) {
            return item.text;
        };
        this.select2LabelConfig = {
            width: '100%',
            data: this.userRoles,
            tags: true,
            formatSelection: select2DisplayFormat,
            formatResult: select2DisplayFormat,
            multiple: true
        };
        if (this.workflowType == 'Sequence') {
            this.mainObjectName = this.diagramOptions['sequenceObjectName'];
        }
        this.csTaggingList = this.commonService.getCsTaggingList(this.mainObjectName, this.taggingList, this.workflowType);
        this.setEditiorOptions();
        this.$resolveData();
    };
    NotificationBlockComponent.prototype.onChange = function (event) {
        if (!event.html) {
            this.messageDelta = null;
            this.assignedUser = null;
            this.mentionContent = '';
            return;
        }
        var delta = event.content;
        this.messageDelta = JSON.stringify(delta);
        this.mentionContent = event.html;
        var contentEl = $(event.html).find('[data-denotation-char="@"]');
        if (contentEl.length) {
            this.oldData = contentEl.data('id');
        }
        if (this.oldData && contentEl.length === 0) {
            this.oldData = this.assignedUser = null;
        }
    };
    NotificationBlockComponent.prototype.selectedRoles = function (event) {
        this.inputModel.model.roles = event;
    };
    NotificationBlockComponent.prototype.setEditiorOptions = function () {
        var _this = this;
        this.editorOptions = {
            mention: {
                mentionListClass: "ql-mention-list mat-elevation-z8",
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                showDenotationChar: true,
                spaceAfterInsert: true,
                onSelect: function (item, insertItem) {
                    if (item.denotationChar === '[') {
                        item['value'] = item['value'] + ']';
                    }
                    insertItem(item);
                    if (item.denotationChar === '@') {
                        _this.assignedUser = item.id;
                    }
                },
                mentionDenotationChars: ["@", "#", "["],
                source: function (searchTerm, renderList, mentionChar) {
                    var values = [];
                    if (mentionChar === '#') {
                        values = _this.tagsData;
                    }
                    if (mentionChar === '[') {
                        values = _this.csTaggingList;
                    }
                    else if (mentionChar === '@') {
                        values = _this.assignedUser ? [] : _this.userData;
                    }
                    if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                    }
                    else {
                        var matches = [];
                        for (var i = 0; i < values.length; i++)
                            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                                matches.push(values[i]);
                        renderList(matches, searchTerm);
                    }
                }
            },
            toolbar: false
        };
    };
    NotificationBlockComponent.prototype.addValue = function () {
        var _this = this;
        this.saving = true;
        this.inputModel.model.assignTo = this.assignedUser;
        this.inputModel.model.message = this.mentionContent;
        this.inputModel.model.textMessage = this.mentionContent.replace(/(<([^>]+)>)/gi, "");
        this.inputModel.model.messageDelta = this.messageDelta;
        this.setAssignedTo(this.assignedUser);
        this.setRolesDisplay();
        // console.log('save',this.inputModel.model);
        setTimeout(function () {
            _this.outputModel.emit(_this.inputModel);
        }, 0);
    };
    NotificationBlockComponent.prototype.setAssignedTo = function (value) {
        var dupUserData = Object.assign([], this.userData);
        var objectUser = _.indexBy(dupUserData, 'id');
        var selectedData = objectUser[value];
        return this.inputModel.model.assignToDisplay = selectedData ? selectedData['username'] : null;
    };
    NotificationBlockComponent.prototype.setRolesDisplay = function () {
        var _this = this;
        var tmpRoles = Object.assign([], this.userRoles);
        var tmpRoleIds = _.indexBy(tmpRoles, 'id');
        this.inputModel.model.rolesDisplay = [];
        _.forEach(this.inputModel.model.roles, function (id) {
            _this.inputModel.model.rolesDisplay.push(tmpRoleIds[id].text);
        });
    };
    NotificationBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    NotificationBlockComponent.prototype.getUser = function () {
        var _this = this;
        var promise = new Promise(function (resolve) {
            _this.workflowService.getUsersList()
                .subscribe(function (resp) {
                _this.userData = resp['users'].map(function (item) {
                    return { id: item.id, value: item.username, username: item.username };
                });
                resolve();
            });
        });
        return promise;
    };
    NotificationBlockComponent.prototype.getTags = function () {
        var _this = this;
        var promise = new Promise(function (resolve) {
            _this.workflowService.getHashTagsList()
                .subscribe(function (resp) {
                _this.tagsData = resp['hashtags'].map(function (item) {
                    return { id: item.id, value: item.description };
                });
                resolve();
            });
        });
        return promise;
    };
    NotificationBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    NotificationBlockComponent.prototype.$resolveData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, assignTo, messageDelta;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.getUser()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.getTags()];
                    case 2:
                        _b.sent();
                        this.isLoading = false;
                        if (this.isEdit) {
                            _a = this.inputModel.model, assignTo = _a.assignTo, messageDelta = _a.messageDelta;
                            this.assignedUser = messageDelta ? assignTo : null;
                            this.messageDelta = messageDelta ? JSON.parse(messageDelta) : [];
                            this.editor.quillEditor.setContents(this.messageDelta);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return NotificationBlockComponent;
}());
export { NotificationBlockComponent };
