import { EventEmitter, OnInit, SimpleChanges, } from '@angular/core';
import { UploadDocumentService } from '../services/upload-document.service';
import { HotTableRegisterer } from '@handsontable/angular';
import { HansontableSelect2 } from '@app/directives';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CsToastBoxService } from '@app/services';
import { HybridFactory } from '@app/core/hybrid-factory';
import Handsontable from 'handsontable';
import { environment } from '../../../../environments/environment';
// Following two variables are use to show .tiff files
// var Tiff = require('tiff.js');
// var fs = require('fs');
var DocumentParsedDetailsComponent = /** @class */ (function () {
    function DocumentParsedDetailsComponent(fileUploadingService, translate, sanitizer, toastBox, confirmationBoxHelper, ajsScope) {
        var _this = this;
        this.fileUploadingService = fileUploadingService;
        this.translate = translate;
        this.sanitizer = sanitizer;
        this.toastBox = toastBox;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.closeModal = new EventEmitter(); // For closing modal from parent component
        // For save button
        this.suppliersList = [];
        this.poNumber = '';
        this.jobId = null;
        this.poErrorCode = 1; // PO is invalid
        this.poErrorMsg = null;
        this.selectedOption = 'items';
        // For confirm button
        this.csSelectedField = "Supplier"; // Which field user wants to change. eg. Supplier
        this.thirdPartyField = "Supplier"; // This field user wants to change. eg. Suppler
        this.csSelectedSupplierName = null; // New commusoft selected supplier name
        this.csSelectedSupplierId = null; // Id of selected supplier
        this.selectSuppliersList = {}; // For reusing commusoft component
        this.spreadSheetData = []; // List of line items
        this.rowToDelete = []; // It will store the deleted row
        this.calculatedNetPrice = 0.00; // Total cost
        this.calculatedTotalAmount = 0.00; // Total amount
        this.calculatedVatRate = 0.00; // Sales Tax
        this.openChildModal = false;
        this.fadeBackground = false;
        // Error Messages
        this.invoiceDateErr = null;
        this.csSelectedSupplierNameErr = null;
        this.invoiceNumberErr = null;
        this.isLoading = true; // To show selected supplier name
        this.allTaxItemsEqual = false;
        this.isPdf = true;
        this.isDisabledConfirmButton = false;
        this.inValidConfirmText = false;
        this.confirmTextModel = '';
        this.confirmText = 'CONFIRM';
        this.validationMessage = '\'CONFIRM\' to confirm the supplier mapping';
        this.showInvoiceErrMsg = false;
        this.partsList = [];
        this.invoiceCreationError = '';
        this.defaultNominalCode = '';
        this.emptyCellValidator = function (value, callback) {
            if (value === '') {
                _this.toastBox.show(_this.translate.instant('Empty.cell.not.allowed'), 1000);
                callback(false);
            }
            else {
                callback(true);
            }
        };
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
    }
    DocumentParsedDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.csSelectedSupplierId > 0 && (this.csSelectedSupplierName != '' || this.csSelectedSupplierName != null || typeof this.csSelectedSupplierName != undefined)) {
            this.fileUploadingService.getSuppliers(this.csSelectedSupplierName).subscribe(function (resp) {
                _this.suppliersList = resp;
                var index = _this.suppliersList.findIndex(function (value) { return value.id == parseInt(_this.csSelectedSupplierId); });
                if (index >= 0) {
                    $("#showSupplier").select2('data', _this.suppliersList[0]);
                }
            });
        }
        this.selectSuppliersList = {
            minimumInputLength: 2,
            width: "100%",
            initSelection: function () { },
            ajax: {
                url: environment.apiBasePath + 'get_all_suppliers',
                data: function (searchText, page) {
                    if (searchText == '') {
                        return false;
                    }
                    return { 'q': encodeURIComponent(searchText) };
                },
                results: function (data) {
                    data['response'] = data;
                    if (Array.isArray(data.response)) {
                        for (var _i = 0, _a = data.response; _i < _a.length; _i++) {
                            var item = _a[_i];
                            var index = _this.suppliersList.findIndex(function (el) { return el.id == item.id; });
                            if (index == -1) {
                                _this.suppliersList.push(item);
                            }
                        }
                    }
                    return { results: data.response };
                },
            },
        };
    };
    DocumentParsedDetailsComponent.prototype.ngAfterViewInit = function () {
        var hotRegisterer = new HotTableRegisterer();
        this.hotInstance = hotRegisterer.getInstance('lineItemsDetails');
    };
    // This will be use to reload the data
    DocumentParsedDetailsComponent.prototype.ngOnChanges = function (changes) {
        // changes.prop contains the old and the new value...
        this.initialization();
    };
    DocumentParsedDetailsComponent.prototype.initialization = function () {
        this.invoiceCreationError = this.translate.instant('Error.while.creating.invoice');
        this.assignValues(); // Used to assign values to invoiceDate, supplier, poNumber and invoice Number
        this.prepareSpreadsheetSettings();
        this.getParsedData(); // Get lineItems, pdf, calculatedNetPrice, calculatedTotalAmount, calculatedVatRate
    };
    DocumentParsedDetailsComponent.prototype.assignValues = function () {
        this.buttonName = this.translate.instant('Confirm.and.add.new.invoice');
        // It will be used to show Sales Tax according to countries [UK/US] 
        if (this.supplierDetails.hasOwnProperty('taxLabel')) {
            this.taxLabel = this.supplierDetails['taxLabel'];
        }
        if (this.supplierDetails.hasOwnProperty('id')) {
            this.parsedDataId = this.supplierDetails['id'];
        }
        if (this.supplierDetails.hasOwnProperty('thirtyPartySupplierName')) {
            this.thirdPartySupplierName = this.supplierDetails['thirtyPartySupplierName'];
        }
        if (this.supplierDetails.hasOwnProperty('poNumber')) {
            this.poNumber = this.supplierDetails['poNumber'];
        }
        /*Mandatory fields:
            Invoice Date
            Supplier name
            Invoice number
        */
        if (this.supplierDetails.hasOwnProperty('invoiceDateObject')) {
            if (this.supplierDetails['invoiceDateObject'] != null) {
                if (this.supplierDetails['invoiceDateObject'].hasOwnProperty('date')) {
                    this.invoiceDate = this.supplierDetails['invoiceDateObject']['date'];
                    this.invoiceDate = new Date(this.invoiceDate);
                }
            }
            else {
                this.invoiceDate = null;
            }
        }
        if (this.supplierDetails.hasOwnProperty('jobId')) {
            this.jobId = this.supplierDetails['jobId'];
        }
        if (this.supplierDetails.hasOwnProperty('invoiceNumber')) {
            this.invoiceNumber = this.supplierDetails['invoiceNumber'];
        }
        // Get the supplier id from commusoft
        if (this.supplierDetails.hasOwnProperty('commusoftSupplierId')) {
            if (this.supplierDetails.commusoftSupplierId != "" || this.supplierDetails.commusoftSupplierId != null) {
                this.csSelectedSupplierId = parseInt(this.supplierDetails.commusoftSupplierId);
                this.isLoading = false;
                if (this.supplierDetails.hasOwnProperty('supplierName')) {
                    this.csSelectedSupplierName = this.supplierDetails.supplierName;
                }
            }
        }
    };
    DocumentParsedDetailsComponent.prototype.validation = function () {
        var _this = this;
        this.isDisabledConfirmButton = false;
        var quantity, unitCost, selectedSalesTax, salesTax, selectedNominalCode, nominalCode, description;
        if (this.csSelectedSupplierId == null ||
            typeof this.csSelectedSupplierId == 'undefined') {
            this.csSelectedSupplierNameErr = "Please select supplier name";
            return;
        }
        if (this.invoiceNumber == null ||
            typeof this.invoiceNumber == 'undefined' ||
            this.invoiceNumber == '') {
            this.invoiceNumberErr = "Please enter invoice number";
            return;
        }
        if (this.spreadSheetData.length > 1) {
            var _loop_1 = function (i) {
                quantity = null;
                unitCost = null;
                salesTax = null;
                nominalCode = null;
                description = null;
                description = this_1.spreadSheetData[i].description;
                quantity = parseFloat(this_1.spreadSheetData[i].quantity);
                unitCost = parseFloat(this_1.spreadSheetData[i].unitPrice);
                selectedSalesTax = this_1.taxItems.find(function (p) { return p['id'] == parseInt(_this.spreadSheetData[i]['taxItemId']); });
                if (selectedSalesTax) {
                    salesTax = parseFloat(selectedSalesTax.effective_tax);
                }
                selectedNominalCode = this_1.listCodes.find(function (p) { return p['id'] == parseInt(_this.spreadSheetData[i]['nominalCodeId']); });
                if (selectedNominalCode) {
                    nominalCode = selectedNominalCode.id;
                }
                if (description == "" ||
                    description == null ||
                    typeof description == 'undefined') {
                    return { value: void 0 };
                }
                else if (isNaN(quantity) ||
                    quantity == 0 ||
                    quantity == "" ||
                    quantity == null ||
                    typeof quantity == 'undefined') {
                    return { value: void 0 };
                }
                else if (isNaN(unitCost) ||
                    unitCost == 0 ||
                    unitCost == "" ||
                    unitCost == null ||
                    typeof unitCost == 'undefined') {
                    return { value: void 0 };
                }
                else if (isNaN(salesTax) ||
                    salesTax == null ||
                    typeof salesTax == 'undefined') {
                    return { value: void 0 };
                }
                else if (isNaN(nominalCode) ||
                    nominalCode == "" ||
                    nominalCode == null ||
                    typeof nominalCode == 'undefined') {
                    return { value: void 0 };
                }
            };
            var this_1 = this, selectedSalesTax;
            for (var i = 0; i < this.spreadSheetData.length - 1; i++) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        else {
            return;
        }
        this.isDisabledConfirmButton = true;
    };
    DocumentParsedDetailsComponent.prototype.getParsedData = function () {
        var _this = this;
        this.fileUploadingService.getAutomatedParseData(this.parsedDataId, this.poNumber, this.csSelectedSupplierId).subscribe(function (resp) {
            if (resp.hasOwnProperty('parselineitemsDetails')) {
                if (resp['parselineitemsDetails'].hasOwnProperty([0])) {
                    if (resp['parselineitemsDetails'][0].hasOwnProperty('selectedOption')) {
                        var selectedOptionResponse = resp['parselineitemsDetails'][0]['selectedOption'];
                        _this.poErrorCode = selectedOptionResponse['errorCode'];
                        _this.poErrorMsg = selectedOptionResponse['errorMessage'];
                        _this.selectedOption = selectedOptionResponse['selectedOption'];
                    }
                    if (resp['parselineitemsDetails'][0].hasOwnProperty('currencySymbols')) {
                        _this.currencySymbols = resp['parselineitemsDetails'][0]['currencySymbols'];
                    }
                    if (resp['parselineitemsDetails'][0].hasOwnProperty('fileName')) {
                        _this.invoicePdf = resp['parselineitemsDetails'][0]['fileName'];
                        _this.loadImage(); // This will be used to convert .tiff file to .jpeg 
                        _this.invoicePdf = _this.sanitizer.bypassSecurityTrustResourceUrl(_this.invoicePdf);
                    }
                    if (resp['parselineitemsDetails'][0].hasOwnProperty('defaultNominalCode')) {
                        _this.defaultNominalCode = resp['parselineitemsDetails'][0]['defaultNominalCode'];
                    }
                }
                if (resp['parselineitemsDetails'].hasOwnProperty(['lineItems'])) {
                    _this.spreadSheetData = resp['parselineitemsDetails']['lineItems'];
                    _this.lineItemCount = resp['parselineitemsDetails']['lineItems'].length;
                }
            }
            if (_this.hotInstance) {
                _this.hotInstance.loadData(_this.spreadSheetData);
                _this.hotInstance.render();
                _this.changeOption(_this.selectedOption);
            }
        });
    };
    DocumentParsedDetailsComponent.prototype.onRowDelete = function (instance, row, col) {
        var _this = this;
        if (row == (this.spreadSheetData.length - 1))
            return;
        if (this.selectedOption == 'parts')
            return;
        this.confirmationBoxHelper
            .getConfirmation(this.translate.instant('This row has been saved, are you sure you want to delete it?'), this.ajsScope)
            .then(function () {
            var id = _this.spreadSheetData[row]['id'];
            if (typeof id != 'undefined' && id != null) {
                if (_this.spreadSheetData[row].hasOwnProperty('id')) {
                    _this.fileUploadingService.deleteAIParseDetails(id).subscribe(function (resp) {
                        _this.rowToDelete.push(_this.spreadSheetData[row]['id']);
                        _this.spreadSheetData.splice(row, 1);
                        instance.render();
                        _this.calculateTotal();
                        _this.validation();
                        _this.toastBox.show(_this.translate.instant('Record.deleted.successfully'), 2000);
                    }, function (error) {
                        _this.toastBox.show(_this.translate.instant('Error.while.deleting.record'), 2000);
                    });
                }
            }
            else {
                _this.spreadSheetData.splice(row, 1);
                instance.render();
                _this.calculateTotal();
                _this.validation();
                _this.toastBox.show(_this.translate.instant('Record.deleted.successfully'), 2000);
            }
        });
    };
    DocumentParsedDetailsComponent.prototype.prepareSpreadsheetSettings = function () {
        var _this = this;
        var that = this;
        this.spreadSheetSettings = {
            licenseKey: 'non-commercial-and-evaluation',
            colHeaders: true,
            rowHeaders: false,
            minSpareRows: 1,
            // autoWrapRow :true,
            // autoWrapCol :true,
            manualColumnResize: true,
            manualRowResize: true,
            enterMoves: { row: 0, col: 1 },
            stretchH: 'all',
            // colWidths   : [320, 90, 100, 120, 180, 155, 90],
            columns: [
                {
                    title: this.translate.instant('Pat.description'),
                    data: 'description',
                    required: true,
                    validator: 'emptyCellValidator',
                    allowEmpty: false
                },
                {
                    title: 'Part name',
                    data: 'partId',
                    placeholder: 'Please select',
                    readOnly: true,
                    className: 'overflow_handsontable select_icon required',
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        if (_this.partsList.length == 0) {
                            var description = _this.spreadSheetData[row]['partDescription'];
                            if (description) {
                                value = description[0].toUpperCase() + description.substr(1).toLowerCase();
                            }
                        }
                        else {
                            var partName = _this.partsList.find(function (p) { return p['id'] == value; });
                            if (partName) {
                                _this.spreadSheetData[row]['partDescription'] = value = partName['text'][0].toUpperCase() + partName['text'].substr(1).toLowerCase();
                            }
                            else {
                                value = _this.spreadSheetData[row]['partDescription'];
                            }
                        }
                        Handsontable.cellTypes.text.renderer.apply(_this, [instance, td, row, col, prop, value, cellProperties]);
                    },
                    editor: HansontableSelect2({
                        //minimumInputLength  : 2,
                        dropdownCssClass: "handsontable-select",
                        dropdownAutoWidth: true,
                        ajax: {
                            url: 'get_parts_dropdown?supplierId=' + this.csSelectedSupplierId,
                            data: function (query, page) {
                                if (query == '') {
                                    return false;
                                }
                                return { 'query': encodeURIComponent(query) };
                            },
                            results: function (data, page) {
                                _this.partsList = data.parts;
                                return { results: data.parts };
                            }
                        }
                    }),
                },
                {
                    title: this.translate.instant('Quantity'),
                    data: 'quantity',
                    allowInvalid: true,
                    required: false,
                    validator: 'emptyCellValidator',
                    allowEmpty: false,
                    type: 'numeric'
                },
                {
                    title: this.translate.instant('Unit.Cost'),
                    data: 'unitPrice',
                    allowInvalid: true,
                    required: false,
                    validator: 'emptyCellValidator',
                    allowEmpty: false,
                    type: 'numeric'
                },
                {
                    title: this.translate.instant('Sales.Price'),
                    data: 'unitPrice',
                    allowInvalid: true,
                    required: false,
                    validator: 'emptyCellValidator',
                    allowEmpty: false,
                    type: 'numeric'
                },
                {
                    title: this.taxLabel,
                    data: 'taxItemId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon required',
                    validator: 'emptyCellValidator',
                    allowEmpty: false,
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        var selectedTax;
                        selectedTax = _.findWhere(that.taxItems, { id: parseInt(value) });
                        value = (selectedTax) ? selectedTax.text : "";
                        Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: that.taxItems,
                        dropdownAutoWidth: true,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select',
                        formatResult: function (item) {
                            return item.text;
                        }
                    })
                },
                {
                    title: this.translate.instant('Nominal.Code'),
                    data: 'nominalCodeId',
                    placeholder: 'Please select',
                    className: 'overflow_handsontable select_icon required',
                    validator: 'emptyCellValidator',
                    allowEmpty: false,
                    renderer: function (instance, td, row, col, prop, value, cellProperties) {
                        if ((_this.spreadSheetData.length - 1) != row) {
                            if (typeof _this.spreadSheetData[row]['id'] == 'undefined' || _this.spreadSheetData[row]['id'] == null || _this.spreadSheetData[row]['id'] == '') {
                                _this.spreadSheetData[row]['nominalCodeId'] = _this.defaultNominalCode;
                                value = _this.defaultNominalCode;
                            }
                        }
                        var selectedNominalCode = _.findWhere(that.listCodes, { id: parseInt(value) });
                        value = (selectedNominalCode) ? selectedNominalCode.text : "";
                        Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    },
                    editor: HansontableSelect2({
                        editable: true,
                        data: that.listCodes,
                        dropdownAutoWidth: false,
                        width: 'resolve',
                        dropdownCssClass: 'handsontable-select',
                        formatResult: function (item) {
                            return item.text;
                        }
                    })
                },
                {
                    title: this.translate.instant('Total.Cost.exc.VAT'),
                    data: 'totalPrice',
                    validator: 'emptyCellValidator',
                    allowEmpty: false,
                    disableVisualSelection: false,
                    readOnly: true,
                },
                {
                    title: this.translate.instant('Actions'),
                    readOnly: true,
                    disableVisualSelection: false,
                    renderer: function (instance, td, row, col) {
                        var link = document.createElement('a');
                        link.innerText = 'Delete';
                        link.style.cursor = 'pointer';
                        td.style.textAlign = 'center';
                        Handsontable.dom.addEvent(link, 'click', function () {
                            _this.onRowDelete(instance, row, col);
                        });
                        Handsontable.dom.empty(td);
                        var content = document.createElement('div');
                        content.appendChild(link);
                        td.appendChild(content);
                    }
                }
            ],
            afterChange: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var core = that.hotInstance, changes = args[0];
                if (!changes)
                    return;
                _this.calculateTotal();
                core.render();
                _this.validation();
            },
            afterLoadData: function () {
                _this.calculateTotal();
                _this.validation();
            }
        };
    };
    DocumentParsedDetailsComponent.prototype.calculateTotal = function () {
        var totalVat, effectiveTax, total, selectedTax, selectedId;
        var total_price = 0, total_tax = 0, sub_total_price = 0;
        var quantity, unitPrice;
        var taxArray = [];
        if (this.spreadSheetData.length > 1) {
            for (var i = 0; i < this.spreadSheetData.length - 1; i++) {
                selectedTax = _.findWhere(this.taxItems, { id: parseInt(this.spreadSheetData[i]['taxItemId']) });
                if (typeof selectedTax == 'undefined') {
                    effectiveTax = 0.0;
                }
                else {
                    effectiveTax = parseFloat(selectedTax.effective_tax);
                    taxArray.push(effectiveTax);
                }
                quantity = this.spreadSheetData[i]['quantity'];
                unitPrice = this.spreadSheetData[i]['unitPrice'];
                if (quantity === "" ||
                    quantity === null ||
                    typeof quantity === 'undefined' ||
                    isNaN(quantity) ||
                    unitPrice === "" ||
                    unitPrice === null ||
                    typeof unitPrice === 'undefined' ||
                    isNaN(unitPrice)) {
                    total_price = 0.0;
                    sub_total_price = 0.0;
                    total_tax = 0.0;
                    break;
                }
                else {
                    total = quantity * unitPrice;
                    totalVat = (total * effectiveTax) / 100;
                    this.spreadSheetData[i]['vatRate'] = effectiveTax;
                    this.spreadSheetData[i]['totalPrice'] = total;
                    this.spreadSheetData[i]['total'] = total;
                    total_price += (parseFloat(total) + parseFloat(totalVat));
                    sub_total_price += total;
                    total_tax += parseFloat(totalVat);
                }
            }
            this.calculatedNetPrice = sub_total_price.toFixed(4);
            this.calculatedTotalAmount = total_price.toFixed(4);
            this.calculatedVatRate = total_tax.toFixed(4);
            this.allTaxItemsEqual = taxArray.every(function (val, i, arr) { return val === arr[0]; });
            this.firstTaxItem = taxArray[0];
            if (typeof this.firstTaxItem == 'undefined') {
                this.allTaxItemsEqual = false;
            }
        }
        else {
            this.calculatedNetPrice = 0.00;
            this.calculatedTotalAmount = 0.00;
            this.calculatedVatRate = 0.00;
        }
        if (this.hotInstance) {
            this.hotInstance.render();
        }
    };
    DocumentParsedDetailsComponent.prototype.setNewScheduledDate = function (dateValue) {
        this.invoiceDate = dateValue.startDate;
    };
    DocumentParsedDetailsComponent.prototype.checkInvoiceNumber = function (event) {
        var num = event.target.value;
        if (num.length == 0) {
            this.invoiceNumberErr = "Please enter invoice number";
        }
        else {
            this.invoiceNumberErr = null;
        }
        this.validation();
    };
    DocumentParsedDetailsComponent.prototype.saveParseData = function () {
        var _this = this;
        if (this.isDisabledConfirmButton) {
            var newSpreadSheetData = [];
            newSpreadSheetData = this.spreadSheetData.slice(0, -1);
            var data = {
                'parseData': {
                    'supplierName': this.csSelectedSupplierName,
                    'supplierId': this.csSelectedSupplierId,
                    'invoiceNumber': this.invoiceNumber,
                    'invoiceDate': this.invoiceDate,
                    'poNumber': this.poNumber,
                    'net': this.calculatedNetPrice,
                    'total': this.calculatedTotalAmount,
                    'tax': this.calculatedVatRate,
                    'lineitemDetails': newSpreadSheetData,
                    'selectedOption': this.selectedOption,
                }
            };
            this.fileUploadingService.saveAutomatedParseData(data, this.parsedDataId).subscribe(function (resp) {
                var part = 0;
                if (_this.selectedOption == 'parts') {
                    part = 1;
                }
                _this.fileUploadingService.convertParsedDataToInvoice(_this.parsedDataId, part).subscribe(function (resp) {
                    if (resp.status == 200 || resp.status == 201) {
                        _this.closeFSModal();
                        _this.toastBox.show(_this.translate.instant('Invoice.created.successfully'), 3000);
                    }
                    else {
                        if (resp.status == 400 && resp.hasOwnProperty('message')) {
                            _this.invoiceCreationError = resp.message;
                        }
                        _this.getParsedData();
                        _this.showInvoiceErrMsg = true;
                        setTimeout(function () {
                            _this.showInvoiceErrMsg = false;
                            _this.invoiceCreationError = _this.translate.instant('Error.while.creating.invoice');
                        }, 3000);
                    }
                }, function (error) {
                    _this.toastBox.show(_this.translate.instant('Error.while.creating.invoice'), 3000);
                    _this.getParsedData();
                });
            }, function (error) {
                if (_this.actionType == 'edit') {
                    _this.toastBox.show(_this.translate.instant('Error.while.updating.record'), 3000);
                }
                _this.getParsedData();
            });
        }
    };
    DocumentParsedDetailsComponent.prototype.navigateToInvoice = function () {
        var url = 'suppliers/list_supplier/' + this.csSelectedSupplierId + '/view/supplier_details';
        HybridFactory.navigate(url);
    };
    DocumentParsedDetailsComponent.prototype.saveCSSelectedSupplier = function () {
        if (this.confirmTextModel.trim().toUpperCase() == this.confirmText) {
            var data = {
                'saveAutomatedData': {
                    'commusoftName': this.csSelectedField,
                    'thirdpartyName': this.thirdPartyField,
                    'commusoftValue': this.csSelectedSupplierName,
                    'thirtypartyValue': this.thirdPartySupplierName,
                    'commusoftId': this.csSelectedSupplierId,
                    'parsedDataId': this.parsedDataId
                }
            };
            this.fileUploadingService.saveAutomatedDataMapping(data).subscribe(function (resp) {
            }, function (error) {
            });
            this.hideChildModal();
            this.csSelectedSupplierNameErr = null;
        }
    };
    DocumentParsedDetailsComponent.prototype.showChildModal = function (data) {
        var index = this.suppliersList.findIndex(function (value) { return value.id == parseInt(data); });
        if (index >= 0) {
            this.csSelectedSupplierName = this.suppliersList[index]['text'];
            this.csSelectedSupplierId = this.suppliersList[index]['id'];
            if (this.csSelectedSupplierId != null && typeof this.csSelectedSupplierId != 'undefined') {
                this.openChildModal = true;
                this.fadeBackground = true;
                this.csSelectedSupplierNameErr = null;
            }
            else {
                this.csSelectedSupplierNameErr = "Please select supplier name";
            }
        }
    };
    DocumentParsedDetailsComponent.prototype.hideChildModal = function () {
        this.openChildModal = false;
        this.fadeBackground = false;
        this.inValidConfirmText = false;
        this.confirmTextModel = '';
        this.callSelectPartsOrItemsApi();
        this.validation();
    };
    DocumentParsedDetailsComponent.prototype.loadImage = function () {
        var fileExtension = this.invoicePdf.split('.').pop();
        if (fileExtension == 'tiff' || fileExtension == 'tif') {
            this.isPdf = false;
            // this.http.get(this.invoicePdf, {responseType: 'arraybuffer'})
            //     .subscribe(data => {
            //         let tiff1 = new Tiff({ buffer: data });
            //         // To display .tiff file in canvas
            //         let canvas = tiff1.toCanvas();
            //         // To display .tiff file in .jpeg
            //         const jpgBase64Data = canvas.toDataURL("image/jpeg").replace(/^data:image\/jpeg;base64,/, "");
            //         this.finalBase64Data="data:image/jpeg;base64,"+jpgBase64Data;
            //     });
        }
    };
    // Validating confirmation text
    DocumentParsedDetailsComponent.prototype.checkConfirmText = function () {
        this.inValidConfirmText = !(this.confirmTextModel.trim().toUpperCase() == this.confirmText);
    };
    DocumentParsedDetailsComponent.prototype.closeFSModal = function () {
        this.closeModal.emit();
    };
    DocumentParsedDetailsComponent.prototype.changeOption = function (option) {
        this.selectedOption = option;
        if (this.selectedOption == 'parts') {
            if (this.hotInstance) {
                this.hotInstance.updateSettings({
                    hiddenColumns: {
                        columns: [0, 4],
                        indicators: false
                    },
                    hiddenRows: {
                        rows: [this.spreadSheetData.length - 1],
                        indicators: false
                    }
                });
            }
        }
        else {
            if (this.hotInstance) {
                this.hotInstance.updateSettings({
                    hiddenColumns: {
                        columns: [1],
                        indicators: false
                    },
                    hiddenRows: {
                        rows: [],
                        indicators: false
                    }
                });
            }
        }
        this.validation();
        this.calculateTotal();
    };
    DocumentParsedDetailsComponent.prototype.changePoNumber = function (event) {
        this.poNumber = event.target.value;
        this.callSelectPartsOrItemsApi();
    };
    DocumentParsedDetailsComponent.prototype.callSelectPartsOrItemsApi = function () {
        if (this.poNumber.length == 0) {
            // If po is null then no need to call the API.
            this.poNumber = '';
            this.poErrorCode = 1;
            this.poErrorMsg = '';
            this.selectedOption = 'items';
            this.changeOption(this.selectedOption);
        }
        this.getParsedData();
    };
    return DocumentParsedDetailsComponent;
}());
export { DocumentParsedDetailsComponent };
