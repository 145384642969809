import { EventEmitter, OnInit } from '@angular/core';
var SetcustomfieldvalueBlockComponent = /** @class */ (function () {
    function SetcustomfieldvalueBlockComponent(confirmationBoxHelper, ajsScope) {
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.isEdit = false;
        this.activeField = {};
        this.isConditional = false;
        this.isMarketPlaceView = false;
        this.hasChild = false;
        this.allFields = {};
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.isReadOnlyMode = false;
    }
    SetcustomfieldvalueBlockComponent.prototype.ngOnInit = function () {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if (this.isEdit) {
            this.activeCustomField = this.allFields[this.inputModel.model['col_name']];
        }
    };
    SetcustomfieldvalueBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    SetcustomfieldvalueBlockComponent.prototype.onSelectField = function (selected) {
        this.activeCustomField = this.allFields[selected];
        this.inputModel.model['templateId'] = this.activeCustomField['templateId'];
        this.inputModel.model['value'] = '';
        return;
    };
    SetcustomfieldvalueBlockComponent.prototype.addValue = function () {
        this.inputModel.model.textField = this.activeCustomField.text;
        this.outputModel.emit(this.inputModel);
    };
    SetcustomfieldvalueBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    SetcustomfieldvalueBlockComponent.prototype.validateItems = function () {
        if (!this.inputModel.model['col_name'] || !this.inputModel.model['value']) {
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator === '' || this.inputModel.condition.value === '' : false;
    };
    return SetcustomfieldvalueBlockComponent;
}());
export { SetcustomfieldvalueBlockComponent };
