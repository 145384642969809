<form *ngIf="addonForm && !isViewMode" [formGroup]="addonForm" >
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<div class="modal-body">
		<div class="help_section">
			<div class="help-text" translate>
				Add.addon.help.text
			</div>
		</div>
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">
					<div class="control-group clearfix" *ngIf="showAddOnTemplate">
						<div class="option-column1">
							<label class="cursor-pointer">
								<input type="radio" name="option" value="blank" (click)='optionSelected($event)' checked>
								<span  translate>blank.add-on</span>
							  </label>

						</div>
						<div class="option-column2" *ngIf="!isOptionTemplate && !editAddon && showAddOnTemplate">
							<label  class="cursor-pointer">
								<input type="radio" name="option" value="template" (click)='optionSelected($event)'>
								<span translate>template.add-on</span>
							  </label>
						</div>
					</div>
					<div class="control-group clearfix">
						<label class="control-label required_field" translate>add-on.name</label>
						<div class="controls">
							<input formControlName='optionName' *ngIf='addonType == "blank"' type="text" class="control-input" style="width: 85%;">
                            <!--<span [ngClass]="{
								'display-none':addonType != 'template'
							  }" *ngIf="!editAddon && !templatesLoading" >
								<input formControlName="optionName" type="hidden" csSelect2
								[select2Config]="select2TemplateConfig" [placeholder]="'&#45;&#45; Please select &#45;&#45;'"
								class="chosen-select full-width-select">
							</span>-->
                            <div class="search-template-box" *ngIf='addonType != "blank"'>
                                <ng-container>
                                    <app-search-template [screen]="'option_template'" [addon]="true" [screenLabel]="'Addon templates'"
                                                         [showAddNewTemplate]="showAddNewTemplate" [excludeOptions]="excludeOptions"
                                                         (selectedItem)="templateSelected($event)"></app-search-template>
                                </ng-container>
                            </div>
						</div>
                        <div *ngIf='addonType == "blank"' >
                            <label class="control-label" translate>Customer.displaying.name</label>
                            <input formControlName='customerOptionName'  type="text" class="control-input" style="width: 85%;">
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()" translate>Cancel</a>
		<button type="button" [disabled]='action != "save" || !addonForm.valid' class="btn btn-primary">
			<span *ngIf='action == "save"' translate (click)="save(addonType)">Save</span>
			<span *ngIf='action != "save"' translate>Saving</span>
		</button>
	</div>
</form>
<form *ngIf="addonForm && isViewMode" [formGroup]="addonForm">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>Customer display name</h4>
    </div>
    <div class="modal-body">
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">
                    <div class="control-group clearfix">
                        <label class="control-label" translate>Customer.displaying.name</label>
                        <div class="controls customer-margin">
                            <input readonly type="text" class="control-input" formControlName="customerOptionName">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()" translate>Close</a>
    </div>
</form>
