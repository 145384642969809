import { OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap";
import { Subject } from "rxjs/index";
import { mxgraph } from "mxgraph-factory";
import { TriggerDatasetService } from "@app/workflow-common/services/trigger-dataset.service";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var AddBlockPanelComponent = /** @class */ (function () {
    function AddBlockPanelComponent(modalRef, cd, triggerService, httpService) {
        this.modalRef = modalRef;
        this.cd = cd;
        this.triggerService = triggerService;
        this.httpService = httpService;
        this.showBlockConfig = '';
        this.selectedBlock = '';
        this.isConditional = false;
        this.activeField = {};
        this.selectedBlockModel = {};
        this.selectedTriggerFields = null;
        this.isEdit = false;
        this.isMarketPlaceView = false;
        this.actionBlocks = [];
        this.mainObjectName = '';
        this.hasChild = false;
        this.activeMainObjects = {};
        this.isDisabled = [];
        this.blocksList = [];
        this.workflowType = 'Custom';
        this.isReadOnlyMode = false;
        this.whitelistBlocks = [];
        this.showTaskBlock = true;
    }
    AddBlockPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isReadOnlyMode = this.isMarketPlaceView;
        this.options.objectName = this.mainObjectName;
        if (this.mainObjectName) {
            this.activeMainObjects = this.options['mainObjectsFields'][this.mainObjectName];
        }
        var typeName = this.workflowType == 'Custom' ? 'workflow' : 'sequence';
        this.warningMessage = 'The current ' + typeName + ' template is running in the background. so you can not add or delete blocks until the ' + typeName + ' is executed completely.';
        this.editBlockWarningMessage = 'The current ' + typeName + ' template is running in the background. If you edit the ' + typeName + ', it will affect the running ' + typeName + '.';
        this.httpService.getDagrunnerStatus(this.options.workflowId)
            .subscribe({
            next: function (resp) {
                if (resp.status == 'running') {
                    _this.options.isRunningStatus = true;
                }
                else {
                    _this.options.isRunningStatus = false;
                }
            }
        });
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        this.onClose = new Subject();
        this.hasChild = this.cell.getEdgeCount() > 1;
        var mainObjectCell = this.mxGraph.getModel().getCell('cs_initializer');
        var mainObject = mainObjectCell.getValue().mainObject;
        this.mainObjectName = mainObject;
        var cellValue = this.cell.getValue();
        var cellModel = cellValue['model'];
        this.cellModel = cellModel;
        this.operators = this.triggerService.getOperators();
        this.isConditional = cellValue['name'] === 'conditionalBlock' || cellValue['name'] === 'untilConditionBlock' ? true : false;
        this.deleteMessage = this.workflowType == 'Custom' ? 'This will delete this block and all the following blocks in this workflow.' : 'This will delete this block and all the following blocks in this sequence.';
        if (this.mxGraphService.workflowStatus) {
            this.deleteMessage = this.mxGraphService.deactivateMessage;
        }
        var childNodes = this.mxGraphService.getChildren(this.cell);
        // console.log('child', this.mxGraph.getModel().getCell(this.cell['parentId']), this.cell['parentId']);
        if (childNodes.length && !this.isEdit) {
            childNodes.forEach(function (node) {
                if (_this.cell.getId() == node['parentId'] && (node['block_name'] == 'conditionalBlock' || node['block_name'] == 'untilConditionBlock')) {
                    _this.isDisabled = ['untilConditionBlock', 'conditionalBlock'];
                    _this.toolTipMessage = 'You cannot add the If blocks because the subsequent \n block is already a branch block';
                }
            });
        }
        if (this.isConditional) {
            this.activeField = this.selectedTriggerFields[cellModel['col_name']];
        }
        //set dummy structure
        this.selectedBlockModel = this.prepareBlockModel(this.isConditional);
        if (this.options.hasOwnProperty('whitelist')) {
            this.whitelistBlocks = !this.options['whitelist']['blocks'] ? [] : this.options['whitelist']['blocks'];
            // console.log('whitelistBlocks', this.whitelistBlocks);
        }
        // console.log('isEdit', this.isEdit, this.activeField, this.cell);
        if (this.isEdit) {
            this.isReadOnlyMode = this.isMarketPlaceView && !this.whitelistBlocks.includes(cellValue['name'] + '_' + this.cell.getId());
            if (cellValue['name'] == 'delayBlock' && cellValue['model']['type'] === 'conditional') {
                this.openCloseBlockConfig('conditionalDelayBlock', cellModel);
            }
            else {
                this.openCloseBlockConfig(cellValue['name'], cellModel);
            }
            // console.log('cellValue', cellValue);
            this.isConditional = false;
            if (cellValue['condition'] && cellValue['condition']['col_name'] != '') {
                this.isConditional = true;
                this.selectedBlockModel['condition'] = cellValue['condition'];
                this.selectedBlockModel['isConditional'] = this.isConditional;
                this.activeField = this.selectedTriggerFields[cellValue['condition']['col_name']];
                // console.log('edit', this.selectedBlockModel);
            }
        }
        this.subscription = this.httpService.workflowEventTrigger$.subscribe(function (action) {
            if (action === 'autoSave') {
                _this.showTaskBlock = false;
            }
        });
    };
    AddBlockPanelComponent.prototype.ngAfterViewInit = function () {
        this.cd.detectChanges();
    };
    AddBlockPanelComponent.prototype.saveBlock = function () {
        if (this.selectedBlock === 'delayBlock') {
            if (this.selectedBlockModel['model']['type'] === 'conditional') {
                this.selectedBlockModel['model']['fieldLabel'] = this.selectedTriggerFields[this.selectedBlockModel['model']['col_name']]['text'];
            }
        }
        else if (this.selectedBlock === 'conditionalDelayBlock') {
            this.selectedBlockModel['model']['fieldLabel'] = this.selectedTriggerFields[this.selectedBlockModel['model']['col_name']]['text'];
        }
        // console.log('selectedBlockModel', this.selectedBlockModel);
        this.onClose.next(this.selectedBlockModel);
        this.modalRef.hide();
        //this.showBlockConfig='';
    };
    AddBlockPanelComponent.prototype.addBlockConfiguration = function (event) {
        if (event === void 0) { event = null; }
        this.saveBlock();
    };
    AddBlockPanelComponent.prototype.closeBlockModal = function (closeAll) {
        if (closeAll === 'remove') {
            if (this.mxGraphService.workflowStatus) {
                this.modalRef.hide();
                this.httpService.triggerEvent('deactivate_action');
            }
            else {
                this.onClose.next('deleteBlock');
            }
        }
        if (closeAll) {
            this.modalRef.hide();
        }
        else {
            this.selectedBlock = this.showBlockConfig = '';
        }
    };
    AddBlockPanelComponent.prototype.openCloseBlockConfig = function (blockName, modelValue, isDisabledMode) {
        if (blockName === void 0) { blockName = ''; }
        if (modelValue === void 0) { modelValue = null; }
        if (isDisabledMode === void 0) { isDisabledMode = false; }
        if (this.isDisabled.length && (blockName == 'conditionalBlock' || blockName == 'untilConditionBlock')) {
            return;
        }
        if (isDisabledMode) {
            return;
        }
        if (this.isConditional && blockName === 'conditionalBlock' && !this.isEdit) {
            //return false;
        }
        if (blockName === '') {
            this.selectedBlockModel = this.prepareBlockModel(this.isConditional);
        }
        else {
            this.selectedBlockModel['name'] = blockName;
            this.selectedBlockModel['config'] = this.getBlockConfiguration(blockName);
            this.selectedBlockModel['model'] = this.getBlockModel(blockName, modelValue);
            if (modelValue && modelValue['condition']) {
                this.selectedBlockModel['condition'] = modelValue;
            }
        }
        this.showBlockConfig = blockName;
        this.selectedBlock = blockName;
    };
    AddBlockPanelComponent.prototype.getBlockConfiguration = function (blockName) {
        var config = {
            delayBlock: {
                intervalList: _.range(0, 16),
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields,
                units: ['minutes', 'hours', 'days', 'months']
            },
            conditionalDelayBlock: {
                intervalList: _.range(0, 16),
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields,
                units: ['minutes', 'hours', 'days', 'months']
            },
            conditionalBlock: {
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields
            },
            untilConditionBlock: {
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields,
                intervalList: _.range(0, 16),
                units: ['hours', 'days'],
                untilUnits: ['hours', 'weeks', 'days', 'months']
            },
            emailBlock: {
                intervalList: _.range(0, 16),
                units: ['minutes', 'hours', 'days', 'months']
            },
            officeTaskBlock: {
                fields: this.getFields(),
                fieldsByColName: this.selectedTriggerFields,
                customFields: this.getCustomFields(),
            },
            smsBlock: {
                intervalList: _.range(0, 16),
                units: ['minutes', 'hours', 'days', 'months']
            },
            notificationBlock: {
                fields: this.getFields(),
                fieldsByColName: this.selectedTriggerFields,
                customFields: this.getCustomFields(),
            },
            setcustomfieldvalueBlock: {
                customFields: this.getCustomFields(),
            },
            scheduledActivityBlock: {}
        };
        return config.hasOwnProperty(blockName) ? config[blockName] : null;
    };
    AddBlockPanelComponent.prototype.prepareBlockModel = function (isConditional) {
        if (isConditional === void 0) { isConditional = false; }
        var inputs = {
            name: '',
            config: {},
            model: {},
            isConditional: this.isConditional
        };
        // forming model of conditional type field
        if (isConditional) {
            inputs['condition'] = {
                operator: '',
                value: '',
                col_name: this.cellModel['col_name'],
                text: this.activeField.text,
                type: this.activeField.type,
                fieldType: this.activeField.hasOwnProperty('fieldType') ? this.activeField['fieldType'] : '',
                pageType: this.activeField.hasOwnProperty('pageType') ? this.activeField['pageType'] : '',
            };
        }
        return inputs;
    };
    AddBlockPanelComponent.prototype.getBlockModel = function (blockName, value) {
        if (value === void 0) { value = null; }
        // console.log('value', value);
        var config = {
            delayBlock: {
                interval: value === null ? 0 : value.hasOwnProperty('interval') ? value['interval'] : 0,
                unit: value === null ? 'hours' : value.hasOwnProperty('unit') ? value['unit'] : 'hours',
                type: value === null ? 'default' : value.hasOwnProperty('type') ? value['type'] : 'default',
            },
            conditionalDelayBlock: {
                interval: value === null ? 0 : value.hasOwnProperty('interval') ? value['interval'] : 0,
                unit: value === null ? 'days' : value.hasOwnProperty('unit') ? value['unit'] : 'days',
                col_name: value === null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : '',
                type: 'conditional',
                event_method: value === null ? 'pass on before' : value.hasOwnProperty('event_method') ? value['event_method'] : 'pass on before',
            },
            conditionalBlock: {
                col_name: value === null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : ''
            },
            untilConditionBlock: {
                col_name: value === null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : '',
                interval: value === null ? 1 : value.hasOwnProperty('interval') ? value['interval'] : 1,
                unit: value === null || !value['unit'] ? 'days' : value.hasOwnProperty('unit') ? value['unit'] : 'days',
                untilUnit: value === null || !value['untilUnit'] ? 'days' : value.hasOwnProperty('untilUnit') ? value['untilUnit'] : 'days',
                untilPeriod: value === null ? null : value.hasOwnProperty('untilPeriod') ? value['untilPeriod'] : null,
                untilType: value === null || !value['untilType'] ? 2 : value.hasOwnProperty('untilType') ? value['untilType'] : 2,
                untilManualPeriod: value === null ? null : value.hasOwnProperty('untilManualPeriod') ? value['untilManualPeriod'] : null,
            },
            emailBlock: {
                subject: value === null ? '' : value.hasOwnProperty('subject') ? value['subject'] : '',
                message: value === null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                templateId: value === null ? '' : value.hasOwnProperty('templateId') ? value['templateId'] : '',
                toEmailTag: value === null ? '' : value.hasOwnProperty('toEmailTag') ? value['toEmailTag'] : '',
                ccEmailTag: value === null ? '' : value.hasOwnProperty('ccEmailTag') ? value['ccEmailTag'] : '',
                bccEmailTag: value === null ? '' : value.hasOwnProperty('bccEmailTag') ? value['bccEmailTag'] : '',
                fromEmail: value === null ? '' : value.hasOwnProperty('fromEmail') ? value['fromEmail'] : [],
            },
            officeTaskBlock: {
                assignTo: value === null ? '' : value.hasOwnProperty('assignTo') ? value['assignTo'] : '',
                assignToDisplay: value === null ? '' : value.hasOwnProperty('assignToDisplay') ? value['assignToDisplay'] : '',
                message: value === null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                textMessage: value === null ? '' : value.hasOwnProperty('textMessage') ? value['textMessage'] : '',
                messageDelta: value === null ? '' : value.hasOwnProperty('messageDelta') ? value['messageDelta'] : '',
                tagsDisplay: value === null ? [] : value.hasOwnProperty('tagsDisplay') ? value['tagsDisplay'] : [],
            },
            addjobBlock: {
                jobDescriptionId: value === null ? '' : value.hasOwnProperty('jobDescriptionId') ? value['jobDescriptionId'] : null,
                jobDescriptionDisplay: value === null ? '' : value.hasOwnProperty('jobDescriptionDisplay') ? value['jobDescriptionDisplay'] : '',
            },
            chatmessageBlock: {
                textMessage: value === null ? '' : value.hasOwnProperty('textMessage') ? value['textMessage'] : '',
                messageDelta: value === null ? '' : value.hasOwnProperty('messageDelta') ? value['messageDelta'] : '',
                channelName: value === null ? '' : value.hasOwnProperty('channelName') ? value['channelName'] : '',
            },
            addDairyBlock: {
                eventType: '',
                eventStartDate: '',
                eventTime: '',
                assignEngg: ''
            },
            smsBlock: {
                message: value === null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                title: value === null ? '' : value.hasOwnProperty('title') ? value['title'] : '',
                templateId: value === null ? '' : value.hasOwnProperty('templateId') ? value['templateId'] : '',
                toSmsTag: value === null ? '' : value.hasOwnProperty('toSmsTag') ? value['toSmsTag'] : '',
            },
            notificationBlock: {
                roles: value === null ? [] : value.hasOwnProperty('roles') ? value['roles'] : [],
                rolesDisplay: value === null ? [] : value.hasOwnProperty('roles') ? value['roles'] : [],
                assignTo: value === null ? '' : value.hasOwnProperty('assignTo') ? value['assignTo'] : '',
                assignToDisplay: value === null ? '' : value.hasOwnProperty('assignToDisplay') ? value['assignToDisplay'] : '',
                message: value === null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                textMessage: value === null ? '' : value.hasOwnProperty('textMessage') ? value['textMessage'] : '',
                messageDelta: value === null ? '' : value.hasOwnProperty('messageDelta') ? value['messageDelta'] : '',
                tagsDisplay: value === null ? [] : value.hasOwnProperty('tagsDisplay') ? value['tagsDisplay'] : [],
            },
            setcustomfieldvalueBlock: {
                col_name: value === null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : '',
                templateId: value === null ? '' : value.hasOwnProperty('templateId') ? value['templateId'] : '',
                value: value === null ? '' : value.hasOwnProperty('value') ? value['value'] : ''
            },
            scheduledActivityBlock: {
                assigneeIds: value === null ? [] : value.hasOwnProperty('assigneeIds') ? value['assigneeIds'] : [],
                unit: value === null || !value['unit'] ? 'days' : value.hasOwnProperty('unit') ? value['unit'] : 'days',
                notes: value === null ? '' : value.hasOwnProperty('notes') ? value['notes'] : '',
                description: value === null ? '' : value.hasOwnProperty('description') ? value['description'] : '',
                activityType: value === null ? '' : value.hasOwnProperty('activityType') ? value['activityType'] : '',
                scheduleInterval: value === null ? 0 : value.hasOwnProperty('scheduleInterval') ? value['scheduleInterval'] : 0,
                scheduleTime: value === null ? '' : value.hasOwnProperty('scheduleTime') ? value['scheduleTime'] : '',
            }
        };
        return config.hasOwnProperty(blockName) ? config[blockName] : null;
    };
    AddBlockPanelComponent.prototype.getCustomFields = function () {
        if (!this.activeMainObjects.hasOwnProperty('custom_fields')) {
            return [];
        }
        return this.activeMainObjects['custom_fields'];
    };
    AddBlockPanelComponent.prototype.getFields = function () {
        var fields = [];
        _.forEach(this.activeMainObjects['fields'], function (field) {
            fields.push({
                id: field['col_name'],
                text: field.text,
                type: field.type,
                fieldType: field.hasOwnProperty('fieldType') ? field['fieldType'] : null,
                pageType: field.hasOwnProperty('pageType') ? field['pageType'] : null
            });
        });
        return fields;
    };
    AddBlockPanelComponent.prototype.isValid = function () {
        var isValid = [];
        if (this.selectedBlock === 'conditionalBlock') {
            isValid.push(this.selectedBlockModel.model['col_name'] === '' ? false : true);
        }
        else if (this.selectedBlock === 'delayBlock') {
            isValid.push(this.selectedBlockModel.model['interval'] === '' ? false : true);
        }
        else if (this.selectedBlock === 'emailBlock') {
            isValid.push(true);
        }
        else if (this.selectedBlock === 'smsBlock') {
            isValid.push(true);
        }
        else {
            isValid.push(false);
        }
        if (this.isConditional) {
            var valid = this.selectedBlockModel.condition['operator'] != ''
                && this.selectedBlockModel.condition['value'] != '';
            isValid.push(valid);
        }
        //console.log('isvalid', isValid);
        if (_.contains(isValid, false)) {
            return false;
        }
        else {
            return isValid.length === 0 ? false : true;
        }
    };
    AddBlockPanelComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.showTaskBlock = true;
        }
    };
    return AddBlockPanelComponent;
}());
export { AddBlockPanelComponent };
