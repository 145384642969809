import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit, Output,
    ViewChild
} from '@angular/core';
import {CsFullScreenModalComponent} from "@app/directives";
import {mxgraph, mxgraphFactory} from "mxgraph-factory";
import {MxGraphSequenceService} from "@app/workflow-common/services/mx-graph-sequence";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {Subscription} from "rxjs";
import {DiagramViewComponent} from "@app/workflow-common/diagram-view/diagram-view.component";
import {noop, Subject} from "rxjs/index";
import {CsToastBoxService} from "@app/services";
const {
    mxGraph,
    mxCodec,
    mxUtils,
    mxGeometry,
} = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

@Component({
  selector: 'app-diagram-view-sequence',
  templateUrl: './diagram-view-sequence.component.html',
  styleUrls: ['./diagram-view-sequence.component.scss']
})
export class DiagramViewSequenceComponent extends DiagramViewComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() data:any;
    @Input() csFullModal: CsFullScreenModalComponent;
    @Input() viewMode:boolean=false;
    @Input() scheduleActivityTypes:any[] = [];
    @Input() mode:string = 'sequence_setup'; // sequence_config
    @Input() workflowType: string = 'Sequence';
    @Input() additionalData: object = {};
    @Input() sequenceObjectName: string;
    @Output() afterRenderDiagram: EventEmitter<any> = new EventEmitter<any>();
    @Output() beforeRenderDiagram: EventEmitter<any> = new EventEmitter<any>();

    sourceXml:string=null;
    mxGraph: mxgraph.mxGraph;
    subscription: Subscription;

    showConfirmation: boolean = false;
    confirmationAction: string = '';
    confirmTextInputModel: string = '';
    inValidConfirmText: boolean = false;
    confirmText:string = '';
    activeCell: mxgraph.mxCell;
    validationMessage: string;
    popupModalOnClose: Subject<any>;

    @ViewChild('mx_container') container: ElementRef<HTMLElement>;
    @ViewChild('outlineContainer') outlineContainer: ElementRef<HTMLElement>;

  constructor(
      public diagramService: MxGraphSequenceService,
      public httpService: WorkflowhttpService,
      @Inject('confirmationBoxHelper')  public confirmationBoxHelper,
      @Inject('$scope') public ajsScope,
      public toastBox: CsToastBoxService,
  ) {
      super(diagramService, httpService, confirmationBoxHelper, ajsScope, toastBox);
  }


  ngOnInit() {
      this.subscription = this.httpService.workflowEventTrigger$.subscribe({
          next: (content:any) => {
              if(typeof content == 'string') {
                  if(content === 'autoSave' && this.mode == 'sequence_setup') {
                      this.saveXml(false);
                  }
              }
              else if(typeof content == 'object') {
                  const { action, value } = content;
                  if(action === 'showSkipConfirmation') {
                      this.confirmationAction = 'skip';
                      this.confirmText = 'SKIP';
                      this.showConfirmation=true;
                      this.activeCell = value;
                  }
                  else if (action === 'showUndoConfirmation') {
                      this.confirmationAction = 'undo';
                      this.confirmText = 'UNDO';
                      this.showConfirmation=true;
                      this.activeCell = value;
                  }
              }
          }
      });
  }

  ngAfterViewInit(): void {
      this.beforeRenderDiagram.emit(this);
      const options = {
          xmlSource: this.data.xmlsource,
          workflowId: this.data.id,
          workflowStatus: this.data.status == 1,
          isScheduler: false,
          outlineContainer: this.outlineContainer,
          viewMode: this.viewMode,
          userRoles: this.userRoles,
          tags: this.tags,
          scheduleActivityTypes: this.scheduleActivityTypes,
          usersList: this.usersList,
          workflowType: this.workflowType,
          additionalData: this.additionalData,
          mode: this.mode,
          sequenceObjectName: this.sequenceObjectName,
          mainObjectName: this.sequenceObjectName,
          confirmationBoxHelper: this.confirmationBoxHelper,
          ajsScope: this.ajsScope
      };
      if(this.mode == 'sequence_setup') {
          this.diagramService.initialize(this.container, options);
          this.diagramService.setDiagramComponent(this);
          this.mxGraph = this.diagramService.getGraph();
          this.diagramService.render( (mxGraph:mxgraph.mxGraph) => {
              this.afterRenderDiagram.emit(mxGraph);
          });
      }
      else if(this.mode == 'sequence_config') {
          this.beforeInitialize( (xmlSource: string) => {
              options.xmlSource = xmlSource;
              this.diagramService.initialize(this.container, options);
              this.diagramService.setDiagramComponent(this);
              this.mxGraph = this.diagramService.getGraph();
              const c = this.mxGraph.getModel().getCell('cs_initializer');
              this.diagramService.render( (mxGraph:mxgraph.mxGraph) => {
                  this.afterRenderDiagram.emit(mxGraph);
                  this.mxGraph.updateCellSize(c, true);
              });
          });
      }
  }

  beforeInitialize( cb: Function = noop) {
      let doc = mxUtils.parseXml(this.data.xmlsource);
      let codec = new mxCodec(doc);
      const $mxGraph = new mxGraph(this.container.nativeElement);
      codec.decode(doc.documentElement, $mxGraph.getModel());
      let initializerBlock:mxgraph.mxCell = codec.getObject('cs_initializer');
      let data = {
          model: this.additionalData,
          action: 'sequence_save_send'
      };
      initializerBlock.setValue(data);
      const container = this.diagramService.prepareInitBlockContainer(data, initializerBlock)
      let current = initializerBlock.getGeometry();
      current.width = container['width'];
      current.height = container['height'];
      initializerBlock.setGeometry(new mxGeometry(current.x, current.y, current.width, current.height));
      const selectedContact = this.additionalData['selectedContact'];
      const contactHasMobile = !selectedContact['mobile'] ? false :  selectedContact['mobile'].length > 0
      const triggerData = {
          action: 'save:disabled',
          value: !(this.additionalData['displayContactInfo']['emailAddress'] || this.additionalData['displayContactInfo']['mobile'])
          || (contactHasMobile && !this.additionalData['displayContactInfo']['mobile']),
      };
      this.httpService.triggerCustomHeaderEvent(triggerData);
      const codec2 = new mxCodec();
      let result = codec2.encode($mxGraph.getModel());
      let newXmlSource = mxUtils.getXml(result);
      $mxGraph.destroy();
      cb(newXmlSource);
  }

    checkConfirmText() {
        this.inValidConfirmText = !(this.confirmTextInputModel.trim().toUpperCase() == this.confirmText);
    }

    onCloseConfirmation() {
      this.showConfirmation = false;
      this.confirmTextInputModel = '';
      this.confirmationAction = '';
      this.confirmText = '';
      this.activeCell = null;
    }

    setConfirmSkipUndo() {
      this.checkConfirmText();
      if(this.inValidConfirmText) {
          return false;
      }
      if(this.activeCell) {
          let cellValue = this.activeCell.getValue();
          if(!cellValue['model']['current_task_status']) {
              cellValue['model']['current_task_status'] = 'skip';
          }
          else {
              cellValue['model']['current_task_status'] = '';
          }
          this.diagramService.mxGraph.labelChanged(this.activeCell, cellValue);
          this.onCloseConfirmation();
      }
    }

  sendSequenceAction() {
      const triggerData = {
          action: 'save:disabled',
          value: true,
      };
      this.httpService.triggerCustomHeaderEvent(triggerData);
      const {selectedSequenceId, objectId, displayContactInfo, selectedContact, displaySelectedContactName, displaySequenceName, contactId}:any = this.additionalData;
      const sequence = {};
      sequence['workflowId'] = selectedSequenceId;
      sequence['sequenceObjectName'] = this.sequenceObjectName;
      sequence['objectId'] = objectId;
      sequence['workflowType'] = 'Sequence';
      sequence['xmlSource'] = this.diagramService.getXml();
      sequence['dagSource'] = this.prepareDag();
      sequence['dagsource'] = JSON.parse(this.diagramService.diagramComponent.data.dagsource);
      sequence['sequenceData'] = {
          displayContactInfo: sequence['dagSource'].blockObjects.cs_initializer.model.displayContactInfo,
          selectedContact: sequence['dagSource'].blockObjects.cs_initializer.model.selectedContact,
          displaySelectedContactName:  sequence['dagSource'].blockObjects.cs_initializer.model.displaySelectedContactName,
          displaySequenceName: sequence['dagSource'].blockObjects.cs_initializer.model.displaySequenceName,
          selectedSequenceId: sequence['dagSource'].blockObjects.cs_initializer.model.selectedSequenceId,
          contactId: sequence['dagSource'].blockObjects.cs_initializer.model.contactId,
          exitCriteria: sequence['dagsource'].blockObjects.exitCriteria
      };
      this.httpService.postSendSequenceRequest({sequence}, this.sequenceObjectName, objectId)
      .subscribe( {
          next: (resp: any) => {
              if(resp['status'] == 'success') {
                  this.createDagRunAction(resp['data']);
              }
              else if(resp['status'] == 'error' && resp['warning']) {
                  // this.sendingSequenceText = 'Send'
              }
          }
      })
      //console.log(sequence);
  }

  createDagRunAction(data:any) {
      const { objectId }:any = this.additionalData;
      const sequence = {};
      sequence['workflowId'] = data['workflowId'];
      sequence['sequenceObjectName'] = this.sequenceObjectName;
      sequence['objectId'] = objectId;
      sequence['dagRunId'] = data['dagRunId'];
      sequence['dagStatus'] = data['dagStatus'];
      this.httpService.posSequenceDagRunCreateRequest({sequence}, this.sequenceObjectName, objectId)
      .subscribe({
          next: (resp: any) => {
              if(resp['status'] == 'success') {
                  this.csFullModal.close();
                  let respData = resp['data'];
                  respData['closePopup'] = true;
                  this.popupModalOnClose.next(respData);
              }
              else if(resp['status'] == 'error' && resp['warning']) {
                  const triggerData = {
                      action: 'save:disabled',
                      value: false,
                  };
                  this.httpService.triggerCustomHeaderEvent(triggerData);
              }
          }
      });
  }

  ngOnDestroy(): void {
      this.diagramService.mxOutline.destroy();
      this.diagramService.destroyMxGraph();
      this.data= {};
      this.subscription.unsubscribe();
      console.log('destroyed2 mxgraph');
  }

    @HostListener('window:resize', ['$event'])
    onResize() {
    }
}
