import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as moment from 'moment';
import {FileUploader} from "ng2-file-upload";
import {FormParserServicesService} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/services/form-parser-services.service";
import {CsToastBoxService} from "@app/services";
import {DomSanitizer} from '@angular/platform-browser';
import {AppValidators} from "@app/directives";
import {environment} from "@app/utils/get-api-url";
import { DISABLED } from '@angular/forms/src/model';
import { DOCUMENT } from '@angular/common';
const URL = environment.apiBasePath+'uploadcertificateimage';
declare var $:any;

@Component({
  selector: 'app-dynamic-certificate-form-parser',
  templateUrl: './dynamic-certificate-form-parser.component.html',
  styles: []
})
export class DynamicCertificateFormParserComponent implements OnInit {
  form: FormGroup;
  pages: any;
  select2Config = {
      width: "270px",
  };
  bsConfig: object = {
      adaptivePosition: true
  };

  // Time
  hoursArray: any = [];
  minutesArray: any = [];
  meridiemArray: any = ['AM', 'PM'];
  hour: string;
  minutes: string;
  meridiem: string;

  numberFields: any = [];
  decimalFields: any = [];
  dateFields: any = [];
  photoFields: any = [];
  timeFields: any = {};
  signatureFields: any = [];
  checkboxGroupFields: any = {};
  pageWiseFieldIds: any = {};

  uploaderArray: any = {};
  photoFileInvalidStatus: any = [];
  currentPhotoFormControl: string = '';
  select2LabelConfig: any = {};
  format = (item) => {
        return item.text;
  };

  // list page
  searchText: string = '';
  appliances: any = [];
  limit: number = 50;
  showListFormSidepanel: boolean = false;
  listItemForm: FormArray;
  listItemCount: number = 0;
  numberFieldsList: any = [];
  decimalFieldsList: any = [];
  dateFieldsList: any = [];
  photoFieldsList: any = [];
  timeFieldsList: any = {};
  checkboxGroupFieldsList: any = {};
  singleCheckBoxFieldsList: any = [];
  uploaderArrayList: any = {};
  disableSave: boolean = false;
  listItemValueArray = {};
  listFormLabels: any = {};
  currentListIndex: number = -1;
  type: string = 'ADD'; // 'ADD' or 'EDIT
  listFormIndexAndPageIndex = {};
  formValue: any = {};
  needFetchApplianceData: boolean = false;
  currentlySelectedAppliance: any;
  applianceGroupTypes: any = [];
  typeId = '';
  loading: boolean = false;
  hasAssetModuleAccess: boolean;
  assetData: any = {};
  assetGroupObjectKey: string = '';
  keyLabelArray: any = {};

  // Signature
  showSignatureSidePanel: boolean = false;
  currentSignatureFormControl: string = '';

  // Input with selection choices list
  select2LabelConfigList: any = {};

  // Measurements
  measurements: any = {};
  measurementsValues = {};

  // Dynamic fields
  defaultFieldOptions: any = {};

  // Service job date
  enableServiceJobDate: boolean = false;
  serviceReminderData: any[] = [];
  currentServiceJobDateId: string = '';
  serviceJobDate: string = '';
  listItemFieldOrderArray = {};
  userName: string;
  photoFieldValues: any = {};
  @Input('pageTitle') pageTitle: string='';
  @Input('formData') formData: any;
  @Input('currentPageNumber') currentPageNumber: number = 0;
  @Input('draftData') draftData: any = {};
  @Input('customerId') customerId: number;
  @Input('certificateId') certificateId: number;
  @Input('jobId') jobId: string;
  @Input('customTemplateScreen') customTemplateScreen: number=0; //0-Default
    // value to show all fields
  @Input('opportunityId') opportunityId: number;
  @Output('formStatusChanges') formStatusChanges = new EventEmitter();
  @Output('formValueChanges') formValueChanges = new EventEmitter();
  @Input('customFieldsConfigId') customFieldsConfigId: any;
  @Input('pageId') pageId: any;
  @Input('isDowngrade') isDowngrade: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private toastBox: CsToastBoxService,
              public _DomSanitizer: DomSanitizer,
              @Inject('confirmationBoxHelper') private confirmationBoxHelper,
              @Inject('$scope') private ajsScope,
              @Inject(DOCUMENT) private document,
              private formParserServicesService: FormParserServicesService) {
  }

  ngOnInit() {
      for(let i = 0 ; i < 60; i++) {
          let value = this.pad(i);
          if(i != 0 && i <= 12){
              this.hoursArray.push(value)
          }
          this.minutesArray.push(value)
      }
      this.listItemForm = this.formBuilder.array([]);
  }

  ngAfterViewInit(){
      if(this.isDowngrade){
          this.document.body.classList.add('dc-form-parser');
      }
  }

  ngOnDestroy() {
      if(this.isDowngrade) {
          this.document.body.classList.remove('dc-form-parser');
      }
  }

  onShownDatepicker(text:string) {
      if(this.isDowngrade) {
          this.document.body.classList.remove('dc-shown-datepicker');
          setTimeout(() => {
              if(text == 'shown') {
                  this.document.body.classList.add('dc-shown-datepicker');
              }
          }, 601)
      }
  }

  ngOnChanges(changes){
      if(changes['draftData']){
          this.formValue = this.draftData;
          if(this.listItemForm && this.listItemForm.length)
              if(this.currentPageNumber && this.pages && this.pages[this.currentPageNumber]['pageType'] == 'list')
                  this.setListFormAndApplyPatch();
          if(this.form)
              this.setFormDraftData();
      }
      if(changes['customerId']){
          if(this.needFetchApplianceData) this.fetchApplianceData();
      }
      if(changes['certificateId']){
          if(this.certificateId){
              this.setDynamicFieldValues();
          }
      }
      if(changes['jobId']){
          if(this.jobId){
              this.fetchServiceReminderData();
          }
      }
      if(changes['formData']){
          this.setFormData(this.formData);
      }
  }

  pad(value) {
      return ( value.toString().length < 2 ) ? '0' + value : value;
  }

  /**=========================================================================================
       Handle switching switching page in parent component
  ==========================================================================================*/
  public selectPage(index){
      this.currentPageNumber = index;
      this.formStatusChangeEmit();
      let currentPage = this.pages[index];
      this.formParserServicesService.checkDependantFields(currentPage, this.formValue, this.currentPageNumber).then((value)=>{
          this.formStatusChangeEmit();
      });
      if(currentPage && currentPage['pageType'] == 'list'){
          this.formStatusChanges.emit(true);
          if(this.listItemForm && this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber])){
              let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
              this.listItemCount = formArray.length;
              this.currentListIndex = this.listItemCount - 1;
          }
          let intervel = setInterval(() =>{
              if(this.hasAssetModuleAccess !== undefined) {
                  this.setListFormAndApplyPatch();
                  clearInterval(intervel)
              }
          },100);
      }
      this.setMeasurementValueOnPageChange();
  }

  /**=========================================================================================
       Emits form status changes to the parent component
  ==========================================================================================*/
  formStatusChangeEmit(){
      let currentPageFields = this.pageWiseFieldIds[this.currentPageNumber];
      let currentPageDependantFields = this.formParserServicesService.pageWiseDependantFields[this.currentPageNumber];
      if(currentPageFields){
          let formValid = true;
          for(let i = 0; i < currentPageFields.length; i++){
              let controlName = currentPageFields[i];
              let controlObject;
              if(currentPageDependantFields) {
                  let index = currentPageDependantFields.findIndex((value) => {
                      return value['id'] == controlName;
                  });
                  if (index != -1) {
                      controlObject = currentPageDependantFields[index];
                  }
              }
              if(this.form.get(controlName) && this.form.get(controlName).invalid){
                  formValid = false;
                  if(controlObject && !controlObject['required']) formValid = true;
                  if(!formValid) break;
              }
          }

          for(let h = 0; h < this.singleCheckBoxFieldsList.length; h++){
              let controlNameChk = this.singleCheckBoxFieldsList[h];
              if(this.form.get(controlNameChk).value==false){
                  formValid = false;
                  break;
              }
          }
          if(this.customFieldsConfigId!='' && this.customFieldsConfigId!=undefined){
              this.formStatusChanges.emit({'ID' : this.customFieldsConfigId,'formValidStatus':formValid});
          }else{
              this.formStatusChanges.emit(formValid);
          }
      }
  }

  /**=========================================================================================
       Emits form value changes to the parent component
  ==========================================================================================*/
  formSValueChangeEmit(formValue){
     if(this.decimalFields){
         this.decimalFields.forEach((questionId) =>{
             if(formValue[questionId]){
                 if(this.measurements && this.measurements[questionId]){
                     formValue[questionId] = this.splitValueFromMeasurement(formValue[questionId], questionId);
                 }
                 if(!this.isNumber(formValue[questionId]))
                     formValue[questionId] = "";
             }
         });
     }
     if(this.numberFields){
          this.numberFields.forEach((questionId) =>{
              if(formValue[questionId]){
                  if(this.measurements && this.measurements[questionId]){
                      formValue[questionId] = this.splitValueFromMeasurement(formValue[questionId], questionId);
                  }
                  if(!this.isNumber(formValue[questionId]))
                      formValue[questionId] = "";
              }
          });
     }
     if(this.dateFields){
         this.dateFields.forEach((questionId) =>{
             if(formValue[questionId]){
                 if(this.customFieldsConfigId && this.customFieldsConfigId!=undefined){
                     let formatDate = moment(formValue[questionId]).format('YYYY-MM-D');
                     formValue[questionId] = formatDate;
                 }else{
                     let formatDate = moment(formValue[questionId]).format('ddd D MMM YYYY');
                     formValue[questionId] = formatDate;
                 }
             }});
      }

      // List
      if(this.decimalFieldsList){
          this.decimalFieldsList.forEach((questionId) =>{
              if(formValue[questionId]){
                  if(this.measurements && this.measurements[questionId]){
                      formValue[questionId] = this.splitValueFromMeasurement(formValue[questionId], questionId);
                  }
                  if(!this.isNumber(formValue[questionId]))
                      formValue[questionId] = "";
              }
          });
      }
      if(this.numberFieldsList){
          this.numberFieldsList.forEach((questionId) =>{
              if(formValue[questionId]){
                  if(this.measurements && this.measurements[questionId]){
                      formValue[questionId] = this.splitValueFromMeasurement(formValue[questionId], questionId);
                  }
                  if(!this.isNumber(formValue[questionId]))
                      formValue[questionId] = "";
              }
          });
      }
      if(this.dateFieldsList){
          this.dateFieldsList.forEach((questionId) =>{
              if(formValue[questionId]){
                  let formatDate = moment(formValue[questionId]).format('ddd D MMM YYYY');
                  formValue[questionId] = formatDate;
              }});
     }
     if(this.signatureFields){
         this.signatureFields.forEach(questionId =>{
             if(formValue[questionId]){
                 if(formValue[questionId].includes('data:image/png;base64')) {
                     let formattedBase64 = formValue[questionId].substring(22);
                     formValue[questionId] = formattedBase64;
                 }
             }
         });
     }
     // Append measurement at the end of the value
     if(this.measurements){
         Object.keys(this.measurements).forEach((key) =>{
            if(this.measurements[key] !== undefined && this.measurements[key] != ''
                && formValue && key in formValue && formValue[key] !== undefined && formValue[key] != ''){

                if(!formValue[key].includes(this.measurements[key]))
                    formValue[key] = `${formValue[key]} ${this.measurements[key]}`;
            }
         });
     }
     this.formValue =  {...this.formValue, ...formValue};
     this.formParserServicesService.checkDependantFields(this.pages[this.currentPageNumber], this.formValue, this.currentPageNumber).then((value)=>{
         this.formStatusChangeEmit();
         let page = this.pages[this.currentPageNumber];
         if(page && page['pageType'] != 'list'){
             page['sections'].forEach((section) =>{
                 section['fields'].forEach((field) =>{
                    if((field.hasOwnProperty('previousShowStatus') && !field.previousShowStatus && field.show)){
                        if(this.checkboxGroupFields[field['questionID']] && this.checkboxGroupFields[field['questionID']].length){
                            this.checkboxGroupFields[field['questionID']].forEach(row => {
                                row.formValue = '';
                            });
                        }
                    }
                     if((section['hasDependency'] && !section['show']) || (field['hasDependency'] && !field['show'])){
                         if(this.form.get(field['questionID'])){
                           this.form.get(field['questionID']).setValue(undefined, {emitEvent:false});
                         }
                     }
                 });

             });
             // this.formValue = {...this.formValue, ...this.form.value};
             this.formValueChanges.emit(this.formValue);
         }
     });
     // Updated photo field values in the main form
     this.updatePhotoFieldValues();

     this.formValueChanges.emit(this.formValue);
  }

  isNumber(value) {
        return Number(value) !== NaN && isFinite(value);
  }

  /**=========================================================================================
       If the current page is list page, emit the form status as valid
  ==========================================================================================*/
  checkListPage(){
    if(this.pages){
        let currentPage = this.pages[this.currentPageNumber];
        if(currentPage && currentPage['pageType'] == 'list'){
            this.formStatusChanges.emit(true);
        }
    }
  }

  /**=========================================================================================
     Set the dynamic form using the dynamic form data coming from parent component
  ==========================================================================================*/
  public setFormData(formData){
      if(formData){
          this.numberFields = [];
          this.decimalFields = [];
          this.dateFields = [];
          this.pages = formData['pages'];
          this.form = this.formBuilder.group({});
          this.enableServiceJobDate = false;
          if(this.pages && this.pages.length){
              this.pages.forEach((page, pageIndex) =>{
                  if(page['pageType'] == 'list'){
                      if(page['pageSelectAssetFirst']){
                          this.needFetchApplianceData = true;
                      }
                      this.listItemForm.push(new FormArray([]));
                      this.listFormIndexAndPageIndex[pageIndex] = this.listItemForm.length - 1;
                  }else {
                      this.pageWiseFieldIds[pageIndex] = [];
                      if (page.fields) {
                          page.fields.forEach((field) => {
                              this.formParserServicesService.setDependantFields(field);

                              if(field['fieldAnswerType'] == 'Date' &&
                                  (field['defaults'] &&
                                      field['defaults']['defaultTitle'])) this.enableServiceJobDate = true;

                              this.addFormControl(field, pageIndex);
                          });
                      }
                      page.sections.forEach((section) => {
                          this.formParserServicesService.setDependantSections(section);
                          section.fields.forEach((field) => {
                              this.formParserServicesService.setDependantFields(field);

                              if(field['fieldAnswerType'] == 'Date'
                                  && (field['isServicedJobDT'] ||
                                      (field['defaults'] && field['defaults']['defaultTitle'])
                                  )) this.enableServiceJobDate = true;

                              this.addFormControl(field, pageIndex);
                          });
                      });
                  }
              });

              if(!this.needFetchApplianceData && this.hasAssetModuleAccess == undefined){
                  this.hasAssetModuleAccess = false;
              }
          }
          this.form.valueChanges.subscribe((value) =>{
              this.formStatusChangeEmit();
              this.formSValueChangeEmit(value);
          });

          this.setFormDraftData();
          this.checkListPage();
          this.selectPage(this.currentPageNumber);
          if(this.enableServiceJobDate)
              this.fetchServiceReminderData();
      }
  }

  /**=========================================================================================
      Apply the patch value coming from parent to the form
  ==========================================================================================*/
  setFormDraftData(){
      this.setValueAndMeasurements();
      // Convert formatted date to date string to support bs datePicker
      this.dateFields.forEach((questionId) =>{
          if(this.draftData[questionId]){
              this.draftData[questionId] = new Date(this.draftData[questionId]);
          }
      });
      // Apply the draft value to the form
      this.form.patchValue(this.draftData);
      // Set the time value models with draft value
      this.setTimeModelDraft(this.timeFields);
      // Set the checkbox value models with draft value
      this.setCheckboxModelModelDraft(this.checkboxGroupFields);
      // Set input with selection choices value
      this.setSelect2Draft(this.select2LabelConfig);
          this.formParserServicesService.checkDependantFields(this.pages[this.currentPageNumber], this.formValue, this.currentPageNumber).then((value)=>{
              this.formStatusChangeEmit();
          });
  }

  /**=========================================================================================
     Add form control and validations based on the fields
  ==========================================================================================*/
  addFormControl(field, pageIndex){
      let formControl = new FormControl();
      formControl.setValue(undefined);
      if(field.required){
          if(this.customTemplateScreen==0 || (field.showInScreen && ((this.customTemplateScreen==1 && (field.showInScreen=='1' || field.showInScreen=='3')) || (this.customTemplateScreen==2 && (field.showInScreen=='2' || field.showInScreen=='3'))) && field.showInScreen!='4')){
              formControl.setValidators(Validators.required)
          }
      }
      if(field.defaultValue !== undefined){
          if(field.fieldAnswerType !== 'Date')
              formControl.setValue(field.defaultValue);
          else {
              let date = new Date();
              let year  = date.getFullYear();
              date.setFullYear(year+1);
              formControl.setValue(date);
          }
      }
      if(field.fieldAnswerType == 'Non-editable Input'){
          if(field['defaultID'] == '[user]')
              if(this.userName)
                  formControl.setValue(this.userName);
              else{
                  this.getUserData(formControl);
              }

          formControl.disable();
      }
      if(field['fieldValidations'] && field['fieldValidations'].length){
          this.addFieldValidations(field, formControl);
      }
      if(field['fieldAnswerType'] == 'Single Checkbox' && field['required'])
          this.singleCheckBoxFieldsList.push(field['questionID']);
          this.form.addControl(field['questionID'], formControl);

      if(field['fieldAnswerType'] != 'Photo')
          this.form.addControl(field['questionID'], formControl);

      if(field['fieldAnswerType'] == 'Number')
          this.numberFields.push(field['questionID']);
      if(field['fieldAnswerType'] == 'Decimal')
          this.decimalFields.push(field['questionID']);
      if(field['fieldAnswerType'] == 'Date')
          this.dateFields.push(field['questionID']);
      if(field['fieldAnswerType'] == 'Photo'){
          this.photoFields.push(field['questionID']);
          for(let i = 1; i <= field['max'] ; i++ ){
              let formControlName = `${field['questionID']}_image${i}`;
              this.form.addControl(formControlName, formControl);
              this.setFileUploader(formControlName, 'STANDARD');
              this.pageWiseFieldIds[pageIndex].push(field['questionID']);
              this.photoFileInvalidStatus[formControlName] = false;
          }
      }

      if(field['fieldAnswerType'] == 'Time') {
          let today = moment();
          let timeObject = {};
          timeObject['hour'] = today.format('hh');
          timeObject['minutes'] = today.format('mm');
          timeObject['meridiem'] = today.format('A');
          this.timeFields[field['questionID']] = timeObject;
          this.timeChanged(field['questionID']);
      }

      if(field['fieldAnswerType'] == 'Checkbox'){
          let checkboxArray = [];
          if(field['fieldAnswerOptions'] && field['fieldAnswerOptions'].length){
              field['fieldAnswerOptions'].forEach((option) => {
                 let optionObject = { optionValue: option['optionValue'], formValue: '', answerID: option['answerID'] };
                 checkboxArray.push(optionObject);
              });
              this.checkboxGroupFields[field['questionID']] = checkboxArray;
          }
      }

      if(field['fieldAnswerType'] != 'Photo')
          this.pageWiseFieldIds[pageIndex].push(field['questionID']);

      if(field['fieldAnswerType'] == 'Input with Selection Choices'){
          this.addSelect2Configuration(field, field['questionID'], field['fieldAnswerOptions']);
      }
      if(field['fieldAnswerType'] == 'Signature'){
          this.signatureFields.push(field['questionID']);
      }
      if(field['allMeasurements'] && field['allMeasurements'].length){
          this.measurements[field['questionID']] = '';
          this.measurementsValues[field['questionID']] = field['allMeasurements'];
      }
  }

  /**=========================================================================================
     Add select2 configuration for list/standard pages
  ==========================================================================================*/
  addSelect2Configuration(field, questionId, fieldAnswerOptions, type = 'STANDARD'){
      let labelIdTextArray  = [];
      if(fieldAnswerOptions){
          fieldAnswerOptions.forEach((options) => {
              let data = { id: options['answerID'], text: options['optionValue'] };
              labelIdTextArray.push(data);
          });
      }
      let select2Config = {
          width: '100%',
          data: labelIdTextArray,
          tags: true,
          createSearchChoice: function(term, data) {
              let exists = false;
              data.forEach((idText) => {
                  if(idText.text == term)
                      exists = true;
              });
              return term == ' ' || exists ?  null :
                  { id: term, text: term };
          },
          formatSelection: this.format,
          formatResult: this.format,
          multiple: true
      };
      if(type == 'STANDARD')
          this.select2LabelConfig[questionId] = select2Config;
      else
          this.select2LabelConfigList[questionId] = select2Config;
  }

  /**=========================================================================================
     Creates the file uploader component for every photo field
  ==========================================================================================*/
  setFileUploader(questionID, type){
      let uploadURL=URL;
      if(this.customFieldsConfigId!='' && this.customFieldsConfigId!=undefined){
          uploadURL = URL+'?uploadFrom=customfields';
      }
      let uploader = new FileUploader({
          url: uploadURL,
          itemAlias: 'file',
          autoUpload: true,
          allowedMimeType: ['image/png', 'image/jpeg']
      });
      uploader.onCompleteAll = () => {
          console.log('File upload completed');
      };
      uploader.onWhenAddingFileFailed = (fileItem:any, filter:any) => {
          console.log('File upload failed', fileItem, filter);
          if (filter.name = "mimeType") {
              this.photoFileInvalidStatus[this.currentPhotoFormControl] = true;
          }
      };
      uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
          response = JSON.parse(response);
          if(response.error == false) {
              this.photoFileInvalidStatus[this.currentPhotoFormControl] = false;
              this.photoFieldValues[this.currentPhotoFormControl] = response.message;
              if(this.form.get(this.currentPhotoFormControl)){
                  this.form.get(this.currentPhotoFormControl).setValue(response.message, {emitEvent: false});
              }else{
                  let pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
                  const myForm = pageFormArray.controls[this.currentListIndex] as FormGroup;
                  if(myForm.get(this.currentPhotoFormControl)){
                      myForm.get(this.currentPhotoFormControl).setValue(response.message, {emitEvent: false});
                  }
              }
              this.formSValueChangeEmit({});
          }
      };

      if(type == 'LIST')
          this.uploaderArrayList[questionID] = uploader;
      else
          this.uploaderArray[questionID] = uploader;

      if(this.draftData && this.draftData.hasOwnProperty(questionID)){
          this.photoFieldValues[questionID] = this.draftData[questionID];
      }
  }

  getPhotoArray(maxNumber, questionID){
      let array = [], formControlName = '';
      for(let i = 1; i <= maxNumber; i++ ){
          formControlName = `${questionID}_image${i}`;
          array.push(formControlName);
      }
      return array;
  }

  getPhotoListArray(maxNumber, questionID){
      let array = [], formControlName = '';
      for(let i = 1; i <= maxNumber; i++ ){
          formControlName = `${questionID}_image${i}_page${this.currentPageNumber+1}_${this.currentListIndex+1}`;
          array.push(formControlName);
      }
      return array;
  }

  /**==========================================================================================
    If the type is number or decimal, set the filters for the input to support number/decimal
  ===========================================================================================*/
  setInputFilter(textbox, inputFilter) {
      if(!textbox) return;
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
          textbox.addEventListener(event, function() {
              if (inputFilter(this.value)) {
                  this.oldValue = this.value;
                  this.oldSelectionStart = this.selectionStart;
                  this.oldSelectionEnd = this.selectionEnd;
              } else if (this.hasOwnProperty("oldValue")) {
                  this.value = this.oldValue;
                  this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                  this.value = "";
                }
            });
      });
  }

  onFileSelected(questionId){
      this.currentPhotoFormControl = questionId;
  }

  /**=========================================================================================
    Sets the formatted time value in the form in standard page
  ==========================================================================================*/
  timeChanged(questionId){
      if(this.timeFields[questionId]){
          let timeObject = this.timeFields[questionId];
          let time = `${timeObject.hour}:${timeObject.minutes} ${timeObject.meridiem}`;
          let timeValue=this.timeConvert12T24(time);
          if(this.form.get(questionId)){
              this.form.get(questionId).setValue(timeValue);
          }
      }
  }

  /**=========================================================================================
      Sets the formatted time value in the form in list page
  ==========================================================================================*/
  timeChangedList(questionId){
      if(this.timeFieldsList[questionId]){
          let timeObject = this.timeFieldsList[questionId];
          let time = `${timeObject.hour}:${timeObject.minutes} ${timeObject.meridiem}`;
          let timeValue=this.timeConvert12T24(time);
          let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
          let formGroup = formArray.at(this.currentListIndex);
          if(formGroup.get(questionId)){
              formGroup.get(questionId).setValue(timeValue);
          }
      }
  }

  /**=========================================================================================
     Sets the time value in the models from the draft data coming from parent component
  ==========================================================================================*/
  setTimeModelDraft(timeFields){
      for (const [key, value] of Object.entries(timeFields)){
          if(this.draftData[key]){
              let timeFormat: string = this.draftData[key];
              let timeValue: string = this.timeConvert24T12(timeFormat);
              let hour = timeValue.substring(0, 2);
              let minutes = timeValue.substring(3, 5);
              let meridiem = timeValue.substring(5, 8);
              value['hour'] = hour;
              value['minutes'] = minutes;
              value['meridiem'] = meridiem;
          }
      }
  }

  /**=========================================================================================
    Sets the check box value as comma separated strings in form {Standard page}
   ==========================================================================================*/
  checkboxChanged(questionId){
      setTimeout(()=>{
          if(this.checkboxGroupFields[questionId]){
              let value = '';
              this.checkboxGroupFields[questionId].forEach((option) => {
                  if(option['formValue']){
                      if(value)
                          value += ','+ option['optionValue'];
                      else
                          value = option['optionValue'];
                  }
              });
              if(this.form.get(questionId)){
                  this.form.get(questionId).setValue(value);
              }
          }
      }, 10);
  }

  /**=========================================================================================
     Sets the check box value as comma separated strings in form {List page}
   ==========================================================================================*/
  checkboxChangedList(questionId){
      setTimeout(()=>{
          if(this.checkboxGroupFieldsList[questionId]){
              let value = '';
              this.checkboxGroupFieldsList[questionId].forEach((option) => {
                  if(option['formValue']){
                      if(value)
                          value += ','+ option['optionValue'];
                      else
                          value = option['optionValue'];
                  }
              });
              let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
              let formGroup = formArray.at(this.currentListIndex);
              if(formGroup.get(questionId)){
                  formGroup.get(questionId).setValue(value);
              }
          }
      }, 10);
  }

  /**=========================================================================================
   Sets the check box models from the draft data coming from parent component
  ==========================================================================================*/
  setCheckboxModelModelDraft(checkboxGroupFields){
      for (const [key, value] of Object.entries(checkboxGroupFields)){
          if(this.draftData[key]){
              let checkValue: string = this.draftData[key];
              let valueArray = checkValue.split(',');
              if(valueArray.length){
                  checkboxGroupFields[key].forEach((option) => {
                      valueArray.forEach((value) =>{
                          if(option['optionValue'] == value){
                              option['formValue'] = true;
                          }
                      });
                  });
              }
          }
      }
  }

  setSelect2Draft(select2Config){
      setTimeout(()=>{
          for (const [key, value] of Object.entries(select2Config)){
              if(key in this.formValue){
                  let data = value['data'];
                  let index = data.findIndex((idText) =>{
                      return idText['text'] == this.formValue[key];
                  });
                  let selector = `#${key}`;
                  if(index != -1){
                      $(selector).select2('data', data[index]);
                  }else{
                      if(this.formValue[key])
                          $(selector).select2('data', { id: this.formValue[key], text: this.formValue[key] });
                  }
              }

          }
      }, 100);
  }

  /**========================================================================================
     Handles the input with selection choices changes
  ==========================================================================================*/
  onSelectInputWithSelection(event, questionId, type = 'STANDARD'){
      let data;
      let form:FormGroup;
      if(type == 'STANDARD') {
          data = this.select2LabelConfig[questionId]['data'];
          form = this.form;
          form.get(questionId).setValue('');
      }
      else {
          data = this.select2LabelConfigList[questionId]['data'];
          let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
          form = formArray.at(this.currentListIndex) as FormGroup;
          form.get(questionId).setValue('');
      }

      if(event.length && event.length != 1){
          let index = data.findIndex((idText) =>{
              return idText['id'] == event[1];
          });
          let selector = `#${questionId}`;
          if(index != -1) {
              let idText = data[index];
              $(selector).select2('data', idText);
              form.get(questionId).setValue(idText['text']);
          }else{
              $(selector).select2('data', { id: event[1], text: event[1] });
              form.get(questionId).setValue(event[1]);
          }
      }else if(event.length == 1){
          let index = data.findIndex((idText) =>{
              return idText['id'] == event[0];
          });
          if(index != -1){
              let idText = data[index];
              form.get(questionId).setValue(idText['text']);
          }
      }
  }

  /**========================================================================================
    Selects the appliance
  ==========================================================================================*/
  addAppliance(value, assetGroupObjectKey = ''){
      /** Timeout is set to remove the unwanted close toast from side panel */
      setTimeout(() => {
          this.currentlySelectedAppliance = value;
          this.assetGroupObjectKey = assetGroupObjectKey;
      }, 10);
  }

  checkListPageHasFields(){
      let noFields = true;
      let page = this.pages[this.currentPageNumber];
      if(page){
          let sections = page['sections'];
          if(sections){
              sections.forEach((section) =>{
                  if(section['fields'] && section['fields'].length)
                      noFields = false;
              });
          }
          let fields = page['fields'];
          if(fields && fields.length)
              noFields = false;
      }
      return noFields;
  }

  fetchApplianceData(){
      this.loading = true;
      let params = {};
      params['searchText'] = this.searchText.trim();
      if(this.typeId) params['type'] = this.typeId;
      if(this.limit) params['limit'] = this.limit;
      this.formParserServicesService.getCustomerAppliances(this.customerId, params).subscribe((response) =>{
          if(response){
              this.hasAssetModuleAccess = false;
              if(response['hasAssetModuleAccess']){
                  this.assetData = response['data'];
                  this.hasAssetModuleAccess = true;
                  this.keyLabelArray = response['keyLabelArray']
              }else{
                  this.appliances = response['data'];
              }
              this.applianceGroupTypes = response['applianceGroupType'];
              if(this.searchText == '')
                  this.limit = response['count'];

              this.loading = false;
              this.setListFormAndApplyPatch();
          }
      }, error1 => {
          this.loading = false;
      });
  }

  typeFilterChanged(event){
      if(event)
          this.typeId = event;
      else
          this.typeId = '';
      this.fetchApplianceData();
  }

  openListPageSidePanel(pageIndex, draftData, open = true, applyPatch = false){
      // Show warning message when max number reached
      if(open && this.pages[pageIndex] && this.pages[pageIndex]['pageMaximumNumberOfListItems']){
          if(this.listItemCount == this.pages[pageIndex]['pageMaximumNumberOfListItems']){
              this.toastBox.show('You have reached maximum number of list items!', 3000);
              return;
          }
      }

      this.type = 'ADD';
      this.currentListIndex = this.listItemCount;
      this.listItemCount++;
      this.disableSave = false;
      let listItemForm = this.formBuilder.group({});

      if(this.pages[pageIndex]) {
          let page = this.pages[pageIndex];
          if(page.fields){
              page.fields.forEach((field)=>{
                  listItemForm = this.addListFormControl(field, pageIndex, listItemForm);
              });
          }
          page.sections.forEach((section) => {
              this.formParserServicesService.setDependantSections(section);
              section.fields.forEach((field) => {
                      listItemForm = this.addListFormControl(field, pageIndex, listItemForm);
                  });
          });
          if(page['pageSelectAssetFirst']){
              if(!this.hasAssetModuleAccess){
                  let result = this.formParserServicesService.setApplianceForm
                  (listItemForm, this.currentPageNumber+1, this.listItemCount, this.listItemFieldOrderArray);
                  this.listFormLabels = {...this.listFormLabels, ...result.formLabels};
                  listItemForm = result.listItemForm;
              }else{
                  let result = this.formParserServicesService.setAssetFormWithKeyLabel
                  (listItemForm, this.keyLabelArray, this.currentPageNumber+1, this.listItemCount, this.draftData, this.listItemFieldOrderArray);
                  this.listFormLabels = {...this.listFormLabels, ...result.formLabels};
                  listItemForm = result.formGroup;
              }
          }
      }
      if(open)
          this.showListFormSidepanel = true;
      let pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[pageIndex]) as FormArray;

      /** Check dependencies for the list page */
      listItemForm.valueChanges.subscribe((value) =>{
          let appendString = `_page${pageIndex+1}_${this.currentListIndex+1}`;
          this.formParserServicesService.checkDependantFields(this.pages[pageIndex], value, this.currentPageNumber, 'LIST', appendString).then((value) => {
              this.setListItemFormValidity(listItemForm, appendString)
          });
      });

      if(applyPatch) {
          // Convert formatted date to date string to support bs datePicker
          this.dateFieldsList.forEach((questionId) =>{
              if(this.draftData[questionId]){
                  this.draftData[questionId] = new Date(this.draftData[questionId]);
              }
          });
          this.setValueAndMeasurements();
          listItemForm.patchValue(draftData);
          // Set the time value models with draft value
          this.setTimeModelDraft(this.timeFieldsList);
          // Set the checkbox value models with draft value
          this.setCheckboxModelModelDraft(this.checkboxGroupFieldsList);

          if(this.listItemValueArray[this.currentPageNumber]){
              this.listItemValueArray[this.currentPageNumber].push(listItemForm.value);
          }else{
              this.listItemValueArray[this.currentPageNumber] = [listItemForm.value];
          }
      }
      pageFormArray.push(listItemForm);

      /** Check dependencies for the list page */
      let formValues = {...listItemForm.value, ...draftData};
      let appendString = `_page${pageIndex+1}_${this.currentListIndex+1}`;
      this.formParserServicesService.checkDependantFields(this.pages[pageIndex], formValues, this.currentPageNumber, 'LIST', appendString).then((value) => {
          this.setListItemFormValidity(listItemForm, appendString);
      });
  }

  /**=========================================================================================
     Add form control and validations based on the fields in the list page
  ==========================================================================================*/
  addListFormControl(field, pageIndex, listItemForm){
      let formControl = new FormControl();
      formControl.setValue(undefined);
      let questionId = `${field['questionID']}_page${this.currentPageNumber+1}_${this.listItemCount}`;
      if(field.required){
          formControl.setValidators(Validators.required)
      }
      if(field.defaultValue !== undefined){
          if(field.fieldAnswerType !== 'Date')
              formControl.setValue(field.defaultValue);
          else {
              let date = new Date();
              let year  = date.getFullYear();
              date.setFullYear(year+1);
              formControl.setValue(date);
          }
      }
      if(field.fieldAnswerType == 'Non-editable Input'){
          if(field['defaultID'] == '[user]')
              if(this.userName)
                  formControl.setValue(this.userName);
              else{
                  this.getUserData(formControl);
              }
          formControl.disable();
      }
      if(field['fieldValidations'] && field['fieldValidations'].length){
          this.addFieldValidations(field, formControl);
      }
      if(field['fieldAnswerType'] != 'Photo')
          listItemForm.addControl(questionId, formControl);

      if(field['fieldAnswerType'] == 'Number')
          this.numberFieldsList.push(questionId);
      if(field['fieldAnswerType'] == 'Decimal')
          this.decimalFieldsList.push(questionId);
      if(field['fieldAnswerType'] == 'Date')
          this.dateFieldsList.push(questionId);
      if(field['fieldAnswerType'] == 'Photo'){
          this.photoFieldsList.push(questionId);
          for(let i = 1; i <= field['max'] ; i++ ){
              // let formControlName = `${questionId}_image${i}`;
              let formControlName = `${field['questionID']}_image${i}_page${this.currentPageNumber+1}_${this.listItemCount}`;
              listItemForm.addControl(formControlName, formControl);
              this.setFileUploader(formControlName, 'LIST');
              this.photoFileInvalidStatus[formControlName] = false;
          }
      }

      if(field['fieldAnswerType'] == 'Time') {
          let today = moment();
          let timeObject = {};
          timeObject['hour'] = today.format('hh');
          timeObject['minutes'] = today.format('mm');
          timeObject['meridiem'] = today.format('A');
          this.timeFieldsList[questionId] = timeObject;
          if(listItemForm.get(questionId)){
              let time = `${timeObject['hour']}:${timeObject['minutes']} ${timeObject['meridiem']}`;
              let timeValue=this.timeConvert12T24(time);
              listItemForm.get(questionId).setValue(timeValue);
          }
      }

      if(field['fieldAnswerType'] == 'Checkbox'){
          let checkboxArray = [];
          if(field['fieldAnswerOptions'] && field['fieldAnswerOptions'].length){
              field['fieldAnswerOptions'].forEach((option) => {
                  let optionObject = { optionValue: option['optionValue'], formValue: '', answerID: option['answerID'] };
                  checkboxArray.push(optionObject);
              });
              this.checkboxGroupFieldsList[questionId] = checkboxArray;
          }
      }
      if(field['fieldAnswerType'] == 'Input with Selection Choices'){
          this.addSelect2Configuration(field, questionId, field['fieldAnswerOptions'],'LIST');
      }

      if(field['allMeasurements'] && field['allMeasurements'].length){
          this.measurements[questionId] = '';
          this.measurementsValues[questionId] = field['allMeasurements'];
      }

      // Dynamic input for old structure
      if(field['fieldAnswerType'] == 'Dynamic Input' || field['fieldAnswerType'] == 'Dynamic Input with Selection Choices'){
          if(this.defaultFieldOptions && this.defaultFieldOptions[field['dynamicFieldID']])
              this.addSelect2Configuration(field, questionId, this.defaultFieldOptions[field['dynamicFieldID']], 'LIST');
      }

      this.listFormLabels[questionId] = { label : field['fieldLabel'], visibleOnList: field['visibleOnList'], type : field['fieldAnswerType'] };
      this.formParserServicesService.setDependantFields(field);

      // Set order of list item
      if(this.listItemFieldOrderArray[this.currentPageNumber]){
          if(this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex]){
              let index = this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex].findIndex((v) =>{
                 return v == questionId;
              });
              if(index == -1)
                  this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex].push(questionId);
          }else{
              this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex] = [questionId];
          }
      }else{
          this.listItemFieldOrderArray[this.currentPageNumber] = {};
          this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex] = [questionId];
      }
      return listItemForm;
  }

  onSidepanelClose(){
      this.cancelForm();
  }

  /**=========================================================================================
     Handles the cancel/close of list page side panel
  ==========================================================================================*/
  cancelForm(){
      if(this.type == 'ADD'){
          let pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
          if(pageFormArray.at(this.currentListIndex)){
              pageFormArray.removeAt(this.currentListIndex);
          }
          this.listItemCount--;
          this.currentListIndex--;
      }
      this.currentlySelectedAppliance = null;
      this.showListFormSidepanel = false;
      this.searchText = '';
      this.typeId = '';
  }

  /**=========================================================================================
     Handles the form submission on list page side panel
   ==========================================================================================*/
  onFormSubmit(){
      this.disableSave = true;
      this.showListFormSidepanel = false;
      let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
      let formGroup:FormGroup = formArray.at(this.currentListIndex) as FormGroup;
      let allFormValue = formGroup.value;

      if(this.listItemValueArray[this.currentPageNumber]){
          /** If the page is appliance/asset list page, add the selected appliance/asset to the form */
          if(this.pages[this.currentPageNumber] && this.pages[this.currentPageNumber]['pageSelectAssetFirst']){
              let applianceFormValue = this.getAssetOrApplianceFormValue();
              if(this.hasAssetModuleAccess){
                  let result = this.formParserServicesService.
                  setAssetForm(formGroup, this.currentlySelectedAppliance,this.currentPageNumber + 1, this.listItemCount);
                  formGroup = result.formGroup;
                  this.listFormLabels = {...this.listFormLabels, ...result.formLabels};
                  /** Get the asset value and set to the form  */
                  allFormValue = {...applianceFormValue, ...formGroup.value};
              }else{
                  /** Get the appliance value and set to the form  */
                  allFormValue = {...formGroup.value, ...applianceFormValue};
              }

              this.listItemValueArray[this.currentPageNumber][this.currentListIndex] = allFormValue;
              this.currentlySelectedAppliance = null;
          }else{
              if(this.listItemValueArray[this.currentPageNumber][this.currentListIndex])
                  this.listItemValueArray[this.currentPageNumber][this.currentListIndex] = formGroup.value;
              else
                  this.listItemValueArray[this.currentPageNumber].push(formGroup.value);
          }

      }else{
          /** If the page is appliance/asset list page, add the selected appliance/asset to the form */
          if(this.pages[this.currentPageNumber] && this.pages[this.currentPageNumber]['pageSelectAssetFirst']){
              let applianceFormValue = this.getAssetOrApplianceFormValue();
              if(this.hasAssetModuleAccess){
                  let result = this.formParserServicesService.
                  setAssetForm(formGroup, this.currentlySelectedAppliance,this.currentPageNumber + 1, this.listItemCount);
                  formGroup = result.formGroup;
                  this.listFormLabels = {...this.listFormLabels, ...result.formLabels};
              }
              /** Get the appliance/asset value and set to the form  */
              allFormValue = {...formGroup.value, ...applianceFormValue};
              formGroup.patchValue(allFormValue);

              this.listItemValueArray[this.currentPageNumber] = [allFormValue];
              this.currentlySelectedAppliance = null;
          }else {
              this.listItemValueArray[this.currentPageNumber] = [formGroup.value];
          }
      }

      // Emit the form value with list item
      this.formSValueChangeEmit(allFormValue);

      this.searchText = '';
      this.typeId = '';
  }

  getAssetOrApplianceFormValue(){
      let applianceFormValue = {};
      if(this.hasAssetModuleAccess){
          applianceFormValue = this.formParserServicesService.
          getAssetFormValue(this.currentlySelectedAppliance, this.currentPageNumber + 1, this.listItemCount);
      }else {
          applianceFormValue  = this.formParserServicesService.
          getApplianceFormValue(this.currentlySelectedAppliance, this.currentPageNumber + 1, this.listItemCount);
      }
      return applianceFormValue;
  }

  editListItem(listItem, listIndex) {
      let noFields = this.checkListPageHasFields();
      if(noFields){
          this.toastBox.show('There are no extra fields to edit.', 3000);
          return;
      }

      this.type = 'EDIT';
      this.disableSave = false;
      this.currentListIndex = listIndex;
      let page = this.pages[this.currentPageNumber];
      if(page && page['pageSelectAssetFirst']){
          // Get the selected appliance
          if(this.hasAssetModuleAccess){
              let result = this.formParserServicesService.
              getCurrentlySelectedAsset(listItem, this.listFormLabels);
              this.currentlySelectedAppliance = result.asset;
              this.assetGroupObjectKey = result.assetGroupObjectKey;
          }else{
              this.currentlySelectedAppliance = this.formParserServicesService.
              getCurrentlySelectedAppliance(listItem, this.listFormLabels);
          }

      }

      /** Check dependencies for the list page */
      let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
      let formGroup = formArray.at(this.currentListIndex) as FormGroup;
      let formValues = formGroup.value;
      let appendString = `_page${this.currentPageNumber+1}_${this.currentListIndex+1}`;
      this.formParserServicesService.checkDependantFields(page, formValues, this.currentPageNumber, 'LIST', appendString).then((value) =>{
          this.setListItemFormValidity(formGroup, appendString);
      });

      this.showListFormSidepanel = true;
      // Set input with selection choices value
      this.setSelect2Draft(this.select2LabelConfigList);
  }

  deleteListItem(listItem, listIndex){
      this.confirmationBoxHelper.getConfirmation('Are you sure you want to delete this item?', this.ajsScope)
          .then(() => {
              this.listItemValueArray[this.currentPageNumber].splice(listIndex, 1);
              let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
              let formValue = formArray.at(listIndex).value;
              for (const [key, value] of Object.entries(formValue)){
                  if(key in this.formValue)
                      delete this.formValue[key];
              }
              let formArrayLength = formArray.length;
              formArray.removeAt(listIndex);
              if(listIndex == formArrayLength - 1){
                  this.listItemCount = formArray.length;
                  this.currentListIndex = this.listItemCount - 1;
                  delete this.listItemFieldOrderArray[this.currentPageNumber][listIndex];
                  this.formSValueChangeEmit({});
              }else{
                  let currentPage = this.pages[this.currentPageNumber];
                  if(currentPage){
                      for(let i=listIndex; i < formArrayLength - 1; i++) {
                          let questionIdArray = [];
                          let listItemForm = new FormGroup({});
                          this.currentListIndex = i;
                          this.listItemCount = i+1;
                          if (currentPage.fields) {
                              currentPage.fields.forEach((field) => {
                                  questionIdArray.push(field['questionID']);
                                  listItemForm = this.addListFormControl(field, this.currentPageNumber, listItemForm);
                              });
                          }
                          currentPage.sections.forEach((section) => {
                              section.fields.forEach((field) => {
                                  questionIdArray.push(field['questionID']);
                                  listItemForm = this.addListFormControl(field, this.currentPageNumber, listItemForm);
                              });
                          });

                          if(currentPage['pageSelectAssetFirst']){
                              if(this.hasAssetModuleAccess){
                                  let assetQuestionIds = this.formParserServicesService.getAssetQuestionIds();
                                  questionIdArray = questionIdArray.concat(assetQuestionIds);
                                  listItemForm = this.formParserServicesService.setAssetFormWithKeys(listItemForm, this.currentPageNumber, i);
                              }
                              else {
                                  questionIdArray = this.formParserServicesService.getApplianceQuestionIds(questionIdArray);
                                  listItemForm = this.formParserServicesService.setApplianceFormWithKeys(listItemForm, this.currentPageNumber, i);
                              }
                          }

                          delete this.listItemFieldOrderArray[this.currentPageNumber][i+1];
                          this.listItemFieldOrderArray[this.currentPageNumber][i] = [];
                          questionIdArray.forEach((questionId) =>{
                              let oldTag = `${questionId}_page${this.currentPageNumber+1}_${this.listItemCount+1}`;
                              let newTag = `${questionId}_page${this.currentPageNumber+1}_${this.listItemCount}`;
                              let value = this.formValue[oldTag];
                              if(listItemForm.get(newTag)){
                                  listItemForm.get(newTag).setValue(value);
                                  this.listItemFieldOrderArray[this.currentPageNumber][i].push(newTag);
                                  this.listItemValueArray[this.currentPageNumber][i][newTag] = value;
                              }

                              if(oldTag in this.formValue)
                                  delete this.formValue[oldTag];
                          });
                          formArray.setControl(i, listItemForm);
                          this.listItemValueArray[this.currentPageNumber][i] = listItemForm.value;
                          this.formSValueChangeEmit(listItemForm.value);
                      }
                  }
              }
          }).catch(()=>{});
  }

  setListFormAndApplyPatch(){
     let listPageKeysArray = Object.keys(this.draftData);
     let listItemCount = 0;
     let lastItemInt = 0;
     listPageKeysArray.forEach((key) => {
        if(key.includes('_page'+(this.currentPageNumber+1))){
            let splitArray = key.split('_');
            let lastItem = splitArray[splitArray.length - 1];
            lastItemInt = parseInt(lastItem) as number;
            if(lastItemInt > listItemCount){
                listItemCount = lastItemInt;
            }
        }
     });

     for(let i=0; i < listItemCount; i++){
         let pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
         if(!pageFormArray.at(i))
             this.openListPageSidePanel(this.currentPageNumber, this.draftData, false, true);
     }
  }

  /** Signature handle section ================================================================*/

  openSignatureSidePanel(questionId){
      this.showSignatureSidePanel = true;
      this.currentSignatureFormControl = questionId;
  }

  onSignatureSidePanelClose(){
      this.showSignatureSidePanel = false;
  }

  getSignatureBase64(base64Image){
      let page = this.pages[this.currentPageNumber];
      if(page) {
          if(page['pageType'] !== 'list') {
              if (this.form.get(this.currentSignatureFormControl)) {
                  if(base64Image.includes('data:image/png;base64')) {
                      base64Image = base64Image.substring(22);
                  }
                  this.form.get(this.currentSignatureFormControl).setValue(base64Image);
              }
          }else{
              let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
              let formGroup:FormGroup = formArray.at(this.currentListIndex) as FormGroup;
              if (formGroup.get(this.currentSignatureFormControl)) {
                  if(base64Image.includes('data:image/png;base64')) {
                      base64Image = base64Image.substring(22);
                  }
                  formGroup.get(this.currentSignatureFormControl).setValue(base64Image);
                  // this.formSValueChangeEmit(formGroup.value);
              }
          }
          this.showSignatureSidePanel = false;
      }
  }

  /** =========================================================================================*/

  measurementChanged(questionID){
      if(this.formValue && questionID in this.formValue) {
          if(this.form.get(questionID)){
              let val = {};
              val[questionID] = this.form.get(questionID).value;
              this.formSValueChangeEmit(val);
          }
      }
  }

  /**=========================================================================================
     Handles splitting measurement value and unit from draft data
   ==========================================================================================*/
  setValueAndMeasurements(){
      if(this.measurements){
          Object.keys(this.measurements).forEach((key) =>{
              if(this.measurements[key] == '') {
                  if (key in this.draftData) {
                      let value = this.draftData[key] as string;
                      if (value != '' && typeof value=="string") {
                          let splitArray = value.split(' ');
                          if (splitArray && splitArray.length > 1) {
                              let measurementValue = splitArray[splitArray.length - 1];
                              if (this.measurementsValues[key].includes(measurementValue)) {
                                  this.measurements[key] = measurementValue;
                              }
                              let newValue = '';
                              for (let i = 0; i <= splitArray.length - 1; ++i) {
                                  if (i == 0)
                                      newValue = splitArray[i];
                                  else
                                      newValue += ` ${splitArray[i]}`;
                              }
                              this.draftData[key] = newValue;
                          }
                      }
                  }
              }
          });
      }
  }

  setDynamicFieldValues(){
      if(this.pages && this.pages.length) {
          this.formParserServicesService.getCertificatesFieldOptions(this.certificateId).subscribe((res) =>{
              this.defaultFieldOptions = res;
              this.pages.forEach((page, pageIndex) => {
                  if (page.fields) {
                      page.fields.forEach((field) => {
                          if(field['fieldAnswerType'] == 'Dynamic Input' || field['fieldAnswerType'] == 'Dynamic Input with Selection Choices'){
                              if(page['pageType'] !== 'list'){
                                  this.addSelect2Configuration(field, field['questionID'], res[field['dynamicFieldID']])
                              }
                          }
                      });
                      page.sections.forEach((section) => {
                          this.formParserServicesService.setDependantSections(section);
                          section.fields.forEach((field) => {
                              if(field['fieldAnswerType'] == 'Dynamic Input' || field['fieldAnswerType'] == 'Dynamic Input with Selection Choices'){
                                  if(page['pageType'] !== 'list'){
                                      this.addSelect2Configuration(field, field['questionID'], res[field['dynamicFieldID']])
                                  }
                              }
                          });
                      });
                  }
              });
              this.setSelect2Draft(this.select2LabelConfig);
          }, error1 => {
             console.log(error1);
          });
      }
  }

  /**=========================================================================================
   Handles extra validation for fields in the form data
   ==========================================================================================*/
  addFieldValidations(field, formControl){
      let validation = field['fieldValidations'][0];
      if(validation['fieldValidationName'] == 'Email address'){
          formControl.setValidators(AppValidators.emailValidator);
          field['validationType'] = 'email';
      }
      if(validation['fieldValidationName'] == 'Date'){
          formControl.setValidators(Validators.pattern('^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$'));
          field['validationType'] = 'date';
      }
      if(validation['fieldValidationName'] == 'Custom'){
          formControl.setValidators(Validators.pattern(validation['fieldValidation']));
      }
  }

  /** Fetch service reminder info if the job is a service job */
  fetchServiceReminderData(){
      if(this.jobId && this.enableServiceJobDate){
          this.formParserServicesService.getJobServiceReminders(this.jobId).subscribe((res) => {
              this.serviceReminderData = res['serviceReminders'];
          }, error1 => {
              this.serviceReminderData = [];
          });
      }
  }

  showServiceJobDropDown(questionID){
      this.currentServiceJobDateId = questionID;
      this.serviceJobDate = '';
  }

  serviceJobDateChanged(type = 'STD'){
     if(type == 'STD' && this.form && this.form.get(this.currentServiceJobDateId)){
         this.form.get(this.currentServiceJobDateId).setValue(new Date(this.serviceJobDate));
     }
     if(type == 'LIST'){
         let formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]) as FormArray;
         let formGroup:FormGroup = formArray.at(this.currentListIndex) as FormGroup;
         if(formGroup.get(this.currentServiceJobDateId)){
             formGroup.get(this.currentServiceJobDateId).setValue(new Date(this.serviceJobDate));
         }
     }
  }

  /** Update list item form validity based on the dependencies */
  setListItemFormValidity(listItemForm: FormGroup, appendString){
      let currentPageDependantFields = this.formParserServicesService.pageWiseDependantFields[this.currentPageNumber];
      if(currentPageDependantFields){
          currentPageDependantFields.forEach((value) =>{
              let controlName = value['id'] + appendString;
              if(listItemForm.get(controlName)){
                  if(!value['required']){
                      listItemForm.get(controlName).clearValidators();
                  }else{
                      listItemForm.get(controlName).setValidators(Validators.required);
                  }
                  listItemForm.get(controlName).updateValueAndValidity({emitEvent:false});
              }
          });
      }
      let page = this.pages[this.currentPageNumber];
      if(page && page['pageType'] == 'list'){
          page['sections'].forEach((section) =>{
              section['fields'].forEach((field) =>{
                  if((section['hasDependency'] && !section['show']) || (field['hasDependency'] && !field['show'])){
                      let controlName = field['questionID'] + appendString;
                      if(listItemForm.get(controlName)){
                          listItemForm.get(controlName).setValue(undefined, {emitEvent:false});
                          listItemForm.get(controlName).updateValueAndValidity({emitEvent:false});
                      }
                  }
              });

          });
      }
      listItemForm.updateValueAndValidity({emitEvent:false});
  }

  /** Get user details and set in formControl */
  getUserData(formControl: FormControl){
      let interval = setInterval(() => {
          if(this.jobId){
              this.formParserServicesService.getUserData(this.jobId).subscribe((userData) => {
                  this.userName = userData['userName'];
                  formControl.setValue(this.userName);
                  clearInterval(interval);
              }, error1 => {
                  console.log(error1);
                  clearInterval(interval);
              });
              clearInterval(interval);
          }

          if(this.opportunityId || this.opportunityId == undefined){
              let type = "existing"
              if(this.opportunityId == undefined){
                  this.opportunityId = null;
                  type = "initial"
              }
              this.formParserServicesService.getOpportunityUserData(this.opportunityId,type).subscribe((userData) => {
                  this.userName = userData['userName'];
                  formControl.setValue(this.userName);
                  formControl.disable();
                  clearInterval(interval);
              }, error1 => {
                  console.log(error1);
                  clearInterval(interval);
              });
              clearInterval(interval);
          }
      }, 500);
  }

  /** Set form fields with measurements back to draft data ( ie. without measurement value) */
  setMeasurementValueOnPageChange(){
      if(this.measurements && Object.keys(this.measurements)){
          Object.keys(this.measurements).forEach(questionId => {
              if(this.form && this.form.get(questionId)) {
                  let value = this.form.value[questionId];
                  if (value && typeof value=="string") {
                      let splitArray = value.split(' ');
                      if (splitArray && splitArray.length > 1) {
                          let measurementValue = splitArray[splitArray.length - 1];
                          if (this.measurementsValues[questionId].includes(measurementValue)) {
                              let newValue = '';
                              for (let i = 0; i < splitArray.length - 1; ++i) {
                                  if (i == 0)
                                      newValue = splitArray[i];
                                  else
                                      newValue += ` ${splitArray[i]}`;
                              }
                              this.form.get(questionId).setValue(newValue, {emitEvent: false});
                          }
                      }
                  }
              }
          });
      }
  }

  splitValueFromMeasurement(value, questionId){
      if(value){
          // Value is a number if it comes from the web
          if (typeof(value) == 'number') {
              value = value.toString();
          }
          let splitArray = value.split(' ');
          if (splitArray && splitArray.length > 1) {
              let measurementValue = splitArray[splitArray.length - 1];
              if (this.measurementsValues[questionId].includes(measurementValue)) {
                  let newValue = '';
                  for (let i = 0; i < splitArray.length - 1; ++i) {
                      if (i == 0)
                          newValue = splitArray[i];
                      else
                          newValue += ` ${splitArray[i]}`;
                  }
                  return newValue;
              }else{
                  return "";
              }
          }else return value;
      }else{
          return "";
      }
  }

  updatePhotoFieldValues(){
      Object.keys(this.photoFieldValues).forEach(key => {
          if(this.formValue.hasOwnProperty(key)){
              this.formValue[key] = this.photoFieldValues[key];
          }
      });
  }

  /** Convert time format from 24 hours to 12 hours */
  timeConvert24T12 (time) {
      let ts = time;
      let H = +ts.substr(0, 2);
      let h = (H % 12) || 12;
      let hr = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
      let ampm = H < 12 ? 'AM': 'PM';
      ts = hr + ts.substr(2, 3) + ampm;
      return ts;
  }

  /** Convert time format from 12 hours to 24 hours */
  timeConvert12T24(time){
      let timeRes = time;
      let timeValue;
      let hours = Number(timeRes.match(/^(\d+)/)[1]);
      let minutes = Number(timeRes.match(/:(\d+)/)[1]);
      let AMPM = timeRes.match(/\s(.*)$/)[1];
      if(AMPM == "PM" && hours<12) hours = hours+12;
      if(AMPM == "AM" && hours==12) hours = hours-12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if(hours<10) sHours = "0" + sHours;
      if(minutes<10) sMinutes = "0" + sMinutes;
      timeValue=sHours + ":" + sMinutes;
      return timeValue;
  }

}
