import { EventEmitter, OnInit } from '@angular/core';
import * as moment from "moment";
var ScheduledActivityBlockComponent = /** @class */ (function () {
    function ScheduledActivityBlockComponent(confirmationBoxHelper, ajsScope) {
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.isEdit = false;
        this.activeField = {};
        this.isConditional = false;
        this.isMarketPlaceView = false;
        this.hasChild = false;
        this.allFields = {};
        this.usersList = [];
        this.activityTypes = [];
        this.deleteMessage = 'You are about to delete a task. Are you sure you wish to do this?';
        this.workflowType = 'Custom';
        this.diagramOptions = {};
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.iconClassNames = {
            'Call': 'fa fa-phone',
            'Email': 'fa fa-envelope',
            'Meeting': 'fas fa-calendar-alt',
            'Task': 'fa fa-list-ul'
        };
        this.units = ['days', 'weeks', 'months'];
        this.timeModel = null;
    }
    ScheduledActivityBlockComponent.prototype.ngOnInit = function () {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if (this.workflowType == 'Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        if (!this.isEdit) {
            this.inputModel.model['activityType'] = this.activityTypes[0].id;
            this.timeModel = moment('08:00:00', 'HH:mm:ss').toDate();
        }
        else {
            this.timeModel = moment(this.inputModel.model['scheduleTime'] + ':00', 'HH:mm:ss').toDate();
        }
        var select2DisplayFormat = function (item) {
            return item.text;
        };
        this.select2LabelConfig = {
            width: '100%',
            data: this.usersList,
            tags: true,
            formatSelection: select2DisplayFormat,
            formatResult: select2DisplayFormat,
            multiple: true
        };
    };
    ScheduledActivityBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    ScheduledActivityBlockComponent.prototype.validateItems = function () {
        if (this.inputModel.model['description'] == '' || this.inputModel.model['activityType'] == '') {
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator === '' || this.inputModel.condition.value === '' : false;
    };
    ScheduledActivityBlockComponent.prototype.onChangeTime = function (time) {
        this.inputModel.model['displayTime'] = moment(time).format('HH:mm');
    };
    ScheduledActivityBlockComponent.prototype.onChangedDate = function (value) {
        //console.log(value);
    };
    ScheduledActivityBlockComponent.prototype.setDisplay = function () {
        var _this = this;
        var tmpUserIds = _.indexBy(Object.assign([], this.usersList), 'id');
        var tmpActivityType = _.indexBy(Object.assign([], this.activityTypes), 'id');
        this.inputModel.model.assigneeDisplay = [];
        _.forEach(this.inputModel.model.assigneeIds, function (id) {
            _this.inputModel.model.assigneeDisplay.push(tmpUserIds[id].text);
        });
        this.inputModel.model['activityTypeDisplay'] = tmpActivityType[this.inputModel.model['activityType']]['name'];
    };
    ScheduledActivityBlockComponent.prototype.selectedAsignee = function (event) {
        if (event === void 0) { event = null; }
        this.inputModel.model['assigneeIds'] = event;
    };
    ScheduledActivityBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    ScheduledActivityBlockComponent.prototype.addValue = function () {
        this.setDisplay();
        this.inputModel.model['scheduleTime'] = moment(this.timeModel).format('HH:mm:ss');
        this.outputModel.emit(this.inputModel);
    };
    return ScheduledActivityBlockComponent;
}());
export { ScheduledActivityBlockComponent };
