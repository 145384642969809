import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FolderService = /** @class */ (function (_super) {
    tslib_1.__extends(FolderService, _super);
    function FolderService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.triggerEvent = new BehaviorSubject(null);
        _this.triggerEvent$ = _this.triggerEvent.asObservable();
        return _this;
    }
    FolderService.prototype.getApiCall = function (url) {
        return this.http.post(this.getApiUrl(url), []);
    };
    FolderService.prototype.postApiCall = function (url, params) {
        return this.http.post(this.getApiUrl(url), params);
    };
    FolderService.prototype.addEditEmailTemplate = function (params) {
        return this.http.post(this.getApiUrl('save_templates'), params);
    };
    FolderService.prototype.getReplacedMessage = function (params) {
        return this.http.post(this.getApiUrl('replaced_email_message'), params);
    };
    FolderService.prototype.checkTemplateStatus = function (params) {
        return this.http.post(this.getApiUrl('check_template_status'), params);
    };
    FolderService.prototype.checkFolderStatus = function (params) {
        return this.http.post(this.getApiUrl('check_folder_status'), params);
    };
    FolderService.prototype.checkUniqueFolderName = function (params) {
        return this.http.post(this.getApiUrl('check_unique_folder_name'), params);
    };
    FolderService.prototype.checkUniqueTemplateName = function (params) {
        return this.http.post(this.getApiUrl('check_unique_template_name'), params);
    };
    FolderService.prototype.loadData = function () {
        this.triggerEvent.next(true);
    };
    FolderService.prototype.checkTemplateName = function (params) {
        return this.http.get(this.getApiUrl("checkSetupOptionsExists", params));
    };
    FolderService.prototype.checkProposalTemplateExists = function (value) {
        return this.http.get(this.getApiUrl("checkProposalTemplateExists", value));
    };
    FolderService.ngInjectableDef = i0.defineInjectable({ factory: function FolderService_Factory() { return new FolderService(i0.inject(i1.HttpClient)); }, token: FolderService, providedIn: "root" });
    return FolderService;
}(InterfaceService));
export { FolderService };
