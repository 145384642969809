import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
    AfterViewInit,
    ViewChild, ElementRef, Inject
} from '@angular/core';
import {from} from "rxjs";
import {map} from "rxjs/operators";
// import {WorkflowSetupService} from "@app/features/system-settings/workflow-setup/services/workflow-setup.service";
import { WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";

declare var $: any;
declare var _;

const format = (item) => {
    return item.text;
}

@Component({
    selector: 'cs-workflow-sms-block',
    templateUrl: './sms-block.component.html',
    styles: []
})
export class SmsBlockComponent implements OnInit, AfterViewInit {

    name: 'smsBlock';
    smsTemplateList: any[] = [];
    smsTemplates: any[] = [];
    loading: boolean = true;
    hidden: boolean = true;
    editor: any;
    labels: any;
    messages = {};
    showDeleteBtn:boolean;
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() isConditional:boolean = false;
    @Input() activeField:any = {};
    @Input() operators:any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() workflowType: string = 'Custom';
    @Input() diagramOptions: any = {};
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;

    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    select2TemplateList : any;
    select2LabelConfig : any;
    userList : any[]= [];
    allSmsTags : any[]= [];
    smsNumbers: any[]= [];
    toSmsDetails: any[]= [];
    data :any={};
    userTags : any[]= [];
    previewMessage: string = '';
    message: any;
    dataFunction = function(item){ return item.text; };
    smsScreenLabel: string = 'sms.templates';
    activeTemplateTitle:string = 'Please.Choose';
    title:string = '';

    communicationTags: any = {
        'Custom' : [
            {'id' : 't_0', 'text' : '[customer_mobile]', 'name' : 'TAGS'},
            {'id' : 't_3', 'text' : '[job_contact_mobile]', 'name' : 'TAGS'},
            {'id' : 't_4', 'text' : '[invoice_address_mobile]', 'name' : 'TAGS'},
            {'id' : 't_2', 'text' : '[job_address_primary_contact_mobile]','name' : 'TAGS'},
        ],
        'Sequence': [
            {'id' : 't_0', 'text' : '[sequence_contact_mobile]', 'name' : 'TAGS'},
            /*{'id' : 't_5', 'text': '[opportunity_address_mobile]', 'name': 'TAGS'},
            {'id' : 't_6', 'text': '[opportunity_site_contact_mobile]', 'name': 'TAGS'},*/
        ]
    };

    tagTypes = [
       /* {'id' : 't_0', 'text' : '[customer_mobile]', 'name' : 'TAGS'},
        // {'id' : 't_1', 'text' : '[work_address_mobile]', 'name' : 'TAGS'},
        /!*{'id' : 't_2', 'text' : '[job_address_mobile]', 'name' : 'TAGS'},*!/
        {'id' : 't_3', 'text' : '[job_contact_mobile]', 'name' : 'TAGS'},
        {'id' : 't_4', 'text' : '[invoice_address_mobile]', 'name' : 'TAGS'},
        // {'id' : 't_5', 'text' : '[estimate_address_mobile]', 'name' : 'TAGS'},
        // {'id' : 't_6', 'text' : '[estimate_contact_mobile]', 'name' : 'TAGS'},*/
    ];
    saving: boolean = false;
    constructor(private cdr: ChangeDetectorRef,
        private workflowService: WorkflowhttpService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { }

    async ngOnInit() {
        this.tagTypes = this.communicationTags[this.workflowType];
        if(this.diagramOptions.objectName == 'estimate'){
            this.communicationTags.Custom.push(  {'id' : 't_7', 'text' : '[estimate_contact_mobile]','name' : 'TAGS'});
        }
        if(this.diagramOptions.objectName == 'opportunity' || this.diagramOptions.objectName == 'proposal'){
            this.communicationTags.Custom.push(  {'id' : 't_8', 'text' : '[opportunity_site_contact_mobile]','name' : 'TAGS'});
        }
        if (!this.isEdit) {
            this.previewMessage = this.inputModel.model.message;
        }
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if (this.workflowType == 'Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        this.loading=true;
        await this.getData();
        //console.log('inputModel.model.templateId', this.inputModel.model.templateId);
        if (this.inputModel.model.templateId) {
            await this.getTemplateContent(this.inputModel.model.templateId)
        }
        this.hidden = (this.inputModel.model.message == '') ? true : false;
        this.loading=false;
    }

    getData() {
        return new Promise( (resolve, reject) => {
            this.workflowService.getCommunicationTemplateConfig()
                .toPromise()
                .then(  (resp) => {
                    let data = resp['data'];
                    if(data) {
                        this.userList = data.users;
                        this.userList.forEach((line, index) => {
                            this.userTags.push({
                                id: parseInt(line.id),
                                text: line.text,
                                name: 'USERS'
                            });
                            this.allSmsTags.push(line);
                        });
                        this.tagTypes.forEach((line, index) => {
                            this.allSmsTags.push(line);
                        });
                        this.message = this.previewMessage;
                    }

                    if(this.inputModel.model.toSmsTag){
                        let dropDownData = this.inputModel.model.toSmsTag;
                        this.inputModel.model.toSmsTag = [];
                        dropDownData.forEach((value,key) => {
                            let selectedItem = this.allSmsTags.find((items:any) => items.id == value.value);
                            if(this.allSmsTags.indexOf(value.value) === -1){
                                if(!selectedItem){
                                    let data = { id: 'e_'+key, text: value.displayName };
                                    this.allSmsTags.push(data);
                                    this.smsNumbers.push(data);
                                    this.userTags.push(data);
                                    this.inputModel.model.toSmsTag.push('e_'+key);
                                }else{
                                    this.inputModel.model.toSmsTag.push(value.value);
                                }
                            }
                        });
                    }

                    this.select2LabelConfig = {
                        width: '100%',
                        data: this.groupByTags(),
                        tags: true,
                        createSearchChoice: function (term, data) {
                            let exists = false;
                            data.forEach((idText) => {
                                if(idText.text == term)
                                    exists = true;
                            });
                            return term.indexOf(' ') >= 0 || exists ? null :
                                {
                                    id: term + " (Create New)",
                                    text: term + " (Create New)"
                                };
                        },
                        formatSelection: format,
                        formatResult: format,
                        multiple: true
                    }
                    resolve(true);
                }, (err) => {
                    reject(err);
                })
        });
    }

    async getTemplateContent(templateId:string|number) {
        return new Promise( (resolve, reject) => {
            this.workflowService.getTemplateById(templateId)
            .toPromise()
            .then( (resp) => {
                    let data = resp['data'];
                    if(data) {
                        this.activeTemplateTitle = data['title'];
                        this.message = this.previewMessage = data['message'];
                        this.title = data['title'];
                    }
                    resolve(resp);
                },
                (err) => {
                    reject(err);
            });
        });
    }

    setTemplateDetails(event:any, type: string) {
        this.inputModel.model.templateId = event['templateId'];
        this.previewMessage = event['message'];
        this.message = this.previewMessage;
        this.title = event['templateName'];
    }


    ngAfterViewInit() {

    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    validateItems() {
        if(this.workflowType == 'Sequence'){
            return !this.inputModel.model.templateId;
        }
        else {
            if(!this.inputModel.model.templateId || !this.inputModel.model.toSmsTag){
                return true;
            }
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    groupByTags() {
        const allSMSTag = [];
        const newItems: any[] = [];
        newItems.push(this.userTags);
        newItems.push(this.tagTypes);
        const source = from(newItems);
        const tags = source.pipe(
            map(groupItems => {
                const _item: any = {text: '', children: []};
                let cnt: number = 0;
                groupItems.forEach( group => {
                    if (cnt === 0) {
                        _item.text = group.name;
                    }
                    _item.children.push({
                        id: group.id,
                        text: group.text
                    });
                    cnt++;
                });
                return _item;
            })
        );
        const subscribe = tags.subscribe(val => allSMSTag.push(val));
        return allSMSTag;
    }

    addValue() {
        this.saving=true;
        // let selectedItem = this.smsTemplates.find((item:any) => item.id == this.inputModel.model.templateId);
        this.inputModel.model.title = this.title;
        this.inputModel.model.message = this.previewMessage;
        this.toSmsDetails =  this.workflowType == 'Sequence' ? ['t_0'] : this.inputModel.model.toSmsTag;
        this.inputModel.model.toSmsTag = [];
        let that =this;
        this.toSmsDetails.forEach((smsData,key) => {
            let selectedUser = this.userList.find((items:any) => items.id == smsData);
            let selectedTag = this.tagTypes.find((items:any) => items.id == smsData);
            let selectedNumbers = this.smsNumbers.find((items:any) => items.id == smsData);
            if(!that.inputModel.model.toSmsTag.some((item) => item.value === smsData) && selectedUser){
                this.data = { type:"user",value: smsData, displayName: selectedUser.text };
                this.inputModel.model.toSmsTag.push(this.data);
            }else if(!that.inputModel.model.toSmsTag.some((item) => item.value === smsData) && selectedTag){
                this.data = { type:"tag",value: smsData, displayName: selectedTag.text };
                this.inputModel.model.toSmsTag.push(this.data);
            }else if(!that.inputModel.model.toSmsTag.some((item) => item.value === smsData) || selectedNumbers){
                smsData = (selectedNumbers) ? selectedNumbers.text : smsData;
                this.data = { type:"normal",value: smsData, displayName: smsData };
                this.inputModel.model.toSmsTag.push(this.data);
            }
        });
        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0)
    }

    selectedSmsAddress(event:any = null, data:any = null) {
        let lab = event[event.length - 1];
        if (lab.includes(" (Create New)")) {
            lab = lab.replace(" (Create New)", '');
            let valueArray = $("#additional-sms").select2("data");
            if (valueArray) {
                valueArray[valueArray.length - 1]['id'] = lab;
                valueArray[valueArray.length - 1]['text'] = lab;
            }
            $("#additional-sms").select2('data', valueArray);
            event[event.length - 1] = lab;
        }
        this.inputModel.model.toSmsTag =event;
    }

    uploadTemplateDetails(id) {
        this.hidden = false;
        let selectedItem = this.smsTemplates.find((item:any) => item.id == id);
        this.inputModel.model.title = selectedItem.title;
        this.previewMessage = this.inputModel.model.message = selectedItem.message;
        this.message = this.previewMessage;
        return true;
    }

    setSmsTemplateList(data: any){
        if(!data.length) {
            return;
        }
        data.forEach( (item: any) => {
            this.smsTemplateList.push({
                id: parseInt(item.id),
                text: item.title
            });
            if(this.isEdit && item.id == this.inputModel.model.templateId) {
                this.previewMessage = item.message;
                this.inputModel.model.title = item.title;
            }
        });
    }

    removeCell() {
      this.confirmationBoxHelper
        .getConfirmation(this.deleteMessage, this.ajsScope)
        .then(() => {
            this.close.emit('remove');
        });
    }

}
