import * as tslib_1 from "tslib";
import { InterfaceService } from '@app/interfaces';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AddOpportunityService = /** @class */ (function (_super) {
    tslib_1.__extends(AddOpportunityService, _super);
    function AddOpportunityService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.reloadListData = new BehaviorSubject('');
        _this.reloadListData$ = _this.reloadListData.asObservable();
        _this.completeActivity = new BehaviorSubject('');
        _this.completeActivity$ = _this.completeActivity.asObservable();
        _this.viewModeObserver = new BehaviorSubject('');
        _this.viewModeObserver$ = _this.viewModeObserver.asObservable();
        _this.filterArray = new BehaviorSubject('');
        _this.filterArray$ = _this.filterArray.asObservable();
        _this.searchActivity = new BehaviorSubject('');
        _this.searchActivity$ = _this.searchActivity.asObservable();
        _this.filterUser = new BehaviorSubject('');
        _this.filterUser$ = _this.filterUser.asObservable();
        _this.completeData = new BehaviorSubject('');
        _this.completeData$ = _this.completeData.asObservable();
        _this.opportunitydetails = new BehaviorSubject(null);
        _this.$opportunitydetails = _this.opportunitydetails.asObservable();
        _this.refreshData = new BehaviorSubject('');
        _this.$refreshData = _this.refreshData.asObservable();
        _this.refreshActivityList = new BehaviorSubject('');
        _this.$refreshActivityList = _this.refreshActivityList.asObservable();
        _this.refreshActivityProposal = new BehaviorSubject('');
        _this.$refreshActivityProposal = _this.refreshActivityProposal.asObservable();
        _this.whoIsViewing = new BehaviorSubject('');
        _this.$whoIsViewing = _this.whoIsViewing.asObservable();
        _this.editDetails = new BehaviorSubject('');
        _this.$editDetails = _this.editDetails.asObservable();
        _this.handsonColumnFilter = new BehaviorSubject('');
        _this.$handsonColumnFilter = _this.handsonColumnFilter.asObservable();
        _this.deleteActivityData = new BehaviorSubject('');
        _this.$deleteActivityData = _this.deleteActivityData.asObservable();
        _this.proposalCreated = new BehaviorSubject(false);
        _this.$proposalCreated = _this.proposalCreated.asObservable();
        _this.attachedFiles = new BehaviorSubject('');
        _this.$attachedFiles = _this.attachedFiles.asObservable();
        _this.opportunitySchedule = new BehaviorSubject('');
        _this.$opportunitySchedule = _this.opportunitySchedule.asObservable();
        _this.opportunityNote = new BehaviorSubject('');
        _this.$opportunityNote = _this.opportunityNote.asObservable();
        _this.opportunityStatus = new BehaviorSubject('');
        _this.$opportunityStatus = _this.opportunityStatus.asObservable();
        _this.nextActivity = new BehaviorSubject('');
        _this.nextActivity$ = _this.nextActivity.asObservable();
        _this.opportunityEmailModal = new BehaviorSubject(false);
        _this.opportunityEmailModal$ = _this.opportunityEmailModal.asObservable();
        _this.timelineActivities = new BehaviorSubject('');
        _this.$timelineActivities = _this.timelineActivities.asObservable();
        _this.emailDraft = new BehaviorSubject('');
        _this.$emailDraft = _this.emailDraft.asObservable();
        _this.filterPreferences = new BehaviorSubject('');
        _this.$filterPreferences = _this.filterPreferences.asObservable();
        _this.reloadTimelineData = new BehaviorSubject('');
        _this.reloadTimelineData$ = _this.reloadTimelineData.asObservable();
        _this.invoiceData = new BehaviorSubject('');
        _this.invoiceData$ = _this.invoiceData.asObservable();
        _this.googleFontCollection = new BehaviorSubject(null);
        _this.googleFontCollection$ = _this.googleFontCollection.asObservable();
        _this.attachedFilesList = new BehaviorSubject('');
        _this.$attachedFilesList = _this.attachedFilesList.asObservable();
        return _this;
    }
    AddOpportunityService.prototype.getDropdownData = function (customerId, type, id) {
        return this.http.get(this.getApiUrl("sales/getOpportunityAddData/" + customerId + "?type=" + type + "&id=" + id));
    };
    AddOpportunityService.prototype.addOpportunity = function (data, files, customerId) {
        if (typeof files !== 'undefined') {
            var formData = new FormData();
            if (files != undefined) {
                for (var i = 0; i < files.length; i++) {
                    formData.append("file" + i, files[i]);
                }
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            return this.http.post(this.getApiUrl("sales/addOpportunity/" + customerId), formData);
        }
    };
    AddOpportunityService.prototype.editOpportunity = function (data, files, opportunityId) {
        if (typeof files !== 'undefined') {
            var formData = new FormData();
            if (files != undefined) {
                for (var i = 0; i < files.length; i++) {
                    formData.append("file" + i, files[i]);
                }
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            return this.http.post(this.getApiUrl("sales/editOpportunity/" + opportunityId), formData);
        }
    };
    // get opportunity data
    AddOpportunityService.prototype.getOpportunityData = function (id) {
        var url = this.getApiUrl("sales/getOpportunityDetails/" + id);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getCustomerDetails = function (id) {
        var url = this.getApiUrl("customers/customer/" + id + "/view/property/view?page=1&limit=10");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getPreloadCustomer = function (id) {
        var url = this.getApiUrl("sales/getPreloadCustomer/" + id);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getOpportunityRelatedData = function (templateId) {
        var url = this.getApiUrl("sales/getOpportunityTemplateData/" + templateId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getEmailTemplates = function () {
        var url = this.getApiUrl("listSettingsEmailTemplates?limit=1000&page=1");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.listEmailTemplates = function (propertyId, opportunityId) {
        if (opportunityId === void 0) { opportunityId = null; }
        var url = this.getApiUrl("sales/getEmailTemplates?propertyId=" + propertyId + "&opportunityId=" + opportunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getattachments = function (oppId, opportunityId) {
        if (opportunityId === void 0) { opportunityId = null; }
        var url = this.getApiUrl("getAttatchedFiles/" + oppId + "?limit=1000&page=1&opportunityId=" + opportunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getListActivityType = function () {
        var url = this.getApiUrl("sales/listActivityType");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.createScheduledActivity = function (data, urls) {
        var url = this.getApiUrl(urls);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.createOpportunityEmail = function (data, id, customerId) {
        if (customerId === void 0) { customerId = null; }
        var url = this.getApiUrl("sales/opportunityActivityEmail/" + id + "?customerId=" + customerId);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.createOpportunitySMS = function (data, id) {
        var url = this.getApiUrl("sales/opportunityActivitySMS/" + id);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.createOpportunityCall = function (data, urls) {
        var url = this.getApiUrl(urls);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.createOpportunityNote = function (data, id) {
        var url = this.getApiUrl("sales/opportunity_new_note/" + id);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.getCustomerEmailAddress = function (customerId, type) {
        var url = this.getApiUrl("get_customer_emailaddresses?id=" + customerId + "&type=" + type);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.listActivity = function (oppId) {
        var url = this.getApiUrl("sales/getActivityTimeline/" + oppId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getCustomerSmsAddress = function (customerId) {
        var url = this.getApiUrl("sales/getSMSContacts/" + customerId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getAssignee = function () {
        var url = this.getApiUrl("sales/listActivityAssignee");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getCustomerContact = function (customerId) {
        var url = this.getApiUrl("sales/getPhoneCallContacts/" + customerId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.setRefreshActivityList = function (data) {
        this.reloadListData.next(data);
    };
    AddOpportunityService.prototype.setRefreshActivityProposal = function (data) {
        this.refreshActivityProposal.next(data);
    };
    AddOpportunityService.prototype.loadActivityList = function (data) {
        this.refreshActivityList.next(data);
    };
    AddOpportunityService.prototype.refreshCompleteActivity = function (data) {
        this.refreshData.next(data);
    };
    AddOpportunityService.prototype.setCompleteActivityDetails = function (data) {
        this.completeActivity.next(data);
    };
    AddOpportunityService.prototype.setViewModeObserver = function (data) {
        this.viewModeObserver.next(data);
    };
    AddOpportunityService.prototype.setFilterArray = function (data) {
        this.filterArray.next(data);
    };
    AddOpportunityService.prototype.setSearchActivity = function (data) {
        this.searchActivity.next(data);
    };
    AddOpportunityService.prototype.setFilterUserArray = function (data) {
        this.filterUser.next(data);
    };
    AddOpportunityService.prototype.getSmsTemplates = function () {
        var url = this.getApiUrl("listSettingsSmsTemplates?limit=1000&page=1");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.setGoogleFontCollection = function (data) {
        this.googleFontCollection.next(data);
    };
    AddOpportunityService.prototype.listSmsTemplates = function (propertyId, opportunityId) {
        if (opportunityId === void 0) { opportunityId = null; }
        var url = this.getApiUrl("sales/getSmsTemplates?propertyId=" + propertyId + "&opportunityId=" + opportunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.saveFiles = function (oppId, files, shareOnMobile, deleteIds, id) {
        var formData = new FormData();
        if (files != undefined) {
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i]);
            }
        }
        formData.append("shareOnMobile", shareOnMobile);
        if (deleteIds) {
            formData.append("deleteIds", deleteIds);
        }
        if (id) {
            formData.append("id", id);
        }
        var url = this.getApiUrl("sales/upload_opportunity_activity_attach_file/" + oppId);
        return this.http.post(url, formData);
    };
    AddOpportunityService.prototype.setCompleteData = function (data) {
        this.completeData.next(data);
    };
    AddOpportunityService.prototype.saveSelectedNvagiation = function (data) {
        var url = this.getApiUrl("userColumnOrder");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.getFromEmailAddress = function () {
        var url = this.getApiUrl("get_from_emails");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.addProposalopportunity = function (id, regenerateOption, cheapPrice, isFreemium) {
        var url = this.getApiUrl("sales/add_opportunity_proposal_pages/" + id + "?regenerate=" + regenerateOption + "&cheapPrice=" + cheapPrice + "&isFreemium=" + isFreemium);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getProposalPriceRange = function (id) {
        var url = this.getApiUrl("sales/getProposalPriceRange/" + id);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getOpportunityPages = function (id, proposalId) {
        var url = this.getApiUrl("sales/get_opportunity_pages/" + id + "?opportunityProposalId=" + proposalId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.generatePdfFiles = function (id, data) {
        var url = this.getApiUrl("sales/generate_pdf/" + id);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.addNewPage = function (id, data) {
        var url = this.getApiUrl("sales/add_opportunity_page/" + id);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.editOpportunityPage = function (id, data) {
        var url = this.getApiUrl("sales/edit_opportunity_page/" + id);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.setOpportunityId = function (id) {
        this.opportunitydetails.next(id);
    };
    AddOpportunityService.prototype.updateWhoIsViewing = function (availableUsers) {
        this.whoIsViewing.next(availableUsers);
    };
    AddOpportunityService.prototype.deleteActivity = function (activityId) {
        var url = this.getApiUrl("sales/deleteActivity/" + activityId);
        return this.http.delete(url);
    };
    AddOpportunityService.prototype.deleteAttachFiles = function (id, opportunityId) {
        if (opportunityId === void 0) { opportunityId = null; }
        var url = this.getApiUrl("sales/deleteActivityAttachedFile/" + id + "?opportunityId=" + opportunityId);
        return this.http.delete(url);
    };
    AddOpportunityService.prototype.editNotes = function (data) {
        var url = this.getApiUrl("sales/edit_opportunity_note");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.setEditDetails = function (data) {
        this.editDetails.next(data);
    };
    AddOpportunityService.prototype.setHandsonColumnFilter = function (data) {
        this.handsonColumnFilter.next(data);
    };
    AddOpportunityService.prototype.setDeleteActivity = function (data) {
        this.deleteActivityData.next(data);
    };
    AddOpportunityService.prototype.completeTask = function (activityId) {
        var url = this.getApiUrl("sales/opportunityActivityTask/" + activityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.completeScheduleActivity = function (data) {
        var url = this.getApiUrl("sales/completeOpportunityActivity");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.undoScheduleActivity = function (data) {
        var url = this.getApiUrl("sales/undoOpportunityActivity");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.deleteOpportunity = function (opportunityId) {
        var url = this.getApiUrl("sales/deleteOpportunity");
        return this.http.post(url, opportunityId);
    };
    AddOpportunityService.prototype.cloneOpportunity = function (opportunityId) {
        var url = this.getApiUrl("sales/cloneOpportunity/" + opportunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getOpportunityReasons = function (type) {
        var url = this.getApiUrl("getOpportunityReasons?type=" + type);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.rejectProposal = function (opportunityId, rejectReasonId) {
        var url = this.getApiUrl("sales/rejectProposal/" + opportunityId + "?rejectReasonId=" + rejectReasonId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.cancelProposal = function (opportunityId, cancelReasonId) {
        var url = this.getApiUrl("sales/cancelProposal/" + opportunityId + "?cancelReasonId=" + cancelReasonId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getProposalSectionData = function (id, fromCloseModal, opportunityId) {
        var param = fromCloseModal ? 'yes' : 'no';
        return this.http.get(this.getApiUrl("sales/getProposalSectionData/" + id) + '?fromCloseModal=' + param + '&opportunityId=' + opportunityId);
    };
    AddOpportunityService.prototype.getFinancialBreakdown = function (id) {
        return this.http.get(this.getApiUrl("sales/getFinancialBreakdown/" + id));
    };
    AddOpportunityService.prototype.getProposalPdf = function (id, type) {
        return this.http.get(this.getApiUrl("getProposalTemplatePDF/" + id + "/" + type));
    };
    AddOpportunityService.prototype.getProposalPdfPath = function (id) {
        return this.http.get(this.getApiUrl("getProposalPdfPath/" + id));
    };
    AddOpportunityService.prototype.getAssetsList = function (propertyId, page, searchText) {
        return this.http.get(this.getApiUrl("get_asset_widget_details?propertyId=" + propertyId + "&screenType=job&page=" + page + "&searchText=" + searchText));
    };
    AddOpportunityService.prototype.completeMeeting = function (activityId) {
        var url = this.getApiUrl("sales/opportunityActivityMeeting/" + activityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getPartOptions = function (opportunityProposalId) {
        var url = this.getApiUrl("sales/getPartOptions/" + opportunityProposalId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getUpsellOptions = function (data) {
        return this.http.post(this.getApiUrl("sales/getUpsellOptions"), data);
    };
    AddOpportunityService.prototype.getContactList = function (customerId) {
        var url = this.getApiUrl("customers/customer_list/" + customerId + "/contacts/view?page=1&limit=150");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getContactDetails = function (opportunityId) {
        var url = this.getApiUrl("sales/getContactDetails/" + opportunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.acceptRejectOpportunity = function (data) {
        var url = this.getApiUrl("sales/AcceptRejectOpportunity");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.sendProposalEmailToCustomer = function (data, opportunityId) {
        var url = this.getApiUrl("sales/sendProposalEmailToCustomer/" + opportunityId);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.sendProposalSMSToCustomer = function (data, opportunityId) {
        var url = this.getApiUrl("sales/sendProposalSMSToCustomer/" + opportunityId);
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.printProposalToCustomer = function (opportunityId) {
        var url = this.getApiUrl("sales/printProposalToCustomer/" + opportunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getCustomerTitles = function () {
        var url = this.getApiUrl("listTitles?page=1&limit=1000");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.setProposalStatus = function (status) {
        this.proposalCreated.next(status);
    };
    AddOpportunityService.prototype.updateOpportunityStatus = function (opportunityId, stageId) {
        var url = this.getApiUrl("sales/updateOpportunitiesStatus/" + opportunityId + "?stageId=" + stageId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.updateOpportunityExpireStatus = function (id) {
        var url = this.getApiUrl("sales/updateOpportunityExpireStatus/" + id);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.updateProposalVersion = function (id) {
        var url = this.getApiUrl("sales/updateProposalVersion/" + id);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.updateProposalPricingTaxOption = function (data) {
        var url = this.getApiUrl("sales/updateProposalPricingTaxOption");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.setFileData = function (data) {
        this.attachedFiles.next(data);
    };
    AddOpportunityService.prototype.setReschedule = function (data) {
        this.opportunitySchedule.next(data);
    };
    AddOpportunityService.prototype.setOpportunityNote = function (data) {
        this.opportunityNote.next(data);
    };
    AddOpportunityService.prototype.setOpportunityStatus = function (data) {
        this.opportunityStatus.next(data);
    };
    AddOpportunityService.prototype.getScheduledEmail = function (oppid, activityType) {
        var url = this.getApiUrl("sales/checkTodayEmailActivity/" + oppid + "?activityType=" + activityType);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getAcceptProposalData = function (opportunityId, proposalVersion) {
        var url = this.getApiUrl("sales/getAcceptProposalData/" + opportunityId + "?proposalVersion=" + proposalVersion);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.addOrEditAcceptProposal = function (data) {
        var url = this.getApiUrl("sales/addOrEditAcceptProposal");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.getOpportuntiyAssets = function (opportunityId, customerId) {
        var url = this.getApiUrl("sales/getOpportunityAssets/" + opportunityId + "?propertyId=" + customerId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getOpportunityInvoice = function (data) {
        return this.http.post(this.getApiUrl("sales/getOpportunityInvoiceScheduleExists"), data);
    };
    AddOpportunityService.prototype.getTodayScheduleActivity = function (opportunityId, type) {
        var url = this.getApiUrl("sales/checkTodayActivity/" + opportunityId + "?type=" + type);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.saveActivity = function (oppurtunityId) {
        var url = this.getApiUrl("saveactivity/" + oppurtunityId);
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getDefaultEmailFooter = function () {
        var url = this.getApiUrl("get_default_email_footer");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.getConfirmations = function (templateId, menuType, customerId, opportunityId) {
        if (customerId === void 0) { customerId = null; }
        if (opportunityId === void 0) { opportunityId = null; }
        return this.http.get(this.getApiUrl("getOppurtunityConfirmations/" + templateId + "?type=" + menuType + "&customerId=" + customerId + "&opportunityId=" + opportunityId));
    };
    AddOpportunityService.prototype.setNextActivity = function (data) {
        this.nextActivity.next(data);
    };
    AddOpportunityService.prototype.setOpportunityEmailModal = function (data) {
        this.opportunityEmailModal.next(data);
    };
    AddOpportunityService.prototype.setActivities = function (data) {
        this.timelineActivities.next(data);
    };
    AddOpportunityService.prototype.setEmailDraft = function (data) {
        this.emailDraft.next(data);
    };
    AddOpportunityService.prototype.setfilterPreferences = function (data) {
        this.filterPreferences.next(data);
    };
    /**
     *
     * @param params
     * @returns {Observable<Object>}
     */
    AddOpportunityService.prototype.getLivePriceFromAPI = function (params) {
        return this.http.get(this.getApiUrl("get_integration_parts_prices"), { params: params });
    };
    AddOpportunityService.prototype.getfinanceTemplateDetails = function (opportunityId, optionId, total) {
        return this.http.get(this.getApiUrl("sales/financeTemplate/" + opportunityId + "?optionId=" + optionId + "&total=" + total));
    };
    AddOpportunityService.prototype.getProposalSummary = function (opportunityId) {
        return this.http.get(this.getApiUrl("sales/" + opportunityId + "/proposalSummary"));
    };
    AddOpportunityService.prototype.getTributeData = function (opportunityId) {
        return this.http.get(this.getApiUrl("sales/" + opportunityId + "/getTributeData"));
    };
    AddOpportunityService.prototype.updateContactDetails = function (opportunityId, contactData) {
        return this.http.post(this.getApiUrl("sales/" + opportunityId + "/updateOpportunityDetails"), contactData);
    };
    AddOpportunityService.prototype.getPartUpsell = function (selectedUpsellId) {
        return this.http.get(this.getApiUrl("sales/partUpsell?partUpSellId=" + selectedUpsellId));
    };
    AddOpportunityService.prototype.setRefreshTimelineList = function (data) {
        this.reloadTimelineData.next(data);
    };
    AddOpportunityService.prototype.addAdvancedStylingTemplate = function (data) {
        var url = this.getApiUrl("sales/addAdvancedStylingTemplate");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.getAdvancedStylingTemplate = function () {
        var url = this.getApiUrl("sales/getAdvancedStylingTemplate");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.setinvoiceData = function (data) {
        this.invoiceData.next(data);
    };
    AddOpportunityService.prototype.updatePageNumbers = function (screen, primaryId, pages, proposalId) {
        var url = this.getApiUrl("sales/update_page_numbers/" + screen + "/" + primaryId + "?opportunityProposalId=" + proposalId);
        return this.http.post(url, { pages: pages });
    };
    //Todo: This need to be moved to relavant services
    AddOpportunityService.prototype.getTimelineContacts = function (id, type, tablePKId, screen) {
        var url;
        if (screen == 'customer') {
            url = this.getApiUrl(type + "/new_phone_call/" + id + "/timeline?isCommonRoute=true");
        }
        else if (screen == 'jobs') {
            url = this.getApiUrl("customers/" + type + "/" + id + "/notes/jobs/" + tablePKId + "/new_phone_call/timeline?isCommonRoute=true");
        }
        else if (screen === 'contract') {
            url = this.getApiUrl("customers/" + type + "/" + id + "/notes/jobs/" + tablePKId + "/new_phone_call/timeline?isCommonRoute=true");
        }
        return this.http.get(url);
    };
    AddOpportunityService.prototype.setAttachedFilesList = function (data) {
        this.attachedFilesList.next(data);
    };
    AddOpportunityService.prototype.processEmail = function (data) {
        var url = this.getApiUrl("process_email");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.getSmsContacts = function (customerId) {
        var url = this.getApiUrl("customer/new_sms_message/" + customerId + "/timeline?isCommonRoute=true");
        return this.http.get(url);
    };
    AddOpportunityService.prototype.saveSelectedNavagiation = function (data) {
        var url = this.getApiUrl("userColumnOrder");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.completeCustomerScheduledActivity = function (data) {
        var url = this.getApiUrl("completeCustomerScheduledActivity");
        return this.http.post(url, data);
    };
    AddOpportunityService.prototype.deleteAcceptProposalData = function (opportunityId) {
        var url = this.getApiUrl("sales/deleteAcceptProposalData/" + opportunityId);
        return this.http.delete(url);
    };
    AddOpportunityService.prototype.fetchAndPersistGoogleFontLinks = function (type, data) {
        if (type === void 0) { type = ''; }
        var url = this.getApiUrl("sales/storeGoogleFontLinks");
        if (type == 'POST') {
            return this.http.post(url, data);
        }
        else {
            return this.http.get(url);
        }
    };
    AddOpportunityService.prototype.deleteOpportunityProposal = function (opportunityId) {
        var url = this.getApiUrl("sales/deleteOpportunityProposal/" + opportunityId);
        return this.http.delete(url);
    };
    AddOpportunityService.prototype.saveSupplierProductCode = function (data) {
        return this.http.post(this.getApiUrl("parts_and_prices/supplier_prices/addOrEdit"), 'supplierprice:' + JSON.stringify(data));
    };
    AddOpportunityService.prototype.updatePartSupplierPrices = function (id, proposalId) {
        return this.http.get(this.getApiUrl("sales/updatePartSupplierPrices/" + id + "?opportunityProposalId=" + proposalId));
    };
    AddOpportunityService.prototype.getOpportunityTagsList = function (params) {
        return this.http.get(this.getApiUrl('sales/get_labels', params));
    };
    AddOpportunityService.ngInjectableDef = i0.defineInjectable({ factory: function AddOpportunityService_Factory() { return new AddOpportunityService(i0.inject(i1.HttpClient)); }, token: AddOpportunityService, providedIn: "root" });
    return AddOpportunityService;
}(InterfaceService));
export { AddOpportunityService };
