import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    CommunicationDispatcherService
} from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import { Subscription } from "rxjs";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { BsModalRef } from "ngx-bootstrap";
import {
    CommunicationContainerComponent
} from "@app/shared/communication-mapping/container/communication-container.component";
import {
    AddCommunicationComponent
} from "@app/shared/communication-mapping/layouts/add-communication/add-communication.component";
import { InputModel, TemplateModel } from "@app/shared/communication-mapping/services/input-model";
import { CommunicationHelperService } from "@app/shared/communication-mapping/services/communication-helper.service";
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from "@app/core";
declare var $: any;

@Component({
    selector: 'app-template-form',
    templateUrl: './template-form.component.html',
    styleUrls: ['./template-form.component.scss']
})

export class TemplateFormComponent implements OnInit, OnDestroy {

    isLoading: boolean = true;
    subscription: Subscription;
    emailTemplateList: any = [];
    smsTemplateList: any = [];
    inputModel: InputModel;
    emailTemplateId: null | number;
    smsTemplateId: null | number;
    letterTemplateId: null | number;

    sidePanelModalRef: BsModalRef;
    emailTemplateListIndexById: any = {};
    smsTemplateListIndexById: any = {};
    letterScreenLabel: string = this.translate.instant('letter.templates');
    smsScreenLabel: string = this.translate.instant('sms.templates');
    emailScreenLabel: string = this.translate.instant('Email.template');
    selectedSmsTemplate: string = '';
    selectedEmailTemplate: string = '';
    selectedLetterTemplate: string = '';
    select2TemplateConfig: any;
    @Output() selectedRolesEvent = new EventEmitter<string>();
    selectedRolesList: any = [];
    @Input() roleList: any = [];
    @Input() previousRolesList: any = [];
    @Input() selectedTemplateData: any = [];
    smsModulePermission: boolean;
    @Input() viewMode: boolean;

    constructor(
        private eventDispatcher: CommunicationDispatcherService,
        private modalService: SidepanelModalService,
        private helperService: CommunicationHelperService,
        private translate: TranslateService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: (data: any) => {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                const action = data['actionName'];
                /* Initial load */
                if (action == 'event:loadedData') {
                    const value = data['data'];
                    // this.setEmailTemplateList(value['emailTemplateList']);
                    // this.setSmsTemplateList(value['smsTemplateList']);
                    this.inputModel = value['inputModel'];
                    const activeEmail = this.helperService.findCurrentTemplate(this.inputModel, 'email');
                    const activeSms = this.helperService.findCurrentTemplate(this.inputModel, 'sms');
                    if (activeEmail) {
                        this.emailTemplateId = activeEmail['templateId'];
                        this.selectedEmailTemplate = activeEmail['title'];
                    }
                    if (activeSms) {
                        this.smsTemplateId = activeSms['templateId'];
                        this.selectedSmsTemplate = activeSms['title'];
                    }
                    this.isLoading = false;
                }
            }
        });
        this.smsModulePermission  = this.authService.hasModuleAccess('Sms');

        this.select2TemplateConfig = {
            width: '100%',
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.previousRolesList) {
            let _this = this;
            let selectedOptionsList = [];
            if (changes.previousRolesList.currentValue.length) {
                this.selectedRolesEvent.emit(this.previousRolesList)
                if (typeof this.previousRolesList !== undefined) {
                    setTimeout(() => {
                        $('.notification-roles option').each(function () {
                            let optionVal: any = JSON.parse(JSON.stringify($(this).val()));
                            let splittedString = optionVal.split(': ').pop();
                            splittedString = splittedString.replace(/["']/g, "");
                            if (_this.previousRolesList.includes(splittedString)) {
                                selectedOptionsList.push(optionVal)
                            }
                        });
                        if (selectedOptionsList.length) {
                            $('.notification-roles option').select2('val', selectedOptionsList).trigger('change');
                        }
                    }, 500);
                }
            }
        }
    }

    ngAfterViewInit(): void {

    }
    selectedRoles(value) {
        if (value && typeof value !== undefined) {
            this.selectedRolesList = [];
            value.forEach(val => {
                let splittedString = val.split(': ').pop();
                splittedString = splittedString.replace(/["']/g, "");
                this.selectedRolesList.push(splittedString);
            });
            this.selectedRolesEvent.emit(this.selectedRolesList)
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    setEmailTemplateList(data: any) {
        if (!data.length) {
            return;
        }
        data.forEach((item: any) => {
            this.emailTemplateList.push({
                id: parseInt(item.id),
                text: item.templateName
            });
            this.emailTemplateListIndexById[item.id] = item;
        });
    }

    setSmsTemplateList(data: any) {
        if (!data.length) {
            return;
        }
        data.forEach((item: any) => {
            this.smsTemplateList.push({
                id: parseInt(item.id),
                text: item.title
            });
            this.smsTemplateListIndexById[item.id] = item;
        });
    }

    showAddCommunication(commType: string) {
        const initialState = {};
        this.sidePanelModalRef = this.modalService.show(AddCommunicationComponent, {
            initialState,
            size: 'xl',
            'class': 'cs-form-add-communication',
            panelType: 'sidePanel',
        })
    }

    setTemplateDetails(templateDetails, type) {
        let oldTemplateId = null;
        if (type == 'email') {
            oldTemplateId = this.emailTemplateId;
        }
        else if (type == 'sms') {
            oldTemplateId = this.smsTemplateId;
        }
        else if (type == 'letter') {
            oldTemplateId = this.letterTemplateId;
        }
        const { templates, activeSegmentationValue1, activeSegmentationValue2, customerId } = this.inputModel;
        if (templates.length == 0) {
            templates.push({
                id: 0,
                communicationType: type,
                templateId: templateDetails.templateId,
                segmentationValue1: activeSegmentationValue1,
                segmentationValue2: activeSegmentationValue2,
                customerId: customerId,
                title: templateDetails['templateName'],
                message: templateDetails['message'],
                subject: templateDetails['subject']
            });
        } else {
            const activeItem = templates.find((item: TemplateModel) => {
                return item.communicationType == type
            });
            if (activeItem) {
                //activeItem['id'] = parseInt(templateDetails.id);
                activeItem['templateId'] = parseInt(templateDetails.templateId);
                activeItem['message'] = templateDetails.message;
                activeItem['subject'] = templateDetails.subject;
                activeItem['title'] = templateDetails.templateName;
            }
            else {
                templates.push({
                    id: 0,
                    communicationType: type,
                    templateId: templateDetails.templateId,
                    segmentationValue1: activeSegmentationValue1,
                    segmentationValue2: activeSegmentationValue2,
                    customerId: customerId,
                    title: templateDetails['templateName'],
                    message: templateDetails['message'],
                    subject: templateDetails['subject']
                });
            }
        }
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['updateTemplateDetails'], {
            type,
            value: templateDetails,
            inputModel: this.inputModel
        });
    }

    /*onChangeTemplate(type: string, event: any) {
        let oldTemplateId = null;
        if (type == 'email') {
            oldTemplateId = this.emailTemplateId;
        }
        else if (type == 'sms') {
            oldTemplateId = this.smsTemplateId;
        }
        const { templates, activeSegmentationValue1, activeSegmentationValue2, customerId } = this.inputModel;
        if (templates.length == 0) {
            templates.push({
                id: 0,
                communicationType: type,
                templateId: event,
                segmentationValue1: activeSegmentationValue1,
                segmentationValue2: activeSegmentationValue2,
                customerId: customerId,
            });
        }
        else {
            const activeItem = templates.find((item: TemplateModel) => {
                return activeSegmentationValue1 == item.segmentationValue1
                    && activeSegmentationValue2 == item.segmentationValue2
                    && item.templateId == oldTemplateId
                    && item.communicationType == type
            });
            if (activeItem) {
                activeItem['templateId'] = event;
            }
            else {
                templates.push({
                    id: 0,
                    communicationType: type,
                    templateId: event,
                    segmentationValue1: activeSegmentationValue1,
                    segmentationValue2: activeSegmentationValue2,
                    customerId: customerId,
                });
            }
        }
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['updatedTemplateId'], {
            type,
            value: event,
            inputModel: this.inputModel
        });
    }*/
}
