import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import { CommunicationHttpService } from "@app/shared/communication-mapping/services/communication-http.service";
import { InputModel } from "@app/shared/communication-mapping/services/input-model";
import { CommunicationHelperService } from "@app/shared/communication-mapping/services/communication-helper.service";
var CommunicationLayoutComponent = /** @class */ (function () {
    function CommunicationLayoutComponent(httpService, eventDispatcher, helperService) {
        this.httpService = httpService;
        this.eventDispatcher = eventDispatcher;
        this.helperService = helperService;
        this.objectName = '';
        this.isCustomerSpecific = false;
        this.allowSegmentation = false;
        this.communicationScreenType = null;
        this.allowTypes = ['email', 'sms'];
        this.objectId = null; //tablePkId
        this.objectTypeId = ''; //tableType
        this.categoryType = 'communication';
        this.selectedTemplateData = {};
        this.$instance = new EventEmitter();
        this.viewMode = false;
        this.inputModel = {
            communicationId: 0,
            customerId: null,
            allowTypes: this.allowTypes,
            templates: [],
            segmentationType1: null,
            segmentationType2: null,
            allowSegmentation: this.allowSegmentation,
            activeSegmentationValue1: null,
            activeSegmentationValue2: null,
            objectId: this.objectId,
            objectTypeId: this.objectTypeId,
            subject: null,
            message: null,
            title: null
        };
        this.isLoading = true;
        this.emailTemplateList = [];
        this.smsTemplateList = [];
        this.isValidFormData = false;
        this.roleList = [];
        this.previousRolesList = [];
        this.selectedRoleIdsList = [];
        this.clientDetails = {};
    }
    CommunicationLayoutComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // await this.getEmailTemplates();
                    // await this.getSmsTemplates();
                    return [4 /*yield*/, this.getData(this.objectName, this.categoryType, this.communicationScreenType)];
                    case 1:
                        // await this.getEmailTemplates();
                        // await this.getSmsTemplates();
                        _a.sent();
                        this.isLoading = false;
                        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
                            next: function (data) {
                                if (typeof data != 'object' || !data['actionName']) {
                                    return;
                                }
                                var action = data['actionName'];
                                if (action == CommunicationDispatcherService.CONSTANTS['updateTemplateDetails']) {
                                    var isValid = _this.checkIsValidForm();
                                    _this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['checkIsValidForm'], {
                                        isValid: isValid
                                    });
                                }
                                else if (action == CommunicationDispatcherService.CONSTANTS['saveAndClose']) {
                                    // console.log('saveAndClose');
                                    _this.saveCommunication();
                                }
                            }
                        });
                        this.eventDispatcher.dispatchEvent('event:loadedData', {
                            data: {
                                inputModel: this.inputModel,
                                smsTemplateList: this.smsTemplateList,
                                emailTemplateList: this.emailTemplateList,
                                clientDetails: this.clientDetails,
                                layoutComponent: this
                            }
                        });
                        this.$instance.emit(this);
                        return [2 /*return*/];
                }
            });
        });
    };
    CommunicationLayoutComponent.prototype.ngOnDestroy = function () {
        console.info('destroyed/unsubscribe communication layout component');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    CommunicationLayoutComponent.prototype.selectedRoles = function (roles) {
        this.selectedRoleIdsList = roles;
        var isValid = this.checkIsValidForm();
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['checkIsValidForm'], {
            isValid: isValid
        });
    };
    CommunicationLayoutComponent.prototype.checkIsValidForm = function () {
        /*        if(this.isCustomerSpecific) {
                    if(!this.inputModel['customerId']) {
                        this.isValidFormData=false;
                        return false;
                    }
                }
                if(this.allowSegmentation) {
                    if(!this.inputModel['segments'].length) {
                        this.isValidFormData=false;
                        return false;
                    }
                }
                if(!this.inputModel.emailTemplateId && !this.inputModel.smsTemplateId) {
                    this.isValidFormData=false;
                    return false;
                }
                this.isValidFormData=true;*/
        if ((this.selectedRoleIdsList.length || this.categoryType == 'communication') && this.inputModel.templates.length) {
            return true;
        }
        return false;
    };
    CommunicationLayoutComponent.prototype.getData = function (objectName, categoryType, screenType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var params = {};
                        if (_this.objectTypeId) {
                            params['objectTypeId'] = _this.objectTypeId;
                        }
                        if (_this.objectId) {
                            params['objectId'] = _this.objectId;
                        }
                        _this.httpService.getCommunicationByScreenType(objectName, categoryType, screenType, params)
                            .toPromise()
                            .then(function (resp) {
                            var _data = resp['data'];
                            if (_this.categoryType == 'notification') {
                                _this.roleList = resp['roles'];
                                _this.previousRolesList = resp['notificationPreferences'];
                            }
                            if (_data['communicationId'] != 0) {
                                _this.inputModel.communicationId = _data['communicationId'];
                                _this.inputModel.segmentationType1 = _data['segmentationType1'];
                                _this.inputModel.segmentationType2 = _data['segmentationType2'];
                            }
                            _this.inputModel.templates = _this.loadTemplates(_data);
                            _this.clientDetails = resp['clientDetails'];
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    CommunicationLayoutComponent.prototype.loadTemplates = function (_data) {
        /*if( (!_data.hasOwnProperty('templates') || _data['templates'].length == 0) && Object.keys(this.selectedTemplateData).length === 0 ){
            return _data['defaultTemplates'];
        }
        else */ if (this.selectedTemplateData && this.selectedTemplateData.hasOwnProperty('templates')) {
            return this.selectedTemplateData['templates'];
        }
        else if (_data.hasOwnProperty('templates')) {
            return _data['templates'];
        }
    };
    CommunicationLayoutComponent.prototype.getEmailTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.httpService.getEmailTemplate()
                            .toPromise()
                            .then(function (resp) {
                            if (resp['emailTemplates']) {
                                _this.emailTemplateList = resp['emailTemplates'];
                            }
                            console.log('email', resp);
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    CommunicationLayoutComponent.prototype.getSmsTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.httpService.getSMSTemplate()
                            .toPromise()
                            .then(function (resp) {
                            if (resp['smsTemplates']) {
                                _this.smsTemplateList = resp['smsTemplates'];
                            }
                            console.log('sms', resp);
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    CommunicationLayoutComponent.prototype.saveCommunication = function () {
        var _this = this;
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['beforeSaveAction'], {});
        var _a = this.inputModel, communicationId = _a.communicationId, segmentationType1 = _a.segmentationType1, segmentationType2 = _a.segmentationType2, templates = _a.templates, customerId = _a.customerId;
        var communication = {
            communicationId: communicationId,
            segmentationType1: segmentationType1,
            segmentationType2: segmentationType2,
            customerId: customerId,
            templates: templates,
            objectId: this.objectId,
            objectTypeId: this.objectTypeId,
            allowSegmentation: this.allowSegmentation,
            roleIds: this.selectedRoleIdsList
        };
        if (this.objectTypeId != '' && this.objectId == 0) {
            this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['inlineSaveAction'], {
                formData: { communication: communication }
            });
        }
        else {
            this.httpService.saveCommunication(this.objectName, this.categoryType, this.communicationScreenType, { communication: communication })
                .subscribe({
                next: function (resp) {
                    _this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['afterSaveAction'], {
                        'response': resp
                    });
                }
            });
        }
    };
    return CommunicationLayoutComponent;
}());
export { CommunicationLayoutComponent };
