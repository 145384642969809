<div class="ng-flex-container-main">
    <ng-container *ngIf="isLoading">
        <div class="loading-wrap">
            <span class="circle1"></span>
            <span class="circle2"></span>
            <span class="circle3"></span>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && isEnabled">
        <div *ngIf="sequenceMode == 'sequence_setup'" class="aside-container no-border">
            <div class="aside-inner">
                <ul class="fs-menu">
                    <li *ngFor="let item of menuItems">
                        <ng-container *ngIf="item.isHeader">
                            <div class="nav-header" translate>{{item.title}}</div>
                        </ng-container>
                        <ng-container *ngIf="!item.isHeader">
                            <a href="javascript:void(0);" (click)="setActiveMenu(item)" [ngClass]="{'active': item.id===activeMenu, 'disabled': !item.isActive}">
                                <i class={{item.class}} style="margin-right: 10px;"></i>{{item.title}}
                            </a>
                        </ng-container>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex-grow flex-1" style="flex:1;box-sizing: border-box">
            <div style="height: calc(100% - 10px)" *ngIf="data && activeMenu=='sequence_setup'">
                <app-diagram-view-sequence #workflowDiagram
                  (beforeRenderDiagram)="beforeRenderedDiagram($event)"
                  (afterRenderDiagram)="afterRenderedDiagram($event)"
                  [csFullModal]="csFullModal"
                  [tags]="tagsList"
                  [userRoles]="rolesList"
                  [usersList]="usersList"
                  [scheduleActivityTypes]="scheduleActivityTypes"
                  [viewMode]="false"
                  [mode]="sequenceMode"
                  [additionalData]="additionalData"
                  [sequenceObjectName]="sequenceObjectName"
                  [data]="data">
                </app-diagram-view-sequence>
            </div>
            <div style="height: calc(100% - 10px)" *ngIf="data && activeMenu=='exit_criteria_setup'">
                <app-exit-condition-view #workflowDiagram
                   [exitCondition]="exitConditions"
                   [data]="data"
                   (selectedExitCriteria)="selectedExitCriteria($event)"
                >
                </app-exit-condition-view>
            </div>
        </div>
    </ng-container>
</div>
