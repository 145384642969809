import { EventEmitter, OnInit } from '@angular/core';
import { CommonDataService } from "@app/services/http-services/common-data.service";
import { Router } from '@angular/router';
import { AuthService } from '@app/core';
var ColorPalatteComponent = /** @class */ (function () {
    function ColorPalatteComponent(commonService, router, authService) {
        this.commonService = commonService;
        this.router = router;
        this.authService = authService;
        this.colorPalatte = true;
        this.savedColors = false;
        this.modal = false;
        this.percentage = 100;
        this.recentColors = [];
        this.background = [
            {
                name: 'Primary',
                value: '#505565'
            },
            {
                name: 'Secondary',
                value: '#979797'
            },
            {
                name: 'Disabled',
                value: '#c9c9c9'
            },
            {
                name: 'Form',
                value: '#ebebeb'
            },
            {
                name: 'Border',
                value: '#eeeeee'
            },
        ];
        this.brand = [
            {
                name: 'Accent',
                value: '#f2994a'
            },
            {
                name: 'Accent-Transparent 20',
                value: '#f2994a33'
            },
            {
                name: 'Secondary',
                value: '#3883c1'
            },
            {
                name: 'Tersier',
                value: '#746ca6'
            },
            {
                name: 'Border',
                value: '#eeeeee'
            },
        ];
        this.searchValues = false;
        this.searchResult = [];
        this.userSearchNotFound = false;
        this.colorValue = new EventEmitter();
        this.errorMessage = false;
        this.savedColorLoading = false;
        this.savedColorTab = 1;
        this.showCloseIcon = false;
        this.searchColor = '';
        this.savedColourAccess = false;
    }
    ColorPalatteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.savedColourAccess = this.authService.permitted(['SetupSavedColours.writeaccess']) === true;
        var localColors = localStorage.getItem('Recent-colors');
        if (localColors) {
            this.recentColors = JSON.parse(localColors);
        }
        $('app-hue').css('height', 'auto');
        this.commonService.savedColorsData$.subscribe(function (data) {
            _this.collection = data;
        });
        // let self = this;
        // this.eventSavedColorWatch = this.ajsScope.$on('event:saved_color_updated', function (event, data) {
        //     if (data) {
        //         self.loadSavedColors();
        //     }
        // });
    };
    // loadSavedColors(){
    //     this.commonService.fetchSavedColorsData();
    // }
    ColorPalatteComponent.prototype.ngOnChanges = function () {
        var localColors = localStorage.getItem('Recent-colors');
        if (localColors) {
            this.recentColors = JSON.parse(localColors);
        }
        if (this.data) {
            if (this.data[0] != '#') {
                this.color = { hexString: '#ffffff' };
            }
            else {
                this.color = { hexString: this.data };
            }
            this.colorDisplay = this.data;
            this.checkInputValue(this.data, 'recentColors');
        }
        this.setCursorPosition();
        this.savedColors = false;
        this.colorPalatte = true;
        this.searchValues = false;
    };
    //overwrites plugin rendered position of the cursor
    ColorPalatteComponent.prototype.setCursorPosition = function () {
        var _this = this;
        var that = this;
        setTimeout(function () {
            if (_this.data) {
                if (_this.data[0] != '#') {
                    _this.color = { hexString: '#ffffff' };
                }
                else {
                    _this.color = { hexString: _this.data };
                }
            }
            else {
                _this.color = { hexString: '#ffffff' };
            }
            var cursor = $('app-cursor .cursor.offset:visible')[0];
            if (cursor) {
                var cursorTop = parseInt(cursor.style.top.replace('px', '')), cursorLeft = parseInt(cursor.style.left.replace('px', ''));
                if (that.color && (cursorTop <= 0 || cursorLeft <= 0)) {
                    var rgb = that.hexToRGB(that.color);
                    var hsv = that.rgbToHsv(rgb);
                    var canvas = $('.main-canvas:visible');
                    if (hsv && hsv.hasOwnProperty('s') && hsv.hasOwnProperty('v')) {
                        $(cursor).css({
                            left: hsv.s / 100 * $(canvas).width() + 'px',
                            top: (1 - hsv.v / 100) * $(canvas).height() + 'px'
                        });
                    }
                }
            }
        }, 100);
    };
    ColorPalatteComponent.prototype.hexToRGB = function (color) {
        if (color && color.hasOwnProperty('hexString') && color.hexString != '#') {
            var rgbHex = color.hexString.replace('#', '').match(/.{1,2}/g);
            var rgb = [
                parseInt(rgbHex[0], 16),
                parseInt(rgbHex[1], 16),
                parseInt(rgbHex[2], 16)
            ];
            return rgb;
        }
    };
    ColorPalatteComponent.prototype.rgbToHsv = function (rgb) {
        if (rgb) {
            var redAbs = void 0, greenAbs = void 0, blueAbs = void 0, red = void 0, green = void 0, blue = void 0, hue = void 0, saturation = void 0, value_1, difference_1, roundColor = void 0;
            redAbs = rgb[0] / 255;
            greenAbs = rgb[1] / 255;
            blueAbs = rgb[2] / 255;
            value_1 = Math.max(redAbs, greenAbs, blueAbs),
                difference_1 = value_1 - Math.min(redAbs, greenAbs, blueAbs);
            roundColor = function (colorAbs) { return (value_1 - colorAbs) / 6 / difference_1 + 1 / 2; };
            if (difference_1 == 0) {
                hue = saturation = 0;
            }
            else {
                saturation = difference_1 / value_1;
                red = roundColor(redAbs);
                green = roundColor(greenAbs);
                blue = roundColor(blueAbs);
                if (redAbs === value_1) {
                    hue = blue - green;
                }
                else if (greenAbs === value_1) {
                    hue = (1 / 3) + red - blue;
                }
                else if (blueAbs === value_1) {
                    hue = (2 / 3) + green - red;
                }
                if (hue < 0) {
                    hue += 1;
                }
                else if (hue > 1) {
                    hue -= 1;
                }
            }
            return {
                h: Math.round(hue * 360),
                s: Math.round(saturation * 100),
                v: Math.round(value_1 * 100)
            };
        }
    };
    // To toggle between color picker and saved colors
    ColorPalatteComponent.prototype.displayContents = function (event, toggle) {
        var _this = this;
        if (toggle == 'savedColors') {
            this.searchColor = '';
            this.userSearchNotFound = false;
            this.showCloseIcon = false;
            if (this.savedColorTab == 1) {
                this.savedColorLoading = true;
                this.savedColors = true;
                this.colorPalatte = false;
                this.cpToggle = false;
                setTimeout(function () {
                    _this.savedColorLoading = false;
                    _this.savedColorTab++;
                }, 1000);
            }
            else {
                this.savedColors = true;
                this.colorPalatte = false;
                this.cpToggle = false;
            }
        }
        else {
            this.colorPalatte = true;
            this.savedColors = false;
            this.cpToggle = true;
            this.searchValues = false;
            this.searchResult = [];
        }
    };
    // To search the colors in saved colors
    ColorPalatteComponent.prototype.searchValue = function (event) {
        var _this = this;
        if (event.target.value) {
            this.searchColor = event.target.value;
            this.searchValues = true;
            this.userSearchNotFound = false;
            var searchValue_1 = [];
            this.searchResult = [];
            // @ts-ignore
            var colorArray = [];
            for (var i = 0; i < this.collection.length; i++) {
                for (var j = 0; j < this.collection[i].value.length; j++) {
                    var data = {
                        'collectionName': this.collection[i]['collectionName'],
                        'value': this.collection[i].value[j]
                    };
                    colorArray.push(data);
                }
            }
            colorArray.filter(function (data, index) {
                var one = data['value']["name"].toLowerCase();
                var two = data['value']["color"];
                var three = data['collectionName'].toLowerCase();
                if (one.includes(event.target.value.toLowerCase()) || three.includes(event.target.value.toLowerCase())) {
                    if (_this.searchResult.length == 0) {
                        var result = {
                            'collectionName': data['collectionName'],
                            'value': [data['value']]
                        };
                        searchValue_1.push(result);
                        _this.searchResult = searchValue_1;
                    }
                    else {
                        var findIndex = _this.searchResult.findIndex(function (x) { return x.collectionName == data['collectionName']; });
                        if (findIndex !== -1) {
                            _this.searchResult[findIndex]['value'].push(data['value']);
                        }
                        else {
                            var result = {
                                'collectionName': data['collectionName'],
                                'value': [data['value']]
                            };
                            _this.searchResult.push(result);
                        }
                    }
                }
                if (searchValue_1.length == 0) {
                    _this.userSearchNotFound = true;
                }
                else {
                    _this.userSearchNotFound = false;
                }
            });
        }
        else {
            this.searchValues = false;
            this.userSearchNotFound = false;
            this.searchResult = [];
        }
    };
    ColorPalatteComponent.prototype.onFocus = function (event) {
        if (event) {
            this.showCloseIcon = true;
        }
        else {
            this.showCloseIcon = false;
        }
    };
    ColorPalatteComponent.prototype.clearInput = function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.searchColor = '';
        this.searchValues = false;
        this.searchResult = [];
        this.showCloseIcon = false;
    };
    // To emit the selected color in saved colors
    ColorPalatteComponent.prototype.colorPicker = function (selectedColor, event) {
        this.color.hexString = selectedColor;
        this.colorDisplay = selectedColor;
        this.checkInputValue(selectedColor, 'savedColors');
        this.colorValue.emit(this.color.hexString);
        setTimeout(function () {
            $(event.srcElement).closest('.cs-color-palatte').children('.picker-color').trigger('click');
        }, 100);
    };
    // To close the modal and to send the selected color to local storage
    ColorPalatteComponent.prototype.closeModal = function (e) {
        var index = this.recentColors.indexOf(this.color.hexString);
        if (index < 0) {
            if (this.recentColors.length < 18) {
                this.recentColors.splice(0, 0, this.color.hexString);
                setTimeout(function () {
                    $('.preset-color').css({ borderRadius: "50%" });
                }, 50);
            }
            else {
                this.recentColors.splice(17, 1);
                this.recentColors.splice(0, 0, this.color.hexString);
                setTimeout(function () {
                    $('.preset-color').css({ borderRadius: "50%" });
                }, 50);
            }
        }
        localStorage.setItem('Recent-colors', JSON.stringify(this.recentColors));
        $(e.srcElement).closest('.cs-color-palatte').children('.picker-color').trigger('click');
    };
    ColorPalatteComponent.prototype.recentColor = function (data, e) {
        this.color = { hexString: data };
        var index = this.recentColors.indexOf(this.color.hexString);
        if (index > 0) {
            this.recentColors.splice(index, 1);
            this.recentColors.unshift(this.color.hexString);
        }
        this.colorValue.emit(this.color.hexString);
        this.colorDisplay = this.color.hexString;
        this.checkInputValue(data, 'recentColors');
        setTimeout(function () {
            $(e.srcElement).closest('.cs-color-palatte').children('.picker-color').trigger('click');
        }, 100);
    };
    ColorPalatteComponent.prototype.eyeDropperOpen = function () {
        var _this = this;
        if ('EyeDropper' in window) {
            var eyeDropper = new window.EyeDropper();
            var abortController = new AbortController();
            var that_1 = this;
            eyeDropper.open({ signal: abortController.signal }).then(function (result) {
                that_1.color = { hexString: result.sRGBHex };
                that_1.colorDisplay = result.sRGBHex;
                that_1.colorValue.emit(_this.color.hexString);
            }).catch(function (e) {
                console.log(e);
            });
        }
        else {
            return;
        }
    };
    ColorPalatteComponent.prototype.getDetails = function () {
        return this.color.hexString;
    };
    ColorPalatteComponent.prototype.colorChange = function (data) {
        this.colorDisplay = data['hexString'];
        this.colorValue.emit(data['hexString']);
        this.percentage = 100;
    };
    ColorPalatteComponent.prototype.alphaValue = function (event) {
        var alpha;
        var alphaValues;
        this.percentage = event.target.value;
        if (this.percentage > 100) {
            this.percentage = 100;
        }
        this.colorDisplay = this.colorDisplay.split('').splice(0, 7).join('');
        if (this.percentage == 5) {
            alphaValues = '0D';
        }
        else if (this.percentage == 4) {
            alphaValues = '0A';
        }
        else if (this.percentage == 3) {
            alphaValues = '08';
        }
        else if (this.percentage == 2) {
            alphaValues = '05';
        }
        else if (this.percentage == 1) {
            alphaValues = '03';
        }
        else if (this.percentage == 0) {
            alphaValues = '00';
        }
        else {
            alpha = (Number(this.percentage) / 100);
            alphaValues = Math.ceil(255 * alpha).toString(16).toUpperCase();
        }
        this.colorDisplay = this.colorDisplay + alphaValues;
        this.color.hexString = this.colorDisplay;
        this.colorValue.emit(this.colorDisplay);
    };
    ColorPalatteComponent.prototype.colorInputValue = function (event) {
        var color = event.target.value;
        if (color[0] == '#' && color[1] == '#') {
            color = color.substring(1);
            this.color = { hexString: color };
        }
        else if (color[0] == '#') {
            this.color = { hexString: color };
        }
        else {
            color = "#" + color;
            this.color = { hexString: color };
        }
        this.colorValue.emit(this.color.hexString);
        this.colorDisplay = this.color.hexString;
        this.setCursorPosition();
    };
    ColorPalatteComponent.prototype.checkInputValue = function (event, type) {
        var color;
        var alpha;
        if (type == 'input') {
            color = this.color.hexString;
        }
        else {
            color = event;
        }
        if (color[0] == '#' && color.length == 8) {
            alpha = color.slice(-1);
        }
        else if (color[0] != '#' && color.length > 8) {
            this.percentage = 100;
            this.color.hexString = '#ffffff';
            this.colorDisplay = '#ffffff';
            return;
        }
        else if (color[0] == '#' && color.length == 7) {
            this.percentage = 100;
            this.color.hexString = this.colorDisplay;
            return;
        }
        else {
            alpha = color.slice(-2);
        }
        this.color.hexString = this.colorDisplay;
        var alphaValue = parseInt(alpha, 16);
        var percentage = Math.round((alphaValue / 255) * 100);
        this.percentage = percentage;
    };
    ColorPalatteComponent.prototype.preventDecimal = function (event) {
        if (event.key === '.') {
            event.preventDefault();
        }
    };
    ColorPalatteComponent.prototype.onDocumentKeydownEnter = function () {
        event.preventDefault();
    };
    ColorPalatteComponent.prototype.ngOnDestroy = function () {
        this.savedColorTab = 0;
        this.savedColors = false;
    };
    ColorPalatteComponent.prototype.routeToSettingsScreen = function () {
        var cancel = $('#cancel-styles');
        cancel ? $('#cancel-styles').trigger('click') : null;
        this.router.navigateByUrl('system_settings/saved_colors');
    };
    return ColorPalatteComponent;
}());
export { ColorPalatteComponent };
