import {
  Directive,
  OnInit,
  ElementRef,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
    Renderer,
    Optional
} from '@angular/core';
import { NgControl } from "@angular/forms";

declare var $: any;

@Directive({
  selector: '[csSelect2]',
  inputs: ['select2Config'],
})

export class CsSelect2Directive implements OnInit {
  @Input('placeholder') placeholder: string = '-- Please choose --';
  @Input('dropdownvalue') value: any = '';
  @Output('selected') valueEmitter: EventEmitter<any> = new EventEmitter();
  @Output('opened') opened: EventEmitter<any> = new EventEmitter();
  @Output() $instance: EventEmitter<any> = new EventEmitter();
  select2Config: any;
  select2: any;

  constructor(
    private _hostRef: ElementRef,
    private _renderer: Renderer,
    @Optional() private control: NgControl,
  ) {
  }

  ngOnInit() {
      // console.log(this.value);
      this.select2Config['placeholder'] = this.placeholder;
  }

    ngAfterViewInit() {
        if (this.control && this.value === '') {
            this.value = this.control.control.value;
        }

        this.select2 = $(this._hostRef.nativeElement)
            .select2(this.select2Config)
            .select2('val', this.value);

        this.select2.on('select2-selected', (event) => {
            this.valueEmitter.emit(event.val);
            if (this.control) {
                this.control.control.setValue(event.val);
            }
        });

        this.select2.on('change', (event) => {
            this.valueEmitter.emit(event.val);
        });

        this.select2.on('select2-open', (event) => {
            this.opened.emit(true);
            if (this.control) {
                this.control.control.markAsTouched();
            }
        });
        this.$instance.emit(this.select2);
    }
}
