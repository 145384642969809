import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
//--
import { InterfaceService } from '@app/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommonService = /** @class */ (function (_super) {
    tslib_1.__extends(CommonService, _super);
    function CommonService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    /* Get client country code
        Response : {"countryName":"STRING<country-name>"}
    */
    CommonService.prototype.getClientCountryCodes = function (params) {
        return this.http.get(this.getApiUrl("get_client_country_code", params));
    };
    /* Set new job phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    CommonService.prototype.setNewJobPhoneCall = function (type, customerId, jobId, mode, data, params) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + customerId + "/notes/jobs/" + jobId + "/new_phone_call/" + mode, params), JSON.stringify(data));
    };
    /* Set new estimate phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    CommonService.prototype.setNewEstimatePhoneCall = function (type, customerId, estimateId, mode, data, params) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + customerId + "/notes/estimate/" + estimateId + "/new_phone_call/" + mode, params), JSON.stringify(data));
    };
    /* Set new estimate phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    CommonService.prototype.setNewCustomerPhoneCall = function (type, customerId, mode, data, params) {
        return this.http.post(this.getApiUrl(type + "/new_phone_call/" + customerId + "/" + mode, params), JSON.stringify(data));
    };
    /* Set new invoice phone call
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    CommonService.prototype.setNewInvoicePhoneCall = function (type, customerId, jobId, invoiceId, mode, data, params) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + customerId + "/notes/jobs/" + jobId + "/invoices/" + invoiceId + "/new_phone_call/" + mode, params), JSON.stringify(data));
    };
    /* get infobar details from communications
       Response : {'tablepkid': number , 'noteId': number, 'success' => 'New note saved' }
    */
    CommonService.prototype.getInfoBarDetails = function (Id, tableType) {
        return this.http.get(this.getApiUrl("getInfoBarDetails?id=" + Id + "&type=" + tableType));
    };
    /* get print and post credits */
    CommonService.prototype.getCheckPrintPostCredits = function () {
        return this.http.get(this.getApiUrl("check_print_post_credits"));
    };
    /* process print and post */
    CommonService.prototype.printAndPostService = function (type, id) {
        return this.http.get(this.getApiUrl("process_printpost?tableType=" + type + "&tablePKID=" + id + "&type=print_post"));
    };
    CommonService.prototype.saveUserNewUIPreference = function (data) {
        return this.http.post(this.getApiUrl("saveUserNewUIPreference"), data);
    };
    /* postcode details call*/
    CommonService.prototype.getPostCodeService = function (postcode) {
        return this.http.get(this.getApiUrl("search_postcode?postcode=" + postcode));
    };
    /* Get county list */
    CommonService.prototype.getCountiesList = function () {
        return this.http.get(this.getApiUrl("get_state_provinces"));
    };
    /*save county*/
    CommonService.prototype.saveNewCounty = function (data) {
        return this.http.post(this.getApiUrl("save_province"), data);
    };
    /* save new landlords */
    CommonService.prototype.saveNewLandlords = function (data) {
        return this.http.post(this.getApiUrl("landlord_create"), data);
    };
    /* save new workaddress */
    CommonService.prototype.saveNewWorkaddress = function (id, type, data) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/work/new_works"), data);
    };
    /* Check unique county */
    CommonService.prototype.checkUniqueCounty = function (province) {
        return this.http.get(this.getApiUrl("is_province_exists?province=" + province));
    };
    CommonService.prototype.postNewInvoiceAddress = function (id, type, data) {
        return this.http.post(this.getApiUrl("customers/" + type + "/" + id + "/invoice_address_v2/new"), data);
    };
    CommonService.prototype.getCurrentUser = function () {
        var url = this.getApiUrl("get_current_user");
        return this.http.get(url);
    };
    CommonService.prototype.updateNewInvoiceAddress = function (data) {
        return this.http.post(this.getApiUrl("invoiceAddressUpdate"), data);
    };
    CommonService.prototype.getViewersInfo = function (data) {
        return data ? {
            imageSource: data['imageSource'] || 'images/profile_avatar_small.jpg',
            name: data['name'] || null
        } : null;
    };
    CommonService.ngInjectableDef = i0.defineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.inject(i1.HttpClient)); }, token: CommonService, providedIn: "root" });
    return CommonService;
}(InterfaceService));
export { CommonService };
