import { EventEmitter, OnInit } from '@angular/core';
import { CsFullScreenModalComponent } from "@app/directives";
import { DiagramViewSequenceComponent } from "@app/workflow-common/diagram-view-sequence/diagram-view-sequence.component";
import { delay, tap } from "rxjs/operators";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var SequenceContainerComponent = /** @class */ (function () {
    function SequenceContainerComponent(workflowService) {
        this.workflowService = workflowService;
        this.data = null;
        this.tagsList = [];
        this.rolesList = [];
        this.scheduleActivityTypes = [];
        this.usersList = [];
        this.isLoading = true;
        this.activeMenu = 'sequence_setup';
        this.isEnabled = true;
        this.sequenceObjectName = '';
        this.readyToView = new EventEmitter();
        this.activeMenuItem = new EventEmitter();
    }
    Object.defineProperty(SequenceContainerComponent.prototype, "content", {
        set: function (content) {
            if (content)
                this.workflowDiagram = content;
        },
        enumerable: true,
        configurable: true
    });
    SequenceContainerComponent.prototype.ngOnInit = function () {
        this.menuItems = [
            {
                id: 'sequence_header',
                title: 'Sequence',
                isHeader: true
            },
            {
                id: 'sequence_setup',
                title: 'Sequence',
                class: 'fas fa-share-alt rot30deg',
                isActive: true,
            },
            {
                id: 'exit_criteria_setup',
                title: 'Exit criteria',
                class: 'fal fa-newspaper',
                isActive: true,
            },
        ];
        console.log(this, this.csFullModal, 'data');
    };
    SequenceContainerComponent.prototype.afterRenderedDiagram = function (mxGraph) {
    };
    SequenceContainerComponent.prototype.getMetaData = function (objectName) {
        var _this = this;
        this.isLoading = true;
        this.workflowService.getObjectFields({ objectName: objectName })
            .pipe(tap(function (resp) {
            _this.setRolesList(resp['rolesList']);
            _this.setTagsList(resp['tagsList']);
            _this.setScheduleActivityTypes(resp['activityTypes']);
            _this.setUsersList(resp['assignee']);
            _this.setExitCriteria(resp['exitCriteria']);
        }))
            .pipe(delay(300))
            .subscribe(function (resp) {
            _this.mainData = resp;
            _this.isLoading = false;
        });
    };
    SequenceContainerComponent.prototype.beforeRenderedDiagram = function (instance) {
        this.workflowDiagram = instance;
        this.data.xmlsource = this.data.xmlSource ? this.data.xmlSource : this.data.xmlsource;
        this.data.type = 'sequence';
        this.readyToView.emit({
            data: this.data,
            mainData: this.mainData
        });
    };
    SequenceContainerComponent.prototype.setActiveMenu = function (item) {
        this.activeMenu = item.id;
        this.activeMenuItem.emit({
            activeMenu: this.activeMenu
        });
    };
    SequenceContainerComponent.prototype.setSelectedData = function (data, additionalData) {
        if (additionalData === void 0) { additionalData = {}; }
        this.data = data;
        this.additionalData = additionalData;
    };
    SequenceContainerComponent.prototype.getDiagramComponent = function () {
        return this.workflowDiagram;
    };
    SequenceContainerComponent.prototype.setTagsList = function (tags) {
        this.tagsList = tags;
    };
    SequenceContainerComponent.prototype.setUsersList = function (users) {
        this.usersList = users;
    };
    SequenceContainerComponent.prototype.setScheduleActivityTypes = function (types) {
        this.scheduleActivityTypes = types;
    };
    SequenceContainerComponent.prototype.setRolesList = function (roles) {
        if (roles === void 0) { roles = []; }
        this.rolesList = roles;
    };
    SequenceContainerComponent.prototype.setExitCriteria = function (conditions) {
        this.exitConditions = conditions;
    };
    SequenceContainerComponent.prototype.selectedExitCriteria = function (instance) {
        this.conditions = instance;
        this.data.condition = instance;
    };
    return SequenceContainerComponent;
}());
export { SequenceContainerComponent };
