import { OnInit } from '@angular/core';
import { Subject } from "rxjs/index";
import { FormBuilder, Validators } from '@angular/forms';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { BsModalRef } from 'ngx-bootstrap';
var ListCreationModalComponent = /** @class */ (function () {
    function ListCreationModalComponent(formBuilder, proposalService, bsModalRef) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.proposalService = proposalService;
        this.bsModalRef = bsModalRef;
        this.suppliers = [];
        this.supplierArray = [];
        this.subscriptions = {};
        this.supplierIdArray = [];
        this.loadingSuppliers = true;
        this.loadingListData = true;
        this.loading = true;
        this.orderFrame = [
            { 'id': 1, 'value': 'Order immediately on acceptance' },
        ];
        this.listFormData = {
            id: '',
            listName: '',
            Suppliers: [],
            expiry: '',
            orderTimeFrame: '',
        };
        this.saving = false;
        this.supplierUpdated = false;
        this.suppliersAdded = [];
        this.subscriptions['sectionSubscriptions'] = this.proposalService.sectionData$.subscribe(function (data) {
            if (data) {
                _this.createdSection = data;
            }
        });
        this.subscriptions['coverPageSubscriptions'] = this.proposalService.coverPage$.subscribe(function (data) {
            if (data) {
                _this.coverPageDetails = data;
                if (_this.coverPageDetails.hasOwnProperty('isoptionTemplate') && _this.coverPageDetails['isoptionTemplate']) {
                    _this.selectedTemplate = 'option';
                }
                else if (_this.coverPageDetails && _this.coverPageDetails.hasOwnProperty('isopportunityTemplate') && _this.coverPageDetails['isopportunityTemplate']) {
                    _this.selectedTemplate = 'opportunity';
                }
                else {
                    _this.selectedTemplate = 'proposal';
                }
            }
        });
    }
    ListCreationModalComponent.prototype.ngOnInit = function () {
        this.getSuppliers();
        this.loadListData();
        this.onClose = new Subject();
        this.select2Config = {
            width: '100%'
        };
    };
    ListCreationModalComponent.prototype.loadListData = function () {
        var _this = this;
        if (this.data.listId) {
            this.loadingListData = true;
            this.proposalService.getPartsListData(this.data.listId).subscribe(function (data) {
                _this.listFormData.id = data.id;
                _this.listFormData.listName = data.listName;
                _this.listFormData.expiry = data.expiry;
                if (data.expiry == null) {
                    _this.listFormData.orderTimeFrame = '1';
                }
                else {
                    _this.listFormData.orderTimeFrame = '2';
                }
                _this.loadedSuppliers = data.suppliers;
                _this.loadingListData = false;
                _this.handleFormBuildAction();
            });
        }
        else {
            this.loadingListData = false;
            this.handleFormBuildAction();
        }
    };
    ListCreationModalComponent.prototype.handleFormBuildAction = function () {
        if (!this.loadingSuppliers && !this.loadingListData) {
            if (this.loadedSuppliers) {
                var suppliers = JSON.parse(this.loadedSuppliers);
                if (suppliers.length != 0) {
                    var supplierArray = [];
                    var _loop_1 = function (i) {
                        var supplierId = suppliers[i].id;
                        this_1.supplierIdArray.push(parseInt(supplierId));
                        var assigneeIndex = this_1.suppliers.findIndex(function (i) { return i.id == supplierId; });
                        if (assigneeIndex != -1) {
                            supplierArray.push(supplierId + ": '" + suppliers[i]['name'] + "'");
                            this_1.supplierArray.push({ id: supplierId, name: suppliers[i]['name'] });
                        }
                    };
                    var this_1 = this;
                    for (var i = 0; i < suppliers.length; i++) {
                        _loop_1(i);
                    }
                    this.listFormData.Suppliers = this.supplierIdArray;
                }
            }
            this.loading = false;
            this.buildForm();
        }
    };
    ListCreationModalComponent.prototype.getSuppliers = function () {
        var _this = this;
        this.loadingSuppliers = true;
        this.proposalService.getEstimatedSuppliers().subscribe(function (data) {
            _this.suppliers = data;
            _this.loadingSuppliers = false;
            _this.handleFormBuildAction();
        });
    };
    ListCreationModalComponent.prototype.buildForm = function () {
        var _this = this;
        this.listForm = this.formBuilder.group({
            'id': [this.listFormData.id],
            'listName': [this.listFormData.listName, Validators.required],
            'Suppliers': [this.listFormData.Suppliers],
            'orderTimeFrame': [this.listFormData.orderTimeFrame, Validators.required],
            'expiry': ['']
        });
        this.listForm.get('orderTimeFrame').valueChanges
            .subscribe(function (value) {
            if (value == 2) {
                _this.listForm.get('expiry').setValidators(Validators.required);
            }
            else {
                _this.listForm.get('expiry').clearValidators();
            }
            _this.listForm.get('expiry').updateValueAndValidity();
        });
        if (this.listFormData.orderTimeFrame == '2') {
            this.listForm.controls['expiry'].setValue(this.listFormData.expiry);
        }
        else {
            this.listForm.controls['expiry'].setValue('');
        }
        setTimeout(function () {
            $('.suppliers').val(_this.supplierIdArray).trigger('change');
        }, 300);
    };
    ListCreationModalComponent.prototype.createList = function () {
        var _this = this;
        this.saving = true;
        if (this.listForm.value.orderTimeFrame == '1') {
            this.listForm.controls['expiry'].setValue(null);
        }
        if (this.data.sectionId == null && this.data.sectionIndex == 0 && this.createdSection && this.createdSection.hasOwnProperty('sectionId')) {
            this.data.sectionId = this.createdSection['sectionId'];
        }
        var suppliers;
        if (this.supplierArray.length == 0) {
            suppliers = this.supplierArray;
        }
        else {
            suppliers = JSON.stringify(this.supplierArray);
        }
        var listPartsData = {
            sectionData: {
                "listId": this.listForm.value.id,
                "listName": this.listForm.value.listName,
                "pageNumber": this.data.pageNumber,
                "proposalTemplatesId": this.data.proposalTemplatesId,
                "sectionId": this.data.sectionId,
                "sectionNumber": this.data.sectionId,
                "setupOptionId": this.data.setupOptionId,
                "expiry": this.listForm.value.expiry,
                "suppliers": suppliers,
                "supplierUpdated": this.supplierUpdated,
                "suppliersAdded": this.suppliersAdded
            }
        };
        this.proposalService.createPartsList(listPartsData).subscribe(function (data) {
            listPartsData.sectionData.listId = data['listId'];
            listPartsData.sectionData.sectionId = data['sectionId'];
            _this.onClose.next(listPartsData.sectionData);
            _this.bsModalRef.hide();
        });
    };
    ListCreationModalComponent.prototype.getselectedSuppliers = function (data) {
        var supplierArray = [];
        var supplierIdArray = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                var index = this.suppliers.findIndex(function (value) { return value.id == parseInt(data[i]); });
                var supplierId = this.suppliers[index]['id'];
                var supplierName = this.suppliers[index]['name'];
                supplierArray.push({
                    'id': supplierId, 'name': supplierName
                });
                var supplier = parseInt(supplierId);
                if (supplierIdArray.indexOf(supplier) == -1) {
                    supplierIdArray.push(supplier);
                }
            }
            this.supplierArray = supplierArray;
        }
        if (this.supplierIdArray.sort().join() != supplierIdArray.sort().join()) {
            this.supplierUpdated = true;
        }
    };
    ListCreationModalComponent.prototype.validateNumber = function (e) {
        var input = String.fromCharCode(e.charCode);
        var reg = /^\d*(?:[.,]\d{1,2})?$/;
        if (!reg.test(input)) {
            e.preventDefault();
        }
        if (e.target.value.length >= 3) {
            e.preventDefault();
        }
        if (e.target.value.length < 0) {
            e.preventDefault();
        }
    };
    return ListCreationModalComponent;
}());
export { ListCreationModalComponent };
