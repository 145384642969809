import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { OptionTemplateService } from '@app/features/system-settings/option-template/services/option-template.service';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { AuthService } from "@app/core";
var AddAddonModalComponent = /** @class */ (function () {
    function AddAddonModalComponent(fb, bsModalRef, optionTemplateService, proposalTemplateService, authService) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.optionTemplateService = optionTemplateService;
        this.proposalTemplateService = proposalTemplateService;
        this.authService = authService;
        this.addonType = 'blank';
        this.editAddon = false;
        this.action = 'save';
        this.select2 = false;
        this.optionsData = [];
        this.editorAddon = false;
        this.subscriptions = {};
        this.templatesLoading = true;
        this.showAddOnTemplate = false;
        this.isViewMode = false;
        this.freemiumAccess = false;
        this.showAddNewTemplate = false;
        this.excludeOptions = [];
    }
    AddAddonModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if (!proposalEditor) {
            this.freemiumAccess = true;
        }
        else {
            this.freemiumAccess = false;
        }
        this.proposalTemplateService.templateName$.subscribe(function (template) {
            _this.template = template;
        });
        this.onClose = new Subject();
        this.buildForm();
        if (this.editorAddon) {
            this.getAllTemplates();
        }
        if (this.selectedAddon && this.selectedAddon.hasOwnProperty('addOnName')) {
            this.addonForm.controls.optionName.setValue(this.selectedAddon.addOnName);
        }
        if (this.selectedAddon && this.selectedAddon.hasOwnProperty('customerOptionName')) {
            this.addonForm.controls.customerOptionName.setValue(this.selectedAddon.customerOptionName);
        }
        this.isViewMode = this.isViewMode;
    };
    AddAddonModalComponent.prototype.initializeSelect2 = function (optionTemplates) {
        var dataToSet = [];
        if (optionTemplates && optionTemplates.length) {
            optionTemplates.forEach(function (item) {
                dataToSet.push({
                    id: parseInt(item.id),
                    text: item.optionName
                });
            });
        }
        this.select2TemplateConfig = {
            width: '100%',
            data: dataToSet
        };
        this.templatesLoading = false;
    };
    AddAddonModalComponent.prototype.getAllTemplates = function () {
        var _this = this;
        this.additionalInfo['type'] = 'addon';
        this.subscriptions['optionsList'] = this.optionTemplateService.getAllOptionTemplates(this.additionalInfo).subscribe(function (data) {
            if (data) {
                _this.showAddOnTemplate = true;
                for (var index = 0; index < data['excludeOptions'].length; index++) {
                    if (data['excludeOptions'][index]['addon'] == 1) {
                        _this.excludeOptions.push(data['excludeOptions'][index]['id']);
                    }
                }
            }
        });
    };
    // Form buildForm
    AddAddonModalComponent.prototype.buildForm = function () {
        this.addonForm = this.fb.group({
            'id': [''],
            'optionName': ['', Validators.required],
            'customerOptionName': ['']
        });
    };
    // submit option
    AddAddonModalComponent.prototype.save = function (optionType) {
        var addInfo = this.additionalInformation;
        var opportunityIdVal = '';
        var opportunityProposalIdVal = '';
        var proposalTemplatesIdVal = '';
        var setupOptionIdVal = '';
        var selectedType = '';
        var pageNumber = '';
        var formValue = this.addonForm.value;
        if (this.editorAddon) {
            if (this.selectedTemplate == 'proposal') {
                proposalTemplatesIdVal = this.template.id;
            }
            else if (this.selectedTemplate == 'option') {
                setupOptionIdVal = this.template.id;
            }
            else {
                opportunityIdVal = this.opportunityId;
                opportunityProposalIdVal = this.opportunityProposalId;
            }
            selectedType = this.selectedTemplate;
            pageNumber = this.lastPg;
            this.action = 'saving';
            if (optionType == 'blank') {
                var option = {
                    "opportunityId": opportunityIdVal,
                    'opportunityProposalId': opportunityProposalIdVal,
                    "selectedTemplate": selectedType,
                    "proposalTemplateId": proposalTemplatesIdVal,
                    "setupOptionId": setupOptionIdVal,
                    "setupOption": {
                        "optionName": formValue.optionName,
                        "customerOptionName": formValue.customerOptionName,
                    },
                    "addOn": 'true',
                    "lastPageNumber": pageNumber
                };
                this.saveAddon(option);
            }
            else {
                var option = {
                    "opportunityId": opportunityIdVal,
                    'opportunityProposalId': opportunityProposalIdVal,
                    "selectedTemplate": selectedType,
                    "proposalTemplateId": proposalTemplatesIdVal,
                    "setupOptionId": setupOptionIdVal,
                    "optionTemplateId": formValue.optionName,
                    "customerOptionName": formValue.customerOptionName,
                    "addOn": 'true',
                    "lastPageNumber": pageNumber
                };
                this.saveAddon(option);
            }
        }
        else {
            if (!this.editAddon) {
                if (addInfo) {
                    opportunityIdVal = addInfo.hasOwnProperty('opportunityId') && addInfo.opportunityId > 0 ? addInfo.opportunityId : '';
                    opportunityProposalIdVal = addInfo.hasOwnProperty('opportunityProposalId') && addInfo.opportunityProposalId > 0 ? addInfo.opportunityProposalId : '';
                    proposalTemplatesIdVal = addInfo.hasOwnProperty('proposalTemplatesId') && addInfo.proposalTemplatesId > 0 ? addInfo.proposalTemplatesId : '';
                    setupOptionIdVal = addInfo.setupOptionId;
                    selectedType = addInfo.selectedTemplate;
                    pageNumber = addInfo.pageNumber;
                }
                this.action = 'saving';
                var option = void 0;
                if (optionType == 'blank') {
                    option = {
                        "opportunityId": opportunityIdVal,
                        'opportunityProposalId': opportunityProposalIdVal,
                        "selectedTemplate": selectedType,
                        "proposalTemplateId": proposalTemplatesIdVal,
                        "setupOptionId": setupOptionIdVal,
                        "setupOption": {
                            "optionName": formValue.optionName,
                            "customerOptionName": formValue.customerOptionName,
                        },
                        "addOn": 'true',
                        "lastPageNumber": pageNumber
                    };
                    this.saveProposalOption(option);
                }
                else {
                    option = {
                        "opportunityId": opportunityIdVal,
                        'opportunityProposalId': opportunityProposalIdVal,
                        "selectedTemplate": selectedType,
                        "proposalTemplateId": proposalTemplatesIdVal,
                        "setupOptionId": setupOptionIdVal,
                        "optionTemplateId": formValue.optionName,
                        "customerOptionName": formValue.customerOptionName,
                        "addOn": 'true',
                        "lastPageNumber": pageNumber
                    };
                    this.saveProposalOption(option);
                }
            }
            else {
                var addOnName = this.addonForm.controls.optionName.value;
                var customerOptionName = this.addonForm.controls.customerOptionName.value;
                var addOnDetails = {
                    addOnName: addOnName,
                    addOnId: this.selectedAddon.addOnId,
                    customerOptionName: customerOptionName
                };
                this.updateAddOn(addOnDetails);
            }
        }
        var msg = {
            type: 'upgradeFreemium'
        };
        top.postMessage(msg, '*');
    };
    AddAddonModalComponent.prototype.updateAddOn = function (addOnDetails) {
        var _this = this;
        this.optionTemplateService.editAddOns(addOnDetails).subscribe(function (data) {
            _this.action = 'save';
            _this.onClose.next(true);
            _this.bsModalRef.hide();
        }, function (error) {
            console.log('err', error);
        });
    };
    //save Proposal Options
    AddAddonModalComponent.prototype.saveAddon = function (option) {
        var _this = this;
        this.optionTemplateService.addProposalOptions(option).subscribe(function (data) {
            var contentPageId = '';
            if (data) {
                if (data.hasOwnProperty('contentPageId')) {
                    contentPageId = data['contentPageId'];
                }
            }
            _this.action = 'saving';
            _this.onClose.next({ added: true, type: _this.addonType, contentPageId: contentPageId });
            _this.bsModalRef.hide();
        }, function (error) {
            console.log('err', error);
        });
    };
    //save Proposal Options
    AddAddonModalComponent.prototype.saveProposalOption = function (option) {
        var _this = this;
        this.optionTemplateService.addOrLinkAddOns(option).subscribe(function (data) {
            _this.proposalTemplateService.setSectionData(data);
            _this.action = 'saving';
            _this.onClose.next({ added: true, type: _this.addonType });
            _this.bsModalRef.hide();
        }, function (error) {
            console.log('err', error);
        });
    };
    // Select option
    AddAddonModalComponent.prototype.optionSelected = function (event) {
        this.addonForm.reset();
        this.addonType = event.target.value;
        if (this.select2 == false) {
            setTimeout(function () {
                $('select.addon-select2').select2();
            }, 500);
            this.select2 = true;
        }
    };
    AddAddonModalComponent.prototype.templateSelected = function (event) {
        this.addonForm.controls['optionName'].setValue(event.templateId);
    };
    AddAddonModalComponent.prototype.ngOnDestroy = function () {
        for (var subscription in this.subscriptions) {
            this.subscriptions[subscription].unsubscribe();
        }
    };
    return AddAddonModalComponent;
}());
export { AddAddonModalComponent };
