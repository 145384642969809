import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { AddEditCertificateService, CsToastBoxService } from "@app/services";
import { DynamicCertificateFormParserComponent } from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/dynamic-certificate-form-parser.component";
var CertificatePageContainerComponent = /** @class */ (function () {
    function CertificatePageContainerComponent(fb, toastBox, confirmationBoxHelper, ajsScope, addEditCertificateService) {
        this.fb = fb;
        this.toastBox = toastBox;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.addEditCertificateService = addEditCertificateService;
        this.currentCertificateData = {
            jobId: null,
            settingsCertificateId: null,
            draftJsonData: null,
        };
        this.isFormValid = true;
        this.lastSavedPageIndex = -1;
        // page index -1 refers to the customer/landlord page
        this.pageIndex = -1;
        this.draftData = {};
        this.customerOrLandlord = 'Customer';
        this.customerOrLandlordInfo = {
            showLandlord: false,
            customerInfo: [],
            landlordInfo: [],
            jobId: null,
            settingsCertificateId: null
        };
        this.valueEmitter = new EventEmitter();
    }
    CertificatePageContainerComponent.prototype.ngOnInit = function () {
        this.customerOrLandlordForm = this.fb.group({
            customerownership: [this.customerOrLandlord, Validators.required]
        });
    };
    CertificatePageContainerComponent.prototype.ngOnChanges = function (changes) {
        if (changes['formData']) {
            this.dynamicCertificateFormParserComponent.setFormData(this.formData);
        }
        if (changes['customerOrLandlord']) {
            if (this.customerOrLandlordForm && this.customerOrLandlordForm.get('customerownership'))
                this.customerOrLandlordForm.get('customerownership').setValue(this.customerOrLandlord);
        }
        if (changes['pageIndex']) {
            this.lastSavedPageIndex = this.pageIndex - 1;
        }
    };
    CertificatePageContainerComponent.prototype.selectPage = function (index) {
        if (!this.isFormValid) {
            this.toastBox.show('Some of the fields are not valid.');
            return;
        }
        if ((this.lastSavedPageIndex + 1 >= index && this.status == 'DRAFT') || this.status == 'ACTIVE') {
            this.pageIndex = index;
            this.dynamicCertificateFormParserComponent.selectPage(index);
            if (index == -1)
                this.isFormValid = true;
        }
    };
    CertificatePageContainerComponent.prototype.gotoNextPage = function () {
        this.pageIndex++;
    };
    CertificatePageContainerComponent.prototype.saveAndContinue = function (saveAndContinue) {
        var _this = this;
        if (saveAndContinue === void 0) { saveAndContinue = true; }
        if (!this.isFormValid && saveAndContinue)
            return;
        if (!this.isFormValid && !saveAndContinue) {
            this.toastBox.show('Some of the fields are not valid.');
            return;
        }
        var params = {};
        if (this.lookupId) {
            params['lookupId'] = this.lookupId;
        }
        this.currentCertificateData.jobId = this.customerOrLandlordInfo.jobId;
        this.currentCertificateData.settingsCertificateId = this.customerOrLandlordInfo.settingsCertificateId;
        var draftArray = {
            customerownership: this.customerOrLandlordForm.value.customerownership,
            jsonDraft: this.formValue,
            currentPageIndex: saveAndContinue ? this.pageIndex : this.pageIndex,
        };
        this.currentCertificateData.draftJsonData = draftArray;
        this.addEditCertificateService.setCertificateJson(this.currentCertificateData, params).subscribe(function (res) {
            _this.toastBox.show('Certificate updated', 3000);
            if (saveAndContinue) {
                _this.lookupId = res['lookupId'];
                _this.draftData = res['jsonDraft'];
                _this.pageIndex++;
                if (_this.pageIndex > _this.lastSavedPageIndex + 1)
                    _this.lastSavedPageIndex++;
                _this.dynamicCertificateFormParserComponent.selectPage(_this.pageIndex);
            }
            else {
                _this.valueEmitter.emit();
            }
        }, function (error1) {
            _this.toastBox.show('Failed to save', 3000);
        });
    };
    CertificatePageContainerComponent.prototype.getFormStatusChanges = function (isFormValid) {
        this.isFormValid = isFormValid;
    };
    CertificatePageContainerComponent.prototype.getFormValueChanges = function (formValue) {
        this.formValue = formValue;
    };
    CertificatePageContainerComponent.prototype.saveAndActivate = function () {
        var _this = this;
        if (!this.isFormValid) {
            this.toastBox.show('Some of the fields are not valid.');
            return;
        }
        var draftArray = {
            customerownership: this.customerOrLandlordForm.value.customerownership,
            jsonDraft: this.formValue,
            currentPageIndex: -1,
        };
        this.currentCertificateData.draftJsonData = draftArray;
        this.addEditCertificateService.setCertificateDataAndActivate(this.lookupId, this.currentCertificateData).subscribe(function (response) {
            if (!response['error']) {
                _this.toastBox.show('Certificate activated', 3000);
                _this.valueEmitter.emit();
            }
            else {
                _this.toastBox.show('Failed activate certificate', 3000);
            }
        }, function (error1) {
            _this.toastBox.show('Failed activate certificate', 3000);
        });
    };
    CertificatePageContainerComponent.prototype.closeModal_ = function () {
        var _this = this;
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to cancel?', this.ajsScope)
            .then(function () {
            _this.valueEmitter.emit();
        }).catch(function () { });
    };
    return CertificatePageContainerComponent;
}());
export { CertificatePageContainerComponent };
