<div class="cs-d-flex flex-column cs-pos-relative cs-divider slim-scroll thin">
    <div class="pos-abt-full bg-white cs-border-right-1">

        <div class="pos-abt cs-header-divider">
            <app-datefilter (onChange)="filterBy($event, true)"></app-datefilter>
           <!-- <div class="search_container clearfix">
                <div class="search_box small">
                    <a class="ss-search"></a><input placeholder="Search" type="search">
                </div>
            </div>-->
        </div>

        <div class="cs-content-divider pos-abt overflow-auto" #dagItemsContainer>
            <div class="wrap-container cs-d-flex flex-column">

                <ng-container *ngIf="loading">
                    <div class="row-item cursor-pointer" *ngFor="let line of [1,2,3,5,6,7,8,9,10]">
                        <div class="cs-d-flex flex-column dr-list-item">
                            <div class="text-layout animated-background small mb-05"  style="width:180px"></div>
                            <div class="text-layout animated-background long"></div>
                            <div class="text-layout animated-background small mt-05" style="width:80px"></div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!dagrunsItems.length && !loading">
                    <div class="no-results-wrap">
                        <div class="warning-icon-wrap with-info-icon">
                            <span class="ss-info icon fix-left">&nbsp;</span>
                        </div>
                        <div class="warning-message">No records found</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isScheduled">
                    <div class="row-item cursor-pointer" *ngFor="let item of dagrunsItems" [ngClass]="{'active': item.id===selectedItem.id}" (click)="dagHistoryHandler(item)">
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='customer'">
                            <div class="fs-bold row-item-title">Customer: {{item.propertyId}}
<!--                                <div class="diagram-container f-right">-->
<!--                                    <span class="color-box color-box-sm circle color-box-status-{{item.status}}"></span>-->
<!--                                </div>-->
                            </div>
                            <div class="">{{item.name}}</div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='invoice'">
                            <div class="fs-bold row-item-title">Invoice: {{item.invoiceno}}, Job: {{item.jobId}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='job'">
                            <div class="fs-bold row-item-title">Job: {{item.jobId}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName === 'certificates'">
                            <div class="fs-bold row-item-title">Certificate: {{item.jobId}}, CertificateName: {{item.certificateName}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName === 'diaryEvents'">
                            <div class="fs-bold row-item-title">Diary: {{item.dagrun_tablepkid}}
                            </div>
                            <div class="row-item-desc">{{getDiaryDescription(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName === 'payment'">
                            <div class="fs-bold row-item-title">Payment: {{item.dagrun_tablepkid}}, Invoice: {{item.invoiceNumber}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName === 'opportunity'">
                            <div class="fs-bold row-item-title">Opportunity: {{item.dagrun_tablepkid}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName === 'proposal'">
                            <div class="fs-bold row-item-title">Proposal: {{item.dagrun_tablepkid}}, Opportunity: {{item.opportunityId}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>
                        <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName === 'estimate'">
                            <div class="fs-bold row-item-title">Estimate: {{item.dagrun_tablepkid}}, Property: {{item.propertyId}}
                            </div>
                            <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                            <span>{{getCreatedDate(item)}}</span>
                        </div>


                    <!--<ng-template #scheduler_tmpl>
                        <div class="cs-d-flex flex-column pos-rlt dr-list-item scheduler-list-item">
                            <span [hidden]="item.id == toggleOpenClose"><i class="cs-icon fa fa-chevron-right" aria-hidden="true"></i></span>
                            <span [hidden]="item.id != toggleOpenClose"><i class="cs-icon fa fa-chevron-down" aria-hidden="true"></i></span>
                            <div class="fs-bold row-item-title">Number of records: {{item.schedulerresponse ? item.schedulerresponse['count'] : '0'}}</div>
                            <div class="row-item-desc">Run at {{getCreatedDate(item, true)}}</div>
                        </div>
                        <div class="row-child-container" *ngIf="item.id == toggleOpenClose">
                            <div class="row-item cursor-pointer" *ngFor="let line of [1,2,3,5,6]">
                                <div class="cs-d-flex flex-column dr-list-item">
                                    <div class="text-layout animated-background small mb-05"  style="width:180px"></div>
                                    <div class="text-layout animated-background long"></div>
                                    <div class="text-layout animated-background small mt-05" style="width:80px"></div>
                                </div>
                            </div>
                            <div class="row-item cursor-pointer">
                                <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='job'">
                                    <div class="fs-bold row-item-title">Job number:5</div>
                                    <div class="row-item-desc">asdasdad</div>
                                    <span>asdasd</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>-->
                    </div>
                </ng-container>
                <ng-container *ngIf="isScheduled">
                    <div class="row-item" *ngFor="let item of dagrunsItems" [ngClass]="{'open': item.id===toggleOpenClose}">
                        <div class="cs-d-flex flex-column pos-rlt dr-list-item scheduler-list-item">
                            <div (click)="schedulerDagRuns(item)" class="cs-icon cursor-pointer">
                                <span [hidden]="item.id == toggleOpenClose"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                <span [hidden]="item.id != toggleOpenClose"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                            </div>
                            <div class="fs-bold row-item-title">Number of records: {{item.schedulerresponse ? item.schedulerresponse['count'] : '0'}}</div>
                            <div class="row-item-desc">Run at {{getCreatedDate(item, true)}}</div>
                        </div>

                        <div class="row-child-container"
                             *ngIf="item.id == toggleOpenClose" [ngClass]="{'is-infinite-scroll': enableInfiniteScroll && !childLoading}"
                             infiniteScroll
                             [infiniteScrollDistance]="1"
                             [infiniteScrollThrottle]="100"
                             [scrollWindow]="false"
                             (scrolled)="onScroll(item)">
                            <ng-container *ngIf="childLoading">
                                <div class="row-item cursor-pointer" *ngFor="let line of [1,2,3,5]">
                                    <div class="cs-d-flex flex-column dr-list-item">
                                        <div class="text-layout animated-background small mb-05"  style="width:180px"></div>
                                        <div class="text-layout animated-background long"></div>
                                        <div class="text-layout animated-background small mt-05" style="width:80px"></div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="row-item cursor-pointer" *ngFor="let item of dagrunsItemsScheduler" [ngClass]="{'active': item.id===selectedItem.id}" (click)="dagHistoryHandler(item)">
                                <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='job'">
                                    <div class="fs-bold row-item-title">Job number: {{item.jobId}}
                                        <div class="diagram-container f-right">
                                            <span class="color-box color-box-sm circle color-box-status-{{item.status}}"></span>
                                        </div>
                                    </div>
                                    <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                                    <span>{{getCreatedDate(item)}}</span>
                                </div>
                                <div *ngIf="objectName==='customer'" class="cs-d-flex flex-column dr-list-item">
                                    <div class="fs-bold row-item-title">Property number: {{item.PropertyId}}
                                    </div>
                                    <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                                    <span>{{getCreatedDate(item)}}</span>
                                </div>
                                <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='diaryEvents'">
                                    <div class="fs-bold row-item-title">Diary number: {{item.Diaryid}}
                                    </div>
                                    <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                                    <span>{{getCreatedDate(item)}}</span>
                                </div>
                                <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='invoice'">
                                    <div class="fs-bold row-item-title">Invoice number: {{item.InvoiceId}}
                                    </div>
                                    <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                                    <span>{{getCreatedDate(item)}}</span>
                                </div>
                                <div class="cs-d-flex flex-column dr-list-item" *ngIf="objectName==='certificates'">
                                    <div class="fs-bold row-item-title">Certificate number: {{item.CertificateId}}
                                    </div>
                                    <div class="row-item-desc">{{getPropertyAddress(item)}}</div>
                                    <span>{{getCreatedDate(item)}}</span>
                                </div>
                            </div>
                            <div class="row-item cursor-pointer" *ngIf="isLoadingInfiniteScroll">
                                <div class="cs-d-flex flex-column dr-list-item">
                                    <div class="text-layout animated-background small mb-05"  style="width:180px"></div>
                                    <div class="text-layout animated-background long"></div>
                                    <div class="text-layout animated-background small mt-05" style="width:80px"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="pos-abt cs-footer-divider" *ngIf="dagrunsItems.length">
            <div class="cs-d-flex justify-content-center align-items-center h-100">
                <div class="ml-10">
                    <span>{{currentPage == 1 ? currentPage : (pageLimit * currentPage + 1) - pageLimit }} to {{ (pageLimit * currentPage) >=  totalEntriesCount ? totalEntriesCount : (pageLimit * currentPage)}} of {{ totalEntriesCount }} entries</span>
                </div>
                <cspagination-migrated class="flex-grow-1"
                   (fetchData)="fetchHistory($event)"
                   [totalEntries]="totalEntriesCount"
                   [currentPage]="currentPage"
                   [limit]="pageLimit">
                </cspagination-migrated>
            </div>

        </div>
    </div>
</div>
