import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UploadDocumentService } from '../services/upload-document.service';
import { HotTableRegisterer } from '@handsontable/angular';
import { HansontableSelect2 } from '@app/directives';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CsToastBoxService } from '@app/services';
import { BsModalRef } from 'ngx-bootstrap';
import { HybridFactory } from '@app/core/hybrid-factory';
import Handsontable from 'handsontable';
import { environment } from '../../../../environments/environment';
declare var _: any;
declare var jQuery;
declare var $: any;
// Following two variables are use to show .tiff files
// var Tiff = require('tiff.js');
// var fs = require('fs');

@Component({
    selector: 'app-document-parsed-details',
    templateUrl: './document-parsed-details.component.html',
    styleUrls: ['./document-parsed-details.component.css']
})
export class DocumentParsedDetailsComponent implements OnInit {

    @Input() taxItems: any;                     // List of vat rate
    @Input() listCodes: any;                    // List of nominal code
    @Input() supplierDetails: any;              // Details related to supplier
    @Input() actionType: any;                   // Confirm or Edit actions       
    @Output() closeModal = new EventEmitter();  // For closing modal from parent component

    // For save button
    suppliersList: any = [];
    invoiceDate: Date;
    invoiceNumber: any;
    poNumber: any = '';
    taxLabel: any;
    jobId: any = null;
    poErrorCode: any = 1; // PO is invalid
    poErrorMsg: any = null;
    selectedOption: any = 'items';

    // For confirm button
    csSelectedField: any = "Supplier"; // Which field user wants to change. eg. Supplier
    thirdPartyField: any = "Supplier"; // This field user wants to change. eg. Suppler

    csSelectedSupplierName: any = null; // New commusoft selected supplier name
    thirdPartySupplierName: any; // Supplier name coming from third party

    parsedDataId: any;          // For which record you want to change supplier name
    csSelectedSupplierId: any = null;  // Id of selected supplier

    saveMappedFieldName: any;   // Not necessary right now

    selectSuppliersList: any = {}; // For reusing commusoft component

    // For hot table
    hotInstance: Handsontable;
    spreadSheetSettings: Handsontable.GridSettings;
    spreadSheetData = []; // List of line items

    rowToDelete: Object[] = [];             // It will store the deleted row
    calculatedNetPrice: any = 0.00;         // Total cost
    calculatedTotalAmount: any = 0.00;      // Total amount
    currencySymbols: any;
    heightForReceiptScanningSection: any    // For deciding height of pdf
    invoicePdf: any;
    calculatedVatRate: any = 0.00;          // Sales Tax

    bsModalRef: BsModalRef;

    openChildModal = false;
    fadeBackground = false;

    // Error Messages
    invoiceDateErr: any = null;
    csSelectedSupplierNameErr: any = null;
    invoiceNumberErr: any = null;
    
    isLoading: boolean = true; // To show selected supplier name

    // To check button name
    buttonName: any;
    lineItemCount: any;

    allTaxItemsEqual: boolean = false;
    firstTaxItem: any;
    isPdf: boolean = true;
    finalBase64Data: string;

    isDisabledConfirmButton: boolean = false;

    inValidConfirmText: boolean = false;
    confirmTextModel: string = '';
    confirmText: string = 'CONFIRM';
    validationMessage = '\'CONFIRM\' to confirm the supplier mapping';
    showInvoiceErrMsg: boolean = false;
    
    partsList = [];

    invoiceCreationError = '';
    defaultNominalCode = '';
    
    constructor(
        private fileUploadingService: UploadDocumentService,
        public translate: TranslateService,
        private sanitizer: DomSanitizer,
        private toastBox: CsToastBoxService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { 
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
    }

    ngOnInit() {
        if (this.csSelectedSupplierId > 0 && (this.csSelectedSupplierName != '' || this.csSelectedSupplierName != null || typeof this.csSelectedSupplierName != undefined)) {
            this.fileUploadingService.getSuppliers(this.csSelectedSupplierName).subscribe((resp) => {
                this.suppliersList = resp;
                let index = this.suppliersList.findIndex((value: any) => value.id == parseInt(this.csSelectedSupplierId));

                if (index >= 0) {
                    $("#showSupplier").select2('data', this.suppliersList[0]);
                }
            });
        }

        this.selectSuppliersList = {
            minimumInputLength: 2,
            width: "100%",
            initSelection: () => {},
            ajax: {
                url: environment.apiBasePath + 'get_all_suppliers',
                data: (searchText, page) => {
                    if (searchText == '') {
                        return false;
                    }
                    return {'q': encodeURIComponent(searchText)};
                },
                results: (data) => {
                    data['response'] = data;
                    if (Array.isArray(data.response)) {
                        for (var item of data.response) {
                            let index = this.suppliersList.findIndex(el => el.id == item.id);
                            if (index == -1) {
                                this.suppliersList.push(item);
                            }
                        }
                    }
                    return {results: data.response}
                },
            },
        };
    }

    ngAfterViewInit() {
        const hotRegisterer = new HotTableRegisterer();
        this.hotInstance = hotRegisterer.getInstance('lineItemsDetails');
    }

    // This will be use to reload the data
    ngOnChanges(changes: SimpleChanges) {
        // changes.prop contains the old and the new value...
        this.initialization();
    }

    initialization() {
        this.invoiceCreationError = this.translate.instant('Error.while.creating.invoice');

        this.assignValues();	// Used to assign values to invoiceDate, supplier, poNumber and invoice Number
		
        this.prepareSpreadsheetSettings();
    
        this.getParsedData();  	// Get lineItems, pdf, calculatedNetPrice, calculatedTotalAmount, calculatedVatRate

    }

    assignValues() {
        this.buttonName = this.translate.instant('Confirm.and.add.new.invoice');
        // It will be used to show Sales Tax according to countries [UK/US] 
        if (this.supplierDetails.hasOwnProperty('taxLabel')) {
            this.taxLabel = this.supplierDetails['taxLabel'];
        }

        if (this.supplierDetails.hasOwnProperty('id')) {
            this.parsedDataId = this.supplierDetails['id'];
        }

        if (this.supplierDetails.hasOwnProperty('thirtyPartySupplierName')) {
            this.thirdPartySupplierName = this.supplierDetails['thirtyPartySupplierName']; 
        }

        if (this.supplierDetails.hasOwnProperty('poNumber')) {
            this.poNumber = this.supplierDetails['poNumber'];
        }

        /*Mandatory fields:
            Invoice Date
            Supplier name
            Invoice number
        */

        if (this.supplierDetails.hasOwnProperty('invoiceDateObject')) {
            if (this.supplierDetails['invoiceDateObject'] != null) {
                if (this.supplierDetails['invoiceDateObject'].hasOwnProperty('date')) {
                    this.invoiceDate = this.supplierDetails['invoiceDateObject']['date'];
                    this.invoiceDate = new Date(this.invoiceDate);
                }
            } else {
                this.invoiceDate = null;
            }
        }

        if (this.supplierDetails.hasOwnProperty('jobId')) {
            this.jobId = this.supplierDetails['jobId'];
        }

        if (this.supplierDetails.hasOwnProperty('invoiceNumber')) {
            this.invoiceNumber = this.supplierDetails['invoiceNumber'];
        }

        // Get the supplier id from commusoft
        if (this.supplierDetails.hasOwnProperty('commusoftSupplierId')) {
            if (this.supplierDetails.commusoftSupplierId != "" || this.supplierDetails.commusoftSupplierId != null ) {
                this.csSelectedSupplierId = parseInt(this.supplierDetails.commusoftSupplierId);
                this.isLoading = false;

                if (this.supplierDetails.hasOwnProperty('supplierName')) {
                    this.csSelectedSupplierName = this.supplierDetails.supplierName;
                }
            }
        }
    }

    validation() {
        this.isDisabledConfirmButton = false;
        var quantity, unitCost, selectedSalesTax, salesTax, selectedNominalCode, nominalCode, description;

        if (this.csSelectedSupplierId           == null ||
            typeof this.csSelectedSupplierId    == 'undefined') {
            this.csSelectedSupplierNameErr = "Please select supplier name";
            return;
        }
        
        if (this.invoiceNumber          == null ||
            typeof this.invoiceNumber   == 'undefined' ||
            this.invoiceNumber          == '') {
            this.invoiceNumberErr = "Please enter invoice number";
            return;
        }

        if (this.spreadSheetData.length > 1) {
            for (let i = 0; i < this.spreadSheetData.length - 1; i++) {
                quantity = null;
                unitCost = null;
                salesTax = null;
                nominalCode = null;
                description = null;

                description = this.spreadSheetData[i].description;
                quantity = parseFloat(this.spreadSheetData[i].quantity);
                unitCost = parseFloat(this.spreadSheetData[i].unitPrice);

                var selectedSalesTax = this.taxItems.find(p => p['id'] == parseInt(this.spreadSheetData[i]['taxItemId']));

                if (selectedSalesTax) {
                    salesTax = parseFloat(selectedSalesTax.effective_tax);
                }

                selectedNominalCode = this.listCodes.find(p => p['id'] == parseInt(this.spreadSheetData[i]['nominalCodeId']));
                
                if (selectedNominalCode) {
                    nominalCode = selectedNominalCode.id;
                }

                if (description         == ""   ||
                    description         == null ||
                    typeof description  == 'undefined') {
                    return;

                } else if (isNaN(quantity)  ||
                    quantity        == 0    ||
                    quantity        == ""   ||
                    quantity        == null ||
                    typeof quantity == 'undefined') {
                    return;

                } else if (isNaN(unitCost)  ||
                    unitCost        == 0    ||
                    unitCost        == ""   ||
                    unitCost        == null ||
                    typeof unitCost == 'undefined') {
                    return;

                } else if (isNaN(salesTax)  ||
                    salesTax        == null ||
                    typeof salesTax == 'undefined') {
                    return;
                } else if (isNaN(nominalCode)       ||
                    nominalCode         == ""       ||
                    nominalCode         == null     ||
                    typeof nominalCode == 'undefined') {
                   return;
                }
            } 
        } else {
            return ;
        }
        this.isDisabledConfirmButton = true;
    }

    getParsedData() {
        this.fileUploadingService.getAutomatedParseData(this.parsedDataId, this.poNumber, this.csSelectedSupplierId).subscribe((resp) => {

            if (resp.hasOwnProperty('parselineitemsDetails')) {
                if (resp['parselineitemsDetails'].hasOwnProperty([0])) {
                    if (resp['parselineitemsDetails'][0].hasOwnProperty('selectedOption')) {
                        var selectedOptionResponse = resp['parselineitemsDetails'][0]['selectedOption'];

                        this.poErrorCode = selectedOptionResponse['errorCode'];
                        this.poErrorMsg = selectedOptionResponse['errorMessage'];
                        this.selectedOption = selectedOptionResponse['selectedOption'];
                    }

                    if (resp['parselineitemsDetails'][0].hasOwnProperty('currencySymbols')) {
                        this.currencySymbols = resp['parselineitemsDetails'][0]['currencySymbols'];
                    }

                    if (resp['parselineitemsDetails'][0].hasOwnProperty('fileName')) {
                        this.invoicePdf = resp['parselineitemsDetails'][0]['fileName'];
                        this.loadImage(); // This will be used to convert .tiff file to .jpeg 
                        this.invoicePdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.invoicePdf);
                    }

                    if (resp['parselineitemsDetails'][0].hasOwnProperty('defaultNominalCode')) {
                        this.defaultNominalCode = resp['parselineitemsDetails'][0]['defaultNominalCode'];
                    }
                }

                if (resp['parselineitemsDetails'].hasOwnProperty(['lineItems'])) {
                    this.spreadSheetData = resp['parselineitemsDetails']['lineItems'];
                    this.lineItemCount = resp['parselineitemsDetails']['lineItems'].length;
                }
            }

            if (this.hotInstance) {
                this.hotInstance.loadData(this.spreadSheetData);
                this.hotInstance.render();
                this.changeOption(this.selectedOption);
            }
        });
    }


    onRowDelete(instance, row, col) {
        if (row == (this.spreadSheetData.length - 1)) return;

        if (this.selectedOption == 'parts') return;

        this.confirmationBoxHelper
            .getConfirmation(this.translate.instant('This row has been saved, are you sure you want to delete it?'), this.ajsScope)
            .then(() => {
                let id = this.spreadSheetData[row]['id'];
                if (typeof id != 'undefined' && id != null) {
                    if (this.spreadSheetData[row].hasOwnProperty('id')) {
                        this.fileUploadingService.deleteAIParseDetails(id).subscribe((resp) => {
                            this.rowToDelete.push(this.spreadSheetData[row]['id']);
                            this.spreadSheetData.splice(row, 1);
                            instance.render();
                            this.calculateTotal();
                            this.validation();
                            this.toastBox.show(this.translate.instant('Record.deleted.successfully'), 2000);
                        }, error => {
                            this.toastBox.show(this.translate.instant('Error.while.deleting.record'), 2000);
                        });
                    } 
                } else {
                    this.spreadSheetData.splice(row, 1);
                    instance.render();
                    this.calculateTotal();
                    this.validation();
                    this.toastBox.show(this.translate.instant('Record.deleted.successfully'), 2000);
                }
            });
    }

    prepareSpreadsheetSettings() {
        let that = this;
        this.spreadSheetSettings = {
            licenseKey  : 'non-commercial-and-evaluation',
            colHeaders  : true,
            rowHeaders  : false,
            minSpareRows: 1,
            // autoWrapRow :true,
            // autoWrapCol :true,
            manualColumnResize: true,
            manualRowResize: true,
            enterMoves: { row: 0, col: 1 },
            stretchH: 'all',
            // colWidths   : [320, 90, 100, 120, 180, 155, 90],
            columns     : [
                {
                    title       : this.translate.instant('Pat.description'),
                    data        : 'description',
                    required    : true,
                    validator   : 'emptyCellValidator',
                    allowEmpty  : false
                },
                {
                    title       : 'Part name',
                    data        : 'partId',
                    placeholder : 'Please select',
                    readOnly    : true,
                    className   : 'overflow_handsontable select_icon required',
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {

                        if (this.partsList.length == 0) {
                            let description = this.spreadSheetData[row]['partDescription'];
                            if (description) {
                                value  = description[0].toUpperCase() + description.substr(1).toLowerCase();
                            }
                            
                        } else {
                            let partName = this.partsList.find(p => p['id'] == value);
                            if (partName) {
                                this.spreadSheetData[row]['partDescription'] = value  = partName['text'][0].toUpperCase() + partName['text'].substr(1).toLowerCase();
                            } else {
                                value = this.spreadSheetData[row]['partDescription'];
                            }
                            
                        }

                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties])
                    },
                    editor: HansontableSelect2({
                        //minimumInputLength  : 2,
                        dropdownCssClass    : "handsontable-select",
                        dropdownAutoWidth   : true,
                        ajax                : {
                        url                 : 'get_parts_dropdown?supplierId=' + this.csSelectedSupplierId,
                            data: (query, page) => {
                                if (query == '') {
                                    return false;
                                }
                                return {'query': encodeURIComponent(query)};
                            },
                            results: (data, page) => {
                                this.partsList = data.parts;
                                return {results: data.parts};
                            }
                        }
                    }),
                },
                {
                    title       : this.translate.instant('Quantity'),
                    data        : 'quantity',
                    allowInvalid: true,
                    required    : false,
                    validator   : 'emptyCellValidator',
                    allowEmpty  : false,
                    type        : 'numeric'
                    
                },
                {
                    title       : this.translate.instant('Unit.Cost'),
                    data        : 'unitPrice',
                    allowInvalid: true,
                    required    : false,
                    validator   : 'emptyCellValidator',
                    allowEmpty  : false,
                    type        : 'numeric'
                },
                {
                    title       : this.translate.instant('Sales.Price'),
                    data        : 'unitPrice',
                    allowInvalid: true,
                    required    : false,
                    validator   : 'emptyCellValidator',
                    allowEmpty  : false,
                    type        : 'numeric'
                },
                {
                    title       : this.taxLabel,
                    data        : 'taxItemId',
                    placeholder : 'Please select',
                    className   : 'overflow_handsontable select_icon required',
                    validator   : 'emptyCellValidator',
                    allowEmpty  : false,
                    renderer    : (instance, td, row, col, prop, value, cellProperties) => {
                        let selectedTax;
                        selectedTax = _.findWhere(that.taxItems, { id: parseInt(value) });

                        value = (selectedTax) ? selectedTax.text : "";

                        Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    },
                    editor: HansontableSelect2({
                        editable            : true,
                        data                :that.taxItems,
                        dropdownAutoWidth   : true,
                        width               : 'resolve',
                        dropdownCssClass    : 'handsontable-select',
                        formatResult        : function (item) {
                            return item.text;
                        }
                    })
                },
                {
                    title       : this.translate.instant('Nominal.Code'),
                    data        : 'nominalCodeId',
                    placeholder : 'Please select',
                    className   : 'overflow_handsontable select_icon required',
                    validator   : 'emptyCellValidator',
                    allowEmpty  : false,
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {

                        if ((this.spreadSheetData.length - 1) != row) {
                            if (typeof this.spreadSheetData[row]['id'] == 'undefined' || this.spreadSheetData[row]['id'] == null || this.spreadSheetData[row]['id'] == '') {
                                this.spreadSheetData[row]['nominalCodeId'] = this.defaultNominalCode;
                                value = this.defaultNominalCode;
                            }
                        }

                        let selectedNominalCode = _.findWhere(that.listCodes, { id: parseInt(value) });

                        value = (selectedNominalCode) ? selectedNominalCode.text : "";

                        Handsontable.cellTypes.text.renderer(instance, td, row, col, prop, value, cellProperties);
                    },
                    editor                  : HansontableSelect2({
                        editable            : true,
                        data                : that.listCodes,
                        dropdownAutoWidth   : false,
                        width               : 'resolve',
                        dropdownCssClass    : 'handsontable-select',
                        formatResult        : function (item) {
                            return item.text;
                        }
                    })
                },
                {
                    title                   : this.translate.instant('Total.Cost.exc.VAT'),
                    data                    : 'totalPrice',
                    validator               : 'emptyCellValidator',
                    allowEmpty              : false,
                    disableVisualSelection  : false,
                    readOnly                : true,
                },
                {
                    title                   : this.translate.instant('Actions'),
                    readOnly                : true,
                    disableVisualSelection  : false,
                    renderer                : (instance, td, row, col) => {
                        let link: HTMLAnchorElement = document.createElement('a');
                        link.innerText = 'Delete';
                        link.style.cursor = 'pointer';
                        td.style.textAlign = 'center';
                        Handsontable.dom.addEvent(link, 'click', () => {
                            this.onRowDelete(instance, row, col);
                        });
                        Handsontable.dom.empty(td);
                        let content: HTMLDivElement = document.createElement('div');
                        content.appendChild(link);
                        td.appendChild(content);
                    }
                }
            ],
            afterChange: (...args: Array<any>) => {
                let core = that.hotInstance, changes = args[0];

                if (!changes) return;
                this.calculateTotal();
                core.render();
                this.validation();
            },
            afterLoadData: () => {
                this.calculateTotal();
                this.validation();
            }
        };
        
    }

    calculateTotal() {
        let totalVat, effectiveTax, total, selectedTax, selectedId;

        var total_price = 0, total_tax = 0, sub_total_price = 0;
        var quantity, unitPrice;

        var taxArray = [];

        if (this.spreadSheetData.length > 1) {
            for (let i = 0; i < this.spreadSheetData.length - 1; i++) {
                selectedTax = _.findWhere(this.taxItems, { id: parseInt(this.spreadSheetData[i]['taxItemId']) });
    
                if (typeof selectedTax == 'undefined') {
                    effectiveTax = 0.0;
                } else {
                    effectiveTax = parseFloat(selectedTax.effective_tax);
                    taxArray.push(effectiveTax);
                }
                
                quantity = this.spreadSheetData[i]['quantity'];
                unitPrice = this.spreadSheetData[i]['unitPrice'];

                if (
                    quantity            === ""          ||
                    quantity            === null        ||
                    typeof quantity     === 'undefined' ||
                    isNaN(quantity)                     ||
                    unitPrice           === ""          ||
                    unitPrice           === null        ||
                    typeof unitPrice    === 'undefined' ||
                    isNaN(unitPrice)) {
                        total_price = 0.0;
                        sub_total_price = 0.0;
                        total_tax = 0.0;
                    break;
                } else {
                    total = quantity * unitPrice;

                    totalVat = (total * effectiveTax) / 100;

                    this.spreadSheetData[i]['vatRate']      = effectiveTax;
                    this.spreadSheetData[i]['totalPrice']   = total;
                    this.spreadSheetData[i]['total']        = total;

                    total_price     += (parseFloat(total) + parseFloat(totalVat));
                    sub_total_price += total;
                    total_tax       += parseFloat(totalVat);
                }
            }
            this.calculatedNetPrice     = sub_total_price.toFixed(4);
            this.calculatedTotalAmount  = total_price.toFixed(4);
            this.calculatedVatRate      = total_tax.toFixed(4);

            this.allTaxItemsEqual       = taxArray.every((val, i, arr) => val === arr[0]);
            this.firstTaxItem = taxArray[0];

            if (typeof this.firstTaxItem == 'undefined') {
                this.allTaxItemsEqual = false;
            }

        } else {
            this.calculatedNetPrice     = 0.00;
            this.calculatedTotalAmount  = 0.00;
            this.calculatedVatRate      = 0.00;
        }

        if (this.hotInstance) {
            this.hotInstance.render();
        }
    }

    emptyCellValidator = (value, callback) => {
        if (value === '') {
            this.toastBox.show(this.translate.instant('Empty.cell.not.allowed'), 1000);
            callback(false);
        }else{
            callback(true);
        }
    }

    setNewScheduledDate(dateValue) {
        this.invoiceDate = dateValue.startDate;
    }

    checkInvoiceNumber(event) {
        let num = event.target.value;
        if (num.length == 0) {
            this.invoiceNumberErr = "Please enter invoice number";
        } else {
            this.invoiceNumberErr = null;
        }
        this.validation();
    }

    saveParseData() {
        if (this.isDisabledConfirmButton) {
            var newSpreadSheetData = [];
            newSpreadSheetData = this.spreadSheetData.slice(0, -1);
            var data = {
                'parseData': {
                    'supplierName'      : this.csSelectedSupplierName,
                    'supplierId'        : this.csSelectedSupplierId,
                    'invoiceNumber'     : this.invoiceNumber,
                    'invoiceDate'       : this.invoiceDate,
                    'poNumber'          : this.poNumber,
                    'net'               : this.calculatedNetPrice,
                    'total'             : this.calculatedTotalAmount,
                    'tax'               : this.calculatedVatRate,
                    'lineitemDetails'   : newSpreadSheetData,
                    'selectedOption'    : this.selectedOption,
                }
            };

            this.fileUploadingService.saveAutomatedParseData(data, this.parsedDataId).subscribe((resp) => {
                let part = 0;
                if (this.selectedOption == 'parts') {
                    part = 1;
                }

                this.fileUploadingService.convertParsedDataToInvoice(this.parsedDataId, part).subscribe((resp) => {
                    if (resp.status == 200 || resp.status == 201) {
                        this.closeFSModal();
                        this.toastBox.show(this.translate.instant('Invoice.created.successfully'), 3000);
                    } else {
                        if (resp.status == 400 && resp.hasOwnProperty('message')) {
                            this.invoiceCreationError = resp.message;
                        }
                        this.getParsedData();
                        
                        this.showInvoiceErrMsg = true;
                        
                        setTimeout(() => { 
                            this.showInvoiceErrMsg = false;
                            this.invoiceCreationError = this.translate.instant('Error.while.creating.invoice');
                        }, 3000);
                    }
                }, error => {
                    this.toastBox.show(this.translate.instant('Error.while.creating.invoice'), 3000);
                    this.getParsedData();
                });
            }, error => {
                if (this.actionType == 'edit') {
                    this.toastBox.show(this.translate.instant('Error.while.updating.record'), 3000);
                }
                this.getParsedData();
            });

        }
    }

    navigateToInvoice() {
        let url = 'suppliers/list_supplier/' + this.csSelectedSupplierId + '/view/supplier_details';
        HybridFactory.navigate(url);
    }

    saveCSSelectedSupplier() {  
        if (this.confirmTextModel.trim().toUpperCase() == this.confirmText) {
            var data = {
                'saveAutomatedData': {
                    'commusoftName'     : this.csSelectedField,
                    'thirdpartyName'    : this.thirdPartyField,
                    'commusoftValue'    : this.csSelectedSupplierName,
                    'thirtypartyValue'  : this.thirdPartySupplierName,
                    'commusoftId'       : this.csSelectedSupplierId,
                    'parsedDataId'      : this.parsedDataId
                }
            }
    
            this.fileUploadingService.saveAutomatedDataMapping(data).subscribe((resp) => {
            }, error => {
            });
            this.hideChildModal();
            this.csSelectedSupplierNameErr = null;
        }
    }

    showChildModal(data) {
        let index = this.suppliersList.findIndex((value: any) => value.id == parseInt(data));
        if (index >= 0) {
            this.csSelectedSupplierName = this.suppliersList[index]['text'];
            this.csSelectedSupplierId = this.suppliersList[index]['id'];

            if (this.csSelectedSupplierId != null && typeof this.csSelectedSupplierId != 'undefined') {
                this.openChildModal = true;
                this.fadeBackground = true;
                this.csSelectedSupplierNameErr = null;
            } else {
                this.csSelectedSupplierNameErr = "Please select supplier name";
            }
        }
    }

    hideChildModal() {
        this.openChildModal = false;
        this.fadeBackground = false;
        this.inValidConfirmText = false;
        this.confirmTextModel = '';

        this.callSelectPartsOrItemsApi();
        this.validation();
    }

    loadImage() {
        var fileExtension = this.invoicePdf.split('.').pop(); 
        if (fileExtension == 'tiff' || fileExtension == 'tif') {
            this.isPdf = false;
            // this.http.get(this.invoicePdf, {responseType: 'arraybuffer'})
            //     .subscribe(data => {
            //         let tiff1 = new Tiff({ buffer: data });

            //         // To display .tiff file in canvas
            //         let canvas = tiff1.toCanvas();

            //         // To display .tiff file in .jpeg
            //         const jpgBase64Data = canvas.toDataURL("image/jpeg").replace(/^data:image\/jpeg;base64,/, "");

            //         this.finalBase64Data="data:image/jpeg;base64,"+jpgBase64Data;
            //     });
        }
        
    }

    // Validating confirmation text
    checkConfirmText() {
        this.inValidConfirmText = !(this.confirmTextModel.trim().toUpperCase() == this.confirmText);
    }

    closeFSModal() {
        this.closeModal.emit();
    }

    changeOption(option) {
        this.selectedOption = option;
        if (this.selectedOption == 'parts') {
            if (this.hotInstance) {
                this.hotInstance.updateSettings({
                    hiddenColumns: {
                        columns: [0, 4],
                        indicators: false
                    },
                    hiddenRows: {
                        rows: [this.spreadSheetData.length - 1],
                        indicators: false
                    }
                })
            }
        } else {
            if (this.hotInstance) {
                this.hotInstance.updateSettings({
                    hiddenColumns: {
                        columns: [1],
                        indicators: false
                    },
                    hiddenRows: {
                        rows: [],
                        indicators: false
                    }
                })
            }
        }

        this.validation();
        this.calculateTotal();
    }

    changePoNumber(event) {
        this.poNumber = event.target.value;
        this.callSelectPartsOrItemsApi();
    }

    callSelectPartsOrItemsApi() {
        if (this.poNumber.length == 0) {
            // If po is null then no need to call the API.
            this.poNumber = '';
            this.poErrorCode = 1;
            this.poErrorMsg = '';
            this.selectedOption = 'items';
            this.changeOption(this.selectedOption);
        }

        this.getParsedData();
    }
}
