import { ElementRef, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap";
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { Router } from "@angular/router";
import { TimelineService } from "@app/shared/services/timeline.service";
import { AuthService } from "@app/core";
import { Subject } from "rxjs";
import { FileUploader } from "ng2-file-upload";
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SidepanelService } from "@app/sidepanel/services/sidepanel.service";
var PagePropertiesConfigurationComponent = /** @class */ (function () {
    function PagePropertiesConfigurationComponent(bsModalRef, proposaltemplateservice, router, fb, ts, confirmationBoxHelper, ajsScope, sidepanelService, authService) {
        var _this = this;
        this.bsModalRef = bsModalRef;
        this.proposaltemplateservice = proposaltemplateservice;
        this.router = router;
        this.fb = fb;
        this.ts = ts;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.sidepanelService = sidepanelService;
        this.authService = authService;
        this.pageBackground = 'color';
        this.backgroundColorDisplay = '#ffffff';
        this.pageBackgroundColor = 'No background';
        this.pagePropertiesImageblock = false;
        this.restoreBgColor = '';
        this.backgroundImageCroppedImage = '';
        this.isUploadPdfPage = false;
        this.disabled = false;
        this.errorMsg = '';
        this.commonCroppedImageShowing = '';
        this.setAllPageBackground = false;
        this.bgColorChecked = false;
        this.bgImageChecked = false;
        this.bgHedaerChecked = false;
        this.bgFooterChecked = false;
        this.correctSizeAndFormat = [];
        this.wrongSizeAndFormat = [];
        this.pagePropertiesLayoutStyle = 'dont_repeat';
        this.backgroundImgfileSelected = false;
        this.shimmerLoadingEnabled = false;
        this.headerFileSelected = false;
        this.setOldPageData = false;
        this.footerFileSelected = false;
        this.pagePropertiesHeaderFooterSelectedPage = [];
        //condition
        this.firstTimeUserUploadImage = false;
        this.imageUploadProgress = false;
        this.sidepanelOpen = true;
        this.dataToPass = '';
        //remove Logic
        this.pagePropertiesBackgroundImageRemove = false;
        this.pagePropertiesBackgroundHeaderRemove = false;
        this.pagePropertiesBackgroundFooterRemove = false;
        this.pagePropertiesBackgroundImageBackup = '';
        this.coverPage = false;
        this.cropType = 'jpeg';
        this.cropQuality = 60;
        this.headerCropQuality = 60;
        this.footerCropQuality = 60;
        this.headerCropWidth = 1200;
        this.headerCropHeight = 100;
        this.headerCroppedImages = [];
        this.headerCroppedFiles = [];
        this.headerKeepSpectRatio = false;
        this.footerCropWidth = 1200;
        this.footerCropHeight = 100;
        this.footerCroppedImages = [];
        this.footerCroppedFiles = [];
        this.footerKeepSpectRatio = false;
        this.screenType = '';
        this.optionId = '';
        this.minValue = 50;
        this.maxValue = 200;
        this.maxHeaderValue = 200;
        this.maxFooterValue = 200;
        this.setAllPagesProperties = {
            'setAllPage': '',
            'headerImageUrl': '',
            'headerImageHeight': '',
            'footerImageUrl': '',
            'footerImageHeight': '',
            'pageColorOrBackgroundImage': '',
            'style': ''
        };
        this.dragCounter = 0;
        this.sidepanelService.pagePropertiesSidePanel.subscribe(function (modalStatus) {
            if (modalStatus) {
                _this.cancel();
            }
        });
        this.proposaltemplateservice.pagePropertiesUpdates$.subscribe(function (data) {
            if (data) {
                _this.setAllPagesProperties = JSON.parse(data);
            }
        });
    }
    PagePropertiesConfigurationComponent.prototype.ngOnInit = function () {
        this.buildFormPagePropertiesForm();
        this.onClose = new Subject();
        this.uploader = new FileUploader({
            url: "",
            autoUpload: false,
            itemAlias: 'file',
            allowedMimeType: ['image/png', 'image/jpeg'],
            disableMultipart: true
        });
        if (this.setAllPagesProperties.hasOwnProperty('setAllPage') && this.setAllPagesProperties.setAllPage) {
            this.setAllPageBackground = this.setAllPagesProperties.setAllPage;
        }
        else {
            this.setAllPageBackground = this.setAllPagesProperties.setAllPage;
        }
        if ($('#cs_editor').contents().find(this.id).attr('data-page-type') == 'EHP') {
            this.coverPage = true;
        }
        else {
            this.coverPage = false;
        }
        if (this.option == 'pageBackground') {
            this.backgroundImageList = this.backgroundImageList;
            if (this.backgroundColor) {
                if (this.backgroundColor && this.backgroundColor.includes('rgb')) {
                    this.pageBackgroundColor = this.rgbToHex(this.backgroundColor);
                    this.pagePropertiesForm.get('bgColor').setValue(this.pageBackgroundColor);
                }
                this.pageBackground = 'color';
                this.bgColorChecked = true;
                this.pagePropertiesImageblock = false;
            }
            else {
                this.backgroundImageUrl = '';
                this.backgroundColorDisplay = '#ffffff';
            }
            if (this.backgroundImg && this.backgroundImg != "none") {
                this.pageBackground = 'image';
                this.bgImageChecked = true;
                this.pagePropertiesImageblock = true;
                this.backgroundImageUrl = this.backgroundImg.slice(4, -1).replace(/"/g, "");
                this.backgroundImageCroppedImage = this.backgroundImageUrl;
                this.pagePropertiesLayoutStyle = this.oldPagePropoertiesLayout;
                this.oldPagePropertiesBackgroundDetails = {
                    'layoutStyle': this.oldPagePropoertiesLayout,
                    'backgroundImageUrl': this.backgroundImageCroppedImage
                };
                this.isUploadPdfPage = this.isUploadPdfPage;
            }
            else {
                this.backgroundImageCroppedImage = '';
                this.pagePropertiesLayoutStyle = 'dont_repeat';
            }
            if (this.pageHeaderImage && this.pageHeaderImage != 'none' && this.pageHeaderImage.length) {
                this.pageHeaderImageUrl = this.pageHeaderImage[0];
                if (this.pageHeaderHeight[0] > 0) {
                    this.headerCropHeight = this.pageHeaderHeight[0];
                    this.maxHeaderValue = parseInt(this.headerCropHeight);
                }
                this.pageHeaderImageBackupImage = this.pageHeaderImageUrl;
                this.pagePropertiesHeaderCroppedImage = this.pageHeaderImageUrl;
                this.oldPagePropertiesHeaderDetails = this.pagePropertiesHeaderCroppedImage;
            }
            else {
                this.pageHeaderImageUrl = '';
                this.pagePropertiesHeaderCroppedImage = '';
                this.oldPagePropertiesHeaderDetails = '';
            }
            if (this.pageFooterImage && this.pageFooterImage != 'none' && this.pageFooterImage.length) {
                this.pageFooterImageUrl = this.pageFooterImage[0];
                if (this.pageFooterHeight[0] > 0) {
                    this.footerCropHeight = this.pageFooterHeight[0];
                    this.maxFooterValue = parseInt(this.footerCropHeight);
                }
                this.pageFooterImageBackupImage = this.pageFooterImageUrl;
                this.pagePropertiesFooterCroppedImage = this.pageFooterImageUrl;
                this.oldPagePropertiesFooterDetails = this.pagePropertiesFooterCroppedImage;
            }
            else {
                this.pageFooterImage = '';
                this.pagePropertiesFooterCroppedImage = '';
                this.oldPagePropertiesFooterDetails = '';
            }
            if (this.pageHeaderImage.length || this.pageFooterImage.length) {
                this.bgHedaerChecked = true;
            }
            else {
                this.bgHedaerChecked = false;
            }
            this.restoreBgColor = this.backgroundColor;
            var bgColor = {
                bgColor: this.restoreBgColor
            };
            $('#cs_editor').contents().find('div.cs_selected').find('.cs_margin').attr('old-bgclr', bgColor);
        }
    };
    // Purpose: Converts an RGB color string to its corresponding hexadecimal representation.
    // If the input is "rgb(255, 255, 255)" (white), it returns 'No Background'.
    PagePropertiesConfigurationComponent.prototype.rgbToHex = function (rgb) {
        var noBackground = "";
        if (rgb == "rgb(255, 255, 255)") {
            noBackground = 'No Background';
            return noBackground;
        }
        var _a = rgb.match(/\d+/g).map(Number), r = _a[0], g = _a[1], b = _a[2];
        var hex = "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
        return hex.toUpperCase();
    };
    // Purpose: Updates properties related to the page background based on the selected option.
    // Parameters: - option: String representing the selected background option ('color' or 'image').
    PagePropertiesConfigurationComponent.prototype.backgroundOption = function (option) {
        var _this = this;
        this.backgroundImgfileSelected = false;
        if (option == 'color') {
            this.disabled = true;
            this.pageBackground = 'color';
            this.bgColorChecked = !this.bgColorChecked;
            this.bgImageChecked = false;
            this.pagePropertiesForm.get('bgImage').setValue('');
        }
        else if (option == "image") {
            this.disabled = true;
            this.pageBackground = 'image';
            this.bgImageChecked = !this.bgImageChecked;
            this.bgColorChecked = false;
            this.pagePropertiesForm.get('bgColor').setValue('');
            setTimeout(function () {
                _this.dynamicHeight = $('.page-background-section').height() + 9;
            }, 1500);
        }
    };
    // Purpose: Updates a class variable ('myValue') after a specified delay.
    // Parameters: - data: The value to be assigned to 'myValue'.
    PagePropertiesConfigurationComponent.prototype.detectChanges = function (data) {
        var that = this;
        this.myValue = '';
        setTimeout(function () {
            that.myValue = data;
        }, 50);
    };
    // Purpose: Updates the page background color and triggers additional actions based on the specified type.
    // Parameters:- data: The new color value.
    PagePropertiesConfigurationComponent.prototype.updateColor = function (data, type) {
        this.pageBackgroundColor = data;
        this.pagePropertiesForm.get('bgColor').setValue(this.pageBackgroundColor);
        if (type === 'pageBackgroundColor') {
            var pageTypeElement = this.getPageTypeElement();
            var pageTypeClass = pageTypeElement.getAttribute('data-page-type') === 'EHP' ? 'cs_selected' : 'cs_selected_border';
            if (!$(pageTypeElement).hasClass(pageTypeClass)) {
                $(pageTypeElement).addClass(pageTypeClass);
            }
            // $(pageTypeElement).css('background-image', '');
            this.renderingStylesDom('', this.pageUniqueId, this.pageBackgroundColor, 'pageBackgroundColor');
        }
    };
    // Purpose: Retrieves the page type element based on the current page ID.
    PagePropertiesConfigurationComponent.prototype.getPageTypeElement = function () {
        return $('#cs_editor').contents().find("div[data_id=" + this.pageUniqueId + "]")[0];
    };
    PagePropertiesConfigurationComponent.prototype.getAllHeaderPageElement = function () {
        return $('#cs_editor').contents().find("#headerandfooter_header_" + this.pageUniqueId);
    };
    PagePropertiesConfigurationComponent.prototype.getAllFooterPageElement = function () {
        return $('#cs_editor').contents().find("#headerandfooter_footer_" + this.pageUniqueId);
    };
    // Purpose: Handles the event when an image is cropped, updating data and UI elements accordingly.
    // Parameters:
    //   - event: ImageCroppedEvent containing information about the cropped image.
    //   - resizeType: Type of image being cropped ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    PagePropertiesConfigurationComponent.prototype.imageCropped = function (event, resizeType) {
        if (!this.setOldPageData) {
            this.updateOldPageData(resizeType, event.base64);
            this.setOldPageData = true;
        }
        var heightMaximum = 50;
        if (resizeType == 'pageHeader') {
            heightMaximum = this.headerCropHeight;
            if (event.width > 1920) {
                this.headerCropWidth = '1920';
                this.headerCropHeight = Math.round((event.height / event.width) * 1920) + '';
            }
        }
        else if (resizeType == 'pageFooter') {
            heightMaximum = this.footerCropHeight;
            if (event.width > 1920) {
                this.footerCropWidth = '1920';
                this.footerCropHeight = Math.round((event.height / event.width) * 1920) + '';
            }
        }
        if (heightMaximum <= 200) {
            this.updateCroppedImage(resizeType, event.base64);
            this.showTempImage(resizeType);
            this.sidepanelOpen = false;
        }
    };
    PagePropertiesConfigurationComponent.prototype.imageLoaded = function () {
        //if you want image loading time use this methods
    };
    PagePropertiesConfigurationComponent.prototype.cropperReady = function () {
        this.imageUploadProgress = false;
    };
    // Purpose: Updates Old page data based on the type of image being cropped.
    // Parameters:
    //   - resizeType: Type of image being cropped ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    //   - base64: The base64 representation of the cropped image.
    PagePropertiesConfigurationComponent.prototype.updateOldPageData = function (resizeType, base64) {
        if (resizeType === 'pageBackgroundImage' && this.oldPagePropertiesBackgroundDetails && this.oldPagePropertiesBackgroundDetails.hasOwnProperty('backgroundImageUrl')) {
            this.oldPagePropertiesBackgroundDetails.backgroundImageUrl = base64;
        }
        else if (resizeType === 'pageHeader' && this.oldPagePropertiesHeaderDetails !== '') {
            this.oldPagePropertiesHeaderDetails = base64;
        }
        else if (resizeType === 'pageFooter' && this.oldPagePropertiesFooterDetails !== '') {
            this.oldPagePropertiesFooterDetails = base64;
        }
    };
    // Purpose: Updates the cropped image data and corresponding form control.
    // Parameters:
    //   - resizeType: Type of image being cropped ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    //   - base64: The base64 representation of the cropped image.
    PagePropertiesConfigurationComponent.prototype.updateCroppedImage = function (resizeType, base64) {
        if (resizeType === 'pageBackgroundImage') {
            this.backgroundImageCroppedImage = base64;
            this.pagePropertiesForm.get('bgImage').setValue(this.backgroundImageCroppedImage);
        }
        else if (resizeType === 'pageHeader') {
            this.pagePropertiesHeaderCroppedImage = base64;
            this.pagePropertiesForm.get('headerImage').setValue(this.pagePropertiesHeaderCroppedImage);
        }
        else if (resizeType === 'pageFooter') {
            this.pagePropertiesFooterCroppedImage = base64;
            this.pagePropertiesForm.get('footerImage').setValue(this.pagePropertiesFooterCroppedImage);
        }
    };
    // Purpose: Displays a temporary image on the UI based on the provided type (pageBackgroundImage, pageHeader, or pageFooter).
    // Parameters:
    //   - resizeType: Type of image being displayed ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    PagePropertiesConfigurationComponent.prototype.showTempImage = function (resizeType) {
        var croppedImage = resizeType === 'pageBackgroundImage' ? this.backgroundImageCroppedImage : resizeType === 'pageHeader' ? this.pagePropertiesHeaderCroppedImage : this.pagePropertiesFooterCroppedImage;
        this.showingTempImageOnEditor(croppedImage, resizeType);
    };
    // Purpose: Requests a temporary image from the server and updates relevant UI elements.
    PagePropertiesConfigurationComponent.prototype.showingTempImageOnEditor = function (croppedImage, showingType) {
        var _this = this;
        var setImageAndRender = function (locationKey, showingType) {
            if (croppedImage) {
                _this[locationKey] = croppedImage;
                _this.renderingStylesDom(_this[locationKey], _this.pageUniqueId, '', showingType);
                if (showingType === 'pageBackgroundImage') {
                    _this.pagePropertiesBgImageSelectedPage = _this.getPageTypeElement();
                    _this.pagePropertiesBgImageLayouts(_this.pagePropertiesLayoutStyle);
                }
            }
        };
        if (showingType === 'pageBackgroundImage') {
            setImageAndRender('pageBackgroundImageLocation', 'pageBackgroundImage');
        }
        else if (showingType === 'pageHeader') {
            setImageAndRender('pageHeaderImageLocation', 'pageHeader');
            // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
        }
        else if (showingType === 'pageFooter') {
            setImageAndRender('pageFooterImageLocation', 'pageFooter');
            // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
        }
    };
    PagePropertiesConfigurationComponent.prototype.pagePropertiesBgImageLayouts = function (layoutValue) {
        var selectedValue = layoutValue;
        this.pagePropertiesLayoutStyle = selectedValue;
        switch (selectedValue) {
            case 'dont_repeat':
                this.setPagePropertiesBgImageStyles('no-repeat', 'cover');
                break;
            case 'repeat_vertically':
                this.setPagePropertiesBgImageStyles('repeat', 'contain');
                break;
            case 'repeat_vertically_horizontally':
                this.setPagePropertiesBgImageStyles('repeat', 'auto');
                break;
            case 'repeat':
                this.setPagePropertiesBgImageStyles('no-repeat', 'cover');
                break;
        }
    };
    PagePropertiesConfigurationComponent.prototype.setPagePropertiesBgImageStyles = function (repeatValue, sizeValue) {
        $(this.pagePropertiesBgImageSelectedPage).css({
            backgroundRepeat: repeatValue,
            backgroundSize: sizeValue,
            backgroundColor: ''
        });
    };
    // Purpose: Handles the upload of a page background image and triggers additional actions.
    PagePropertiesConfigurationComponent.prototype.pagebackgroundImageUpload = function (event) {
        var _this = this;
        $('.drag-zone-animation-section').css('display', 'none');
        this.imageUploadProgress = true;
        this.firstTimeUserUploadImage = true;
        this.handleFileUpload(event, 'pageBackgroundImage', function (location) {
            _this.pageBackgroundImageLocation = location;
            _this.pagePropertiesBgImageSelectedPage = _this.getPageTypeElement();
            _this.pagePropertiesBgImageLayouts(_this.pagePropertiesLayoutStyle);
        });
    };
    // Purpose: Handles the file upload process, checks file size and format, and updates UI elements accordingly.
    // Parameters:
    //   - event: File input change event.
    //   - uploadType: Type of image being uploaded ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    //   - onSuccess: Callback function to handle successful file upload.
    PagePropertiesConfigurationComponent.prototype.handleFileUpload = function (event, uploadType, onSuccess) {
        var _this = this;
        if (uploadType == 'pageBackgroundImage') {
            this.backgroundImgfileSelected = true;
        }
        else if (uploadType == 'pageHeader') {
            this.headerFileSelected = true;
        }
        else if (uploadType == 'pageFooter') {
            this.footerFileSelected = true;
        }
        // event.preventDefault();
        // const files: any = (event.type === 'change' && event.isTrusted) ? (event.target as HTMLInputElement).files : event;
        var files = [];
        if (event.type === 'change' && event.isTrusted) {
            files = event.target.files;
        }
        else {
            files = [event[0]];
        }
        var originalFormatData = [];
        this.fileErrorMsg = false;
        // this.backgroundImageUrl = false;
        // this.pageHeaderImageUrl = false;
        this.correctSizeAndFormat = [];
        this.wrongSizeAndFormat = [];
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var checkMaxFileSize = (file.size / 1024 / 1024).toFixed(2);
            var fileType = this.ts.getFileGroup(file.name.substr(file.name.lastIndexOf('.') + 1));
            if (parseInt(checkMaxFileSize) <= 30 && fileType !== 'unknown') {
                originalFormatData.push(file);
                this.correctSizeAndFormat.push({
                    'name': file.name,
                    'size': (file.size / 1024).toFixed(2),
                    'type': fileType
                });
            }
            else {
                this.wrongSizeAndFormat.push({
                    'name': file.name,
                    'size': (file.size / 1024).toFixed(2),
                    'type': fileType
                });
            }
        }
        if (this.correctSizeAndFormat.length) {
            setTimeout(function () {
                var reader = new FileReader();
                _this.imagePath = files;
                reader.readAsDataURL(files[0]);
                reader.onload = function (_event) {
                    if (uploadType == 'pageBackgroundImage') {
                        var fileSizeQuality = (files[0].size / 1024 / 1024).toFixed(2);
                        _this.resizeImage(files[0], 816, 1120).then(function (resizedImage) {
                            // Use the resized image data (base64) here
                            // console.log('Resized image:', resizedImage);
                            _this.backgroundImageUrl = resizedImage;
                        }).catch(function (error) {
                            console.error('Error resizing image:', error);
                        });
                        _this.setImageUploadQuality(fileSizeQuality, uploadType);
                        _this.pagePropertiesBackgroundImageRemove = false;
                        _this.backgroundImageCroppedImage = _this.backgroundImageUrl;
                        // this.commonCroppedImageShowing = this.backgroundImageCroppedImage
                    }
                    else if (uploadType == 'pageHeader') {
                        var fileSizeQuality = (files[0].size / 1024 / 1024).toFixed(2);
                        _this.setImageUploadQuality(fileSizeQuality, uploadType);
                        _this.headerKeepSpectRatio = false;
                        _this.headerCroppedFiles = [];
                        _this.headerCroppedImages = [];
                        _this.resizeImage(files[0], 816, 1120).then(function (resizedImage) {
                            // Use the resized image data (base64) here
                            // console.log('Resized image:', resizedImage);
                            _this.pageHeaderImageUrl = resizedImage;
                        }).catch(function (error) {
                            console.error('Error resizing image:', error);
                        });
                        _this.pagePropertiesBackgroundHeaderRemove = false;
                        // this.pageHeaderImageUrl = reader.result;
                        _this.pagePropertiesHeaderCroppedImage = _this.pageHeaderImageUrl;
                        // let currentPage = this.getAllHeaderPageElement();
                        // if (currentPage.hasClass('page-properties-hide-header-image')) {
                        //     $(currentPage).removeClass('page-properties-hide-header-image')
                        // }
                        _this.scrollToBottom('pageHeader');
                        // this.commonCroppedImageShowing = this.pagePropertiesHeaderCroppedImage
                    }
                    else if (uploadType == 'pageFooter') {
                        var fileSizeQuality = (files[0].size / 1024 / 1024).toFixed(2);
                        _this.setImageUploadQuality(fileSizeQuality, uploadType);
                        _this.footerKeepSpectRatio = false;
                        _this.footerCroppedFiles = [];
                        _this.footerCroppedImages = [];
                        _this.resizeImage(files[0], 816, 1120).then(function (resizedImage) {
                            // Use the resized image data (base64) here
                            // console.log('Resized image:', resizedImage);
                            _this.pageFooterImageUrl = resizedImage;
                        }).catch(function (error) {
                            console.error('Error resizing image:', error);
                        });
                        /*Array.from(event.target.files).forEach((file: any) => {
                            this.footerCroppedFiles.push({
                                ext: this.getFilenameExtension(file),
                                file: file,
                                type: file.type,
                            });
                        });*/
                        _this.pagePropertiesBackgroundFooterRemove = false;
                        // this.pageFooterImageUrl = reader.result;
                        _this.pagePropertiesFooterCroppedImage = _this.pageFooterImageUrl;
                        var currentPage = _this.getAllFooterPageElement();
                        // if (currentPage.hasClass('page-properties-hide-footer-image')) {
                        //     $(currentPage).removeClass('page-properties-hide-footer-image')
                        // }
                        _this.scrollToBottom('pageFooter');
                        // this.commonCroppedImageShowing = this.pagePropertiesFooterCroppedImage
                    }
                    _this.firstTimeUserUploadImage = true;
                    _this.backgroundImgfileSelected = false;
                    _this.headerFileSelected = false;
                    _this.footerFileSelected = false;
                    _this.sidepanelOpen = false;
                    event.target.value = '';
                    // this.showingTempImage(this.commonCroppedImageShowing,uploadType);
                };
            }, 4000);
        }
        else {
            if (this.wrongSizeAndFormat && this.wrongSizeAndFormat.length) {
                this.fileErrorMsg = true;
                if (uploadType === 'pageBackgroundImage') {
                    this.backgroundImageUrl = false;
                    this.backgroundImgfileSelected = false;
                    this.showBackgroundImageError = true;
                }
                else if (uploadType == 'pageHeader') {
                    this.showBackgroundHeaderError = true;
                }
                else if (uploadType == 'pageFooter') {
                    this.showBackgroundFooterError = true;
                }
            }
        }
    };
    PagePropertiesConfigurationComponent.prototype.selectImage = function (event) {
        var _this = this;
        this.imageUploadProgress = true;
        this.shimmerLoadingEnabled = true;
        this.backgroundImageUrl = '';
        if (event.target && event.target.src) {
            this.errorMsg = '';
            setTimeout(function () {
                _this.backgroundImageUrl = event.target.src;
                _this.backgroundImageCroppedImage = _this.backgroundImageUrl;
                _this.shimmerLoadingEnabled = false;
            }, 1200);
        }
    };
    PagePropertiesConfigurationComponent.prototype.cancel = function () {
        this.removeAllIds();
        this.blockSettingModal.cancel();
        if (this.pagePropertiesBackgroundImageBackup != null && this.pagePropertiesBackgroundImageBackup != '') {
            var currentPage = this.getPageTypeElement();
            var styles = {
                backgroundImage: "url(" + this.pagePropertiesBackgroundImageBackup + ")",
            };
            $(currentPage).css(styles);
        }
        var placeEverythingElement = $('#cs_editor').contents().find('#place_everything');
        var findBlockHeader = $(placeEverythingElement).find("#headerandfooter_header_" + this.pageUniqueId);
        var findBlockFooter = $(placeEverythingElement).find("#headerandfooter_footer_" + this.pageUniqueId);
        var findImageBlockHeader = $(findBlockHeader).find('img').get(0);
        var findImageBlockFooter = $(findBlockFooter).find('img').get(0);
        if (this.pageHeaderImageBackupImage != null && this.pageHeaderImageBackupImage != '') {
            findImageBlockHeader.setAttribute('src', "" + this.pageHeaderImageBackupImage);
            findImageBlockHeader.setAttribute('height', '');
            findImageBlockHeader.setAttribute('width', this.countryType === 'unitedkingdom' ? 792 : 814);
            findBlockHeader.css({
                "background-image": "",
                "background-repeat": "",
                "background-size": "",
                "background-position": "",
                "height": ""
            });
        }
        else {
            findImageBlockHeader.setAttribute('src', "");
            findImageBlockHeader.setAttribute('height', '');
            findImageBlockHeader.setAttribute('width', '');
        }
        if (this.pageFooterImageBackupImage != null && this.pageFooterImageBackupImage != '') {
            findImageBlockFooter.setAttribute('src', "" + this.pageFooterImageBackupImage);
            findImageBlockFooter.setAttribute('height', '');
            findImageBlockHeader.setAttribute('width', this.countryType === 'unitedkingdom' ? 792 : 814);
            findBlockFooter.css({
                "background-image": "",
                "background-repeat": "",
                "background-size": "",
                "background-position": "",
                "height": ""
            });
        }
        else {
            findImageBlockFooter.setAttribute('src', "");
            findImageBlockFooter.setAttribute('height', '');
            findImageBlockFooter.setAttribute('width', '');
        }
        // if (this.pagePropertiesBackgroundHeaderRemove) {
        //     let allHeaderPage = this.getAllHeaderPageElement();
        //     if (allHeaderPage) {
        //         $(allHeaderPage).removeClass('page-properties-hide-header-image');
        //     }
        // }
        // if (this.pagePropertiesBackgroundFooterRemove) {
        //     let allFooterPage = this.getAllFooterPageElement();
        //     if (allFooterPage) {
        //         $(allFooterPage).removeClass('page-properties-hide-footer-image');
        //     }
        // }
        if (this.option === 'pageBackground' && (this.pageBackground === 'color' || this.pageBackground === 'image')) {
            var pageType = this.getPageTypeElement();
            var selectedElement = $('#cs_editor').contents().find('div.cs_selected');
            var selectedBorderStyle = $('#cs_editor').contents().find('div.cs_selected_border');
            if (pageType.getAttribute('data-page-type') === 'EHP') {
                $(selectedElement).css('background-color', this.restoreBgColor);
                $(selectedElement).find('.cs_margin').removeAttr('old-bgclr');
            }
            else {
                $(selectedBorderStyle).css('background-color', this.restoreBgColor);
                $(selectedBorderStyle).find('.cs_margin').removeAttr('old-bgclr');
            }
            /*if (!this.sidepanelOpen){
                if (this.oldPagePropertiesBackgroundDetails) {
                    this.restorePageBackgroundDetails();
                }
                if (this.oldPagePropertiesHeaderDetails) {
                    this.restoreHeaderFooterDetails();
                }
            }*/
        }
        this.bsModalRef.hide();
    };
    // Purpose: Handles the upload of page header or footer image and triggers additional actions.
    PagePropertiesConfigurationComponent.prototype.pageHeaderFooterImageUpload = function (event, uploadType) {
        var _this = this;
        this.firstTimeUserUploadImage = true;
        this.imageUploadProgress = true;
        if (uploadType == 'pageHeader') {
            this.handleFileUpload(event, 'pageHeader', function (location) {
                _this.pageHeaderImageLocation = location;
                // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
            });
        }
        else if (uploadType == 'pageFooter') {
            this.handleFileUpload(event, 'pageFooter', function (location) {
                _this.pageFooterImageLocation = location;
                // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
            });
        }
    };
    // Purpose: Builds the form group for page properties using the FormBuilder.
    PagePropertiesConfigurationComponent.prototype.buildFormPagePropertiesForm = function () {
        this.pagePropertiesForm = this.fb.group({
            bgColor: [''],
            bgImage: [''],
            headerImage: [''],
            footerImage: [''],
            pageMarginValue: [''],
        });
    };
    PagePropertiesConfigurationComponent.prototype.onSubmit = function () {
        var _this = this;
        this.imageUploadProgress = true;
        this.dataToPass = "datapassbyparent";
        var formData = this.pagePropertiesForm.value;
        var observables = [];
        var responsesMap = {};
        if (!this.coverPage) {
            this.blockSettingModal.save(this.setAllPageBackground);
        }
        if (formData.bgColor && formData.bgColor != 'No Background') {
            observables.push(this.proposaltemplateservice.saveBackgroundColor(formData.bgColor).pipe(tap(function (response) { return responsesMap['backgroundColor'] = response; })));
        }
        if (formData.bgImage) {
            observables.push(this.proposaltemplateservice.addPageImages(formData.bgImage, this.pageUniqueId).pipe(tap(function (response) { return responsesMap['backgroundImage'] = response; })));
        }
        if (formData.headerImage) {
            observables.push(this.proposaltemplateservice.pagePropertiesBackgroundImage(formData.headerImage, this.pageUniqueId, 'saveHeaderFooterImage', 'header').pipe(tap(function (response) { return responsesMap['header'] = response; })));
        }
        if (formData.footerImage) {
            observables.push(this.proposaltemplateservice.pagePropertiesBackgroundImage(formData.footerImage, this.pageUniqueId, 'saveHeaderFooterImage', 'footer').pipe(tap(function (response) { return responsesMap['footer'] = response; })));
        }
        if (this.setAllPageBackground) {
            observables.push(this.proposaltemplateservice.addPageImages(formData.bgImage, this.pageUniqueId).pipe(tap(function (response) { return responsesMap['setBackgroundAllPages'] = response; })));
        }
        if (observables.length == 0) {
            var closeModalData = this.createCloseModalData('');
            this.emitCloseModalData(closeModalData);
        }
        else {
            forkJoin(observables).subscribe(function (responses) {
                var closeModalData = _this.createCloseModalData(responsesMap);
                _this.emitCloseModalData(closeModalData);
                _this.imageUploadProgress = false;
            }, function (error) {
                console.error('Error in API calls:', error);
            });
        }
        // if(this.screenType != 'content_library'){
        //     let data = {};
        //     if(this.screenType == 'option_template'){
        //         data = {
        //             screenType: this.screenType,
        //             id: this.optionId,
        //             addon: false
        //         }
        //     } else if (this.screenType == 'proposal_templates') {
        //         data = {
        //             screenType: this.screenType,
        //             id: this.proposalTemplateId,
        //             addon: false
        //         }
        //     } else if (this.screenType == 'opportunity') {
        //         data = {
        //             screenType: this.screenType,
        //             id: this.opportunityProposalId,
        //             addon: false,
        //             opportunityId: this.opportunityId
        //         }
        //     }
        //     this.proposaltemplateservice.upgradeDowngradeFreemiumBlocks(data).subscribe((res: any) => {
        //
        //     })
        // }
    };
    // Purpose: Creates a data object containing information about the changes made during the modal closure.
    // Parameters: - result: The result object obtained from API calls.
    PagePropertiesConfigurationComponent.prototype.createCloseModalData = function (result) {
        var closeModalData = {
            'setAllPageBackground': this.setAllPageBackground,
            'pagePropertiesLayout': this.pagePropertiesLayoutStyle,
            'savePagePropertiesBackgroundColor': false,
            'savePagePropertiesBackgroundImage': false,
            'savePagePropertiesHeader': false,
            'savePagePropertiesFooter': false,
            'pageBackgroundColor': '',
            'pagebackgroundImage': '',
            'pageHeaderImage': '',
            'pageFooterImage': '',
            'pageHeaderHeight': this.headerCropHeight,
            'pageFooterHeight': this.footerCropHeight,
            'pagePropertiesBackgroundImageRemove': this.pagePropertiesBackgroundImageRemove,
            'pagePropertiesBackgroundHeaderRemove': this.pagePropertiesBackgroundHeaderRemove,
            'pagePropertiesBackgroundFooterRemove': this.pagePropertiesBackgroundFooterRemove,
            'pageMarginvalue': this.pagePropertiesForm.get('pageMarginValue'),
        };
        if (result.backgroundColor && result.backgroundColor.hasOwnProperty('backgroundColor')) {
            closeModalData.pageBackgroundColor = result.backgroundColor.backgroundColor;
            closeModalData.savePagePropertiesBackgroundColor = true;
        }
        var setImageData = function (type, locationKey, saveKey) {
            if (result[type] && result[type].hasOwnProperty('file')) {
                closeModalData[locationKey] = result[type].file.location;
                closeModalData[saveKey] = true;
            }
        };
        setImageData('backgroundImage', 'pagebackgroundImage', 'savePagePropertiesBackgroundImage');
        setImageData('header', 'pageHeaderImage', 'savePagePropertiesHeader');
        setImageData('footer', 'pageFooterImage', 'savePagePropertiesFooter');
        return closeModalData;
    };
    PagePropertiesConfigurationComponent.prototype.emitCloseModalData = function (closeModalData) {
        this.onClose.next(closeModalData);
        this.removeAllIds();
        this.disabled = false;
        this.bsModalRef.hide();
    };
    // Purpose: Updates the CSS styles dynamically for page elements based on the provided image URL and propertiesType.
    // Parameters:
    //   - imageUrl: The URL of the image to be applied as a background.
    //   - pageId: The ID of the page element.
    //   - colorCode: The color code (used when propertiesType is 'pageBackgroundColor').
    //   - propertiesType: Type of properties being updated ('pageBackgroundImage', 'pageBackgroundColor', 'pageHeader', or 'pageFooter').
    PagePropertiesConfigurationComponent.prototype.renderingStylesDom = function (imageUrl, pageId, colorCode, propertiesType) {
        if (propertiesType === void 0) { propertiesType = ''; }
        var styleId = '';
        var getElementId = '';
        var dynamicStyle = '';
        var placeEverythingElement = $('#cs_editor').contents().find('#place_everything');
        if (propertiesType === 'pageHeader') {
            styleId = 'pagePropertiesDynamicStylesHeader';
            var findImageBlock = $(placeEverythingElement).find("#headerandfooter_header_" + this.pageUniqueId).find('img').get(0);
            findImageBlock.setAttribute('src', "" + imageUrl);
            findImageBlock.setAttribute('height', '');
            findImageBlock.setAttribute('width', this.countryType === 'unitedkingdom' ? 792 : 814);
            // dynamicStyle = `#headerandfooter_header_${this.pageUniqueId} {
            //     background-image: url(${imageUrl}) !important;
            //     background-repeat: no-repeat;
            //     background-color: '';
            //     }
            //
            //     #headerandfooter_header_${this.pageUniqueId} {
            //         height: ${this.headerCropHeight}px !important;
            //         width: 100%;
            //         border-bottom: 1px solid #ccc;
            //     }
            //
            //     `;
        }
        else if (propertiesType === 'pageFooter') {
            styleId = 'pagePropertiesDynamicStylesFooter';
            var findImageBlock = $(placeEverythingElement).find("#headerandfooter_footer_" + this.pageUniqueId).find('img').get(0);
            findImageBlock.setAttribute('src', "" + imageUrl);
            findImageBlock.setAttribute('height', '');
            findImageBlock.setAttribute('width', this.countryType === 'unitedkingdom' ? 792 : 814);
            // dynamicStyle = `#headerandfooter_footer_${this.pageUniqueId} {
            //     background-image: url(${imageUrl}) !important;
            //     background-repeat: no-repeat;
            //     background-color: '';
            //     }
            //
            //     #headerandfooter_footer_${this.pageUniqueId}{
            //     height: ${this.footerCropHeight}px !important;
            //     border-top: 1px solid #ccc;
            //     }
            //
            //     `;
        }
        else {
            styleId = 'pagePropertiesDynamicStyles';
            var $element = this.getPageTypeElement();
            if ($element) {
                getElementId = $($element).attr('data_id');
                if (propertiesType === 'pageBackgroundImage') {
                    dynamicStyle = "[data_id=\"" + getElementId + "\"] {\n                          background-image: url(" + imageUrl + ") !important;\n                          background-color: '';\n                        }";
                }
                else if (propertiesType === 'pageBackgroundColor') {
                    dynamicStyle = "[data_id=\"" + getElementId + "\"] {\n                          background-image: url() !important;\n                          background-color: " + colorCode + " !important;\n                        }";
                }
            }
        }
        var style = $('#cs_editor').contents().find("#" + styleId);
        if (style.length === 0) {
            style = document.createElement('style');
            style.id = styleId;
        }
        else {
            style.remove();
            style = document.createElement('style');
            style.id = styleId;
        }
        style.innerHTML = dynamicStyle;
        if (placeEverythingElement) {
            placeEverythingElement[0].appendChild(style);
        }
    };
    //Purpose: Removes dynamically created style elements by their IDs from the DOM.
    PagePropertiesConfigurationComponent.prototype.removeAllIds = function () {
        var pagePropertiesDynamicStyles = $('#cs_editor').contents().find("#pagePropertiesDynamicStyles");
        var pagePropertiesDynamicStylesHeader = $('#cs_editor').contents().find("#pagePropertiesDynamicStylesHeader");
        var pagePropertiesDynamicStylesFooter = $('#cs_editor').contents().find("#pagePropertiesDynamicStylesFooter");
        if (pagePropertiesDynamicStyles) {
            pagePropertiesDynamicStyles.remove();
        }
        if (pagePropertiesDynamicStylesHeader) {
            pagePropertiesDynamicStylesHeader.remove();
        }
        if (pagePropertiesDynamicStylesFooter) {
            pagePropertiesDynamicStylesFooter.remove();
        }
    };
    PagePropertiesConfigurationComponent.prototype.removeAllImages = function (removeType) {
        var that = this;
        this.confirmationBoxHelper.getConfirmation("Are you sure you want delete this image ?", this.ajsScope)
            .then(function () {
            if (removeType == 'backgroundImage') {
                that.backgroundImageUrl = '';
                that.pagePropertiesForm.get('bgImage').setValue('');
                that.pagePropertiesBackgroundImageRemove = true;
                var pagePropertiesDynamicStyles = $('#cs_editor').contents().find("#pagePropertiesDynamicStyles");
                if (pagePropertiesDynamicStyles) {
                    pagePropertiesDynamicStyles.remove();
                }
                var currentPage = that.getPageTypeElement();
                if (currentPage.style.backgroundImage != '') {
                    that.pagePropertiesBackgroundImageBackup = currentPage.style.backgroundImage.slice(4, -1).replace(/"/g, "");
                    currentPage.style.backgroundImage = '';
                }
                setTimeout(function () {
                    that.dynamicHeight = $('.page-background-section').height() + 9;
                }, 1500);
                // that.fileInput.nativeElement.value = '';
            }
            else if (removeType == 'backgroundHeader') {
                that.pageHeaderImageUrl = '';
                that.pagePropertiesForm.get('headerImage').setValue('');
                that.pagePropertiesBackgroundHeaderRemove = true;
                var pagePropertiesDynamicStylesHeader = $('#cs_editor').contents().find("#pagePropertiesDynamicStylesHeader");
                if (pagePropertiesDynamicStylesHeader) {
                    pagePropertiesDynamicStylesHeader.remove();
                }
                // let $headerandfooter_header = that.getAllHeaderPageElement();
                // if ($headerandfooter_header) {
                //     $($headerandfooter_header).addClass('page-properties-hide-header-image');
                // }
                that.headerCropHeight = 100;
                that.headerError = '';
                that.maxHeaderValue = 200;
                // that.headerImage.nativeElement.value = '';
            }
            else if (removeType == 'backgroundFooter') {
                that.pageFooterImageUrl = '';
                that.pagePropertiesForm.get('footerImage').setValue('');
                that.pagePropertiesBackgroundFooterRemove = true;
                var pagePropertiesDynamicStylesFooter = $('#cs_editor').contents().find("#pagePropertiesDynamicStylesFooter");
                if (pagePropertiesDynamicStylesFooter) {
                    pagePropertiesDynamicStylesFooter.remove();
                }
                // let $headerandfooter_footer = that.getAllFooterPageElement();
                // if ($headerandfooter_footer) {
                //     $($headerandfooter_footer).addClass('page-properties-hide-footer-image');
                // }
                that.footerCropHeight = 100;
                that.footerError = '';
                that.maxFooterValue = 200;
                // that.footerImage.nativeElement.value = '';
            }
        });
    };
    PagePropertiesConfigurationComponent.prototype.getspectRatio = function (ratioType) {
        var cropWidth, cropHeight, keepSpectRatio;
        if (ratioType === 'pageHeader') {
            cropWidth = this.headerCropWidth;
            cropHeight = this.headerCropHeight;
            keepSpectRatio = 'headerKeepSpectRatio';
        }
        else if (ratioType === 'pageFooter') {
            cropWidth = this.footerCropWidth;
            cropHeight = this.footerCropHeight;
            keepSpectRatio = 'footerKeepSpectRatio';
        }
        else {
            return 1;
        }
        if (cropWidth && cropHeight) {
            this[keepSpectRatio] = true;
            return parseInt(cropWidth) / parseInt(cropHeight);
        }
        else {
            return 1;
        }
    };
    PagePropertiesConfigurationComponent.prototype.getFilenameExtension = function (file) {
        var ex = file.name.split('.').pop();
        if (ex === 'jpg')
            ex = 'jpeg';
        // console.log(ex);
        return ex;
    };
    PagePropertiesConfigurationComponent.prototype.onInputChange = function (event, pageType) {
        var _this = this;
        this.imageUploadProgress = false;
        var enteredValue = parseInt(event.target.value, 10);
        if (isNaN(enteredValue)) {
            this.imageUploadProgress = true;
            return;
        }
        var errorPrefix = pageType === 'pageHeader' ? 'Header' : 'Footer';
        var maxAllowedValue = pageType === 'pageHeader' ? this.maxHeaderValue : this.maxFooterValue;
        var errorMessage = '';
        var isValid = true;
        if (enteredValue < this.minValue || enteredValue > 200) {
            errorMessage = "Height must range from 50 to 200 pixels.";
            isValid = false;
        }
        else if (enteredValue > maxAllowedValue) {
            errorMessage = "Height cannot exceed the previously saved value of " + maxAllowedValue + " pixels.";
            isValid = false;
        }
        if (pageType === 'pageHeader') {
            this.headerError = errorMessage;
            if (isValid) {
                this.headerValue = enteredValue;
                this.headerCropHeight = enteredValue;
            }
        }
        else {
            this.footerError = errorMessage;
            if (isValid) {
                this.footerValue = enteredValue;
                this.footerCropHeight = enteredValue;
            }
        }
        if (!isValid)
            return;
        setTimeout(function () {
            if (pageType === 'pageHeader') {
                if (enteredValue < _this.minValue || enteredValue > 200) {
                    _this.headerError = "Height must range from 50 to 200 pixels.";
                }
                else if (enteredValue > _this.maxHeaderValue) {
                    _this.headerError = "Height cannot exceed the previously saved value of " + _this.maxHeaderValue + " pixels.";
                }
                else {
                    _this.headerError = '';
                    _this.headerValue = enteredValue;
                    _this.headerCropHeight = enteredValue;
                }
            }
            else {
                if (enteredValue < _this.minValue || enteredValue > 200) {
                    _this.footerError = "Height must range from 50 to 200 pixels.";
                }
                else if (enteredValue > _this.maxFooterValue) {
                    _this.footerError = "Height cannot exceed the previously saved value of " + _this.maxFooterValue + " pixels.";
                }
                else {
                    _this.footerError = '';
                    _this.footerValue = enteredValue;
                    _this.footerCropHeight = enteredValue;
                }
            }
        }, 100);
    };
    PagePropertiesConfigurationComponent.prototype.setImageUploadQuality = function (fileSize, uploadType) {
        var cropQuality;
        if (parseInt(fileSize) >= 30) {
            cropQuality = 40;
        }
        else if (parseInt(fileSize) >= 20) {
            cropQuality = 50;
        }
        else if (parseInt(fileSize) >= 15) {
            cropQuality = 60;
        }
        else if (parseInt(fileSize) >= 5) {
            cropQuality = 70;
        }
        else {
            cropQuality = 80;
        }
        switch (uploadType) {
            case 'pageBackgroundImage':
                this.cropQuality = cropQuality;
                break;
            case 'pageHeader':
                this.headerCropQuality = cropQuality;
                break;
            case 'pageFooter':
                this.footerCropQuality = cropQuality;
                break;
            default:
                console.warn('Unexpected upload type:', uploadType);
                break;
        }
    };
    PagePropertiesConfigurationComponent.prototype.resizeImage = function (file, maxWidth, maxHeight) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = function (event) {
                var img = new Image();
                img.src = event.target.result;
                img.onload = function () {
                    var canvas = document.createElement('canvas');
                    var width = img.width;
                    var height = img.height;
                    // Calculate new dimensions
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    }
                    else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(canvas.toDataURL('image/jpeg', 0.9));
                };
            };
            reader.onerror = function (error) { return reject(error); };
            reader.readAsDataURL(file);
        });
    };
    PagePropertiesConfigurationComponent.prototype.recievedFromMarginvalue = function (event) {
        this.pagePropertiesForm.get('pageMarginValue').setValue(event);
    };
    PagePropertiesConfigurationComponent.prototype.handleDragEvents = function (element) {
        element.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        element.on('dragover dragenter', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!element.hasClass('blur')) {
                element.find('.upload-popover').removeClass('display-none');
                element.find('.remove-this-class').addClass('upload-input');
                element.find('.ano').addClass('blur');
            }
        });
        element.on('dragleave', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!element.has(e.relatedTarget).length) {
                element.find('.upload-popover').addClass('display-none');
                element.find('.ano').removeClass('blur');
            }
        });
        element.on('drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
            element.find('.pay-in-full').addClass('clickable');
            element.find('.upload-popover').addClass('display-none');
            element.find('.ano').removeClass('blur');
        });
    };
    PagePropertiesConfigurationComponent.prototype.ngAfterViewInit = function () {
        this.handleDragEvents($('#drop-zone'));
        this.handleDragEvents($('#pageHeaderSection'));
        this.handleDragEvents($('#pageFooterSection'));
    };
    PagePropertiesConfigurationComponent.prototype.scrollToBottom = function (scrollType) {
        var getPageElement = this.getPageTypeElement();
        var postData = {
            'pageId': this.pageUniqueId,
            'id': $(getPageElement).attr('id'),
            'pageNumber': getPageElement.id.split('_pgNo_')[1],
            'scrollType': scrollType
        };
        window.top.postMessage({ type: 'headerAndFooter', data: postData }, '*');
    };
    PagePropertiesConfigurationComponent.prototype.toggleHeaderFooter = function () {
        this.bgHedaerChecked = !this.bgHedaerChecked;
    };
    return PagePropertiesConfigurationComponent;
}());
export { PagePropertiesConfigurationComponent };
