import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, AfterViewInit, Inject} from '@angular/core';
declare var _;

@Component({
    selector: 'app-conditional-block',
    templateUrl: './conditional-block.component.html',
    styles: []
})
export class ConditionalBlockComponent implements OnInit, AfterViewInit {

    name: 'conditionalBlock';
    isRequiredDelay:false;
    showDeleteBtn:boolean;
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView: boolean = false;
    @Input() hasChild:boolean = false;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;


    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    conditionalFields:any = [];
    saving: boolean = false

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope) { }

    ngOnInit() {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        //console.log(this.isConditional)
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    ngAfterViewInit() {

    }

    validateItems() {
        if(!this.inputModel.model.col_name ){
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    addValue() {
        /*let selectedItem = _.find(this.inputModel.config.fields, (field) => {
            return field.id == this.inputModel.model['col_name']
        });*/
        this.saving=true;
        let selectedItem:any = this.inputModel.config.fieldsByColName[this.inputModel.model['col_name']];
        //console.log('selectedItem', selectedItem);
        if(selectedItem && selectedItem['fieldType'] && selectedItem['pageType']) {
            this.inputModel.model['fieldType'] = selectedItem['fieldType'];
            this.inputModel.model['pageType'] = selectedItem['pageType'];
        }
        if(selectedItem && selectedItem['templateId']) {
            this.inputModel.model['templateId'] = selectedItem['templateId'];
        }
        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0)
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }
}

