import {Component, ElementRef, OnInit, ViewChild,ViewChildren, HostListener, Inject} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";
import {
    ProposalTemplateService
} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {Router} from "@angular/router";
import {TimelineService} from "@app/shared/services/timeline.service";
import {AuthService} from "@app/core";
import {Subject} from "rxjs";
import {FileUploader} from "ng2-file-upload";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {FormBuilder, FormGroup} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import { BlockSettingsModalComponent } from '../block-settings-modal/block-settings-modal.component';
import { ImageCropperComponent} from "ngx-image-cropper";
import {SidepanelService} from "@app/sidepanel/services/sidepanel.service";

@Component({
    selector: 'app-page-properties-configuration',
    templateUrl: './page-properties-configuration.component.html',
    styleUrls: ['./page-properties-configuration.component.scss']
})
export class PagePropertiesConfigurationComponent implements OnInit {
    public onClose: Subject<any>;
    public uploader: FileUploader;
    @ViewChild(BlockSettingsModalComponent) blockSettingModal;
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    option: any
    title: string;
    pageBackground: any = 'color';
    backgroundColor: any;
    backgroundColorDisplay: any = '#ffffff';
    pageBackgroundColor: any = 'No background';
    pagePropertiesImageblock: boolean = false;
    restoreBgColor: any = '';
    backgroundImg: any;
    backgroundImageUrl: any;
    backgroundImageCroppedImage: any = '';
    isUploadPdfPage: boolean = false;
    disabled: boolean = false;
    myValue: any;
    pageUniqueId: any;
    imagePath: any;
    errorMsg: string = '';
    checked: any;
    open: boolean;
    commonCroppedImageShowing: any = '';
    templateId: any;
    backgroundImageList: any;
    setAllPageBackground: boolean = false;
    bgColorChecked: boolean = false;
    bgImageChecked: boolean = false;
    bgHedaerChecked: boolean = false;
    bgFooterChecked: boolean = false;
    @ViewChild('section1') section1: ElementRef<HTMLInputElement>;
    @ViewChild('section2') section2: ElementRef<HTMLInputElement>;
    @ViewChild('section3') section3: ElementRef<HTMLInputElement>;
    fileErrorMsg: any;
    showBackgroundImageError: any;
    showBackgroundHeaderError: any;
    showBackgroundFooterError: any;
    correctSizeAndFormat: Array<any> = [];
    wrongSizeAndFormat: Array<any> = [];
    pageBackgroundImageLocation: any;
    pagePropertiesBgImageSelectedPage: any;
    pagePropertiesLayoutStyle: any = 'dont_repeat';
    backgroundImgfileSelected: boolean = false;
    shimmerLoadingEnabled: boolean = false;
    //pageloyout
    oldPagePropoertiesLayout: any;
    oldPagePropertiesBackgroundDetails: any;
    //headerandfooter
    pageHeaderImage: any;
    pageHeaderImageUrl: any;
    pagePropertiesHeaderCroppedImage: any;
    pageHeaderImageBackupImage:any;
    pageFooterImageBackupImage:any;
    headerFileSelected: boolean = false;
    oldPagePropertiesHeaderDetails: any;
    setOldPageData = false;
    pageHeaderImageLocation: any;
    //footer logic
    pageFooterImage: any;
    pageFooterImageUrl: any;
    pagePropertiesFooterCroppedImage: any;
    oldPagePropertiesFooterDetails: any;
    footerFileSelected: boolean = false;
    pageFooterImageLocation: any;
    pagePropertiesForm: FormGroup;
    pagePropertiesHeaderFooterSelectedPage: any = [];
    //condition
    firstTimeUserUploadImage: boolean = false;
    imageUploadProgress: boolean = false;
    sidepanelOpen = true;
    pageType :any;
    dataToPass: any = '';
    //remove Logic
    pagePropertiesBackgroundImageRemove: boolean = false;
    pagePropertiesBackgroundHeaderRemove: boolean = false;
    pagePropertiesBackgroundFooterRemove: boolean = false;
    pagePropertiesBackgroundImageBackup = '';


    coverPage: boolean = false;
    id:any;
    @ViewChild('fileInput') fileInput;
    @ViewChild('headerImage') headerImage;
    @ViewChild('footerImage') footerImage;
    @ViewChildren(ImageCropperComponent) imageCroppers: any;
    cropType = 'jpeg';
    cropQuality :any = 60;
    headerCropQuality :any = 60;
    footerCropQuality :any = 60;
    headerCropWidth :any = 1200;
    headerCropHeight :any = 100;
    headerCroppedImages = [];
    headerCroppedFiles = [];
    headerKeepSpectRatio = false;

    footerCropWidth :any = 1200;
    footerCropHeight :any = 100;
    footerCroppedImages = [];
    footerCroppedFiles = [];
    footerKeepSpectRatio = false;
    // passDynamicClass = "page-properties-color-palate"

    pageHeaderHeight;
    pageFooterHeight;
    dynamicHeight:any;
    countryType:any;

    screenType: string = '';
    optionId: string = '';
    proposalTemplateId: any;
    opportunityProposalId: any;
    opportunityId:any;

    headerValue: number;
    footerValue: number;
    minValue: number = 50;
    maxValue: number = 200;
    maxHeaderValue: number = 200;
    maxFooterValue: number = 200;
    headerError: string;
    footerError: string;
    setAllPagesProperties:any = {
        'setAllPage':  '',
        'headerImageUrl':  '',
        'headerImageHeight':  '',
        'footerImageUrl':  '',
        'footerImageHeight':  '',
        'pageColorOrBackgroundImage': '',
        'style' : ''
    };
    constructor(public bsModalRef: BsModalRef,
                public proposaltemplateservice: ProposalTemplateService,
                private router: Router,
                private fb: FormBuilder,
                private ts: TimelineService,
                @Inject('confirmationBoxHelper') private confirmationBoxHelper,
                @Inject('$scope') private ajsScope,
                private sidepanelService: SidepanelService,
                private authService: AuthService) {

        this.sidepanelService.pagePropertiesSidePanel.subscribe((modalStatus: any) => {
            if(modalStatus) {
                this.cancel();
            }
        });

        this.proposaltemplateservice.pagePropertiesUpdates$.subscribe((data: any) => {
                if(data) {
                    this.setAllPagesProperties = JSON.parse(data);
                }
        });

    }

    ngOnInit() {
        this.buildFormPagePropertiesForm();
        this.onClose = new Subject();
        this.uploader = new FileUploader({
            url: ``,
            autoUpload: false,
            itemAlias: 'file',
            allowedMimeType: ['image/png', 'image/jpeg'],
            disableMultipart: true
        });

        if(this.setAllPagesProperties.hasOwnProperty('setAllPage') && this.setAllPagesProperties.setAllPage){
            this.setAllPageBackground = this.setAllPagesProperties.setAllPage;
        }else{
            this.setAllPageBackground = this.setAllPagesProperties.setAllPage;
        }
        
        if($('#cs_editor').contents().find(this.id).attr('data-page-type') == 'EHP'){
            this.coverPage = true;
        } else {
            this.coverPage = false;
        }

        if (this.option == 'pageBackground') {
            this.backgroundImageList = this.backgroundImageList;

            if (this.backgroundColor) {
                if (this.backgroundColor && this.backgroundColor.includes('rgb')) {
                    this.pageBackgroundColor = this.rgbToHex(this.backgroundColor);
                    this.pagePropertiesForm.get('bgColor').setValue(this.pageBackgroundColor);
                }
                this.pageBackground = 'color';
                this.bgColorChecked = true;
                this.pagePropertiesImageblock = false;

            } else {
                this.backgroundImageUrl = ''
                this.backgroundColorDisplay = '#ffffff';
            }

            if (this.backgroundImg && this.backgroundImg != "none") {
                this.pageBackground = 'image';
                this.bgImageChecked = true;
                this.pagePropertiesImageblock = true;
                this.backgroundImageUrl = this.backgroundImg.slice(4, -1).replace(/"/g, "");
                this.backgroundImageCroppedImage = this.backgroundImageUrl
                this.pagePropertiesLayoutStyle = this.oldPagePropoertiesLayout;
                this.oldPagePropertiesBackgroundDetails = {
                    'layoutStyle': this.oldPagePropoertiesLayout,
                    'backgroundImageUrl': this.backgroundImageCroppedImage
                }
                this.isUploadPdfPage = this.isUploadPdfPage;

            } else {
                this.backgroundImageCroppedImage = ''
                this.pagePropertiesLayoutStyle = 'dont_repeat';
            }

            if (this.pageHeaderImage && this.pageHeaderImage != 'none' && this.pageHeaderImage.length) {
                this.pageHeaderImageUrl = this.pageHeaderImage[0];
                if (this.pageHeaderHeight[0] > 0){
                    this.headerCropHeight = this.pageHeaderHeight[0];
                    this.maxHeaderValue = parseInt(this.headerCropHeight);
                }
                this.pageHeaderImageBackupImage = this.pageHeaderImageUrl;
                this.pagePropertiesHeaderCroppedImage = this.pageHeaderImageUrl;
                this.oldPagePropertiesHeaderDetails = this.pagePropertiesHeaderCroppedImage;

            } else {
                this.pageHeaderImageUrl = '';
                this.pagePropertiesHeaderCroppedImage = '';
                this.oldPagePropertiesHeaderDetails = '';
            }

            if (this.pageFooterImage && this.pageFooterImage != 'none' && this.pageFooterImage.length) {
                this.pageFooterImageUrl = this.pageFooterImage[0];
                if (this.pageFooterHeight[0] > 0){
                    this.footerCropHeight = this.pageFooterHeight[0];
                    this.maxFooterValue = parseInt(this.footerCropHeight);
                }
                this.pageFooterImageBackupImage = this.pageFooterImageUrl;
                this.pagePropertiesFooterCroppedImage = this.pageFooterImageUrl;
                this.oldPagePropertiesFooterDetails = this.pagePropertiesFooterCroppedImage;

            } else {
                this.pageFooterImage = '';
                this.pagePropertiesFooterCroppedImage = '';
                this.oldPagePropertiesFooterDetails = '';
            }

            if (this.pageHeaderImage.length || this.pageFooterImage.length) {
                this.bgHedaerChecked = true;
            } else {
                this.bgHedaerChecked = false;
            }

            this.restoreBgColor = this.backgroundColor;
            let bgColor: any = {
                bgColor: this.restoreBgColor
            }
            $('#cs_editor').contents().find('div.cs_selected').find('.cs_margin').attr('old-bgclr', bgColor);

        }
    }


    // Purpose: Converts an RGB color string to its corresponding hexadecimal representation.
    // If the input is "rgb(255, 255, 255)" (white), it returns 'No Background'.
    rgbToHex(rgb: string): string {
        let noBackground = "";
        if (rgb == "rgb(255, 255, 255)") {
            noBackground = 'No Background'
            return noBackground;
        }
        const [r, g, b] = rgb.match(/\d+/g)!.map(Number);
        const hex = `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
        return hex.toUpperCase();
    }

    // Purpose: Updates properties related to the page background based on the selected option.
    // Parameters: - option: String representing the selected background option ('color' or 'image').
    backgroundOption(option) {
        this.backgroundImgfileSelected = false;
        if (option == 'color') {
            this.disabled = true;
            this.pageBackground = 'color';
            this.bgColorChecked = !this.bgColorChecked;
            this.bgImageChecked = false;
            this.pagePropertiesForm.get('bgImage').setValue('');
        } else if (option == "image") {
            this.disabled = true;
            this.pageBackground = 'image';
            this.bgImageChecked = !this.bgImageChecked;
            this.bgColorChecked = false;
            this.pagePropertiesForm.get('bgColor').setValue('');
            setTimeout(() => {
                this.dynamicHeight  = $('.page-background-section').height()+9;
            }, 1500);
        }
    }

    // Purpose: Updates a class variable ('myValue') after a specified delay.
    // Parameters: - data: The value to be assigned to 'myValue'.
    detectChanges(data) {
        let that = this;
        this.myValue = ''
        setTimeout(() => {
            that.myValue = data;
        }, 50);
    }

    // Purpose: Updates the page background color and triggers additional actions based on the specified type.
    // Parameters:- data: The new color value.
    updateColor(data, type) {
        this.pageBackgroundColor = data;
        this.pagePropertiesForm.get('bgColor').setValue(this.pageBackgroundColor);
        if (type === 'pageBackgroundColor') {
            const pageTypeElement: any = this.getPageTypeElement();
            const pageTypeClass = pageTypeElement.getAttribute('data-page-type') === 'EHP' ? 'cs_selected' : 'cs_selected_border';
            if (!$(pageTypeElement).hasClass(pageTypeClass)) {
                $(pageTypeElement).addClass(pageTypeClass);
            }
            // $(pageTypeElement).css('background-image', '');
            this.renderingStylesDom('', this.pageUniqueId, this.pageBackgroundColor, 'pageBackgroundColor');
        }
    }

    // Purpose: Retrieves the page type element based on the current page ID.
    private getPageTypeElement(): any {
        return $('#cs_editor').contents().find(`div[data_id=${this.pageUniqueId}]`)[0];
    }

    private getAllHeaderPageElement() {
        return $('#cs_editor').contents().find(`#headerandfooter_header_${this.pageUniqueId}`);
    }

    private getAllFooterPageElement() {
        return $('#cs_editor').contents().find(`#headerandfooter_footer_${this.pageUniqueId}`);
    }

    // Purpose: Handles the event when an image is cropped, updating data and UI elements accordingly.
    // Parameters:
    //   - event: ImageCroppedEvent containing information about the cropped image.
    //   - resizeType: Type of image being cropped ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    imageCropped(event: ImageCroppedEvent, resizeType: string): void {
        if (!this.setOldPageData) {
            this.updateOldPageData(resizeType, event.base64);
            this.setOldPageData = true;
        }
        let heightMaximum = 50;
        if (resizeType == 'pageHeader'){
            heightMaximum = this.headerCropHeight;
            if (event.width > 1920) {
                this.headerCropWidth = '1920';
                this.headerCropHeight = Math.round((event.height / event.width) * 1920) + '';
            }

        }else if (resizeType == 'pageFooter'){
            heightMaximum = this.footerCropHeight;
            if (event.width > 1920) {
                this.footerCropWidth = '1920';
                this.footerCropHeight = Math.round((event.height / event.width) * 1920) + '';
            }
        }

        if (heightMaximum <= 200){
            this.updateCroppedImage(resizeType, event.base64);
            this.showTempImage(resizeType);
            this.sidepanelOpen = false;
        }
    }

    imageLoaded(){
        //if you want image loading time use this methods
    }

    cropperReady(){
        this.imageUploadProgress = false;
    }


    // Purpose: Updates Old page data based on the type of image being cropped.
    // Parameters:
    //   - resizeType: Type of image being cropped ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    //   - base64: The base64 representation of the cropped image.
    private updateOldPageData(resizeType: string, base64: string): void {
        if (resizeType === 'pageBackgroundImage' && this.oldPagePropertiesBackgroundDetails && this.oldPagePropertiesBackgroundDetails.hasOwnProperty('backgroundImageUrl')) {
            this.oldPagePropertiesBackgroundDetails.backgroundImageUrl = base64;
        } else if (resizeType === 'pageHeader' && this.oldPagePropertiesHeaderDetails !== '') {
            this.oldPagePropertiesHeaderDetails = base64;
        } else if (resizeType === 'pageFooter' && this.oldPagePropertiesFooterDetails !== '') {
            this.oldPagePropertiesFooterDetails = base64;
        }
    }

    // Purpose: Updates the cropped image data and corresponding form control.
    // Parameters:
    //   - resizeType: Type of image being cropped ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    //   - base64: The base64 representation of the cropped image.
    private updateCroppedImage(resizeType: string, base64: string): void {
        if (resizeType === 'pageBackgroundImage') {
            this.backgroundImageCroppedImage = base64;
            this.pagePropertiesForm.get('bgImage').setValue(this.backgroundImageCroppedImage);
        } else if (resizeType === 'pageHeader') {
            this.pagePropertiesHeaderCroppedImage = base64;
            this.pagePropertiesForm.get('headerImage').setValue(this.pagePropertiesHeaderCroppedImage);
        } else if (resizeType === 'pageFooter') {
            this.pagePropertiesFooterCroppedImage = base64;
            this.pagePropertiesForm.get('footerImage').setValue(this.pagePropertiesFooterCroppedImage);
        }
    }

    // Purpose: Displays a temporary image on the UI based on the provided type (pageBackgroundImage, pageHeader, or pageFooter).
    // Parameters:
    //   - resizeType: Type of image being displayed ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    private showTempImage(resizeType: string): void {
        const croppedImage = resizeType === 'pageBackgroundImage' ? this.backgroundImageCroppedImage : resizeType === 'pageHeader' ? this.pagePropertiesHeaderCroppedImage : this.pagePropertiesFooterCroppedImage;
        this.showingTempImageOnEditor(croppedImage, resizeType);
    }

    // Purpose: Requests a temporary image from the server and updates relevant UI elements.
    showingTempImageOnEditor(croppedImage, showingType) {
        const setImageAndRender = (locationKey: string, showingType: string) => {
            if (croppedImage) {
                this[locationKey] = croppedImage;
                this.renderingStylesDom(this[locationKey], this.pageUniqueId, '', showingType);
                if (showingType === 'pageBackgroundImage') {
                    this.pagePropertiesBgImageSelectedPage = this.getPageTypeElement();
                    this.pagePropertiesBgImageLayouts(this.pagePropertiesLayoutStyle);
                }
            }
        };
        if (showingType === 'pageBackgroundImage') {
            setImageAndRender('pageBackgroundImageLocation', 'pageBackgroundImage');
        } else if (showingType === 'pageHeader') {
            setImageAndRender('pageHeaderImageLocation', 'pageHeader');
            // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
        } else if (showingType === 'pageFooter') {
            setImageAndRender('pageFooterImageLocation', 'pageFooter');
            // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
        }

    }

    pagePropertiesBgImageLayouts(layoutValue) {
        const selectedValue = layoutValue;
        this.pagePropertiesLayoutStyle = selectedValue;

        switch (selectedValue) {
            case 'dont_repeat':
                this.setPagePropertiesBgImageStyles('no-repeat', 'cover');
                break;
            case 'repeat_vertically':
                this.setPagePropertiesBgImageStyles('repeat', 'contain');
                break;
            case 'repeat_vertically_horizontally':
                this.setPagePropertiesBgImageStyles('repeat', 'auto');
                break;
            case 'repeat':
                this.setPagePropertiesBgImageStyles('no-repeat', 'cover');
                break;
        }

    }

    private setPagePropertiesBgImageStyles(repeatValue: string, sizeValue: string): void {
        $(this.pagePropertiesBgImageSelectedPage).css({
            backgroundRepeat: repeatValue,
            backgroundSize: sizeValue,
            backgroundColor: ''
        });
    }


    // Purpose: Handles the upload of a page background image and triggers additional actions.
    pagebackgroundImageUpload(event: any): void {
        $('.drag-zone-animation-section').css('display','none');
        this.imageUploadProgress = true;
        this.firstTimeUserUploadImage = true;
        this.handleFileUpload(event, 'pageBackgroundImage', (location) => {
            this.pageBackgroundImageLocation = location;
            this.pagePropertiesBgImageSelectedPage = this.getPageTypeElement();
            this.pagePropertiesBgImageLayouts(this.pagePropertiesLayoutStyle);
        });
    }

    // Purpose: Handles the file upload process, checks file size and format, and updates UI elements accordingly.
    // Parameters:
    //   - event: File input change event.
    //   - uploadType: Type of image being uploaded ('pageBackgroundImage', 'pageHeader', or 'pageFooter').
    //   - onSuccess: Callback function to handle successful file upload.

    handleFileUpload(event: any, uploadType: string, onSuccess: (location: string) => void): void {
        if (uploadType == 'pageBackgroundImage') {
            this.backgroundImgfileSelected = true;
        } else if (uploadType == 'pageHeader') {
            this.headerFileSelected = true;
        } else if (uploadType == 'pageFooter') {
            this.footerFileSelected = true;
        }
        // event.preventDefault();
        // const files: any = (event.type === 'change' && event.isTrusted) ? (event.target as HTMLInputElement).files : event;
        let files:any = [];
        if (event.type === 'change' && event.isTrusted) {
            files = event.target.files;
        } else {
            files = [event[0]];
        }
        const originalFormatData: File[] = [];
        this.fileErrorMsg = false;
        // this.backgroundImageUrl = false;
        // this.pageHeaderImageUrl = false;
        this.correctSizeAndFormat = [];
        this.wrongSizeAndFormat = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const checkMaxFileSize = (file.size / 1024 / 1024).toFixed(2);
            const fileType = this.ts.getFileGroup(file.name.substr(file.name.lastIndexOf('.') + 1));
            if (parseInt(checkMaxFileSize) <= 30 && fileType !== 'unknown') {
                originalFormatData.push(file);
                this.correctSizeAndFormat.push({
                    'name': file.name,
                    'size': (file.size / 1024).toFixed(2),
                    'type': fileType
                });
            } else {
                this.wrongSizeAndFormat.push({
                    'name': file.name,
                    'size': (file.size / 1024).toFixed(2),
                    'type': fileType
                });
            }
        }

        if (this.correctSizeAndFormat.length) {

            setTimeout(() => {
                const reader = new FileReader();
                this.imagePath = files;
                reader.readAsDataURL(files[0]);
                reader.onload = (_event) => {
                    if (uploadType == 'pageBackgroundImage') {
                        const fileSizeQuality = (files[0].size / 1024 / 1024).toFixed(2);

                        this.resizeImage(files[0], 816, 1120).then(resizedImage => {
                            // Use the resized image data (base64) here
                            // console.log('Resized image:', resizedImage);
                            this.backgroundImageUrl = resizedImage;
                        }).catch(error => {
                            console.error('Error resizing image:', error);
                        });

                        this.setImageUploadQuality(fileSizeQuality,uploadType);
                        this.pagePropertiesBackgroundImageRemove = false;
                        this.backgroundImageCroppedImage = this.backgroundImageUrl;
                        // this.commonCroppedImageShowing = this.backgroundImageCroppedImage
                    } else if (uploadType == 'pageHeader') {
                        const fileSizeQuality = (files[0].size / 1024 / 1024).toFixed(2);
                        this.setImageUploadQuality(fileSizeQuality,uploadType);
                        this.headerKeepSpectRatio = false;
                        this.headerCroppedFiles = [];
                        this.headerCroppedImages = [];
                        this.resizeImage(files[0], 816, 1120).then(resizedImage => {
                            // Use the resized image data (base64) here
                            // console.log('Resized image:', resizedImage);
                            this.pageHeaderImageUrl = resizedImage;
                        }).catch(error => {
                            console.error('Error resizing image:', error);
                        });
                        this.pagePropertiesBackgroundHeaderRemove = false;
                        // this.pageHeaderImageUrl = reader.result;
                        this.pagePropertiesHeaderCroppedImage = this.pageHeaderImageUrl;
                        // let currentPage = this.getAllHeaderPageElement();
                        // if (currentPage.hasClass('page-properties-hide-header-image')) {
                        //     $(currentPage).removeClass('page-properties-hide-header-image')
                        // }
                        this.scrollToBottom('pageHeader');
                        // this.commonCroppedImageShowing = this.pagePropertiesHeaderCroppedImage
                    } else if (uploadType == 'pageFooter') {
                        const fileSizeQuality = (files[0].size / 1024 / 1024).toFixed(2);
                        this.setImageUploadQuality(fileSizeQuality,uploadType);
                        this.footerKeepSpectRatio = false;
                        this.footerCroppedFiles = [];
                        this.footerCroppedImages = [];
                        this.resizeImage(files[0], 816, 1120).then(resizedImage => {
                            // Use the resized image data (base64) here
                            // console.log('Resized image:', resizedImage);
                            this.pageFooterImageUrl = resizedImage;
                        }).catch(error => {
                            console.error('Error resizing image:', error);
                        });
                        /*Array.from(event.target.files).forEach((file: any) => {
                            this.footerCroppedFiles.push({
                                ext: this.getFilenameExtension(file),
                                file: file,
                                type: file.type,
                            });
                        });*/
                        this.pagePropertiesBackgroundFooterRemove = false;
                        // this.pageFooterImageUrl = reader.result;
                        this.pagePropertiesFooterCroppedImage = this.pageFooterImageUrl;
                        let currentPage = this.getAllFooterPageElement();
                        // if (currentPage.hasClass('page-properties-hide-footer-image')) {
                        //     $(currentPage).removeClass('page-properties-hide-footer-image')
                        // }
                        this.scrollToBottom('pageFooter');
                        // this.commonCroppedImageShowing = this.pagePropertiesFooterCroppedImage
                    }
                    this.firstTimeUserUploadImage = true;
                    this.backgroundImgfileSelected = false;
                    this.headerFileSelected = false;
                    this.footerFileSelected = false;
                    this.sidepanelOpen = false;
                    event.target.value = '';
                    // this.showingTempImage(this.commonCroppedImageShowing,uploadType);
                };
            }, 4000);
        } else {
            if (this.wrongSizeAndFormat && this.wrongSizeAndFormat.length) {
                this.fileErrorMsg = true;
                if (uploadType === 'pageBackgroundImage') {
                    this.backgroundImageUrl = false;
                    this.backgroundImgfileSelected = false;
                    this.showBackgroundImageError = true;
                }else if (uploadType == 'pageHeader') {
                    this.showBackgroundHeaderError = true;
                } else if (uploadType == 'pageFooter') {
                    this.showBackgroundFooterError = true;
                }
            }
        }
    }

    selectImage(event) {
        this.imageUploadProgress = true;
        this.shimmerLoadingEnabled = true;
        this.backgroundImageUrl = '';
        if (event.target && event.target.src) {
            this.errorMsg = ''
            setTimeout(() => {
                this.backgroundImageUrl = event.target.src
                this.backgroundImageCroppedImage = this.backgroundImageUrl
                this.shimmerLoadingEnabled = false;
            }, 1200);
        }
    }

    cancel(): void {
        this.removeAllIds();
        this.blockSettingModal.cancel();
        if (this.pagePropertiesBackgroundImageBackup != null && this.pagePropertiesBackgroundImageBackup != '') {
            let currentPage = this.getPageTypeElement()
            const styles = {
                backgroundImage: `url(${this.pagePropertiesBackgroundImageBackup})`,
            };
            $(currentPage).css(styles);
        }

        const placeEverythingElement: any = $('#cs_editor').contents().find('#place_everything');
        let findBlockHeader = $(placeEverythingElement).find(`#headerandfooter_header_${this.pageUniqueId}`);
        let findBlockFooter = $(placeEverythingElement).find(`#headerandfooter_footer_${this.pageUniqueId}`);
        let findImageBlockHeader :any = $(findBlockHeader).find('img').get(0);
        let findImageBlockFooter :any = $(findBlockFooter).find('img').get(0);
        if(this.pageHeaderImageBackupImage != null && this.pageHeaderImageBackupImage != ''){
            findImageBlockHeader.setAttribute('src',`${this.pageHeaderImageBackupImage}`);
            findImageBlockHeader.setAttribute('height','');
            findImageBlockHeader.setAttribute('width',this.countryType === 'unitedkingdom' ? 792 : 814)
            findBlockHeader.css({
                "background-image": "",
                "background-repeat": "",
                "background-size": "",
                "background-position": "",
                "height": ""
            });
        }else{
            findImageBlockHeader.setAttribute('src',``);
            findImageBlockHeader.setAttribute('height','');
            findImageBlockHeader.setAttribute('width','');
        }

        if(this.pageFooterImageBackupImage != null && this.pageFooterImageBackupImage != ''){
            findImageBlockFooter.setAttribute('src',`${this.pageFooterImageBackupImage}`);
            findImageBlockFooter.setAttribute('height','');
            findImageBlockHeader.setAttribute('width',this.countryType === 'unitedkingdom' ? 792 : 814)
            findBlockFooter.css({
                "background-image": "",
                "background-repeat": "",
                "background-size": "",
                "background-position": "",
                "height": ""
            });
        }else{
            findImageBlockFooter.setAttribute('src',``);
            findImageBlockFooter.setAttribute('height','');
            findImageBlockFooter.setAttribute('width','');
        }


        // if (this.pagePropertiesBackgroundHeaderRemove) {
        //     let allHeaderPage = this.getAllHeaderPageElement();
        //     if (allHeaderPage) {
        //         $(allHeaderPage).removeClass('page-properties-hide-header-image');
        //     }
        // }
        // if (this.pagePropertiesBackgroundFooterRemove) {
        //     let allFooterPage = this.getAllFooterPageElement();
        //     if (allFooterPage) {
        //         $(allFooterPage).removeClass('page-properties-hide-footer-image');
        //     }
        // }

        if (this.option === 'pageBackground' && (this.pageBackground === 'color' || this.pageBackground === 'image')) {
            let pageType = this.getPageTypeElement();
            let selectedElement = $('#cs_editor').contents().find('div.cs_selected');
            let selectedBorderStyle = $('#cs_editor').contents().find('div.cs_selected_border');
            if (pageType.getAttribute('data-page-type') === 'EHP') {
                $(selectedElement).css('background-color', this.restoreBgColor);
                $(selectedElement).find('.cs_margin').removeAttr('old-bgclr');
            } else {
                $(selectedBorderStyle).css('background-color', this.restoreBgColor);
                $(selectedBorderStyle).find('.cs_margin').removeAttr('old-bgclr');
            }
            /*if (!this.sidepanelOpen){
                if (this.oldPagePropertiesBackgroundDetails) {
                    this.restorePageBackgroundDetails();
                }
                if (this.oldPagePropertiesHeaderDetails) {
                    this.restoreHeaderFooterDetails();
                }
            }*/
        }

        this.bsModalRef.hide();
    }

    // Purpose: Handles the upload of page header or footer image and triggers additional actions.
    pageHeaderFooterImageUpload(event: any, uploadType: string): void {
        this.firstTimeUserUploadImage = true;
        this.imageUploadProgress = true;
        if (uploadType == 'pageHeader') {
            this.handleFileUpload(event, 'pageHeader', (location) => {
                this.pageHeaderImageLocation = location;
                // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
            });
        } else if (uploadType == 'pageFooter') {
            this.handleFileUpload(event, 'pageFooter', (location) => {
                this.pageFooterImageLocation = location;
                // this.pagePropertiesHeaderFooterSelectedPage = this.getHeaderFooterElement();
            });
        }

    }

    // Purpose: Builds the form group for page properties using the FormBuilder.
    buildFormPagePropertiesForm() {
        this.pagePropertiesForm = this.fb.group({
            bgColor: [''],
            bgImage: [''],
            headerImage: [''],
            footerImage: [''],
            pageMarginValue : [''],
        });
    }

    onSubmit() {
        this.imageUploadProgress = true;
        this.dataToPass = "datapassbyparent"
        const formData = this.pagePropertiesForm.value;
        const observables = [];
        const responsesMap = {};
        if(!this.coverPage){
            this.blockSettingModal.save(this.setAllPageBackground);
        }
        if (formData.bgColor && formData.bgColor != 'No Background') {
            observables.push(this.proposaltemplateservice.saveBackgroundColor(formData.bgColor).pipe(
                tap(response => responsesMap['backgroundColor'] = response)
            ));
        }
        if (formData.bgImage) {
            observables.push(this.proposaltemplateservice.addPageImages(formData.bgImage, this.pageUniqueId).pipe(
                tap(response => responsesMap['backgroundImage'] = response)
            ));
        }
        if (formData.headerImage) {
            observables.push(this.proposaltemplateservice.pagePropertiesBackgroundImage(formData.headerImage, this.pageUniqueId, 'saveHeaderFooterImage','header').pipe(
                tap(response => responsesMap['header'] = response)
            ));
        }
        if (formData.footerImage) {
            observables.push(this.proposaltemplateservice.pagePropertiesBackgroundImage(formData.footerImage, this.pageUniqueId, 'saveHeaderFooterImage','footer').pipe(
                tap(response => responsesMap['footer'] = response)
            ));
        }
        if (this.setAllPageBackground) {
            observables.push(this.proposaltemplateservice.addPageImages(formData.bgImage, this.pageUniqueId).pipe(
                tap(response => responsesMap['setBackgroundAllPages'] = response)
            ));
        }
        if (observables.length == 0) {
            const closeModalData = this.createCloseModalData('');
            this.emitCloseModalData(closeModalData);
        } else {
            forkJoin(observables).subscribe(
                (responses) => {
                    const closeModalData = this.createCloseModalData(responsesMap);
                    this.emitCloseModalData(closeModalData);
                    this.imageUploadProgress = false;
                },
                (error) => {
                    console.error('Error in API calls:', error);
                }
            );
        }
        // if(this.screenType != 'content_library'){
        //     let data = {};
        //     if(this.screenType == 'option_template'){
        //         data = {
        //             screenType: this.screenType,
        //             id: this.optionId,
        //             addon: false
        //         }
        //     } else if (this.screenType == 'proposal_templates') {
        //         data = {
        //             screenType: this.screenType,
        //             id: this.proposalTemplateId,
        //             addon: false
        //         }
        //     } else if (this.screenType == 'opportunity') {
        //         data = {
        //             screenType: this.screenType,
        //             id: this.opportunityProposalId,
        //             addon: false,
        //             opportunityId: this.opportunityId
        //         }
        //     }
        //     this.proposaltemplateservice.upgradeDowngradeFreemiumBlocks(data).subscribe((res: any) => {
        //
        //     })
        // }
    }

    // Purpose: Creates a data object containing information about the changes made during the modal closure.
    // Parameters: - result: The result object obtained from API calls.
    private createCloseModalData(result: any): any {
        const closeModalData = {
            'setAllPageBackground': this.setAllPageBackground,
            'pagePropertiesLayout': this.pagePropertiesLayoutStyle,
            'savePagePropertiesBackgroundColor': false,
            'savePagePropertiesBackgroundImage': false,
            'savePagePropertiesHeader': false,
            'savePagePropertiesFooter': false,
            'pageBackgroundColor': '',
            'pagebackgroundImage': '',
            'pageHeaderImage': '',
            'pageFooterImage': '',
            'pageHeaderHeight': this.headerCropHeight,
            'pageFooterHeight': this.footerCropHeight,
            'pagePropertiesBackgroundImageRemove': this.pagePropertiesBackgroundImageRemove,
            'pagePropertiesBackgroundHeaderRemove': this.pagePropertiesBackgroundHeaderRemove,
            'pagePropertiesBackgroundFooterRemove': this.pagePropertiesBackgroundFooterRemove,
            'pageMarginvalue' : this.pagePropertiesForm.get('pageMarginValue'),
        };

        if (result.backgroundColor && result.backgroundColor.hasOwnProperty('backgroundColor')) {
            closeModalData.pageBackgroundColor = result.backgroundColor.backgroundColor;
            closeModalData.savePagePropertiesBackgroundColor = true;
        }

        const setImageData = (type: string, locationKey: string, saveKey: string) => {
            if (result[type] && result[type].hasOwnProperty('file')) {
                closeModalData[locationKey] = result[type].file.location;
                closeModalData[saveKey] = true;
            }
        };

        setImageData('backgroundImage', 'pagebackgroundImage', 'savePagePropertiesBackgroundImage');
        setImageData('header', 'pageHeaderImage', 'savePagePropertiesHeader');
        setImageData('footer', 'pageFooterImage', 'savePagePropertiesFooter');

        return closeModalData;
    }

    private emitCloseModalData(closeModalData: any): void {
        this.onClose.next(closeModalData);
        this.removeAllIds();
        this.disabled = false;
        this.bsModalRef.hide();
    }

    // Purpose: Updates the CSS styles dynamically for page elements based on the provided image URL and propertiesType.
    // Parameters:
    //   - imageUrl: The URL of the image to be applied as a background.
    //   - pageId: The ID of the page element.
    //   - colorCode: The color code (used when propertiesType is 'pageBackgroundColor').
    //   - propertiesType: Type of properties being updated ('pageBackgroundImage', 'pageBackgroundColor', 'pageHeader', or 'pageFooter').
    renderingStylesDom(imageUrl, pageId, colorCode, propertiesType = '') {
        let styleId = '';
        let getElementId = '';
        let dynamicStyle = '';
        const placeEverythingElement: any = $('#cs_editor').contents().find('#place_everything');
        if (propertiesType === 'pageHeader') {
            styleId = 'pagePropertiesDynamicStylesHeader';
            let findImageBlock:any = $(placeEverythingElement).find(`#headerandfooter_header_${this.pageUniqueId}`).find('img').get(0)
               findImageBlock.setAttribute('src',`${imageUrl}`);
               findImageBlock.setAttribute('height','');
               findImageBlock.setAttribute('width',this.countryType === 'unitedkingdom' ? 792 : 814)

            // dynamicStyle = `#headerandfooter_header_${this.pageUniqueId} {
            //     background-image: url(${imageUrl}) !important;
            //     background-repeat: no-repeat;
            //     background-color: '';
            //     }
            //
            //     #headerandfooter_header_${this.pageUniqueId} {
            //         height: ${this.headerCropHeight}px !important;
            //         width: 100%;
            //         border-bottom: 1px solid #ccc;
            //     }
            //
            //     `;
        } else if (propertiesType === 'pageFooter') {
            styleId = 'pagePropertiesDynamicStylesFooter';
            let findImageBlock :any = $(placeEverythingElement).find(`#headerandfooter_footer_${this.pageUniqueId}`).find('img').get(0)
                findImageBlock.setAttribute('src',`${imageUrl}`);
                findImageBlock.setAttribute('height','');
                findImageBlock.setAttribute('width',this.countryType === 'unitedkingdom' ? 792 : 814)

            // dynamicStyle = `#headerandfooter_footer_${this.pageUniqueId} {
            //     background-image: url(${imageUrl}) !important;
            //     background-repeat: no-repeat;
            //     background-color: '';
            //     }
            //
            //     #headerandfooter_footer_${this.pageUniqueId}{
            //     height: ${this.footerCropHeight}px !important;
            //     border-top: 1px solid #ccc;
            //     }
            //
            //     `;
        } else {
            styleId = 'pagePropertiesDynamicStyles';
            let $element = this.getPageTypeElement();
            if ($element) {
                getElementId = $($element).attr('data_id');
                if (propertiesType === 'pageBackgroundImage') {
                    dynamicStyle = `[data_id="${getElementId}"] {
                          background-image: url(${imageUrl}) !important;
                          background-color: '';
                        }`;
                } else if (propertiesType === 'pageBackgroundColor') {
                    dynamicStyle = `[data_id="${getElementId}"] {
                          background-image: url() !important;
                          background-color: ${colorCode} !important;
                        }`;
                }
            }
        }

        let style: any = $('#cs_editor').contents().find(`#${styleId}`);
        if (style.length === 0) {
            style = document.createElement('style');
            style.id = styleId;
        } else {
            style.remove();
            style = document.createElement('style');
            style.id = styleId;
        }

        style.innerHTML = dynamicStyle;


        if (placeEverythingElement) {
            placeEverythingElement[0].appendChild(style);
        }
    }


    //Purpose: Removes dynamically created style elements by their IDs from the DOM.
    removeAllIds() {
        let pagePropertiesDynamicStyles: any = $('#cs_editor').contents().find(`#pagePropertiesDynamicStyles`);
        let pagePropertiesDynamicStylesHeader: any = $('#cs_editor').contents().find(`#pagePropertiesDynamicStylesHeader`);
        let pagePropertiesDynamicStylesFooter: any = $('#cs_editor').contents().find(`#pagePropertiesDynamicStylesFooter`);

        if (pagePropertiesDynamicStyles) {
            pagePropertiesDynamicStyles.remove();
        }
        if (pagePropertiesDynamicStylesHeader) {
            pagePropertiesDynamicStylesHeader.remove();
        }
        if (pagePropertiesDynamicStylesFooter) {
            pagePropertiesDynamicStylesFooter.remove();
        }
    }

    removeAllImages(removeType) {

        let that = this;
        this.confirmationBoxHelper.getConfirmation(`Are you sure you want delete this image ?`, this.ajsScope)
            .then(function () {
                if (removeType == 'backgroundImage') {
                    that.backgroundImageUrl = '';
                    that.pagePropertiesForm.get('bgImage').setValue('');
                    that.pagePropertiesBackgroundImageRemove = true;
                    let pagePropertiesDynamicStyles: any = $('#cs_editor').contents().find(`#pagePropertiesDynamicStyles`);
                    if (pagePropertiesDynamicStyles) {
                        pagePropertiesDynamicStyles.remove();
                    }
                    let currentPage = that.getPageTypeElement()
                    if (currentPage.style.backgroundImage != '') {
                        that.pagePropertiesBackgroundImageBackup = currentPage.style.backgroundImage.slice(4, -1).replace(/"/g, "");
                        currentPage.style.backgroundImage = '';
                    }
                    setTimeout(() => {
                        that.dynamicHeight  = $('.page-background-section').height()+9;
                    }, 1500);
                    // that.fileInput.nativeElement.value = '';

                } else if (removeType == 'backgroundHeader') {
                    that.pageHeaderImageUrl = '';
                    that.pagePropertiesForm.get('headerImage').setValue('');
                    that.pagePropertiesBackgroundHeaderRemove = true;
                    let pagePropertiesDynamicStylesHeader: any = $('#cs_editor').contents().find(`#pagePropertiesDynamicStylesHeader`);
                    if (pagePropertiesDynamicStylesHeader) {
                        pagePropertiesDynamicStylesHeader.remove();
                    }
                    // let $headerandfooter_header = that.getAllHeaderPageElement();
                    // if ($headerandfooter_header) {
                    //     $($headerandfooter_header).addClass('page-properties-hide-header-image');
                    // }
                    that.headerCropHeight = 100;
                    that.headerError = '';
                    that.maxHeaderValue = 200;
                    // that.headerImage.nativeElement.value = '';

                } else if (removeType == 'backgroundFooter') {
                    that.pageFooterImageUrl = '';
                    that.pagePropertiesForm.get('footerImage').setValue('');
                    that.pagePropertiesBackgroundFooterRemove = true;
                    let pagePropertiesDynamicStylesFooter: any = $('#cs_editor').contents().find(`#pagePropertiesDynamicStylesFooter`);
                    if (pagePropertiesDynamicStylesFooter) {
                        pagePropertiesDynamicStylesFooter.remove();
                    }
                    // let $headerandfooter_footer = that.getAllFooterPageElement();
                    // if ($headerandfooter_footer) {
                    //     $($headerandfooter_footer).addClass('page-properties-hide-footer-image');
                    // }
                    that.footerCropHeight = 100;
                    that.footerError = '';
                    that.maxFooterValue = 200;
                    // that.footerImage.nativeElement.value = '';
                }
            });
    }


    getspectRatio(ratioType) {
        let cropWidth, cropHeight, keepSpectRatio;
        if (ratioType === 'pageHeader') {
            cropWidth = this.headerCropWidth;
            cropHeight = this.headerCropHeight;
            keepSpectRatio = 'headerKeepSpectRatio';
        } else if (ratioType === 'pageFooter') {
            cropWidth = this.footerCropWidth;
            cropHeight = this.footerCropHeight;
            keepSpectRatio = 'footerKeepSpectRatio';
        } else {
            return 1;
        }

        if (cropWidth && cropHeight) {
            this[keepSpectRatio] = true;
            return parseInt(cropWidth) / parseInt(cropHeight);
        } else {
            return 1;
        }
    }


    getFilenameExtension(file:any) {
        let ex = file.name.split('.').pop();
        if (ex === 'jpg') ex = 'jpeg';
        // console.log(ex);
        return ex;
    }

    onInputChange(event: any, pageType: string) {
        this.imageUploadProgress = false;
        const enteredValue = parseInt(event.target.value, 10);

        if (isNaN(enteredValue)) {
            this.imageUploadProgress = true;
            return;
        }

        const errorPrefix = pageType === 'pageHeader' ? 'Header' : 'Footer';
        const maxAllowedValue = pageType === 'pageHeader' ? this.maxHeaderValue : this.maxFooterValue;
        let errorMessage = '';
        let isValid = true;

        if (enteredValue < this.minValue || enteredValue > 200) {
            errorMessage = `Height must range from 50 to 200 pixels.`;
            isValid = false;
        } else if (enteredValue > maxAllowedValue) {
            errorMessage = `Height cannot exceed the previously saved value of ${maxAllowedValue} pixels.`;
            isValid = false;
        }

        if (pageType === 'pageHeader') {
            this.headerError = errorMessage;
            if (isValid) {
                this.headerValue = enteredValue;
                this.headerCropHeight = enteredValue;
            }
        } else {
            this.footerError = errorMessage;
            if (isValid) {
                this.footerValue = enteredValue;
                this.footerCropHeight = enteredValue;
            }
        }

        if (!isValid) return;

        setTimeout(() => {
            if (pageType === 'pageHeader') {
                if (enteredValue < this.minValue || enteredValue > 200) {
                    this.headerError = `Height must range from 50 to 200 pixels.`;
                } else if (enteredValue > this.maxHeaderValue) {
                    this.headerError = `Height cannot exceed the previously saved value of ${this.maxHeaderValue} pixels.`;
                } else {
                    this.headerError = '';
                    this.headerValue = enteredValue;
                    this.headerCropHeight = enteredValue;
                }
            } else {
                if (enteredValue < this.minValue || enteredValue > 200) {
                    this.footerError = `Height must range from 50 to 200 pixels.`;
                } else if (enteredValue > this.maxFooterValue) {
                    this.footerError = `Height cannot exceed the previously saved value of ${this.maxFooterValue} pixels.`;
                } else {
                    this.footerError = '';
                    this.footerValue = enteredValue;
                    this.footerCropHeight = enteredValue;
                }
            }
        }, 100);
    }

    setImageUploadQuality(fileSize, uploadType) {
        let cropQuality;
        if (parseInt(fileSize) >= 30) {
            cropQuality = 40;
        } else if (parseInt(fileSize) >= 20) {
            cropQuality = 50;
        } else if (parseInt(fileSize) >= 15) {
            cropQuality = 60;
        } else if (parseInt(fileSize) >= 5) {
            cropQuality = 70;
        } else {
            cropQuality = 80;
        }

        switch(uploadType) {
            case 'pageBackgroundImage':
                this.cropQuality = cropQuality;
                break;
            case 'pageHeader':
                this.headerCropQuality = cropQuality;
                break;
            case 'pageFooter':
                this.footerCropQuality = cropQuality;
                break;
            default:
                console.warn('Unexpected upload type:', uploadType);
                break;
        }
    }


    resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    // Calculate new dimensions
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(canvas.toDataURL('image/jpeg', 0.9));
                };
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    recievedFromMarginvalue(event) {
        this.pagePropertiesForm.get('pageMarginValue').setValue(event)
    }

    dragCounter = 0;


        handleDragEvents(element: any) {
            element.on('drag dragstart dragend dragover dragenter dragleave drop', function (e: any) {
                e.preventDefault();
                e.stopPropagation();
            });

            element.on('dragover dragenter', function (e: any) {
                e.preventDefault();
                e.stopPropagation();

                if (!element.hasClass('blur')) {
                    element.find('.upload-popover').removeClass('display-none');
                    element.find('.remove-this-class').addClass('upload-input');
                    element.find('.ano').addClass('blur');
                }
            });

            element.on('dragleave', function (e: any) {
                e.preventDefault();
                e.stopPropagation();
                if (!element.has(e.relatedTarget).length) {
                    element.find('.upload-popover').addClass('display-none');
                    element.find('.ano').removeClass('blur');
                }
            });

            element.on('drop', function (e: any) {
                e.preventDefault();
                e.stopPropagation();
                element.find('.pay-in-full').addClass('clickable');
                element.find('.upload-popover').addClass('display-none');
                element.find('.ano').removeClass('blur');
            });
        }


    ngAfterViewInit() {
        this.handleDragEvents($('#drop-zone'));
        this.handleDragEvents($('#pageHeaderSection'));
        this.handleDragEvents($('#pageFooterSection'));
    }

    scrollToBottom(scrollType){
        const getPageElement = this.getPageTypeElement();

        let postData = {
            'pageId' : this.pageUniqueId,
            'id' : $(getPageElement).attr('id'),
            'pageNumber' : getPageElement.id.split('_pgNo_')[1],
            'scrollType' : scrollType
        }
        window.top.postMessage({ type: 'headerAndFooter', data: postData}, '*');
    }

    toggleHeaderFooter() {
        this.bgHedaerChecked = !this.bgHedaerChecked;
    }

}
