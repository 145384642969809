import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import * as moment from "moment";

declare var _;

@Component({
  selector: 'app-scheduledactivity-block',
  templateUrl: './scheduled-activity-block.component.html',
    styleUrls: ['./scheduled-activity-block.component.scss']
// {margin-bottom: 0} .cs-timepicker {width:150px}
})
export class ScheduledActivityBlockComponent implements OnInit {

    name: 'scheduledActivityBlock';

    showDeleteBtn:boolean;
    activeCustomField:any;
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView: boolean = false;
    @Input() hasChild:boolean = false;
    @Input() allFields: any = {};
    @Input() usersList: any[] = [];
    @Input() activityTypes: any[] = [];
    @Input() deleteMessage:string = 'You are about to delete a task. Are you sure you wish to do this?';
    @Input() warningMessage:string;
    @Input() workflowType: string = 'Custom';
    @Input() diagramOptions: any = {};
    @Input() status: any;
    @Input() isReadOnlyMode:boolean = false;

    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    iconClassNames: Object = {
        'Call': 'fa fa-phone',
        'Email': 'fa fa-envelope',
        'Meeting': 'fas fa-calendar-alt',
        'Task': 'fa fa-list-ul'
    };
    units: any[] = ['days', 'weeks', 'months'];
    select2LabelConfig: any;
    timeModel:Date = null;

    constructor(
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { }

    ngOnInit() {
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if(this.workflowType =='Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        if(!this.isEdit) {
            this.inputModel.model['activityType'] = this.activityTypes[0].id;
            this.timeModel = moment('08:00:00', 'HH:mm:ss').toDate();
        }
        else {
            this.timeModel = moment( this.inputModel.model['scheduleTime']+':00', 'HH:mm:ss').toDate();
        }
        const select2DisplayFormat = (item: any) => {
            return item.text;
        };
        this.select2LabelConfig = {
            width: '100%',
            data: this.usersList,
            tags: true,
            formatSelection: select2DisplayFormat,
            formatResult: select2DisplayFormat,
            multiple: true
        }
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    validateItems() {
        if(this.inputModel.model['description'] == '' || this.inputModel.model['activityType'] == ''){
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    onChangeTime(time: Date) {
        this.inputModel.model['displayTime'] = moment(time).format('HH:mm');
    }
    onChangedDate(value:any) {
        //console.log(value);
    }

    setDisplay() {
        let tmpUserIds = _.indexBy(Object.assign([], this.usersList), 'id');
        let tmpActivityType = _.indexBy(Object.assign([], this.activityTypes), 'id');
        this.inputModel.model.assigneeDisplay = [];
        _.forEach(this.inputModel.model.assigneeIds, (id) => {
            this.inputModel.model.assigneeDisplay.push(tmpUserIds[id].text);
        });
        this.inputModel.model['activityTypeDisplay'] = tmpActivityType[this.inputModel.model['activityType']]['name'];
    }
    selectedAsignee(event:any = null){
        this.inputModel.model['assigneeIds'] = event;
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    addValue() {
        this.setDisplay();
        this.inputModel.model['scheduleTime'] = moment( this.timeModel).format('HH:mm:ss');
        this.outputModel.emit(this.inputModel);
    }
}
