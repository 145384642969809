import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
declare var _;
@Component({
  selector: 'app-untilcondition-block',
  templateUrl: './untilcondition-block.component.html',
  styles: []
})
export class UntilconditionBlockComponent implements OnInit {

    name: 'untilConditionBlock';
    showDeleteBtn:boolean;
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView: boolean = false;
    @Input() hasChild:boolean = false;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;


    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    conditionalFields:any = [];
    untilMin:Date = new Date();
    untilMax:Date = new Date();
    untilPeriodModel:Date;

  constructor(
      @Inject('confirmationBoxHelper') private confirmationBoxHelper,
      @Inject('$scope') private ajsScope  ) {

  }

  ngOnInit() {
      if(typeof this.inputModel.model.untilType == 'string') {
          this.inputModel.model.untilType = parseInt(this.inputModel.model.untilType);
      }
      this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
      this.untilMin.setDate(this.untilMin.getDate() + 1);
      this.untilMax.setDate(this.untilMax.getDate() + 180);
      if(this.inputModel.model['untilPeriod']) {
          this.untilPeriodModel = new Date(this.inputModel.model['untilPeriod']);
      }
      else {
          this.untilPeriodModel=null;
      }
      //console.log('isConditional',this.isConditional, this.inputModel);
  }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    addValue() {
        /*let selectedItem = _.find(this.inputModel.config.fields, (field) => {
            return field.id == this.inputModel.model['col_name']
        });*/
        let selectedItem:any = this.inputModel.config.fieldsByColName[this.inputModel.model['col_name']];
        //console.log('selectedItem', selectedItem);
        if(selectedItem && selectedItem['fieldType'] && selectedItem['pageType']) {
            this.inputModel.model['fieldType'] = selectedItem['fieldType'];
            this.inputModel.model['pageType'] = selectedItem['pageType'];
        }
        if(selectedItem && selectedItem['templateId']) {
            this.inputModel.model['templateId'] = selectedItem['templateId'];
        }
        this.inputModel.model['untilPeriod'] = this.untilPeriodModel ? moment(this.untilPeriodModel).format() : null;
        this.outputModel.emit(this.inputModel);
    }

    validateItems() {
        if(!this.inputModel.model.col_name
            || this.inputModel.model.interval == 0
            || (this.inputModel.model.untilType==2 && !this.inputModel.model.untilManualPeriod)
            || (this.inputModel.model.untilType==1 && !this.untilPeriodModel)){
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    select2:any = null;
    setSelect2(instance:any) {
      this.select2 = instance
    }

    closeModal() {
        if(this.select2) {
            this.select2.select2('val', '');
            this.select2.trigger('change');
        }
        this.untilPeriodModel=null;
        this.close.emit(this.isEdit);
    }

}
