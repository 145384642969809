import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from "rxjs/index";
import {BsModalRef} from 'ngx-bootstrap';
import {OptionTemplateService} from '../services/option-template.service';
import {
    ProposalTemplateService
} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {AppValidators} from "@app/directives";
import {
    SettingsGrantTemplatesService
} from "@app/features/system-settings/grants-templates/services/grants-templates.service";
import { environment } from './../../../../../environments/environment';
import {Router} from "@angular/router";
declare var $: any;

export interface optionTemplateData {
	id: any;
	optionName?: string,
    customerOptionName?: string,
	addon?: boolean
}

@Component({
	selector: 'app-option-template-add-edit',
	templateUrl: './option-template-add-edit.component.html',
	styleUrls: ['./option-template-add-edit.scss'],
})
export class OptionTemplateAddEditComponent implements OnInit {

	optionTemplateForm: FormGroup;
	public onClose: Subject<boolean>;
	select2Config : any;
	action: string = '';
	isEdit: boolean = false;
	loading : boolean = false;
	fromInvoice : boolean = false;
    fromGrants = false;
    grantsData: any;
    customerOptionNameEdit: boolean = false;
	data: any;
	financeEditForm : FormGroup;
    grantsForm: FormGroup;
    optionGT: any;
	tableType : any;
	tablePkId : any;
	optionId : any;
	financeTemplateId : null;
	type : any;
	optionTemplateData = {
		id: '',
		optionName: '',
        customerOptionName: '',
		financeTemplate : null,
		addon: false
	}
	financeTemplateList : any;
	uniqueName:boolean = false;
	dataSaved:boolean = false;
    customerOptionName: string = '';
    customerOptionNameEditForm: FormGroup;
    opportunityProposalId: any;
    isAddOn: boolean = false;
    optionName: string = '';
    newCustomerOptionName: string = '';
    isViewMode: boolean = false;


    newfinaceTemplates : boolean = false;
    FinanceForm : string = 'hideFinanceForm';
    templateForm: any;
    formData = {
        templateName: '',
        yearRates: [],
        deposits: ''
    }
    apr = []
    terms = [];
    showInput: boolean = false;
    valid: boolean = false;
    checked: boolean = false;
    selectedApr: any;
    selectedTerms: any;
    selectedDeposit: any;
    financeAction = 'save';
    financeUniqueName:boolean = false;
    financeLoading : boolean = false;
    financeDataSaved: boolean = false;
    depositOne = 10;
    depositTwo = 20;
    depositThree = 30;
    depositFour = 50;
    integrated = false;
    multiplierError: boolean = false;
    isAddonTemplate:boolean = false;
    disable: boolean = true;
    select2ConfigForInvoiceAddress: any;
    representativeDetails: any;
    screenType: string = '';
    opportunityId: any = '';


    constructor(
		private formBuilder: FormBuilder,
		public bsModalRef: BsModalRef,
		private optionTemplateService: OptionTemplateService,
        private propasalTemplateService: ProposalTemplateService,
        private grantsTemplateService: SettingsGrantTemplatesService,
        private router: Router,
        public proposaltemplateservice: ProposalTemplateService,
    ) {

	}

	ngOnInit() {
		this.onClose = new Subject();
        let url = this.router.url;
        this.screenType = url[url.length - 1];
        if(url.includes('option_template')) {
            this.screenType = 'option_template';
        } else if (url.includes('proposal_templates')) {
            this.screenType = 'proposal_templates';
        } else if (url.includes('opportunity')) {
            this.screenType = 'opportunity';
        } else if (url.includes('addon_template')) {
            this.screenType = 'addon_template';
        }
		this.select2Config = {
			width: '100%'
		};
		if(this.fromInvoice){
			this.financeEditForm = this.formBuilder.group({
				'financeTemplate' : [this.financeTemplateId],
			});
		} else if (this.customerOptionNameEdit) {
            if (this.tableType) {
                this.customerOptionName = this.customerOptionName;
                this.optionId = this.optionId;
                this.tableType = this.tableType;
                this.optionName = this.optionName;
                this.isViewMode = this.isViewMode;
            } else {
                this.customerOptionName = this.customerOptionName;
                this.optionId = this.optionId;
                this.tableType = this.tableType;
                this.opportunityProposalId = this.opportunityProposalId;
                this.optionName = this.optionName;
                this.isViewMode = this.isViewMode;
            }
            this.customerOptionNameEditForm = this.formBuilder.group({
                'customerOptionName' : [this.customerOptionName, Validators.required]
            });
        }else if(this.newfinaceTemplates){
            this.newfinaceTemplates = true;
            this.FinanceForm = "showFinanceForm";
            this.buildForm();
            setTimeout( ()=>{
                this.disableMonths()
            }, 200);
        } else if (this.fromGrants) {
            this.select2ConfigForInvoiceAddress = {
                minimumInputLength: 2,
                width: "75%",
                initSelection: () => { },
                ajax: {
                    url: `${environment.apiBasePath}sales/getInvoiceAddress`,
                    data: (searchText, page) => {
                        if (searchText == '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(searchText) };
                    },
                    results: (data) => {
                        return { results: data['invoiceAddresses'] }
                    },
                }
            };
            this.grantsForm = new FormGroup({
                grantTemplate: new FormControl(this.type === 'edit' ? this.optionGT.id : '', Validators.required),
                grantLabel: new FormControl(this.type === 'edit' ? (this.optionGT.grantLabel || this.optionGT.templateName) : '', Validators.required),
                amount: new FormControl(this.type === 'edit' ? { value: this.optionGT.amount, disabled: this.optionGT.isFixed } : '', Validators.required),
                invoiceAddress: new FormControl(this.type === 'edit' ? this.optionGT.invoiceAddress.id : '', Validators.required),
                invoiceDescription: new FormControl(this.type === 'edit' ? this.optionGT.invoiceDescription : '', Validators.required),
                invoiceNote: new FormControl(this.type === 'edit' ? this.optionGT.invoiceNote : '', Validators.required),
                nominalCode: new FormControl(this.type === 'edit' ? this.optionGT.nominalCode : '', Validators.required),
                taxRate: new FormControl(this.type === 'edit' ? this.optionGT.taxItem : '', Validators.required),
                isInclusive: new FormControl(this.type === 'edit' ? (this.optionGT.isInclusive ? 'yes' : 'no') : '', Validators.required),
                paymentTerms: new FormControl(this.type === 'edit' ? this.optionGT.paymentTerms : '', Validators.required),
            });
            if (this.grantsData && this.grantsData.invoiceCategories && this.grantsData.invoiceCategories.length) {
                this.grantsForm.addControl('businessUnit', new FormControl(this.type === 'edit' ? this.optionGT.businessGroup : '', Validators.required));
            }
            if (this.grantsData && this.grantsData.userGroup && this.grantsData.userGroup.length) {
                this.grantsForm.addControl('userGroup', new FormControl(this.type === 'edit' ? this.optionGT.userGroup : '', Validators.required));
            }
            if (this.type === 'edit') {
                setTimeout(() => {
                    $(".grants_invoice_address").select2('data', { id: this.optionGT.invoiceAddress.id, text: this.optionGT.invoiceAddress.text });
                }, 200);
            }
        }
        else{
			if (this.data) {
				// Set values for edit screen
				this.isEdit = true;
				this.optionTemplateData.id = this.data.id;
                if(!this.isAddonTemplate) {
                    this.optionTemplateData.optionName = this.data.optionName;
                }else{
                    this.optionTemplateData.optionName = this.data.addonName;
                }
                this.optionTemplateData.customerOptionName =  this.data.customerOptionName;
				// this.optionTemplateData.addon = this.data.addOn;
				// if(this.data.addOn == "True") {
                //     this.isAddOn = true;
				// 	this.optionTemplateData.addon = true
				// } else {
				// 	this.optionTemplateData.addon = false
				// }
			}

			// Form intialization
			this.optionTemplateForm = this.formBuilder.group({
				'id': [this.optionTemplateData.id],
				'optionName': [this.optionTemplateData.optionName, Validators.required],
                'customerOptionName': [this.optionTemplateData.customerOptionName],
				// 'addOn': [this.optionTemplateData.addon]
			});
		}


	}

    onSelectingGrant(id: string|number) {
        const selectedGrant = this.grantsData.grants.find((grant: any) => grant.id == id);
        this.grantsForm.patchValue({
            grantTemplate: selectedGrant.id,
            grantLabel: selectedGrant.grantLabel || selectedGrant.templateName,
            amount: selectedGrant.amount,
            invoiceAddress: +selectedGrant.invoiceAddress.id,
            invoiceDescription: selectedGrant.invoiceDescription,
            invoiceNote: selectedGrant.invoiceNote,
            nominalCode: +selectedGrant.nominalCode,
            businessUnit: +selectedGrant.businessUnit,
            userGroup: +selectedGrant.userGroup,
            taxRate: +selectedGrant.taxItem,
            isInclusive: selectedGrant.isInclusive ? 'yes' : 'no',
            paymentTerms: selectedGrant.paymentTerms,
        });

        if (this.grantsData && this.grantsData.invoiceCategories && this.grantsData.invoiceCategories.length) {
            this.grantsForm.patchValue({'businessUnit': +selectedGrant.businessUnit });
        }
        if (this.grantsData && this.grantsData.userGroups && this.grantsData.userGroups.length) {
            this.grantsForm.patchValue({'userGroup': +selectedGrant.userGroup });
        }
        selectedGrant.isFixed ? this.grantsForm.controls.amount.disable() : this.grantsForm.controls.amount.enable();
        this.grantsForm.updateValueAndValidity();
        setTimeout(() => {
            $(".grants_invoice_address").select2('data', { id: selectedGrant.invoiceAddress.id, text: selectedGrant.invoiceAddress.text});
            $('.grants_nominal_code').val(+selectedGrant.nominalCode).trigger('change');
            $('.grants_business_unit').val(+selectedGrant.businessUnit).trigger('change');
            $('.grants_user_group').val(+selectedGrant.userGroup).trigger('change');
            $('.grants_tax_rate').val(+selectedGrant.taxItem).trigger('change');
            $('.grants_is_inclusive').val(selectedGrant.isInclusive ? 'yes' : 'no').trigger('change');
        }, 200);
    }
	// Save option template
	save() {
		this.dataSaved = false;
		this.action = 'saving';
		let optionValue = {"setupOption": this.optionTemplateForm.value}
        let optionName = optionValue.setupOption.optionName
        let customerName = optionValue.setupOption.customerOptionName
        if(optionName[optionName.length - 1] == ' '){
            optionValue.setupOption.optionName = optionName.slice(0, optionName.length - 1)
        } else if (customerName[customerName.length - 1] == ' '){
            optionValue.setupOption.customerOptionName = customerName.slice(0, customerName.length - 1)
        }
        if(this.isAddonTemplate){
            optionValue['setupOption']['addOn'] = true;
        }
		this.optionTemplateService.addOrEditOptionTemplate(optionValue)
			.subscribe(resp => {
				if (resp) {
					this.dataSaved = true;
					this.onClose.next(true);
					this.bsModalRef.hide();
					this.action = '';
				}
			}, error => {
				console.log('err', error);
			});
	}

	updateFinanceTemplate(){
		this.action = 'saving';
		let data  = this.financeEditForm.value;
		data['optionId'] = this.optionId;
		data['tablePkId'] = this.tablePkId;
		data['tableType'] = this.tableType;
		if(data['financeTemplate']==""){
			data['financeTemplate']="0";
		}
		if(this.type == 'add' && data['financeTemplate']=="0"){
			this.onClose.next(data['financeTemplate']);
			this.bsModalRef.hide();
			this.action = '';
		}else{
			this.optionTemplateService.updateFinanceOption(data, this.type)
			.subscribe(resp => {
				this.action = 'saving';
				if(data['financeTemplate'] == null){
					data['financeTemplate'] = true;
				}
                this.upgradeFreemium();
				this.onClose.next(data['financeTemplate']);
				this.bsModalRef.hide();
			}, error => {
				console.log('err', error);
			});
		}
	}

    addOrUpdateGrantsTemplate() {
        this.action = 'saving';
        let data  = this.grantsForm.value;
        data['optionId'] = this.optionId;
        data['tablePkId'] = this.tablePkId;
        data['tableType'] = this.tableType;
        data['type'] = this.type;
        if(data['grantTemplate']==""){
            data['grantTemplate']="0";
        }
        const selectedGrant = this.grantsData.grants.find((grant: any) => grant.id == data['grantTemplate']);
        data['templateName'] = selectedGrant.templateName;
        data['isFixedAmount'] = selectedGrant.isFixed;
        data['grantInvoiceCreation'] = selectedGrant.grantInvoiceCreation;
        data['depositCalculation'] = selectedGrant.depositCalculation;
        data['amount'] = this.grantsForm.controls.amount.value;
        if(data['type'] === 'edit') {
            data['optionGrantId'] = this.optionGT.optionGrantId;
        }
        this.grantsTemplateService.linkGrantsToOptionTemplate(data)
            .subscribe(resp => {
                this.action = 'saving';
                if (data['grantTemplate'] == null) {
                    data['grantTemplate'] = true;
                }
                this.upgradeFreemium();
                this.onClose.next(data['grantTemplate']);
                this.bsModalRef.hide();
            }, error => {
                console.log('err', error);
            });
    }

    updateCustomerOptionName() {
        const data = this.customerOptionNameEditForm.value;
        if (data['customerOptionName'] && !this.isViewMode)  {
                const customerOptionData = {
                    'optionId' : this.optionId,
                    'customerOptionName': data['customerOptionName'],
                    'tableType': this.tableType,
                    'tablePkId' : this.tablePkId
                };
                    this.propasalTemplateService.editCustomerOptionName(customerOptionData).subscribe(result => {
                        if (result) {
                            this.customerOptionName = result['customerOptionName'];
                            this.dataSaved = true;
                            this.onClose.next(customerOptionData['customerOptionName']);
                            this.bsModalRef.hide();
                            this.action = 'saving';
                        }
                    });
            }
    }

	// Getting control values for validation check
	get f() {
		return this.optionTemplateForm.controls;
	}
	// Check template name already exists
	checkUniqueTemplateName(value) {
		this.loading= false;
		let checkName: boolean = true;
		const q = new Promise((resolve, reject) => {
			let params: any = {
				optionName: value,
                isAddonTemplate:this.isAddonTemplate
			};
			if (this.isEdit) {
				params.selectedId = this.data.id;
				if (this.data.optionName === value.trim()) {
					checkName = false;
				} else {
					checkName = true;
				}
			} else {
				checkName = true;
			}
			if (checkName) {
				this.optionTemplateService.checkTemplateName(params).subscribe(resp => {
					if (resp == true){
					  this.loading = true;
					  this.uniqueName = true;
					}
					else {
					  this.loading = false;
					  this.uniqueName = false;
					  resolve(null);
					}
				  });
			} else {
				this.uniqueName = false;
				this.loading = false;
				resolve(null);
			}
		})
		return q;
	}



    //finance templates related code

    isUniqueName(value) {
        this.financeLoading = true;
        let checkName: boolean = true;
        const q = new Promise( (resolve, reject) => {
            let params:any = {
                templateName: value,
            };

            if(checkName) {
                this.propasalTemplateService.checkFinanceTemplateExists(params).subscribe(resp => {
                    if (resp == true) {
                        this.financeLoading = true;
                        this.financeUniqueName = true;
                    }
                    else {
                        this.financeLoading = false;
                        this.financeUniqueName = false;
                        resolve(null);
                    }
                });
            } else {
                this.financeLoading = false;
                resolve(null);
            }
        })
        return q;
    }

    buildForm() {
        this.templateForm = this.formBuilder.group({
            templateName: [this.formData.templateName, Validators.required],
            yearRates: new FormArray([this.getYearRatesControl()]),
            deposits: this.getDepositControl()
        });
    }

    getYearRatesControl(){
        let year = '';
        let control = new FormGroup({
            "months": new FormControl(year, [Validators.required, Validators.min(1)]),
            "rateByCustomer": new FormControl(0, [Validators.required, AppValidators.percentageValidator]),
            "multipliers": new FormControl( 0,[Validators.required, Validators.min(0), Validators.max(1)]),
            "disableTerms": new FormControl(false),
            "archived": new FormControl(0)
        });
        return control;
    }

    getDepositControl(){
        let deposits = new FormGroup({
            "deposit1": new FormControl(10, [Validators.required, AppValidators.percentageValidator]),
            "deposit2": new FormControl(20, [Validators.required, AppValidators.percentageValidator]),
            "deposit3": new FormControl(30, [Validators.required, AppValidators.percentageValidator]),
            "deposit4": new FormControl(50, [Validators.required, AppValidators.percentageValidator]),
            "allowOther": new FormControl(false, Validators.required)
        });
        return deposits;
    }

    addYearRateFormGroup(event, index) {
        let formArray = this.templateForm.get('yearRates') as FormArray;
        let lastIndex = formArray.length - 1;
        if (event.target.value > 0 && (index == lastIndex)) {
            let formGroup = this.formBuilder.group({
                "months": new FormControl('', [Validators.min(1)]),
                "rateByCustomer": new FormControl(0, [Validators.required, AppValidators.percentageValidator]),
                "multipliers": new FormControl( 0,[Validators.required, Validators.min(0), Validators.max(1)]),
                "disableTerms": new FormControl(false),
                "archived": new FormControl(0)
            })
            formArray.push(formGroup);
        }
        this.addAprAndYearRates(event);
    }

    changeTerms(event) {
        if (event.target.value != '') {
            // let term = event.target.value
            let apr  = $('#apr').val();
            this.checkTerms(apr,'second')
        }
    }

    deleteYearRow(index){
        if(index >= 1){
            let formArray = this.templateForm.get('yearRates') as FormArray;
            formArray.removeAt(index);
        }
    }

    // Getting control values for validation check

    get formControls() { return this.templateForm.controls; }

    addAprAndYearRates(event){
        if (event.target.value != ''){
            let apr = new Set();
            let year = new Set();
            for (let i = 0; i < this.templateForm.value.yearRates.length; i++) {
                if (!apr.has(this.templateForm.value.yearRates[i].rateByCustomer)) {
                    apr.add(parseFloat(this.templateForm.value.yearRates[i].rateByCustomer).toFixed(2))
                }
                if (!year.has(this.templateForm.value.yearRates[i].year)) {
                    year.add(this.templateForm.value.yearRates[i].year)
                }
            }
            if (apr){
                let convertArrayApr = Array.from(apr);
                this.apr = convertArrayApr;
            }
            if (year){
                let convertArrayyear = Array.from(year);
                this.terms = convertArrayyear.filter(item => item);
            }
        }
    }
    avoidArithmeticOperator(e){
        var k = e.keyCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8   || (k >= 48 && k <= 57));
    }

    avoidArithmeticOperatorForMultiplier(e){
        var k = e.keyCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 46 || (k >= 48 && k <= 57));
    }

    omitMaxPercentage(event, controlName = null, index = null) {
        if (index == null) {
            let depositControl = this.templateForm.get('deposits').controls[controlName].value;
            if (depositControl >= 100) {
                this.templateForm.get('deposits').controls[controlName].setValue(100)
            }
        } else {
            let ratesFormArray = this.templateForm.get('yearRates') as FormArray;
            let rateControl = ratesFormArray.controls[index]['controls'][controlName];
            if (rateControl.value >= 100) {
                rateControl.setValue(100)
            }
        }
        this.addAprAndYearRates(event);
    }

    onFocusOutEvent(event, controlName = null, index = null){
        let ratesFormArray = this.templateForm.get('yearRates') as FormArray;
        let rateControl = ratesFormArray.controls[index]['controls'][controlName];
        rateControl.setValue(parseFloat(rateControl.value).toFixed(2));
    }
    omitSpecialChar(event, value){
        if(value.length>=4 && value.includes('.')){
            if(value.indexOf('.')<2){
                return false;
            }
        }
        var charCode = (event.which) ? event.which : event.keyCode;
        if(charCode == 46) return true;
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    addRepresentative(event){
        if(event.target.checked){
            this.showInput = true;
        } else {
            this.showInput = false;
        }
    }

    checkInput(){
        let terms = document.getElementById('terms') as HTMLInputElement
        let apr = document.getElementById('apr') as HTMLInputElement
        let deposit = document.getElementById('deposit') as HTMLInputElement
        let termsValue = terms ? terms.value : '';
        let aprValue = apr ? apr.value : '';
        let depositValue = deposit ? deposit.value : ''
        if (this.showInput && termsValue && aprValue && depositValue) {
            this.valid = true;
        } else {
            this.valid = false;
        }
        this.representativeDetails = {
            apr: aprValue,
            deposit: depositValue,
            representativeDetails: true,
            terms: termsValue
        }
        this.disableMonths();
    }

    checkTerms(event,type){
        let apr
        if(type == 'event'){
            apr = event.target.value;
        } else {
            apr = event
        }
        this.terms = []
        for(let index=0;index < this.templateForm.controls.yearRates.length;index++ ){
            if(apr == this.templateForm.controls.yearRates.value[index]['rateByCustomer'] && !this.templateForm.value.yearRates[index]['disableTerms'] && (this.templateForm.value.yearRates[index]['months'] != '' && this.templateForm.value.yearRates[index]['months'] != ' '))
                this.terms.push(this.templateForm.controls.yearRates.value[index]['months'])
        }
    }

    calculateMultipliers(index){
        let data  = this.templateForm.get('yearRates').value;
        let rate = this.templateForm.get('yearRates').value[index]['rateByCustomer'];
        let months = this.templateForm.get('yearRates').value[index]['months'];
        let r = parseFloat((parseFloat(rate)/12/100).toFixed(5));
        let value = parseFloat((Math.pow((1+r),months)).toFixed(5))
        let multipliers = (r*value/(value -1)).toFixed(5);
        if(rate){
            this.templateForm.get('yearRates').controls[index].controls.multipliers.setValue(multipliers ? multipliers : 0)
        }
        if(index == 0){
            if(months && rate == 0){
                this.disable = true
            } else {
                this.disable = false
            }
        }
    }


    financeSave() {
        this.financeDataSaved = false;
        this.financeAction = 'saving';
        let data: any = this.templateForm.value;
        let lastIndex = data['yearRates'].length - 1;
        if(lastIndex > 0 && (data['yearRates'][lastIndex]['months'] == ' ' || data['yearRates'][lastIndex]['months'] == '')){
            data['yearRates'].splice(lastIndex, 1)
        }
        data['deposits'] = JSON.stringify(data['deposits']);
        data['yearRates'] = JSON.stringify(data['yearRates']);
        data['integrated'] = this.integrated
        if(this.showInput){
            let terms = (document.getElementById('terms') as HTMLInputElement).value;
            let apr = (document.getElementById('apr') as HTMLInputElement).value;
            let deposit = (document.getElementById('deposit')as HTMLInputElement).value
            let representativeDetails = {
                'representativeDetails' : true,
                'terms' : terms,
                'apr' : apr,
                'deposit': deposit
            }
            let removeEmptyValues = [];
            let convertDataIntoArray = JSON.parse(data['yearRates']);
            if (convertDataIntoArray && convertDataIntoArray.length){
                for (let i = 0; i < convertDataIntoArray.length; i++) {
                    let trimEmptyValues = typeof convertDataIntoArray[i].months == 'string' ? convertDataIntoArray[i].months.trim() : convertDataIntoArray[i].months ;
                    if (trimEmptyValues != ''){
                        removeEmptyValues.push(convertDataIntoArray[i])
                    }
                }
            }
            removeEmptyValues.push(representativeDetails)
            data['yearRates'] = JSON.stringify(removeEmptyValues);
            // data['yearRates'].push(representativeDetails);
            // data['yearRates'] = JSON.stringify(data['yearRates']);
        }
        this.propasalTemplateService.addOrEditFinance(data)
            .subscribe(resp => {
                if (resp) {
                    this.dataSaved = true;
                    this.onClose.next(true);
                    this.bsModalRef.hide();
                    this.financeAction = 'save';
                }
            }, error => {
                console.log('err', error);
            });
    }

    removeTerms(event,index){
        if(event.target.checked){
            this.templateForm.get('yearRates').controls[index].value['disableTerms'] = false
        } else {
            this.templateForm.get('yearRates').controls[index].value['disableTerms'] = true
        }
        this.checkTerms(this.templateForm.get('yearRates').controls[index].value['rateByCustomer'],'second');
    }

    disableMonths(){
        let data = []
        for (let index = 0; index < this.templateForm.value.yearRates.length; index++ ) {
            if(this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['rateByCustomer'] >= 0 && this.templateForm.value.yearRates[index]['months'] != '' && this.templateForm.value.yearRates[index]['months'] != ' '){
                let position = data.indexOf(this.templateForm.value.yearRates[index]['rateByCustomer'])
                if(position < 0){
                    data.push(this.templateForm.value.yearRates[index]['rateByCustomer'])
                }
            }
        }
        for (let index = 0; index < this.templateForm.value.yearRates.length; index++ ){
            if(this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['rateByCustomer'] >= 0 && this.templateForm.value.yearRates[index]['months'] != ' '){
                if(this.templateForm.value.yearRates[index]['rateByCustomer'] && this.templateForm.value.yearRates[index]['archived'] != 1){
                    this.templateForm.value.yearRates[index]['archived'] = 0;
                }
            }
        }

        let obj = []
        let value = {}
        for (let i = 0; i < data.length; i++ ) {
            for (let index = 0; index < this.templateForm.value.yearRates.length; index++ ){
                if(this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['rateByCustomer'] >= 0 && this.templateForm.value.yearRates[index]['months'] != ' '){
                    if( data[i] == this.templateForm.value.yearRates[index]['rateByCustomer']){
                        obj.push(this.templateForm.value.yearRates[index]['months'])
                    }
                }
            }
            value[data[i]] = obj
            obj = []
        }
        for ( let data in value){
            let selectedMonths = Math.max(...value[data])
            for(let index=0;index < this.templateForm.value.yearRates.length;index++ ){
                if(this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer')  && this.templateForm.value.yearRates[index]['months'] == selectedMonths){
                    if((data == this.templateForm.value.yearRates[index]['rateByCustomer']) && !this.templateForm.value.yearRates[index]['archived']) {
                        this.templateForm.value.yearRates[index]['archived'] = 2;
                    }
                }
            }
        }
        if(this.representativeDetails) {
            for(let index=0;index < this.templateForm.value.yearRates.length;index++ ){
                if(this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['months'] == this.representativeDetails.terms && this.templateForm.value.yearRates[index]['rateByCustomer'] == parseFloat(this.representativeDetails.apr).toFixed(2)){
                    this.templateForm.value.yearRates[index]['archived'] = 2;
                }
            }
        }
    }

    changeValue(index) {
        let value = parseFloat(this.templateForm.value.yearRates[index]['rateByCustomer']).toFixed(2)
        this.templateForm.get('yearRates').controls[index].controls.rateByCustomer.setValue(value)
        if(parseInt(value) == 0){
            this.templateForm.get('yearRates').controls[index].controls.multipliers.setValue(0)
        }
    }

    upgradeFreemium() {
        let data = {};
        if(this.screenType != 'addon_template'){
            if(this.screenType == 'option_template'){
                data = {
                    screenType: this.screenType,
                    id: this.optionId,
                    addon: false
                }
            } else if (this.screenType == 'proposal_templates') {
                data = {
                    screenType: this.screenType,
                    id: this.tablePkId,
                    addon: false
                }
            } else if (this.screenType == 'opportunity') {
                data = {
                    screenType: this.screenType,
                    id: this.tablePkId,
                    addon: false,
                    opportunityId: this.opportunityId
                }
            }
            this.proposaltemplateservice.upgradeDowngradeFreemiumBlocks(data).subscribe((res: any) => {

            });
        }
    }

}
