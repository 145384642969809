import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//--
import { InterfaceService } from '@app/interfaces';
import { Observable } from 'rxjs';
import {FormControl, FormGroup} from "@angular/forms";


@Injectable({
    providedIn: 'root'
})
export class FormParserServicesService extends InterfaceService {
    public assetQuestionIds: any = [];
    public dependantFields: any = {};
    public dependantSections: any = {};
    public parentDependantFields: any = {};
    public parentDependantFieldsForSection: any = {};
    public checkBoxFields: any = [];
    public pageWiseDependantFields: any = {};
    public pages:any = {};
    constructor(private http: HttpClient) {
        super();
    }

    /**
     *
     * @param customerId
     * @param {Object} params
     * @returns {Observable<any>}
     */
    getCustomerAppliances(customerId, params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`${customerId}/getAllAppliances`, params));
    }

    /**
     *
     * @returns {{fieldAnswerType: string; fieldLabel: string; questionID: string}[]}
     */
    getApplianceData() {
        const applianceData = [
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Appliance type',
                questionID: '!applianceType',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Fuel type',
                questionID: '!applianceFuel',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Make',
                questionID: '!applianceMake',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Model',
                questionID: '!applianceModel',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Location',
                questionID: '!applianceLocation',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Flue',
                questionID: '!applianceFlue',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Serial number',
                questionID: '!applianceSerialNo',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Reference',
                questionID: '!applianceReference',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Ventilation',
                questionID: '!applianceVentilation',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Status',
                questionID: '!applianceStatus',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Used for',
                questionID: '!applianceUsedFor',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Did you install the appliance?',
                questionID: '!applianceFInstall',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Have you notified the manufacturer?',
                questionID: '!applianceFNotified',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Installed on',
                questionID: '!applianceInstalledOn',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Installed by',
                questionID: '!applianceInstalledBy',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Commissioned on',
                questionID: '!applianceCommissioned',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'In warranty',
                questionID: '!applianceWarranty',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Warranty start date',
                questionID: '!applianceWarStart',

            },
            {
                fieldAnswerType: 'Appliance Data',
                fieldLabel: 'Warranty end date',
                questionID: '!applianceWarEnd',

            }
        ];
        return applianceData;
    }

    /**
     *
     * @param applianceFormData
     * @param pageNumber
     * @param itemCount
     * @returns {{}}
     */
    getApplianceFormValue(applianceFormData, pageNumber, itemCount){
        let applianceData = this.getApplianceData();
        let applianceFormValue = {};
        applianceData.forEach((appliance) => {
            let tag = `${appliance.questionID}_page${pageNumber}_${itemCount}`;
            if(applianceFormData[appliance.fieldLabel] !== undefined){
                applianceFormValue[tag] = applianceFormData[appliance.fieldLabel];
            }
        });
        return applianceFormValue;
    }

    /**
     *
     * @param {FormGroup} listItemForm
     * @param pageNumber
     * @param itemCount
     * @returns {{listItemForm: FormGroup; formLabels: {}}}
     */
    setApplianceForm(listItemForm: FormGroup, pageNumber, itemCount, listItemFieldOrderArray){
        let applianceData = this.getApplianceData();
        let newFormLabels = {};
        let nonAssetFields;
        if(listItemFieldOrderArray[pageNumber-1]){
            if(listItemFieldOrderArray[pageNumber-1][itemCount-1]){
                nonAssetFields = listItemFieldOrderArray[pageNumber-1][itemCount-1];
            }
            listItemFieldOrderArray[pageNumber-1][itemCount-1] = [];
        }else{
            listItemFieldOrderArray[pageNumber-1] = {};
            listItemFieldOrderArray[pageNumber-1][itemCount-1] = [];
        }
        applianceData.forEach((appliance) => {
            let tag = `${appliance.questionID}_page${pageNumber}_${itemCount}`;
            let formControl = new FormControl('');
            listItemForm.addControl(tag, formControl);
            let visibleOnList = false;
            if(appliance['questionID'] == '!applianceSerialNo' || appliance['questionID'] == '!applianceModel'
            || appliance['questionID'] ==  '!applianceMake' || appliance['questionID'] ==  '!applianceType')
                visibleOnList = true;
            newFormLabels[tag] = { label : appliance['fieldLabel'], visibleOnList: visibleOnList, type : '' };
            listItemFieldOrderArray[pageNumber-1][itemCount-1].push(tag);
        });

        if(nonAssetFields)
            listItemFieldOrderArray[pageNumber-1][itemCount-1] = listItemFieldOrderArray[pageNumber-1][itemCount-1].concat(nonAssetFields);
        return { listItemForm: listItemForm, formLabels: newFormLabels };
    }

    /**
     *
     * @param listItem
     * @param labels
     * @returns {{}}
     */
    getCurrentlySelectedAppliance(listItem, labels){
        let appliance = {};
        for (const [questionId, value] of Object.entries(listItem)){
            if(labels[questionId]){
                appliance[labels[questionId]['label']] = value;
            }
        }
        return appliance;
    }

    /**
     *
     * @param questionIdArray
     * @returns {any}
     */
    getApplianceQuestionIds(questionIdArray){
        let applianceData = this.getApplianceData();
        applianceData.forEach((appliance) => {
            questionIdArray.push(appliance.questionID);
        });
        return questionIdArray;
    }

    /**
     *
     * @returns {any}
     */
    getAssetQuestionIds(){
        return this.assetQuestionIds;
    }

    /**
     *
     * @param assetObjectArray
     * @param pageNumber
     * @param itemCount
     * @returns {{}}
     */
    getAssetFormValue(assetObjectArray, pageNumber, itemCount){
        let assetFormValue = {};
        assetObjectArray.forEach((asset) => {
            let tag = `${asset.questionID}_page${pageNumber}_${itemCount}`;
            if(asset['fieldValue'] !== undefined){
                assetFormValue[tag] = asset['fieldValue'];
            }
        });
        return assetFormValue;
    }

    /**
     *
     * @param {FormGroup} formGroup
     * @param assetObjectArray
     * @param pageNumber
     * @param itemCount
     * @returns {{formGroup: FormGroup; formLabels: {}}}
     */
    setAssetForm(formGroup: FormGroup, assetObjectArray, pageNumber, itemCount){
        let newFormLabels = {};
        assetObjectArray.forEach((asset) => {
            this.setAssetQuestionId(asset.questionID);
            let tag = `${asset.questionID}_page${pageNumber}_${itemCount}`;
            let formControl = new FormControl('');
            formControl.setValue(asset['fieldValue']);
            if(tag.includes('!') && formGroup.get(tag)){
                formGroup.removeControl(tag);
            }
            formGroup.addControl(tag, formControl);
            if(asset['fieldValue'] !== undefined && asset['fieldValue'].includes('/clientimages/'))
                newFormLabels[tag] = { label : asset['fieldLabel'], visibleOnList: true, type : 'Photo' };
            else
                newFormLabels[tag] = { label : asset['fieldLabel'], visibleOnList: true, type : '' };

        });
        return { formGroup: formGroup, formLabels: newFormLabels };
    }

    /**
     *
     * @param listItem
     * @param labels
     * @returns {{asset: any[]; assetGroupObjectKey: string}}
     */
    getCurrentlySelectedAsset(listItem, labels){
        let asset = [];
        let assetGroup:any = '';
        let assetType:any = '';
        for (const [questionId, value] of Object.entries(listItem)){
            if(labels[questionId] && labels[questionId]['visibleOnList']){
                let assetValue = {};
                assetValue['fieldValue'] = value;
                assetValue['fieldLabel'] = labels[questionId]['label'];
                let idArray = questionId.split('_');
                let questionID = '';
                if(idArray.length == 3){
                    questionID = idArray[0];
                }else{
                    for(let i=0; i<idArray.length - 2; ++i){
                        if(questionID){
                            questionID += '_'+idArray[i];
                        }else{
                            questionID = idArray[i];
                        }
                    }
                }

                assetValue['questionID'] = questionID;
                let valueInString = value as string;
                if(valueInString != undefined && valueInString.includes('/clientimages/')) assetValue['photo'] = true;
                else assetValue['photo'] = false;

                if(labels[questionId]['label'] == 'Asset group' || labels[questionId]['label'] == 'Asset type'){
                    assetValue['hide'] = false;
                    if(labels[questionId]['label'] == 'Asset group') assetGroup = value;
                    if(labels[questionId]['label'] == 'Asset type') assetType = value;
                }
                if(questionId.includes('!'))
                    asset.push(assetValue);
            }
        }
        let assetGroupObjectKey = assetGroup + ' | ' + assetType;
        return { asset: asset, assetGroupObjectKey: assetGroupObjectKey };
    }

    /**
     *
     * @param questionID
     */
    setAssetQuestionId(questionID){
        let index = this.assetQuestionIds.findIndex((value) =>{
           return value == questionID;
        });
        if(index == -1){
            this.assetQuestionIds.push(questionID);
        }
    }

    /**
     *
     * @param {FormGroup} listItemForm
     * @param pageNumber
     * @param itemCount
     * @returns {FormGroup}
     */
    setAssetFormWithKeys(listItemForm: FormGroup, pageNumber, itemCount){
        this.assetQuestionIds.forEach((questionID) => {
            let tag = `${questionID}_page${pageNumber+1}_${itemCount+1}`;
            let formControl = new FormControl('');
            listItemForm.addControl(tag, formControl);
        });
        return listItemForm;
    }

    /**
     *
     * @param {FormGroup} listItemForm
     * @param pageNumber
     * @param itemCount
     * @returns {FormGroup}
     */
    setApplianceFormWithKeys(listItemForm: FormGroup, pageNumber, itemCount){
        this.getApplianceData().forEach((appliance) => {
            let questionID = appliance['questionID'];
            let tag = `${questionID}_page${pageNumber+1}_${itemCount+1}`;
            let formControl = new FormControl('');
            listItemForm.addControl(tag, formControl);
        });
        return listItemForm;
    }


    /**
     *
     * @param {FormGroup} listItemForm
     * @param keyLabelArray
     * @param pageNumber
     * @param itemCount
     * @param draftData
     * @param listItemFieldOrderArray
     * @returns {{formGroup: FormGroup; formLabels: {}}}
     */
    setAssetFormWithKeyLabel(listItemForm: FormGroup, keyLabelArray, pageNumber, itemCount, draftData, listItemFieldOrderArray) {
        let newFormLabels = {};
        let nonAssetFields;
        if(listItemFieldOrderArray[pageNumber-1]){
            if(listItemFieldOrderArray[pageNumber-1][itemCount-1]){
                nonAssetFields = listItemFieldOrderArray[pageNumber-1][itemCount-1];
            }
            listItemFieldOrderArray[pageNumber-1][itemCount-1] = [];
        }else{
            listItemFieldOrderArray[pageNumber-1] = {};
            listItemFieldOrderArray[pageNumber-1][itemCount-1] = [];
        }
        keyLabelArray.forEach((data) => {
            let key = data['questionID'];
            let value = data['fieldLabel'];
            this.setAssetQuestionId(key);
            let tag = `${key}_page${pageNumber}_${itemCount}`;
            if(tag in draftData){
                let formControl = new FormControl('');
                listItemForm.addControl(tag, formControl);
                if(draftData[tag].includes('/clientimages/'))
                    newFormLabels[tag] = { label : value, visibleOnList: true, type : 'Photo' };
                else
                    newFormLabels[tag] = { label : value, visibleOnList: true, type : '' };
            }
            listItemFieldOrderArray[pageNumber-1][itemCount-1].push(tag);
        });

        if(nonAssetFields)
            listItemFieldOrderArray[pageNumber-1][itemCount-1] = listItemFieldOrderArray[pageNumber-1][itemCount-1].concat(nonAssetFields);
        return { formGroup: listItemForm, formLabels: newFormLabels };
    }

    /**
     *
     * @param field
     * @param listQuestionId
     */
    setDependantFields(field){
        if(field['fieldDependencies'] && field['fieldDependencies'].length){
            this.dependantFields[field['questionID']] = field['fieldDependencies'];
        }
        if(field['dependentQuestions'] && field['dependentQuestions'].length){
            this.parentDependantFields[field['questionID']] = field['fieldAnswerOptions'];
            if(field['fieldAnswerType'] == 'Checkbox'){
                // Check if this checkbox is already added into the array as it happens when the form is closed
                // and reopened again
                if (this.checkBoxFields.indexOf(field['questionID']) == -1) {
                    this.checkBoxFields.push(field['questionID']);
                }
            }
        }
        if(field['dependentSections'] && field['dependentSections'].length){
            this.parentDependantFieldsForSection[field['questionID']] = field['fieldAnswerOptions'];

            // Do this even if a section is dependent on a checkbox in another section on same page
            if(field['fieldAnswerType'] == 'Checkbox'){
                // Check if this checkbox is already added into the array as it happens when the form is closed
                // and reopened again
                if (this.checkBoxFields.indexOf(field['questionID']) == -1) {
                    this.checkBoxFields.push(field['questionID']);
                }
            }
        }
    }

    /**
     *
     * @param section
     */
    setDependantSections(section){
        if(section['fieldDependencies'] && section['fieldDependencies'].length){
            this.dependantSections[section['sectionID']] = section['fieldDependencies'];
        }
    }

    /**
     *
     * @param page
     * @param formValue
     * @param pageNumber
     * @param {string} type
     * @param postAppendString
     * @returns {Promise<any>}
     */
    checkDependantFields(page, formValue, pageNumber, type = 'STANDARD', postAppendString?): Promise<any> {
        this.pages = page;
        if(this.pages) {
            if(!this.pageWiseDependantFields[pageNumber]){
                this.pageWiseDependantFields[pageNumber] = [];
            }
            if (this.pages.fields) {
                this.pages.fields.forEach((field) => {
                    if (field['questionID'] in this.dependantFields) {
                        this.checkFieldDependencies(field, formValue, pageNumber);
                    }
                });
            }
            this.pages.sections.forEach((section) => {
                if (section['sectionID'] in this.dependantSections) {
                    if (type == 'STANDARD') {
                        this.checkSectionDependencies(section, formValue, pageNumber);
                    } else {
                        this.checkSectionDependencies(section, formValue, pageNumber, type, postAppendString);
                    }
                }
                section.fields.forEach((field) => {
                    if (field['questionID'] in this.dependantFields) {
                        if (type == 'STANDARD') {
                            this.checkFieldDependencies(field, formValue, pageNumber);
                        } else {
                            this.checkFieldDependencies(field, formValue, pageNumber, type, postAppendString);
                        }
                    }
                });
            });
        }
        return new Promise(resolve => {
            resolve(true);
        });
    }

    /**
     *
     * @param field
     * @param formValue
     * @param pageNumber
     * @param {string} type
     * @param postAppendString
     */
    checkFieldDependencies(field, formValue, pageNumber, type = 'STANDARD', postAppendString?) {
        field['hasDependency'] = true;
        let fieldDependencies = this.dependantFields[field['questionID']];
        let answerIdsObject = {};
        for (let i = 0; i < fieldDependencies.length; ++i) {
            let parentQuestionArray = fieldDependencies[i]['parentQuestions'];
            for (let j = 0; j < parentQuestionArray.length; ++j) {
                let answerId = '';
                let answerIdArray = [];
                let parentQuestionId = parentQuestionArray[j]['questionID'];
                let formValueKey = '';

                if(type == 'STANDARD') formValueKey = parentQuestionId;
                else formValueKey = parentQuestionId+postAppendString;

                if (this.parentDependantFields[parentQuestionId] && formValueKey in formValue) {
                        this.parentDependantFields[parentQuestionId].forEach(option => {
                            /** Check box alone requires a different check */
                            if (this.checkBoxFields.includes(parentQuestionId)) {
                                if(formValue[formValueKey] !== undefined){
                                    let valueArray = formValue[formValueKey].split(',');
                                    valueArray.forEach(value => {
                                        if (option['optionValue'] == value) {
                                            answerIdArray.push(option['answerID']);
                                        }
                                    });
                                }
                            } else {
                                if (option['optionValue'] == formValue[formValueKey]) {
                                    answerId = option['answerID'];
                                }
                            }
                        });
                        answerIdsObject[parentQuestionArray[j]['questionID']] = false;
                        /** Check box alone requires a different check */
                        if (this.checkBoxFields.includes(parentQuestionId)) {
                            let includeAnswerId = false;
                            answerIdArray.forEach((answerId) => {
                                if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                                    includeAnswerId = true;
                                }
                            });
                            if (includeAnswerId) answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                        } else {
                            if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                                answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                            }
                        }
                }
            }
        }
        field['previousShowStatus'] = field['show'];
        field['show'] = true;

        let index = this.pageWiseDependantFields[pageNumber].findIndex((value) => {
            return value['id'] == field['questionID'];
        });
        if(index == -1 && field['required'])
            this.pageWiseDependantFields[pageNumber].push({ id : field['questionID'], required : true});
        if(index !== -1){
            this.pageWiseDependantFields[pageNumber][index] = { id : field['questionID'], required : true};
        }

        index = this.pageWiseDependantFields[pageNumber].findIndex((value) => {
            return value['id'] == field['questionID'];
        });
        let deleteValue = false;

        if(!Object.keys(answerIdsObject).length) {
            field['show'] = false;
            deleteValue = true;
        }

        for (const [answerId, value] of Object.entries(answerIdsObject)){
            if(!value){
                field['show'] = false;
                deleteValue = true;
            }
        }

        if(this.pages.hasOwnProperty('sections') && fieldDependencies.length){
            this.pages.sections.forEach((sectionRow,sectionKey) => {
                if(sectionRow.hasOwnProperty('fields')){
                    sectionRow.fields.forEach((fieldVal,fieldKey) => {
                        let checkParentIndex = fieldDependencies[0].parentQuestions.findIndex(x => x.questionID == fieldVal.questionID)
                        if(checkParentIndex !== -1 &&  (fieldVal.hasOwnProperty('show') && !fieldVal.show)){
                            field.show = false;
                        }
                    });
                }
            });
        }


        if(deleteValue && index != -1){
            this.pageWiseDependantFields[pageNumber][index]['required'] = false;
        }
    }

    /**
     *
     * @param section
     * @param formValue
     * @param pageNumber
     * @param {string} type
     * @param postAppendString
     */
    checkSectionDependencies(section, formValue, pageNumber, type = 'STANDARD', postAppendString?){
        section['hasDependency'] = true;
        let fieldDependencies = this.dependantSections[section['sectionID']];
        let answerIdsObject = {};
        for (let i = 0; i < fieldDependencies.length; ++i) {
            let parentQuestionArray = fieldDependencies[i]['parentQuestions'];
            for (let j = 0; j < parentQuestionArray.length; ++j) {
                let answerId = '';
                let answerIdArray = [];
                let parentQuestionId = parentQuestionArray[j]['questionID'];
                let formValueKey = '';

                if(type == 'STANDARD') formValueKey = parentQuestionId;
                else formValueKey = parentQuestionId+postAppendString;

                if (this.parentDependantFieldsForSection[parentQuestionId] && formValueKey in formValue) {
                    this.parentDependantFieldsForSection[parentQuestionId].forEach(option => {
                        /** Check box alone requires a different check */
                        if (this.checkBoxFields.includes(parentQuestionId)) {
                            if(formValue[formValueKey] !== undefined){
                                let valueArray = formValue[formValueKey].split(',');
                                valueArray.forEach(value => {
                                    if (option['optionValue'] == value) {
                                        answerIdArray.push(option['answerID']);
                                    }
                                });
                            }
                        } else {
                            if (option['optionValue'] == formValue[formValueKey]) {
                                answerId = option['answerID'];
                            }
                        }
                    });
                    answerIdsObject[parentQuestionArray[j]['questionID']] = false;
                    /** Check box alone requires a different check */
                    if (this.checkBoxFields.includes(parentQuestionId)) {
                        let includeAnswerId = false;
                        answerIdArray.forEach((answerId) => {
                            if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                                includeAnswerId = true;
                            }
                        });
                        if (includeAnswerId) answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                    } else {
                        if (parentQuestionArray[j]['answerID'].includes(answerId)) {
                            answerIdsObject[parentQuestionArray[j]['questionID']] = true;
                        }
                    }
                }
            }
        }

        section['show'] = true;
        if(!Object.keys(answerIdsObject).length)
            section['show'] = false;

        for (const [answerId, value] of Object.entries(answerIdsObject)){
            if(!value){
                section['show'] = false;
            }
        }
        if(this.pages.hasOwnProperty('sections') && fieldDependencies.length){
            this.pages.sections.forEach((sectionRow,sectionKey) => {
                if(sectionRow.hasOwnProperty('fields')){
                    sectionRow.fields.forEach((field,fieldKey) => {
                        let checkParentIndex = fieldDependencies[0].parentQuestions.findIndex(x => x.questionID == field.questionID)
                        if(checkParentIndex !== -1 && (sectionRow.hasOwnProperty('show') && !sectionRow.show)){
                            section.show = false;
                        }
                    });
                }
            });
        }


        if(section['fields']){
            let fields = section['fields'];
            fields.forEach((field) => {
                let index = this.pageWiseDependantFields[pageNumber].findIndex((value) => {
                    return value['id'] == field['questionID'];
                });
                if(index == -1 && field['required']){
                    this.pageWiseDependantFields[pageNumber].push({ id : field['questionID'], required : section['show'] })
                }else{
                    this.pageWiseDependantFields[pageNumber][index] = { id : field['questionID'], required : section['show']};
                }
            });
        }
    }

    /**
     *
     * @param certificateId
     * @returns {Observable<any>}
     */
    getCertificatesFieldOptions(certificateId): Observable<any> {
        return this.http.get(this.getApiUrl(`${certificateId}/getcertificatesfieldoptions`));
    }

    /**
     *
     * @param jobId
     * @returns {Observable<any>}
     */
    getJobServiceReminders(jobId): Observable<any> {
        return this.http.get(this.getApiUrl(`${jobId}/getJobServiceReminders`));
    }

    /**
     *
     * @param jobId
     * @returns {Observable<any>}
     */
    getUserData(jobId): Observable<any> {
        return this.http.get(this.getApiUrl(`${jobId}/getDiaryUserData`));
    }

     /**
     *
     * @param opportunityId
     * @returns {Observable<any>}
     */
      getOpportunityUserData(opportunityId, type): Observable<any> {
        return this.http.get(this.getApiUrl(`sales/getOpportunityDiaryUserData?type=${type}&id=${opportunityId}`));
    }
}
