import { OnDestroy, OnInit } from '@angular/core';
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import { CommunicationHelperService } from "@app/shared/communication-mapping/services/communication-helper.service";
var CommunicationTemplateViewComponent = /** @class */ (function () {
    function CommunicationTemplateViewComponent(eventDispatcher, helperService) {
        this.eventDispatcher = eventDispatcher;
        this.helperService = helperService;
        this.enableView = false;
        this.select2Options = {
            minimumResultsForSearch: -1
        };
        this.previewModel = 'email';
        this.currentViewPort = 'desktop';
        this.select2PreviewModelInstance = null;
        this.emailMessage = '';
        this.smsMessage = '';
        this.letterMessage = '';
        this.emailTemplateListIndexById = {};
        this.smsTemplateListIndexById = {};
        this.loadingTemplateMessage = true;
        this.noMessageView = true;
        this.loadingMessage = true;
        this.clientDetails = {};
        this.randomMobileNumber = 0;
        this.finalSave = true;
    }
    CommunicationTemplateViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.eventDispatcher.triggerEvent$.subscribe({
            next: function (data) {
                if (typeof data != 'object' || !data['actionName']) {
                    return;
                }
                var action = data['actionName'];
                if (action == 'event:loadedData') {
                    var value = data['data'];
                    _this.inputModel = value['inputModel'];
                    _this.clientDetails = value['clientDetails'];
                    var emailList = value['emailTemplateList'];
                    var smsList = value['smsTemplateList'];
                    _this.loadingTemplateMessage = false;
                    if (emailList.length > 0) {
                        emailList.forEach(function (item) {
                            _this.emailTemplateListIndexById[item.id] = item;
                        });
                    }
                    if (smsList.length > 0) {
                        smsList.forEach(function (item) {
                            _this.smsTemplateListIndexById[item.id] = item;
                        });
                    }
                    _this.layoutComponent = value['layoutComponent'];
                    var activeEmail = _this.helperService.findCurrentTemplate(_this.inputModel, 'email');
                    var activeSms = _this.helperService.findCurrentTemplate(_this.inputModel, 'sms');
                    var activeLetter = _this.helperService.findCurrentTemplate(_this.inputModel, 'letter');
                    if (activeEmail || activeSms) {
                        // this.enableView=true;
                        _this.previewModel = _this.helperService.getCurrentPreview(activeEmail, activeSms, activeLetter);
                        if (_this.previewModel == 'sms' || _this.previewModel == 'letter')
                            _this.currentViewPort = 'mobile';
                        else
                            _this.currentViewPort = 'desktop';
                        if (activeEmail) {
                            _this.emailMessage = activeEmail['message'];
                            _this.noMessageView = false;
                        }
                        if (activeSms) {
                            _this.smsMessage = activeSms['message'];
                            _this.noMessageView = false;
                        }
                        if (activeLetter) {
                            _this.letterMessage = activeLetter['message'];
                            _this.noMessageView = false;
                        }
                        _this.reloadMessage();
                    }
                    else if (!activeEmail && !activeSms && !activeLetter) {
                        _this.noMessageView = true;
                        _this.loadingTemplateMessage = false;
                    }
                }
                if (action == 'event:updatedTemplateId') {
                    _this.noMessageView = false;
                    _this.previewModel = data['type'];
                    if (_this.select2PreviewModelInstance) {
                        _this.select2PreviewModelInstance.select2('val', _this.previewModel);
                    }
                    if (data['type'] == 'email') {
                        _this.emailMessage = _this.emailTemplateListIndexById[data['value']]['message'];
                        _this.currentViewPort = 'desktop';
                    }
                    else if (data['type'] == 'sms') {
                        _this.smsMessage = _this.smsTemplateListIndexById[data['value']]['message'];
                        _this.currentViewPort = 'mobile';
                    }
                    _this.reloadMessage();
                }
                if (action == CommunicationDispatcherService.CONSTANTS['updateTemplateDetails']) {
                    _this.previewModel = data['type'];
                    if (_this.select2PreviewModelInstance && _this.previewModel) {
                        _this.select2PreviewModelInstance.select2('val', _this.previewModel);
                    }
                    if (data['type'] == 'email') {
                        _this.emailMessage = data['value']['message'];
                        _this.noMessageView = false;
                        _this.currentViewPort = 'desktop';
                    }
                    else if (data['type'] == 'sms') {
                        _this.smsMessage = data['value']['message'];
                        _this.noMessageView = false;
                        _this.currentViewPort = 'mobile';
                    }
                    else if (data['type'] == 'letter') {
                        _this.letterMessage = data['value']['message'];
                        _this.noMessageView = false;
                        _this.currentViewPort = 'mobile';
                    }
                    _this.reloadMessage();
                }
            }
        });
        this.randomMobileNumber = this.generatePhoneNumber();
    };
    CommunicationTemplateViewComponent.prototype.setSelect2PreviewModel = function (instance) {
        this.select2PreviewModelInstance = instance;
    };
    CommunicationTemplateViewComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    CommunicationTemplateViewComponent.prototype.changeViewPort = function (view) {
        if (view === this.currentViewPort)
            return;
        this.currentViewPort = view;
        this.reloadMessage();
    };
    CommunicationTemplateViewComponent.prototype.onChangePreviewOption = function (value) {
        if (value == 'sms' || value == 'letter')
            this.currentViewPort = 'mobile';
        else if (value == 'email')
            this.currentViewPort = 'desktop';
        this.reloadMessage();
    };
    CommunicationTemplateViewComponent.prototype.reloadTemplate = function () {
        var _this = this;
        this.loadingTemplateMessage = true;
        setTimeout(function () {
            _this.loadingTemplateMessage = false;
        }, 600);
    };
    CommunicationTemplateViewComponent.prototype.reloadMessage = function () {
        var _this = this;
        this.loadingMessage = true;
        setTimeout(function () {
            _this.loadingMessage = false;
        }, 600);
    };
    CommunicationTemplateViewComponent.prototype.generatePhoneNumber = function () {
        return Math.floor(Math.random() * 9000000000) + 1000000000;
    };
    CommunicationTemplateViewComponent.prototype.save = function () {
        if (this.finalSave) {
            this.finalSave = false;
            this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['saveAndClose'], {});
        }
    };
    CommunicationTemplateViewComponent.prototype.closeModal = function () {
        this.eventDispatcher.dispatchEvent(CommunicationDispatcherService.CONSTANTS['close'], {});
    };
    return CommunicationTemplateViewComponent;
}());
export { CommunicationTemplateViewComponent };
