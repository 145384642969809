import { OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { OptionTemplateService } from '../services/option-template.service';
import { ProposalTemplateService } from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import { AppValidators } from "@app/directives";
import { SettingsGrantTemplatesService } from "@app/features/system-settings/grants-templates/services/grants-templates.service";
import { environment } from './../../../../../environments/environment';
import { Router } from "@angular/router";
var OptionTemplateAddEditComponent = /** @class */ (function () {
    function OptionTemplateAddEditComponent(formBuilder, bsModalRef, optionTemplateService, propasalTemplateService, grantsTemplateService, router, proposaltemplateservice) {
        this.formBuilder = formBuilder;
        this.bsModalRef = bsModalRef;
        this.optionTemplateService = optionTemplateService;
        this.propasalTemplateService = propasalTemplateService;
        this.grantsTemplateService = grantsTemplateService;
        this.router = router;
        this.proposaltemplateservice = proposaltemplateservice;
        this.action = '';
        this.isEdit = false;
        this.loading = false;
        this.fromInvoice = false;
        this.fromGrants = false;
        this.customerOptionNameEdit = false;
        this.optionTemplateData = {
            id: '',
            optionName: '',
            customerOptionName: '',
            financeTemplate: null,
            addon: false
        };
        this.uniqueName = false;
        this.dataSaved = false;
        this.customerOptionName = '';
        this.isAddOn = false;
        this.optionName = '';
        this.newCustomerOptionName = '';
        this.isViewMode = false;
        this.newfinaceTemplates = false;
        this.FinanceForm = 'hideFinanceForm';
        this.formData = {
            templateName: '',
            yearRates: [],
            deposits: ''
        };
        this.apr = [];
        this.terms = [];
        this.showInput = false;
        this.valid = false;
        this.checked = false;
        this.financeAction = 'save';
        this.financeUniqueName = false;
        this.financeLoading = false;
        this.financeDataSaved = false;
        this.depositOne = 10;
        this.depositTwo = 20;
        this.depositThree = 30;
        this.depositFour = 50;
        this.integrated = false;
        this.multiplierError = false;
        this.isAddonTemplate = false;
        this.disable = true;
        this.screenType = '';
        this.opportunityId = '';
    }
    OptionTemplateAddEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        var url = this.router.url;
        this.screenType = url[url.length - 1];
        if (url.includes('option_template')) {
            this.screenType = 'option_template';
        }
        else if (url.includes('proposal_templates')) {
            this.screenType = 'proposal_templates';
        }
        else if (url.includes('opportunity')) {
            this.screenType = 'opportunity';
        }
        else if (url.includes('addon_template')) {
            this.screenType = 'addon_template';
        }
        this.select2Config = {
            width: '100%'
        };
        if (this.fromInvoice) {
            this.financeEditForm = this.formBuilder.group({
                'financeTemplate': [this.financeTemplateId],
            });
        }
        else if (this.customerOptionNameEdit) {
            if (this.tableType) {
                this.customerOptionName = this.customerOptionName;
                this.optionId = this.optionId;
                this.tableType = this.tableType;
                this.optionName = this.optionName;
                this.isViewMode = this.isViewMode;
            }
            else {
                this.customerOptionName = this.customerOptionName;
                this.optionId = this.optionId;
                this.tableType = this.tableType;
                this.opportunityProposalId = this.opportunityProposalId;
                this.optionName = this.optionName;
                this.isViewMode = this.isViewMode;
            }
            this.customerOptionNameEditForm = this.formBuilder.group({
                'customerOptionName': [this.customerOptionName, Validators.required]
            });
        }
        else if (this.newfinaceTemplates) {
            this.newfinaceTemplates = true;
            this.FinanceForm = "showFinanceForm";
            this.buildForm();
            setTimeout(function () {
                _this.disableMonths();
            }, 200);
        }
        else if (this.fromGrants) {
            this.select2ConfigForInvoiceAddress = {
                minimumInputLength: 2,
                width: "75%",
                initSelection: function () { },
                ajax: {
                    url: environment.apiBasePath + "sales/getInvoiceAddress",
                    data: function (searchText, page) {
                        if (searchText == '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(searchText) };
                    },
                    results: function (data) {
                        return { results: data['invoiceAddresses'] };
                    },
                }
            };
            this.grantsForm = new FormGroup({
                grantTemplate: new FormControl(this.type === 'edit' ? this.optionGT.id : '', Validators.required),
                grantLabel: new FormControl(this.type === 'edit' ? (this.optionGT.grantLabel || this.optionGT.templateName) : '', Validators.required),
                amount: new FormControl(this.type === 'edit' ? { value: this.optionGT.amount, disabled: this.optionGT.isFixed } : '', Validators.required),
                invoiceAddress: new FormControl(this.type === 'edit' ? this.optionGT.invoiceAddress.id : '', Validators.required),
                invoiceDescription: new FormControl(this.type === 'edit' ? this.optionGT.invoiceDescription : '', Validators.required),
                invoiceNote: new FormControl(this.type === 'edit' ? this.optionGT.invoiceNote : '', Validators.required),
                nominalCode: new FormControl(this.type === 'edit' ? this.optionGT.nominalCode : '', Validators.required),
                taxRate: new FormControl(this.type === 'edit' ? this.optionGT.taxItem : '', Validators.required),
                isInclusive: new FormControl(this.type === 'edit' ? (this.optionGT.isInclusive ? 'yes' : 'no') : '', Validators.required),
                paymentTerms: new FormControl(this.type === 'edit' ? this.optionGT.paymentTerms : '', Validators.required),
            });
            if (this.grantsData && this.grantsData.invoiceCategories && this.grantsData.invoiceCategories.length) {
                this.grantsForm.addControl('businessUnit', new FormControl(this.type === 'edit' ? this.optionGT.businessGroup : '', Validators.required));
            }
            if (this.grantsData && this.grantsData.userGroup && this.grantsData.userGroup.length) {
                this.grantsForm.addControl('userGroup', new FormControl(this.type === 'edit' ? this.optionGT.userGroup : '', Validators.required));
            }
            if (this.type === 'edit') {
                setTimeout(function () {
                    $(".grants_invoice_address").select2('data', { id: _this.optionGT.invoiceAddress.id, text: _this.optionGT.invoiceAddress.text });
                }, 200);
            }
        }
        else {
            if (this.data) {
                // Set values for edit screen
                this.isEdit = true;
                this.optionTemplateData.id = this.data.id;
                if (!this.isAddonTemplate) {
                    this.optionTemplateData.optionName = this.data.optionName;
                }
                else {
                    this.optionTemplateData.optionName = this.data.addonName;
                }
                this.optionTemplateData.customerOptionName = this.data.customerOptionName;
                // this.optionTemplateData.addon = this.data.addOn;
                // if(this.data.addOn == "True") {
                //     this.isAddOn = true;
                // 	this.optionTemplateData.addon = true
                // } else {
                // 	this.optionTemplateData.addon = false
                // }
            }
            // Form intialization
            this.optionTemplateForm = this.formBuilder.group({
                'id': [this.optionTemplateData.id],
                'optionName': [this.optionTemplateData.optionName, Validators.required],
                'customerOptionName': [this.optionTemplateData.customerOptionName],
            });
        }
    };
    OptionTemplateAddEditComponent.prototype.onSelectingGrant = function (id) {
        var selectedGrant = this.grantsData.grants.find(function (grant) { return grant.id == id; });
        this.grantsForm.patchValue({
            grantTemplate: selectedGrant.id,
            grantLabel: selectedGrant.grantLabel || selectedGrant.templateName,
            amount: selectedGrant.amount,
            invoiceAddress: +selectedGrant.invoiceAddress.id,
            invoiceDescription: selectedGrant.invoiceDescription,
            invoiceNote: selectedGrant.invoiceNote,
            nominalCode: +selectedGrant.nominalCode,
            businessUnit: +selectedGrant.businessUnit,
            userGroup: +selectedGrant.userGroup,
            taxRate: +selectedGrant.taxItem,
            isInclusive: selectedGrant.isInclusive ? 'yes' : 'no',
            paymentTerms: selectedGrant.paymentTerms,
        });
        if (this.grantsData && this.grantsData.invoiceCategories && this.grantsData.invoiceCategories.length) {
            this.grantsForm.patchValue({ 'businessUnit': +selectedGrant.businessUnit });
        }
        if (this.grantsData && this.grantsData.userGroups && this.grantsData.userGroups.length) {
            this.grantsForm.patchValue({ 'userGroup': +selectedGrant.userGroup });
        }
        selectedGrant.isFixed ? this.grantsForm.controls.amount.disable() : this.grantsForm.controls.amount.enable();
        this.grantsForm.updateValueAndValidity();
        setTimeout(function () {
            $(".grants_invoice_address").select2('data', { id: selectedGrant.invoiceAddress.id, text: selectedGrant.invoiceAddress.text });
            $('.grants_nominal_code').val(+selectedGrant.nominalCode).trigger('change');
            $('.grants_business_unit').val(+selectedGrant.businessUnit).trigger('change');
            $('.grants_user_group').val(+selectedGrant.userGroup).trigger('change');
            $('.grants_tax_rate').val(+selectedGrant.taxItem).trigger('change');
            $('.grants_is_inclusive').val(selectedGrant.isInclusive ? 'yes' : 'no').trigger('change');
        }, 200);
    };
    // Save option template
    OptionTemplateAddEditComponent.prototype.save = function () {
        var _this = this;
        this.dataSaved = false;
        this.action = 'saving';
        var optionValue = { "setupOption": this.optionTemplateForm.value };
        var optionName = optionValue.setupOption.optionName;
        var customerName = optionValue.setupOption.customerOptionName;
        if (optionName[optionName.length - 1] == ' ') {
            optionValue.setupOption.optionName = optionName.slice(0, optionName.length - 1);
        }
        else if (customerName[customerName.length - 1] == ' ') {
            optionValue.setupOption.customerOptionName = customerName.slice(0, customerName.length - 1);
        }
        if (this.isAddonTemplate) {
            optionValue['setupOption']['addOn'] = true;
        }
        this.optionTemplateService.addOrEditOptionTemplate(optionValue)
            .subscribe(function (resp) {
            if (resp) {
                _this.dataSaved = true;
                _this.onClose.next(true);
                _this.bsModalRef.hide();
                _this.action = '';
            }
        }, function (error) {
            console.log('err', error);
        });
    };
    OptionTemplateAddEditComponent.prototype.updateFinanceTemplate = function () {
        var _this = this;
        this.action = 'saving';
        var data = this.financeEditForm.value;
        data['optionId'] = this.optionId;
        data['tablePkId'] = this.tablePkId;
        data['tableType'] = this.tableType;
        if (data['financeTemplate'] == "") {
            data['financeTemplate'] = "0";
        }
        if (this.type == 'add' && data['financeTemplate'] == "0") {
            this.onClose.next(data['financeTemplate']);
            this.bsModalRef.hide();
            this.action = '';
        }
        else {
            this.optionTemplateService.updateFinanceOption(data, this.type)
                .subscribe(function (resp) {
                _this.action = 'saving';
                if (data['financeTemplate'] == null) {
                    data['financeTemplate'] = true;
                }
                _this.upgradeFreemium();
                _this.onClose.next(data['financeTemplate']);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log('err', error);
            });
        }
    };
    OptionTemplateAddEditComponent.prototype.addOrUpdateGrantsTemplate = function () {
        var _this = this;
        this.action = 'saving';
        var data = this.grantsForm.value;
        data['optionId'] = this.optionId;
        data['tablePkId'] = this.tablePkId;
        data['tableType'] = this.tableType;
        data['type'] = this.type;
        if (data['grantTemplate'] == "") {
            data['grantTemplate'] = "0";
        }
        var selectedGrant = this.grantsData.grants.find(function (grant) { return grant.id == data['grantTemplate']; });
        data['templateName'] = selectedGrant.templateName;
        data['isFixedAmount'] = selectedGrant.isFixed;
        data['grantInvoiceCreation'] = selectedGrant.grantInvoiceCreation;
        data['depositCalculation'] = selectedGrant.depositCalculation;
        data['amount'] = this.grantsForm.controls.amount.value;
        if (data['type'] === 'edit') {
            data['optionGrantId'] = this.optionGT.optionGrantId;
        }
        this.grantsTemplateService.linkGrantsToOptionTemplate(data)
            .subscribe(function (resp) {
            _this.action = 'saving';
            if (data['grantTemplate'] == null) {
                data['grantTemplate'] = true;
            }
            _this.upgradeFreemium();
            _this.onClose.next(data['grantTemplate']);
            _this.bsModalRef.hide();
        }, function (error) {
            console.log('err', error);
        });
    };
    OptionTemplateAddEditComponent.prototype.updateCustomerOptionName = function () {
        var _this = this;
        var data = this.customerOptionNameEditForm.value;
        if (data['customerOptionName'] && !this.isViewMode) {
            var customerOptionData_1 = {
                'optionId': this.optionId,
                'customerOptionName': data['customerOptionName'],
                'tableType': this.tableType,
                'tablePkId': this.tablePkId
            };
            this.propasalTemplateService.editCustomerOptionName(customerOptionData_1).subscribe(function (result) {
                if (result) {
                    _this.customerOptionName = result['customerOptionName'];
                    _this.dataSaved = true;
                    _this.onClose.next(customerOptionData_1['customerOptionName']);
                    _this.bsModalRef.hide();
                    _this.action = 'saving';
                }
            });
        }
    };
    Object.defineProperty(OptionTemplateAddEditComponent.prototype, "f", {
        // Getting control values for validation check
        get: function () {
            return this.optionTemplateForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    // Check template name already exists
    OptionTemplateAddEditComponent.prototype.checkUniqueTemplateName = function (value) {
        var _this = this;
        this.loading = false;
        var checkName = true;
        var q = new Promise(function (resolve, reject) {
            var params = {
                optionName: value,
                isAddonTemplate: _this.isAddonTemplate
            };
            if (_this.isEdit) {
                params.selectedId = _this.data.id;
                if (_this.data.optionName === value.trim()) {
                    checkName = false;
                }
                else {
                    checkName = true;
                }
            }
            else {
                checkName = true;
            }
            if (checkName) {
                _this.optionTemplateService.checkTemplateName(params).subscribe(function (resp) {
                    if (resp == true) {
                        _this.loading = true;
                        _this.uniqueName = true;
                    }
                    else {
                        _this.loading = false;
                        _this.uniqueName = false;
                        resolve(null);
                    }
                });
            }
            else {
                _this.uniqueName = false;
                _this.loading = false;
                resolve(null);
            }
        });
        return q;
    };
    //finance templates related code
    OptionTemplateAddEditComponent.prototype.isUniqueName = function (value) {
        var _this = this;
        this.financeLoading = true;
        var checkName = true;
        var q = new Promise(function (resolve, reject) {
            var params = {
                templateName: value,
            };
            if (checkName) {
                _this.propasalTemplateService.checkFinanceTemplateExists(params).subscribe(function (resp) {
                    if (resp == true) {
                        _this.financeLoading = true;
                        _this.financeUniqueName = true;
                    }
                    else {
                        _this.financeLoading = false;
                        _this.financeUniqueName = false;
                        resolve(null);
                    }
                });
            }
            else {
                _this.financeLoading = false;
                resolve(null);
            }
        });
        return q;
    };
    OptionTemplateAddEditComponent.prototype.buildForm = function () {
        this.templateForm = this.formBuilder.group({
            templateName: [this.formData.templateName, Validators.required],
            yearRates: new FormArray([this.getYearRatesControl()]),
            deposits: this.getDepositControl()
        });
    };
    OptionTemplateAddEditComponent.prototype.getYearRatesControl = function () {
        var year = '';
        var control = new FormGroup({
            "months": new FormControl(year, [Validators.required, Validators.min(1)]),
            "rateByCustomer": new FormControl(0, [Validators.required, AppValidators.percentageValidator]),
            "multipliers": new FormControl(0, [Validators.required, Validators.min(0), Validators.max(1)]),
            "disableTerms": new FormControl(false),
            "archived": new FormControl(0)
        });
        return control;
    };
    OptionTemplateAddEditComponent.prototype.getDepositControl = function () {
        var deposits = new FormGroup({
            "deposit1": new FormControl(10, [Validators.required, AppValidators.percentageValidator]),
            "deposit2": new FormControl(20, [Validators.required, AppValidators.percentageValidator]),
            "deposit3": new FormControl(30, [Validators.required, AppValidators.percentageValidator]),
            "deposit4": new FormControl(50, [Validators.required, AppValidators.percentageValidator]),
            "allowOther": new FormControl(false, Validators.required)
        });
        return deposits;
    };
    OptionTemplateAddEditComponent.prototype.addYearRateFormGroup = function (event, index) {
        var formArray = this.templateForm.get('yearRates');
        var lastIndex = formArray.length - 1;
        if (event.target.value > 0 && (index == lastIndex)) {
            var formGroup = this.formBuilder.group({
                "months": new FormControl('', [Validators.min(1)]),
                "rateByCustomer": new FormControl(0, [Validators.required, AppValidators.percentageValidator]),
                "multipliers": new FormControl(0, [Validators.required, Validators.min(0), Validators.max(1)]),
                "disableTerms": new FormControl(false),
                "archived": new FormControl(0)
            });
            formArray.push(formGroup);
        }
        this.addAprAndYearRates(event);
    };
    OptionTemplateAddEditComponent.prototype.changeTerms = function (event) {
        if (event.target.value != '') {
            // let term = event.target.value
            var apr = $('#apr').val();
            this.checkTerms(apr, 'second');
        }
    };
    OptionTemplateAddEditComponent.prototype.deleteYearRow = function (index) {
        if (index >= 1) {
            var formArray = this.templateForm.get('yearRates');
            formArray.removeAt(index);
        }
    };
    Object.defineProperty(OptionTemplateAddEditComponent.prototype, "formControls", {
        // Getting control values for validation check
        get: function () { return this.templateForm.controls; },
        enumerable: true,
        configurable: true
    });
    OptionTemplateAddEditComponent.prototype.addAprAndYearRates = function (event) {
        if (event.target.value != '') {
            var apr = new Set();
            var year = new Set();
            for (var i = 0; i < this.templateForm.value.yearRates.length; i++) {
                if (!apr.has(this.templateForm.value.yearRates[i].rateByCustomer)) {
                    apr.add(parseFloat(this.templateForm.value.yearRates[i].rateByCustomer).toFixed(2));
                }
                if (!year.has(this.templateForm.value.yearRates[i].year)) {
                    year.add(this.templateForm.value.yearRates[i].year);
                }
            }
            if (apr) {
                var convertArrayApr = Array.from(apr);
                this.apr = convertArrayApr;
            }
            if (year) {
                var convertArrayyear = Array.from(year);
                this.terms = convertArrayyear.filter(function (item) { return item; });
            }
        }
    };
    OptionTemplateAddEditComponent.prototype.avoidArithmeticOperator = function (e) {
        var k = e.keyCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57));
    };
    OptionTemplateAddEditComponent.prototype.avoidArithmeticOperatorForMultiplier = function (e) {
        var k = e.keyCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 46 || (k >= 48 && k <= 57));
    };
    OptionTemplateAddEditComponent.prototype.omitMaxPercentage = function (event, controlName, index) {
        if (controlName === void 0) { controlName = null; }
        if (index === void 0) { index = null; }
        if (index == null) {
            var depositControl = this.templateForm.get('deposits').controls[controlName].value;
            if (depositControl >= 100) {
                this.templateForm.get('deposits').controls[controlName].setValue(100);
            }
        }
        else {
            var ratesFormArray = this.templateForm.get('yearRates');
            var rateControl = ratesFormArray.controls[index]['controls'][controlName];
            if (rateControl.value >= 100) {
                rateControl.setValue(100);
            }
        }
        this.addAprAndYearRates(event);
    };
    OptionTemplateAddEditComponent.prototype.onFocusOutEvent = function (event, controlName, index) {
        if (controlName === void 0) { controlName = null; }
        if (index === void 0) { index = null; }
        var ratesFormArray = this.templateForm.get('yearRates');
        var rateControl = ratesFormArray.controls[index]['controls'][controlName];
        rateControl.setValue(parseFloat(rateControl.value).toFixed(2));
    };
    OptionTemplateAddEditComponent.prototype.omitSpecialChar = function (event, value) {
        if (value.length >= 4 && value.includes('.')) {
            if (value.indexOf('.') < 2) {
                return false;
            }
        }
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode == 46)
            return true;
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    };
    OptionTemplateAddEditComponent.prototype.addRepresentative = function (event) {
        if (event.target.checked) {
            this.showInput = true;
        }
        else {
            this.showInput = false;
        }
    };
    OptionTemplateAddEditComponent.prototype.checkInput = function () {
        var terms = document.getElementById('terms');
        var apr = document.getElementById('apr');
        var deposit = document.getElementById('deposit');
        var termsValue = terms ? terms.value : '';
        var aprValue = apr ? apr.value : '';
        var depositValue = deposit ? deposit.value : '';
        if (this.showInput && termsValue && aprValue && depositValue) {
            this.valid = true;
        }
        else {
            this.valid = false;
        }
        this.representativeDetails = {
            apr: aprValue,
            deposit: depositValue,
            representativeDetails: true,
            terms: termsValue
        };
        this.disableMonths();
    };
    OptionTemplateAddEditComponent.prototype.checkTerms = function (event, type) {
        var apr;
        if (type == 'event') {
            apr = event.target.value;
        }
        else {
            apr = event;
        }
        this.terms = [];
        for (var index = 0; index < this.templateForm.controls.yearRates.length; index++) {
            if (apr == this.templateForm.controls.yearRates.value[index]['rateByCustomer'] && !this.templateForm.value.yearRates[index]['disableTerms'] && (this.templateForm.value.yearRates[index]['months'] != '' && this.templateForm.value.yearRates[index]['months'] != ' '))
                this.terms.push(this.templateForm.controls.yearRates.value[index]['months']);
        }
    };
    OptionTemplateAddEditComponent.prototype.calculateMultipliers = function (index) {
        var data = this.templateForm.get('yearRates').value;
        var rate = this.templateForm.get('yearRates').value[index]['rateByCustomer'];
        var months = this.templateForm.get('yearRates').value[index]['months'];
        var r = parseFloat((parseFloat(rate) / 12 / 100).toFixed(5));
        var value = parseFloat((Math.pow((1 + r), months)).toFixed(5));
        var multipliers = (r * value / (value - 1)).toFixed(5);
        if (rate) {
            this.templateForm.get('yearRates').controls[index].controls.multipliers.setValue(multipliers ? multipliers : 0);
        }
        if (index == 0) {
            if (months && rate == 0) {
                this.disable = true;
            }
            else {
                this.disable = false;
            }
        }
    };
    OptionTemplateAddEditComponent.prototype.financeSave = function () {
        var _this = this;
        this.financeDataSaved = false;
        this.financeAction = 'saving';
        var data = this.templateForm.value;
        var lastIndex = data['yearRates'].length - 1;
        if (lastIndex > 0 && (data['yearRates'][lastIndex]['months'] == ' ' || data['yearRates'][lastIndex]['months'] == '')) {
            data['yearRates'].splice(lastIndex, 1);
        }
        data['deposits'] = JSON.stringify(data['deposits']);
        data['yearRates'] = JSON.stringify(data['yearRates']);
        data['integrated'] = this.integrated;
        if (this.showInput) {
            var terms = document.getElementById('terms').value;
            var apr = document.getElementById('apr').value;
            var deposit = document.getElementById('deposit').value;
            var representativeDetails = {
                'representativeDetails': true,
                'terms': terms,
                'apr': apr,
                'deposit': deposit
            };
            var removeEmptyValues = [];
            var convertDataIntoArray = JSON.parse(data['yearRates']);
            if (convertDataIntoArray && convertDataIntoArray.length) {
                for (var i = 0; i < convertDataIntoArray.length; i++) {
                    var trimEmptyValues = typeof convertDataIntoArray[i].months == 'string' ? convertDataIntoArray[i].months.trim() : convertDataIntoArray[i].months;
                    if (trimEmptyValues != '') {
                        removeEmptyValues.push(convertDataIntoArray[i]);
                    }
                }
            }
            removeEmptyValues.push(representativeDetails);
            data['yearRates'] = JSON.stringify(removeEmptyValues);
            // data['yearRates'].push(representativeDetails);
            // data['yearRates'] = JSON.stringify(data['yearRates']);
        }
        this.propasalTemplateService.addOrEditFinance(data)
            .subscribe(function (resp) {
            if (resp) {
                _this.dataSaved = true;
                _this.onClose.next(true);
                _this.bsModalRef.hide();
                _this.financeAction = 'save';
            }
        }, function (error) {
            console.log('err', error);
        });
    };
    OptionTemplateAddEditComponent.prototype.removeTerms = function (event, index) {
        if (event.target.checked) {
            this.templateForm.get('yearRates').controls[index].value['disableTerms'] = false;
        }
        else {
            this.templateForm.get('yearRates').controls[index].value['disableTerms'] = true;
        }
        this.checkTerms(this.templateForm.get('yearRates').controls[index].value['rateByCustomer'], 'second');
    };
    OptionTemplateAddEditComponent.prototype.disableMonths = function () {
        var data = [];
        for (var index = 0; index < this.templateForm.value.yearRates.length; index++) {
            if (this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['rateByCustomer'] >= 0 && this.templateForm.value.yearRates[index]['months'] != '' && this.templateForm.value.yearRates[index]['months'] != ' ') {
                var position = data.indexOf(this.templateForm.value.yearRates[index]['rateByCustomer']);
                if (position < 0) {
                    data.push(this.templateForm.value.yearRates[index]['rateByCustomer']);
                }
            }
        }
        for (var index = 0; index < this.templateForm.value.yearRates.length; index++) {
            if (this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['rateByCustomer'] >= 0 && this.templateForm.value.yearRates[index]['months'] != ' ') {
                if (this.templateForm.value.yearRates[index]['rateByCustomer'] && this.templateForm.value.yearRates[index]['archived'] != 1) {
                    this.templateForm.value.yearRates[index]['archived'] = 0;
                }
            }
        }
        var obj = [];
        var value = {};
        for (var i = 0; i < data.length; i++) {
            for (var index = 0; index < this.templateForm.value.yearRates.length; index++) {
                if (this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['rateByCustomer'] >= 0 && this.templateForm.value.yearRates[index]['months'] != ' ') {
                    if (data[i] == this.templateForm.value.yearRates[index]['rateByCustomer']) {
                        obj.push(this.templateForm.value.yearRates[index]['months']);
                    }
                }
            }
            value[data[i]] = obj;
            obj = [];
        }
        for (var data_1 in value) {
            var selectedMonths = Math.max.apply(Math, value[data_1]);
            for (var index = 0; index < this.templateForm.value.yearRates.length; index++) {
                if (this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['months'] == selectedMonths) {
                    if ((data_1 == this.templateForm.value.yearRates[index]['rateByCustomer']) && !this.templateForm.value.yearRates[index]['archived']) {
                        this.templateForm.value.yearRates[index]['archived'] = 2;
                    }
                }
            }
        }
        if (this.representativeDetails) {
            for (var index = 0; index < this.templateForm.value.yearRates.length; index++) {
                if (this.templateForm.value.yearRates[index].hasOwnProperty('rateByCustomer') && this.templateForm.value.yearRates[index]['months'] == this.representativeDetails.terms && this.templateForm.value.yearRates[index]['rateByCustomer'] == parseFloat(this.representativeDetails.apr).toFixed(2)) {
                    this.templateForm.value.yearRates[index]['archived'] = 2;
                }
            }
        }
    };
    OptionTemplateAddEditComponent.prototype.changeValue = function (index) {
        var value = parseFloat(this.templateForm.value.yearRates[index]['rateByCustomer']).toFixed(2);
        this.templateForm.get('yearRates').controls[index].controls.rateByCustomer.setValue(value);
        if (parseInt(value) == 0) {
            this.templateForm.get('yearRates').controls[index].controls.multipliers.setValue(0);
        }
    };
    OptionTemplateAddEditComponent.prototype.upgradeFreemium = function () {
        var data = {};
        if (this.screenType != 'addon_template') {
            if (this.screenType == 'option_template') {
                data = {
                    screenType: this.screenType,
                    id: this.optionId,
                    addon: false
                };
            }
            else if (this.screenType == 'proposal_templates') {
                data = {
                    screenType: this.screenType,
                    id: this.tablePkId,
                    addon: false
                };
            }
            else if (this.screenType == 'opportunity') {
                data = {
                    screenType: this.screenType,
                    id: this.tablePkId,
                    addon: false,
                    opportunityId: this.opportunityId
                };
            }
            this.proposaltemplateservice.upgradeDowngradeFreemiumBlocks(data).subscribe(function (res) {
            });
        }
    };
    return OptionTemplateAddEditComponent;
}());
export { OptionTemplateAddEditComponent };
