<ng-container *ngIf="open && !showCropper">
	<!-- Title -->
	<div class="modal-header" *ngIf="!showCropper">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
        <h4 class="modal-title pull-left" *ngIf="previewImage">Preview</h4>
	</div>
	<!-- Image section -->
	<div class="modal-body image-block-height">
		<ng-container *ngIf="option == 'add' || option == 'edit' || option == 'pricingImage' || option == 'previewImage'">
			<div class="help_section" *ngIf="!previewImage">
				<div class="help-text" translate>
					{{ helpText }}
				</div>
			</div>
			<div class="body-content-section">
				<!-- Drop or select image -->
				<div class="image-select-option">
                    <div *ngIf="option=='add'">
						<ng-container *ngIf="selectedTemplate != 'opportunity' && customTagAccess ">
							<div>How should the image be selected?</div>
							<div class="row-fluid option-radio-btn">
								<div class="option-section">
									<div class="control-group clearfix completedby-section">
										<div class="sales-person-button">
											<label class="cursor-pointer">
												<input class="radio-btn-style background-radio" type="radio"
													   value='uploadImage' name="option" [(ngModel)]="selectedOption"
													   (change)="onOptionChange('uploadImage')" checked/>
												<span translate>Upload/select image now</span>
											</label>
										</div>
										<div class="survey-button" *ngIf="selectedTemplate != 'contentLibrary'">
											<label class="cursor-pointer">
												<input class="radio-btn-style background-radio"
													   (change)="onOptionChange('survey')" name="option"
													   [(ngModel)]="selectedOption" type="radio" value="survey">
												<span translate>Link image to a survey</span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</ng-container>

                        <div *ngIf="selectedOption == 'survey'">
                            <label> Templates </label>
							<select #select2Template [select2Config]="{width: '100%','margin-bottom': '16px'}" tabindex="-1" id="templateId" csSelect2 class="input-styles" [(ngModel)]="selectedTemplateId"
									(selected)="onTemplateChange(selectedTemplateId)">
                                <option [ngValue]="null" disabled>--Please select--</option>
                                <option *ngFor="let template of surveyQuestionsData"
                                        [value]="template.id">{{template.description}}</option>
                            </select>
                            <div *ngIf="questionSelected && questionOptions.length > 0">
                                <label>Survey questions</label>
								<select #select2Question [select2Config]="{width: '100%','margin-bottom': '16px'}" tabindex="-1" id="questionId" csSelect2 class="input-styles" (selected)="onQuestionSelect(selectedQuestionId)"
                                        [(ngModel)]="selectedQuestionId">
                                    <option [ngValue]="null" disabled>--Please select--</option>
									<option [title]="template.questionLabel" *ngFor="let template of questionOptions; let i = index"
											[value]="template.questionId">{{template.questionLabel}}
									</option>
                                </select>
                            </div>
                        </div>
                    </div>
<!--
					<div class="upload-option-bg crop-edit" *ngIf="option=='edit'">
						<div (onFileDrop)="uploadFile($event)" ng2FileDrop [uploader]="uploader">
							<div class="upload-option" (click)="fileInput.click()">
								<p class="upload-option-text" translate>Settings.upload.option.help.text</p>
								<input #fileInput type="file" (change)="selectFile($event)" style="display:none;" />
							</div>
						</div>
						<span *ngIf="errorMsg != ''" class="control-error-message show text-center">
							<span translate>{{errorMsg}}</span>
						</span>
                        <ng-container *ngIf="editImageLoading">
                            <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                            <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                            <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                        </ng-container>
						<ng-container *ngIf="!editImageLoading">
                            <image-cropper class="imgae-loaded sec-img-section" width="50%" [maintainAspectRatio]="false"
                                           [aspectRatio]="4 / 3" [imageURL]="imgURL" (imageCropped)="imageCropped($event)">
                            </image-cropper>
                        </ng-container>
					</div>-->
				</div>
			</div>
		</ng-container>
        <ng-container *ngIf="previewImage">
            <div>
                <img class="image-show" src="{{imgLocation}}">
            </div>
        </ng-container>
		<ng-container *ngIf="(option == 'pricingImage' || option=='edit' || selectedOption == 'uploadImage') && !showCropper && option != 'previewImage'">
            <div class="image-title-section">
                <span class = "parts-specifications required_field" translate>Image profile </span>
            </div>
            <div  class="image-preview-section" *ngIf="croppedImage && !uploading && imgURLs.length == 1">
                <div class="cropper-btn preview-btn" (click)="showCrop(true)"><img  placement = "bottom" tooltip="Crop image" src="angularES/src/assets/images/self_service_portal_assets/crop_option.svg" /></div>
                <div class="cropper-btn" (click)="enableImagePreview()"><img class="preview-btn-image" tooltip = "Preview image" placement = "bottom" src="angularES/src/assets/images/btn-preview.png"/></div>
                <div class="close-btn" (click)="removeSelectedImage()"><img  tooltip ="Remove image" placement = "bottom" src="angularES/src/assets/images/self_service_portal_assets/image_delete.svg" /></div>
                <img #imageElement src="{{croppedImage}}" class="branding-color-img" alt="branding-colour-logo"  [ngClass]="(croppedImagePreview== true)?'cropper-full-image':''">
            </div>
            <div class="pp-bakground-img pp-background-upload-section section-style"   id="drop-zone" (onFileDrop)="uploadFile($event)" ng2FileDrop [uploader]="uploader" [ngClass]="{'images-uploaded': ((croppedImage == '' || multipleImageSelected) && (!uploading || imgURLs.length > 1 )) }" >
               <div class="pp-upload-section-center before-upload-mt-6 ano">
                   <img class="pp-noImage-size" src="images/upload-dark-icon.png">
                   <div class="pp-background-image" >
                       <span>Drag and drop a file here</span>
                       <p class="upload-selection-text-color" (click)="fileInput.click()">Browse file</p>
                       <input #fileInput type="file" (change)="uploadFile($event)" style="display:none;" accept=".jpg,.png,.jpeg,.gif,.bmp,.svg" [attr.multiple]="option == 'pricingImage' ? currentImageCount : null" />
                   </div>
               </div>
                <div class="upload-popover display-none" [style.top.px]="dropZoneTopHeight">
                    <div class="image-upload text-center align-items-center">
                        <div class="image-drag-zone-section">
                            <img class="pp-noImage-size1" src="images/noImage.png">
                        </div>
                        <p class="drag-drop-text">Drop your files here</p>
                    </div>
                </div>
            </div>
            <div class="pp-error-message-section" *ngIf="fileErrorMsg">
                    <div class="pp-error-msg">
                        <span translate>upload.error.text</span>
                    </div>
            </div>
            <div class="pp-error-message-section" *ngIf="uploadErrorMsg">
                <div class="pp-error-msg">
                    <span>You cannot upload more than {{maximumCount}} images.</span>
                </div>
            </div>
            <div class="pp-loading-crop-error-section" *ngIf="uploading && !fileErrorMsg" >
                <ng-container>
                    <div class="pp-bakground-img pp-background-upload-section">
                        <div class="pp-upload-section-center">
                            <div class="circle-wrap">
                                <div class="circle">
                                    <div class="mask full" id="pploadinganimation3">
                                        <div class="fill"></div>
                                    </div>
                                    <div class="mask half">
                                        <div class="fill" id="pploadinganimation4"></div>
                                    </div>
                                    <div class="inside-circle"> </div>
                                </div>
                            </div>
                            <div class="pp-background-image">
                                <span>Uploading...</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="search-section"  *ngIf = "(!isCustomerImagesEmpty || partsImageList.length || categoryImageList.length)">
                <i class="fas fa-search"></i>
                <input class="search" type="search" placeholder="Search" [(ngModel)]="searchText" (input)="searchFiles()">
            </div>
                <ng-container *ngIf="editImageLoading && !backgroundImage">
                    <div class="part-image-list-loading">
                        <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                        <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                        <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>
                    </div>
                </ng-container>
            <!--<div class="part-image-list-loading search-text-style" *ngIf = "isCustomerImagesEmpty && !partsImageList.length && !categoryImageList.length && !editImageLoading">
                <div translate>No.image.available</div>
            </div>-->
            <div class="part-image-list-loading search-text-style" *ngIf = "( !isCustomerImagesEmpty || partsImageList?.length || categoryImageList?.length ) && searchText !=' ' && !filteredCustomerFiles.length && !filteredPartList.length && !filteredActivityFiles.length &&  !filteredSurveyFiles.length && !filteredTemplateFiles.length && !filteredCategoryImageList.length">
                <div translate>Image.not.found</div>
            </div>
            <ng-container *ngIf="!editImageLoading">
                    <div class="part-image-list-loading" *ngIf="partsImageList && filteredPartList.length">
                        <div class="image-section-para option-border-blue attached-file-padding">
                           <span class = "parts-specification" translate>Images.associated.to.parts</span>
                         </div>
                        <div class="grid-container">
                            <ng-container *ngFor="let part of filteredPartList; let i = index;">
                                <div class="image-block" [ngClass]="{'selected-image': isSelected(part.location)}" (click)="selectImage($event, part.location)">
                                    <img src="{{ part.location }}" class="image" />
                                    <div class="list-image">
                                        <img class="image-icon" src="images/file-image.png">
                                        <div class="part-name-text" container="body"
                                             *ngIf="imageNameParsing(part.name, 'part').length > 25"
                                             [tooltip]="imageNameParsing(part.name, 'part')" containerClass="tooltip-image-sidepanel"
                                             placement="top">
                                            {{ imageNameParsing(part.name, 'part') | slice : 0 : 25 }}
                                            ...
                                        </div>
                                        <div class="part-name-text"
                                             *ngIf="imageNameParsing(part.name, 'part').length <= 25">
                                            {{ imageNameParsing(part.name, 'part') }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                <div class="part-image-list-loading" *ngIf="categoryImageList && filteredCategoryImageList.length">
                    <div class="image-section-para option-border-blue attached-file-padding">
                        <span class = "parts-specification" translate>Images associated with part categories / subcategories</span>
                    </div>
                    <div class="grid-container">
                        <ng-container *ngFor="let part of filteredCategoryImageList; let i = index;">
                            <div class="image-block" [ngClass]="{'selected-image': isSelected(part.location)}" (click)="selectImage($event, part.location)">
                                <img src="{{ part.location }}" class="image" />
                                <div class="list-image">
                                    <img class="image-icon" src="images/file-image.png">
                                    <div class="part-name-text" container="body"
                                         *ngIf="imageNameParsing(part.name, 'part').length > 25"
                                         [tooltip]="imageNameParsing(part.name, 'part')" containerClass="tooltip-image-sidepanel"
                                         placement="top">
                                        {{ imageNameParsing(part.name, 'part') | slice : 0 : 25 }}
                                        ...
                                    </div>
                                    <div class="part-name-text"
                                         *ngIf="imageNameParsing(part.name, 'part').length <= 25">
                                        {{ imageNameParsing(part.name, 'part') }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="template-images-block" *ngIf="selectedTemplate=='opportunity'">
                    <div *ngIf="customerImages">
                        <div class="content-image-block content-image-block-selected activity-files survey-files-padding" *ngIf="filteredSurveyFiles.length">
                            <div class="image-section-para option-border-blue attached-file-padding">
                                <span class = "parts-specification" translate>Photos.taken.as.a.part.of.survey</span>
                            </div>
                            <div class="grid-container">
                                <ng-container *ngFor="let img of filteredSurveyFiles; let i = index;">
                                    <div class="image-block" [ngClass]="{'selected-image': isSelected(filteredSurveyFiles[i]?.location)}" (click)="selectImage($event, filteredSurveyFiles[i]?.location )">
                                        <img src="{{ filteredSurveyFiles[i]?.location }}" class="image"/>
                                        <div class="list-image">
                                            <img class="image-icon" src="images/file-image.png">
                                            <!-- here we are not parsing name in frontend we are already getting parsed name -->
                                            <div class="part-name-text" container="body"
                                                 *ngIf="filteredSurveyFiles[i]?.name.length > 25"
                                                 [tooltip]="filteredSurveyFiles[i]?.name" containerClass="tooltip-image-sidepanel"
                                                 placement="top">
                                                {{ filteredSurveyFiles[i]?.name.length | slice : 0 : 25 }}...
                                            </div>
                                            <div class="part-name-text"
                                                 *ngIf="filteredSurveyFiles[i]?.name.length <= 25">
                                                {{ filteredSurveyFiles[i]?.name.length}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="content-image-block content-image-block-selected activity-files survey-files-padding" *ngIf="filteredActivityFiles.length">
                                <div class="image-section-para option-border-blue attached-file-padding">
                                    <span class = "parts-specification" translate>Images.attached.to.opportunity</span>
                                </div>
                                <div class="grid-container">
                                    <ng-container *ngFor="let img of filteredActivityFiles; let i = index;">
                                        <div class="image-block" [ngClass]="{'selected-image': isSelected(filteredActivityFiles[i]?.location)}" (click)="selectImage($event, filteredActivityFiles[i]?.location )">
                                            <img src="{{ filteredActivityFiles[i]?.location }}" class="image"/>
                                            <div class="list-image">
                                                <img class="image-icon" src="images/file-image.png">
                                                <div class="part-name-text" container="body"
                                                     *ngIf="imageNameParsing(filteredActivityFiles[i]?.name, 'customer').length > 25"
                                                     [tooltip]="imageNameParsing(filteredActivityFiles[i]?.name, 'customer')"
                                                     placement="top" containerClass="tooltip-image-sidepanel">
                                                    {{imageNameParsing(filteredActivityFiles[i]?.name, 'customer') | slice : 0 : 25 }}...
                                                </div>
                                                <div class="part-name-text" *ngIf="imageNameParsing(filteredActivityFiles[i]?.name, 'customer').length <= 25">
                                                    {{imageNameParsing(filteredActivityFiles[i]?.name, 'customer')}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                        </div>
                        <div class="content-image-block content-image-block-selected activity-files survey-files-padding" *ngIf="filteredCustomerFiles.length">
                            <div class="image-section-para option-border-blue attached-file-padding">
                                <span class = "parts-specification" translate>Images.added.by.customer</span>
                            </div>
                            <div class="grid-container">
                                <ng-container *ngFor="let img of filteredCustomerFiles; let i = index;">
                                    <div class="image-block" [ngClass]="{'selected-image': isSelected(filteredCustomerFiles[i]?.location)}" (click)="selectImage($event, filteredCustomerFiles[i]?.location )">
                                        <img src="{{ filteredCustomerFiles[i]?.location }}" class="image"/>
                                        <div class="list-image">
                                            <img class="image-icon" src="images/file-image.png">
                                            <div class="part-name-text" container="body"
                                                 *ngIf="imageNameParsing(filteredCustomerFiles[i]?.name, 'customer').length > 25"
                                                 [tooltip]="imageNameParsing(filteredCustomerFiles[i]?.name, 'customer')" containerClass="tooltip-image-sidepanel"
                                                 placement="top">
                                                {{ imageNameParsing(filteredCustomerFiles[i]?.name, 'customer') | slice : 0 : 25 }}
                                                ...
                                            </div>
                                            <div class="part-name-text"
                                                 *ngIf="imageNameParsing(filteredCustomerFiles[i]?.name, 'customer').length <= 25">
                                                {{ imageNameParsing(filteredCustomerFiles[i]?.name, 'customer') }}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="content-image-block content-image-block-selected  survey-files-padding" *ngIf="filteredTemplateFiles.length">
                                      <div class="image-section-para option-border-blue attached-file-padding">
                                          <span class = "parts-specification" translate>Images.attached.to.the.template</span>
                                      </div>
                                      <div class="grid-container">
                                          <ng-container *ngFor="let img of filteredTemplateFiles; let i = index;">
                                              <div class="image-block" [ngClass]="{'selected-image': isSelected(filteredTemplateFiles[i]?.location)}" (click)="selectImage($event, filteredTemplateFiles[i]?.location )">
                                                  <img src="{{ filteredTemplateFiles[i]?.location }}" class="image"/>
                                                  <div class="list-image">
                                                      <img class="image-icon" src="images/file-image.png">
                                                      <div class="part-name-text" container="body"
                                                           *ngIf="imageNameParsing(filteredTemplateFiles[i]?.name, 'customer').length > 25"
                                                           [tooltip]="imageNameParsing(filteredTemplateFiles[i]?.name, 'customer')" containerClass="tooltip-image-sidepanel"
                                                           placement="top">
                                                          {{ imageNameParsing(filteredTemplateFiles[i]?.name, 'customer') | slice : 0 : 25 }}
                                                          ...
                                                      </div>
                                                      <div class="part-name-text"
                                                           *ngIf="imageNameParsing(filteredTemplateFiles[i]?.name, 'customer').length <= 25">
                                                          {{ imageNameParsing(filteredTemplateFiles[i]?.name, 'customer') }}
                                                      </div>
                                                  </div>
                                              </div>
                                          </ng-container>
                                      </div>
                                  </div>
                              </div>
                      </div>
            </ng-container>
		</ng-container>
		<ng-container *ngIf="option=='preview'">
			<div class="divSlide">
				<div class="grid-container" style="display: flex; flex-wrap: wrap">
					<img src={{croppedImage}} class="addPartSildeImages">
				</div>
			</div>
		</ng-container>
	</div>
	<!-- Footer -->
	<div class="modal-footer modal-footer-z-index">
        <span class="pricing-image-count" *ngIf="option == 'pricingImage' && selectedImages.length > 0">{{selectedImages.length + imagesCount}}/ 3 images will be added</span>
        <span class="pricing-image-count" *ngIf="option == 'pricingImage' && selectedImages.length == 0"> {{selectedImages.length}} images will be added</span>
		<a href="javascript:void(0)" class="btn" (click)="cancel()"><span *ngIf="previewImage && !isUploadPdfPage">Close</span><span *ngIf="!previewImage" id="cancel-styles">Cancel</span></a>
		<a href="javascript:void(0)" class="btn" *ngIf="option=='preview'" (click)="backTo()">Back</a>
		<button class="btn btn-primary" *ngIf="!previewImage && selectedOption == 'uploadImage'" [disabled]="(option == 'edit' || option == 'fitToBlock') ? disabled == true : (option == 'pageBackground' ? (disabled == true && !croppedImage && imageblock) : (disabled == true || !croppedImage || uploading))" (click)="addImageBlock()">
			<span translate>Save</span>
		</button>
		<button class="btn btn-primary" *ngIf="!previewImage && selectedOption == 'survey'" [disabled]="(disabled == true && !imgURL && imageblock) || (!selectedQuestionId)" (click)="addSurveyImage()">
            <span translate>Save</span>
        </button>
	</div>
</ng-container>


<ng-container *ngIf="open && showCropper">
    <div class="modal-header" *ngIf="showCropper">
        <h4 class="modal-title pull-left" translate>Crop.image.title</h4>
    </div>
    <div class="modal-body image-block-height">
        <div class="help_section">
            <div class="help-text" translate>Crop.image.help.text</div>
        </div>
        <div class="body-content-section">

            <ng-container *ngIf="(imgURL||croppedImage) && showCropper">
                <img src="{{croppedImage}}" *ngIf="previewImageCropper" class="image-cropper-preview" alt="cropped image">
                <div class="image-cropper-container">
                    <image-cropper *ngIf="!previewImageCropper" class="image-cropper-container"
                     [maintainAspectRatio]="maintainAspectRatio" style="--cropper-outline-color: rgba(255, 255, 255, 0.3)" [aspectRatio]="cropperAspectRatio" [cropper]="cropper"
                    [imageURL]="imgURL" (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()" (imageLoaded)="imageLoaded()">
                </image-cropper>
                </div>
                <div class="divSlide" *ngIf="showCropper && showCropperLoading">
                    <div class="grid-container loading-grid" style="display: flex; flex-wrap: wrap">
                        <ng-container>
                            <div class="img-container animated-background">
                                <img src="images/noImage.png" class="image-blocks addPartSildeImages" />
                                <div style="text-align: center">
                                    <label class="part-overflow"></label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="image-config-layout">
                    <div class = "image-config-content">
                        <div class="image-section-para"><span class="sub-title-text">Settings</span></div>
                        <div>
                            <span>Crop style</span>
                            <div class="aspect-ratio-type">
                                <select name="crop-style" class="aspect-ratio-dropdown" id="crop-style" [(ngModel)]="selectedAspectRatio" (change)="updateAspectRatio(selectedAspectRatio)">
                                    <option *ngFor="let aspectRatio of aspectRatioOptions" [value]="aspectRatio.value">{{ aspectRatio.label }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="image-property-style">
                            <div class="image-dimension-size">
                                <span translate>Width</span>
                                <div class="size-alignment">
                                     <input  id="width" [ngModel]="adjustedWidth"  (blur) = "onBlur('width')" (keydown)="validateWidth($event)" (wheel)="preventWheel($event)"  max="{{defaultWidth}}" min="50" type="number" class="size-text-box common-border-color" maxlength="5">
                                    <span class="text-box-px">px</span>
                                </div>
                            </div>
                            <div class="image-dimension-size">
                                <span translate>Height</span>
                                <div class="size-alignment">
                                     <input [ngModel]="adjustedHeight" (blur)="onBlur('height')" (keydown)="validateHeight($event)" (wheel)="preventWheel($event)" type="number" max="{{maxheight}}" min="50" class="size-text-box common-border-color" maxlength="5">
                                    <span class="text-box-px">px</span>
                                </div>
                            </div>
                        </div>
                        <div class="lock-input-style">
                            <label for="lock-aspect-ratio">
                                <input type="checkbox" class ="lock-aspect-ratio"   id="lock-aspect-ratio" [(ngModel)]="lockAspectRatio" (change)="onLockAspectRatioChange()">
                                Lock aspect ratio
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer modal-footer-z-index">
        <a href="javascript:void(0)" class="btn" (click)="cancelCropper()"><span id="cancel-styles">Cancel</span></a>
        <button class="btn btn-primary"  (click)="closeCropper()">
            <span translate>Done</span>
        </button>
    </div>
</ng-container>
